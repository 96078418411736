<div>
    <div id="add_head">
        <div class="p-0 m-0">
            <button (click)="AddNewCurrency()" class="btn_blue_bg float-right m-0" data-toggle="tooltip"
                title="add new currency">
                <!-- <span class="fa fa-plus btn_plus_span"></span> -->
                <!-- <img src="../../assets/+.png"/> -->
                <img class='ic_plus_white' src="../../assets/+.png" />
            </button>
        </div>
    </div>

    <div id="list">
        <ul>
            <li class="row col-12">
                <span class="col-6 cust_title">Name</span>
                <span class="col-6 cust_title">Code</span>
            </li>
            <li class="row col-12" *ngFor="let currency of currencies">
                <span class="col-6">{{currency.name}}</span>
                <span class="col-6">{{currency.code}}</span>
            </li>
        </ul>
    </div>

</div>

<!--- NEW CURRENCY POPUP --->
<div class="modal fade" bsModal #AddModal="bs-modal" tabindex="-1" id=""
    [config]="{backdrop: 'static', keyboard:false}">
    <div class="modal-dialog">
        <div class="modal-content p-10">
            <div class="modal-header">
                <label style="font-size: 16px;font-weight: bold;display: block;">Add New Currency</label>
                <div (click)="AddModal.hide()"><span class="fa fa-close sp_popup_close"></span></div>
            </div>
            <div class="modal-body">
                <div>
                    <form (ngSubmit)="onSubmit()" id="currency-form" [formGroup]="currencyForm" role="form"
                        #formDir="ngForm" novalidate class="ng-untouched ng-pristine ng-invalid">
                        <div class="row">
                            <div class="col-6 input-group">
                                <span class="border-lable-flt col-12 p-0">
                                    <input type="text" name="name" formControlName="name" class="form-control"
                                        placeholder=" " [ngClass]="{ 'is-invalid': formDir.submitted && name.invalid }"
                                        required>
                                    <label for="label-name">Name<span class="spn_required"> *</span></label>
                                </span>
                                <div class="invalid-feedback" *ngIf="formDir.submitted && name.invalid">
                                    <p *ngIf="name.errors.required">Name is required</p>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-15">
                            <div class="col-6 input-group">
                                <span class="border-lable-flt col-12 p-0">
                                    <input type="text" name="name" formControlName="code" class="form-control"
                                        placeholder=" " [ngClass]="{ 'is-invalid': formDir.submitted && code.invalid }"
                                        required>
                                    <label for="label-name">Code<span class="spn_required"> *</span></label>
                                </span>
                                <div class="invalid-feedback" *ngIf="formDir.submitted && code.invalid">
                                    <p *ngIf="code.errors.required">Code is required</p>
                                </div>
                            </div>
                        </div>
                        <div class="row m-t-15 justify-content-center">
                            <button class="btn_blue_bg" type="submit">Save</button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal-background"></div>