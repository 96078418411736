import { HttpRequestService } from './../../servcies/http-request-service';
import { LocalStorageService } from './../../servcies/localstorage-service';
import { Utilities } from './../../shared/utilities';
import { UserViewModel } from './../../models/vw.user';
import { Group, Unicode, Permissions } from './../../shared/Endpoint';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Endpoint } from 'src/shared/Endpoint';
import { NavigationComponent } from '../navigation/navigation.component';
declare var google: any;

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  auth2: any;
  fromEmail = '0';
  orderId;
  returnUrl = '';

  constructor(
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute,
    private nav: NavigationComponent,
    private httpRequestService: HttpRequestService
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.queryParams.subscribe((params) => {
      var from_email = params['fromEmail'];
      this.returnUrl = params['returnUrl'];
      if (from_email) {
        this.fromEmail = JSON.parse(from_email);
        this.orderId = JSON.parse(params['orderId']);
      } else this.fromEmail = '0';
    });
  }

  ngAfterViewInit(): void {
    let handleCredentialResponse = async (response: any) => {
      const data = { idToken: response.credential, oauth: 'v3' };
      var responsePayload: any = atob(response.credential.split('.')[1]);
      responsePayload = JSON.parse(responsePayload);
      this.zone.run(async () => {
        await this.afterLoginProcess(
          responsePayload.email,
          responsePayload.picture
        );
      });
    };

    let id = 'google-client-script';
    let script = document.getElementById(id);
    if (script === null) {
      let crscript = document.createElement('script');
      crscript.setAttribute('src', 'https://accounts.google.com/gsi/client');
      crscript.setAttribute('id', id);
      crscript.setAttribute('async', '');
      document.body.appendChild(crscript);
      crscript.onload = () => {
        google.accounts.id.initialize({
          client_id: Endpoint.CLIENT_ID,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(document.getElementById('customBtn'), {
          theme: 'filled_blue',
          size: 'large',
          type: 'button',
          text: 'Sign in with Google',
          locale: 'english',
        });
        //  google.accounts.id.prompt();
      };
    } else {
      google.accounts.id.initialize({
        client_id: Endpoint.CLIENT_ID,
        callback: handleCredentialResponse,
      });
      google.accounts.id.renderButton(document.getElementById('customBtn'), {
        theme: 'filled_blue',
        size: 'large',
        type: 'button',
        text: 'Sign in with Google',
        locale: 'english',
      });
      //  google.accounts.id.prompt();
    }
  }

  async afterLoginProcess(email: string, imageUrl: string) {
    let that = this;
    var prs = new HttpParams().set(Unicode.EMAIL, email);
    var resp = await that.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.LOGIN_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && resp.msg) {
      this.localStorage.logout();
      alert(resp.msg);
    } else {
      var user: UserViewModel = resp[0];
      var groups = [];
      var permissions = [];
      resp.forEach((row: UserViewModel) => {
        if (!Utilities.isNullOrEmpty(row.group_rfid)) {
          if (!groups.includes((x) => x.id == row.group_rfid))
            groups.push({ id: row.group_rfid, name: row.group_name });
        }
        if (!Utilities.isNullOrEmpty(row.permission_rfid)) {
          if (!permissions.includes((x) => x.id == row.permission_rfid))
            permissions.push({
              id: row.permission_rfid,
              name: row.permission_name,
              code: row.permission_code,
            });
        }
      });
      user.groups = groups;
      user.permissions = permissions;
      //perm
      if (user.groups.find((x) => x.name == Group.SUSPENSION)) {
        user.isSuspenser = true;
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = false;
      } else if (
        user.groups.length == 1 &&
        user.groups.find((x) => x.name == Group.GPM)
      ) {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = false;
        user.isGPM = true;
      }
      //  else user.isJustSale = false;
      else if (
        user.permissions.find(
          (x) =>
            x.code && x.code.indexOf(Permissions.sales_manager_approve2) >= 0
        )
      ) {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = true;
      } else if (
        user.permissions.find(
          (x) =>
            x.code && x.code.indexOf(Permissions.sales_manager_approve1) >= 0
        )
      ) {
        user.isJustSale = false;
        user.approve1 = true;
        user.approve2 = false;
      } else if (
        user.groups.length == 1 &&
        user.groups.find((x) => x.name == Group.Sale)
      ) {
        user.isJustSale = true;
        user.approve1 = false;
        user.approve2 = false;
      } else {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = false;
      }

      //
      user.img = imageUrl;

      //MENUS
      var menus = [];
      var prs = new HttpParams().set(
        Unicode.GROUP_ID,
        user.groups.map((x) => x.id).join(';')
      );
      var resp_menu = await that.http
        .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.MENU_FILE_NAME, {
          params: prs,
        })
        .toPromise();
      if (resp_menu && !resp_menu.success && !resp_menu.msg) {
        menus = resp_menu;
        menus.forEach((menu) => {
          // menu.img_normal = '../../assets/'+menu.img_normal;
          // menu.img_hover = '../../assets/'+menu.img_hover;
          menu.img = '../../assets/' + menu.img_normal;
        });
        this.localStorage.save(Unicode.MENUS, JSON.stringify(menus));
      }
    }
    //GLOBAL DATA
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.GLOBAL_DATA_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(Unicode.COUNTRIES, JSON.stringify(resp.countries));
      this.localStorage.save(
        Unicode.GOOGLE_PRODUCTS,
        JSON.stringify(resp.products)
      );
      this.localStorage.save(Unicode.PLANS, JSON.stringify(resp.plans));
      this.localStorage.save(
        Unicode.GROUP_PERMISSIONS,
        JSON.stringify(resp.groups_permissions)
      );
      this.localStorage.save(
        Unicode.DEFAULT_CURRENCY_ID,
        resp.defaultCurrencyId ?? '1'
      );
      if (Utilities.isNullOrEmpty(resp.gpm_emails))
        this.localStorage.save(Unicode.GPM_EMAILS, JSON.stringify([]));
      else
        this.localStorage.save(
          Unicode.GPM_EMAILS,
          JSON.stringify(resp.gpm_emails.map((x) => x.email))
        );
      if (resp.groups_permissions) {
        var userGrpPermi = resp.groups_permissions.filter((x) =>
          user.groups.find((y) => y.id == x.group_id)
        );
        userGrpPermi.forEach((element) => {
          user.permissions.push({
            id: element.permission_id,
            name: element.permission_name,
            code: element.permission_code,
          });
        });
      }
      this.localStorage.registerUser(JSON.stringify(user));
      this.localStorage.save(Unicode.USERS, JSON.stringify(resp.users));
    }
    this.localStorage.save('reload', '1');
    // if (this.fromEmail == "1") {
    //   this.router.navigate(['/orders/order-details'], { queryParams: { orderId: JSON.stringify(this.orderId) } });
    // }
    // else this.router.navigate(['/dashboard']);
    this.zone.run(() => {
      if (!Utilities.isNullOrEmpty(this.returnUrl)) {
        var url = decodeURIComponent(this.returnUrl);
        this.router.navigateByUrl(url);
      } else this.router.navigate([menus[0].link]);
    });
  }
}
