<div id="orders_report_content">
    <div class="row col-12 order-header justify-content-lg-around p-0">
        <div class="rep_date_p col-lg-2 col-md-3 col-6 col-sm-6 p-0 m-t-5">
            <span (click)="fdate.toggle()" class="rep_date_p_span">
                <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input name="dp" [(ngModel)]="fromSelectedDate" ngbDatepicker placeholder='Date(from)'
                #fdate="ngbDatepicker" (ngModelChange)="selectDate()" class="my-dp">
        </div>
        <div class="rep_date_p date-p m-r-l-10 col-lg-2 col-md-3 col-6 col-sm-6 p-0 m-t-5">
            <span (click)="tdate.toggle()" class="rep_date_p_span">
                <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input name="dp" placeholder="To" [(ngModel)]="toSelectedDate" ngbDatepicker placeholder='Date(to)'
                #tdate="ngbDatepicker" (ngModelChange)="selectDate()" class="my-dp">
        </div>


        <div class="col-lg-3 col-md-2 col-8 col-sm-8 p-0 m-t-5">
            <ng-select placeholder="Select Sale" bindLabel="sale.name" appendTo="body" [searchable]="true"
                 class="col-12" [clearable]="true" style="padding: 0 !important;border:none !important;"
                (change)="selectSale($event)">
                <ng-option [value]="sale.id" *ngFor="let sale of sales">
                    {{sale.name}}
                </ng-option>
            </ng-select>
        </div>
        <div class="col-lg-2 col-md-2 col-8 col-sm-8 p-0 m-t-5">
            <ng-select placeholder="Select Status" [(ngModel)]="selectedStatus" class="col-12 p-0" [searchable]="true"
                [clearable]="true" (change)="selectStatus()" style="height: 36px;">
                <ng-option [value]="htmlStatus.Pending">{{htmlStatus.Pending}}</ng-option>
                <ng-option [value]="htmlStatus.Approved1">{{htmlStatus.Approved1}}</ng-option>
                <ng-option [value]="htmlStatus.Approved2">{{htmlStatus.Approved2}}</ng-option>
                <ng-option [value]="htmlStatus.Approved_by_GPM">{{htmlStatus.Approved_by_GPM}}</ng-option>
                <ng-option [value]="htmlStatus.Executed">{{htmlStatus.Executed}}</ng-option>
                <ng-option [value]="htmlStatus.Rejected">{{htmlStatus.Rejected}}</ng-option>
                <ng-option [value]="htmlStatus.Deleted">{{htmlStatus.Deleted}}</ng-option>
                <ng-option [value]="htmlStatus.Draft">{{htmlStatus.Draft}}</ng-option>
            </ng-select>
        </div>

        <div class="col-lg-2 col-md-2 col-8 col-sm-8" style="display: contents">
            <input
              style="width: 18px; height: auto"
              type="checkbox"
              (change)="selectNotChecked($event)"
            />
            <label class="title p-t-5" style="margin-top: 10px;">Not checked</label>
          </div>

            
        <button (click)="export()" class="btn_blue_bg col-lg-1 col-md-2 col-2 h-fit-content m-t-5">Export</button>
    </div>
    <div class="div_table m-0">
        <ngx-datatable id="order-table" class="bootstrap row" [rows]="GetData" [columnMode]="'force'"
            [reorderable]="true" [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [externalPaging]="true"
            [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)="pageChanged($event)">

            <ngx-datatable-column prop="order_id" [sortable]="true" [draggable]="true" [canAutoResize]="true" name="ID"
                headerClass="col-lg-1 col-md-1 col-3" cellClass="col-lg-1 col-md-1 col-3">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <a href="{{url}}/orders/order-details?orderId={{row.order_id}}" >{{row.order_id}}</a>
                </ng-template>
            </ngx-datatable-column>
            <!-- <ngx-datatable-column prop="po_id" [sortable]="true" [draggable]="true" [canAutoResize]="true" name="P.O"
                headerClass="col-lg-1 col-md-1 col-2" cellClass="col-lg-1 col-md-1 col-2">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.po_id}}
                </ng-template>
            </ngx-datatable-column> -->
            <!-- <ngx-datatable-column prop="order_date" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Order Date" headerClass="col-1 tbl_repo_visibility" cellClass="col-1 tbl_repo_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.order_date| date:'longDate' }}
                </ng-template>
            </ngx-datatable-column> -->
            <ngx-datatable-column prop="product_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Product" headerClass="col-2 tbl_repo_visibility" cellClass="col-2 tbl_repo_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.product_name}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="order_status" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Status" headerClass="col-lg-1 col-md-2 col-2 tbl_repo_visibility" cellClass="col-lg-1 col-md-2 col-2 tbl_repo_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.order_status}} 
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="customer_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Customer" headerClass="col-lg-3 col-md-3 col-5"
                cellClass="col-lg-3 col-md-3 col-5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.customer_domain}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="usr_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Sale" headerClass="col-lg-1 col-md-2 col-2 tbl_repo_visibility" cellClass="col-lg-1 col-md-2 col-2 tbl_repo_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.usr_name}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="action" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Action" headerClass="col-3 tbl_repo_visibility tbl_action_visibility" cellClass="col-3 tbl_repo_visibility tbl_action_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.action }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column  headerClass="col-lg-1 col-md-2 col-3" cellClass="col-lg-1 col-md-2 col-3 text-center" name="Checked">
            <ng-template let-row="row" ngx-datatable-cell-template>
               <input  *ngIf='!row.checked' type="checkbox" [(ngModel)]='row.checked' id="inp_checked" [disabled]="!canCheck" (click)="checkOrder(row.order_id,$event)"/>
               <label id="checked_order" *ngIf='row.checked'>&#10003;</label>
            </ng-template>
        </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>