import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsComponent } from '../subscriptions.component';
import { SubscriptionListComponent } from './subscription-list.component';

const routes: Routes = [
  { path: '', component: SubscriptionListComponent },
  { path: 'new-subscription', component: SubscriptionsComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionListRoutingModule { }
