import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'settings-groups',
  templateUrl: './groups.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {

  constructor() {

  }

  ngOnInit(): void {}


}
