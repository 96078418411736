import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';

@Component({
  selector: 'app-help',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  content :string = '';

  constructor(private http:HttpClient) { }

  async ngOnInit(): Promise<void> {
    var resp = await this.http.get<any>(Endpoint.GLOBAL_URL + "" + Endpoint.SETTINGS_HELP_PAGE_FILE_NAME).toPromise();
    if (resp && !resp.success && !resp.msg && resp['help']) {
       this.content = resp['help'].value;
    }
  }

}
