import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent } from './../navigation/navigation.component';
import { LayoutsComponent } from './layouts.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutsRoutingModule } from './layouts-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HelpComponent } from './help/help.component';


@NgModule({
  declarations: [LayoutsComponent, NavigationComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule,NgbModule,
    LayoutsRoutingModule
  ]
})
export class LayoutsModule { }
