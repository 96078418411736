import { UserViewModel } from 'src/models/vw.user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, NgZone, ViewEncapsulation } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Unicode, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'app-sale-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sale-details.component.html',
  styleUrls: ['./sale-details.component.scss'],
})
export class SaleDetailsComponent implements OnInit {
  model: UserViewModel = new UserViewModel();
  initModel: UserViewModel = new UserViewModel();
  saleForm: FormGroup;
  currentUser: UserViewModel;
  groups = [];
  saleManagers = [];
  selectedGroups = [];
  initGroups = [];
  permissions: any[] = [];
  initUsrPermissions: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router,
    private zone: NgZone,
    private localStorage: LocalStorageService
  ) {
    this.saleForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      country: [''],
      group: ['', Validators.required],
      manager: [''],
      usrPermissions: this.fb.array([]),
    });
    this.groups = JSON.parse(this.localStorage.get(Unicode.GROUPS));
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.saleManagers = JSON.parse(
      this.localStorage.get(Unicode.SALE_MANAGERS)
    );
  }
  permForm = this.fb.group({
    id: [null],
    name: [null],
    code: [null],
    checked: [false],
  });

  async ngOnInit(): Promise<void> {
    var grpPerms = [];
    var grpPermisJson = this.localStorage.get(Unicode.GROUP_PERMISSIONS);
    if (grpPermisJson) grpPerms = JSON.parse(grpPermisJson);
    this.route.queryParams.subscribe((params) => {
      var resellerUser = params['model'];
      if (resellerUser) {
        this.model = JSON.parse(resellerUser);
        this.initModel = { ...this.model };
      }
    });
    this.zone.run(() => {
      if (!Utilities.isNullOrEmpty(this.model.usr_id)) {
        var ids = this.model.group_ids?.split(',') ?? '';
        ids = Array.from(new Set(ids));
        this.initGroups.push(...ids);
        this.selectedGroups.push(...ids);
      }
    });
    this.permissions = (
      await Utilities.getPermissions(this.http, this.localStorage)
    ).filter((x) => Number.parseInt(x.displayed) == 1);
    this.permissions.forEach((element) => {
      var has =
        grpPerms.find(
          (x) =>
            !Utilities.isNullOrEmpty(x.permission_code) &&
            !Utilities.isNullOrEmpty(x.usr_id) &&
            x.permission_id.toLowerCase().trim() ==
              element.id.toLowerCase().trim() &&
            x.usr_id == this.model.usr_id
        ) != null;
      this.usrPermissions.push(
        this.fb.group({
          id: [element.id],
          name: [element.name],
          code: [element.code],
          checked: has,
        })
      );
      if (has) this.initUsrPermissions.push(element.id);
    });
  }

  async onSubmit() {
    if (this.saleForm.valid) {
      var permission_codes = [];
      var add = Utilities.isNullOrEmpty(this.model.usr_id);
      var params = new HttpParams().set(
        Unicode.IS_ADD_OPERATION,
        add ? '1' : '0'
      );
      if (!this.model.country) this.model.country = null;
      if (!this.model.phone) this.model.phone = null;
      if (!this.model.manager_rfid) this.model.manager_rfid = 'NULL';
      if (Utilities.isNullOrEmpty(this.model.usr_id)) {
        this.model.groups = this.selectedGroups;
        //this.saleForm.controls['usrPermissions'].controls[0].controls['name'].value
        (<FormArray>this.saleForm.controls['usrPermissions']).controls.forEach(
          (cont) => {
            if (
              (<FormGroup>cont).controls['checked'].value &&
              (<FormGroup>cont).controls['checked'].value == true
            ) {
              permission_codes.push((<FormGroup>cont).controls['id'].value);
            }
          }
        );
        this.model.permissions = permission_codes;
      } else {
        (<FormArray>this.saleForm.controls['usrPermissions']).controls.forEach(
          (cont) => {
            if (
              (<FormGroup>cont).controls['checked'].value &&
              (<FormGroup>cont).controls['checked'].value == true
            ) {
              if (
                !this.initUsrPermissions.find(
                  (x) => x == (<FormGroup>cont).controls['id'].value
                )
              )
                permission_codes.push({
                  id: (<FormGroup>cont).controls['id'].value,
                  action: 'A',
                });
            } else {
              if (
                this.initUsrPermissions.find(
                  (x) => x == (<FormGroup>cont).controls['id'].value
                )
              )
                permission_codes.push({
                  id: (<FormGroup>cont).controls['id'].value,
                  action: 'D',
                });
            }
          }
        );
        this.model.permissions = permission_codes;
        //GROUP
        var groups = [];
        var deletedGroups = this.initGroups.filter(
          (x) => !this.selectedGroups.includes(x)
        );
        deletedGroups.forEach((element) => {
          groups.push({ id: element, action: 'D' });
        });
        var newGroups = this.selectedGroups.filter(
          (x) => !this.initGroups.includes(x)
        );
        newGroups.forEach((element) => {
          groups.push({ id: element, action: 'A' });
        });
        this.model.groups = groups;
      }
      var resp: any = await this.http
        .post(
          Endpoint.GLOBAL_URL + Endpoint.UPDATE_SALE_FILE_NAME,
          JSON.stringify(this.model),
          { params: params }
        )
        .toPromise();
      console.log(resp);
      if (resp && !resp.msg) {
        var txt = add ? 'Added' : 'Updated';
        alert('successfully ' + txt);
        await Utilities.updateUsersGroupsPermissions(
          this.http,
          this.localStorage
        );
        if (add) {
          var users: any = this.localStorage.get(Unicode.USERS);
          users = users ? JSON.parse(users) : [];
          this.model.usr_id = resp.id;
          this.model.user_name = this.model.first_name + ' '+this.model.last_name;
          users.push(this.model);
          this.localStorage.save(Unicode.USERS, JSON.stringify(users));
        }
        this.router.navigate(['/sales/']);
      } else alert(resp.msg);
    }
  }

  cancel() {
    this.router.navigate(['/sales/']);
  }

  get firstName() {
    return this.saleForm.get('firstName');
  }
  get lastName() {
    return this.saleForm.get('lastName');
  }
  get email() {
    return this.saleForm.get('email');
  }
  get phone() {
    return this.saleForm.get('phone');
  }
  get country() {
    return this.saleForm.get('country');
  }
  get group() {
    return this.saleForm.get('group');
  }
  get manager() {
    return this.saleForm.get('manager');
  }
  get usrPermissions(): FormArray {
    return <FormArray>this.saleForm.get('usrPermissions');
  }
}
