import { CustomerDetailsComponent } from './customer-details.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerDetailsRoutingModule } from './customer-details-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';

 
@NgModule({
  declarations: [CustomerDetailsComponent],
  imports: [ 
    CommonModule,
    FormsModule,ReactiveFormsModule,
    CustomerDetailsRoutingModule,
    NgSelectModule,NgbModule,
    ModalModule.forRoot(),
    NgbDropdownModule
  ]
})
export class CustomerDetailsModule { }
