<div>
    <div class="">
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link" routerLink="/settings/currency" [routerLinkActive]="['custom-active-link']"
                    [routerLinkActiveOptions]="{exact:true}">Currency</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/settings/exchange_rate" [routerLinkActive]="['custom-active-link']"
                    [routerLinkActiveOptions]="{exact:true}">Exchange Rate</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/settings/groups/list" [routerLinkActive]="['custom-active-link']"
                    [routerLinkActiveOptions]="{exact:true}">Groups</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="/settings/permissions/list" [routerLinkActive]="['custom-active-link']"
                    [routerLinkActiveOptions]="{exact:true}">Permissions</a>
            </li>
        </ul>
    </div>

    <div class="tab-content">
        <router-outlet></router-outlet>
    </div>

</div>