<div id="rates">
    <div class="m-t-35">
        <form (ngSubmit)="addNew()" id="exch-form" [formGroup]="exchForm" role="form" #formDir="ngForm" novalidate
            class="ng-untouched ng-pristine ng-invalid">
            <div class="row m-t-15">
                <div class="col-lg-3 col-md-3 input-group">
                    <span class="border-lable-flt col-12 p-0">
                        <ng-select [multiple]="false" class="form-control text-capitalize"
                            formControlName="currencyFromRfid"
                            [ngClass]="{ 'is-invalid': formDir.submitted && currencyFromRfid.invalid }" placeholder=" "
                            required style="padding: 0 !important;border:none !important;" autofocus>
                            <ng-option *ngFor="let currency of currencies" [value]="currency">{{currency.code}}
                            </ng-option>
                        </ng-select>
                        <label for="label-name">Currency From<span class="spn_required"> *</span></label>
                    </span>
                    <div class="invalid-feedback" *ngIf="formDir.submitted && currencyFromRfid.invalid">
                        <p *ngIf="currencyFromRfid.errors.required">Currency From is required</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-2 input-group">
                    <span class="border-lable-flt col-12 p-0">
                        <ng-select [multiple]="false" class="form-control text-capitalize"
                            formControlName="currencyToRfid"
                            [ngClass]="{ 'is-invalid': formDir.submitted && currencyToRfid.invalid }" placeholder=" "
                            required style="padding: 0 !important;border:none !important;" autofocus>
                            <ng-option *ngFor="let currency of currencies" [value]="currency">{{currency.code}}
                            </ng-option>
                        </ng-select>
                        <label for="label-name">Currency To<span class="spn_required"> *</span></label>
                    </span>
                    <div class="invalid-feedback" *ngIf="formDir.submitted && currencyToRfid.invalid">
                        <p *ngIf="currencyToRfid.errors.required">Currency To is required</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-3 input-group">
                    <span class="border-lable-flt col-12 p-0">
                        <input type="number" name="name" formControlName="rate" class="form-control" placeholder=" "
                            (change)="changeRate($event)"
                            [ngClass]="{ 'is-invalid': formDir.submitted && rate.invalid }" required>
                        <label for="label-name">Rate<span class="spn_required"> *</span></label>
                    </span>
                    <div class="invalid-feedback" *ngIf="formDir.submitted && rate.invalid">
                        <p *ngIf="rate.errors.required">Rate is required</p>
                    </div>
                </div>

                <div class="col-lg-2 col-md-3 input-group">
                    <span class="border-lable-flt col-12 p-0">
                        <input type="number" name="name" formControlName="inverseRate" class="form-control"
                            placeholder=" " >
                        <label for="label-name">Inverse Rate</label>
                    </span>
                </div>

                <div class="col-1">
                    <button type="submit" class="btn_blue_bg font-weight-bold" style="margin: 0;">+</button>
                </div>
            </div>

        </form>
    </div>

    <div id="rates_list">
        <ul *ngIf="rates && rates.length>0">
            <li *ngFor="let rate of rates" class="row">
                <span class="col-3">{{rate.currency_from_code}}</span>
                <span class="col-3">{{rate.currency_to_code}}</span>
                <div class="col-2">
                    <input class="col-8" [(ngModel)]="rate.rate">
                </div>
                <div class="col-2">
                    <input class="col-8" [(ngModel)]="rate.inverse_rate">
                </div>
            </li>
        </ul>
    </div>

    <div class="col-12 text-right m-t-20">
        <button (click)="save()" class="btn_blue_bg font-weight-bold" style="margin: 0;">Save</button>
    </div>

</div>