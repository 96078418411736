import { BaseModel } from './base.model';

export class UserViewModel extends BaseModel {
    usr_id: number;
    first_name: string;
    last_name: string;
    user_name: string;
    user_email: string;
    country:string = null;
    phone:string = null;
    manager_rfid: string;
    manager_rfid2: string;
    manager_email: string;
    manager2_email: string;
    group_rfid: number;
    group_name: string;
    permission_rfid: number;
    permission_name: string;
    permission_code:string;
    img: string;
    group_ids:string;
    group_names:string;
    permission_ids:string;

    //custom
    groups = [];
    permissions = [];
    isJustSale: boolean;
    isGPM:boolean;
    approve1: boolean;
    approve2: boolean;
    canExecute:boolean;
    receiveEmailOnExecution:boolean;
    isSuspenser:boolean;

    constructor() {
        super();
    }
}