import { CustomerModel } from './customer.model';
import { BaseModel } from './base.model';

export class OrderViewModel extends BaseModel {

    order_id: number;
    user_rfid: string;
    customer_rfid: string;
    email_to_send_on_execution:string;
    auth_token:string;
    channel_name:string;
    subscription_rfid: string;
    order_action: string;
    order_date: Date;
    trial_expiry_date;
    commitment_expiry_date;
    start_date;
    string_date:string;
    approved_date1: Date;
    approved_date2: Date;
    gpm_approval_date: Date;
    last_approval_date: Date;
    approval1_email:string;
    approval2_email:string;
    gpm_approval_email:string;
    last_approval_email:string;
    approval1_name:string;
    approval2_name:string;
    gpm_approval_name:string;
    last_approval_name:string;
    order_status: string;
    po_id:string; 
    order_sale_invoice_nbr:string;
    invoice_file_rfid:number;
    subscription_status_is_updated;
    licences_is_updated;
    plan_is_updated;
    start_paid_service;
    subscription_is_new: number ;
    is_new_customer;
    new_licence_number: number = 0;
    new_plan_rfid: number = 0;
    new_subscription_status: string = null;
    new_sku_id: string = null;
    old_sku_id: string = null;
    old_plan_id: number = 0;
    old_licence_number: number = 0;
    description: string = null;
    new_subscription_values:string = null; // format  = SKU;SEATS;PLAN
    usr_name:string;
    usr_email:string;
    manager_name:string;
    manager_email:string;
    customer_name:string;
    customer_domain:string;
    customer_email:string;
    customer_alternate_email:string;
    customer_phone:string;
    customer_region:string;
    customer_address:string;
    opportunity_nbr:string;
    opportunity_source:string;
    opportunity_status:string;
    google_rep:string;
    selling_price:number;
    cost_price:number;
    billing_method:string;
    //LAYOUT
    statusText:string;
    plan_code:string;
    product_name:string;
    licences:string;
    action:string;
    transfer_to_direct: boolean = false;
    deleted:boolean;
    update_renewel_settings: boolean = false;
    renewel_type:string = "";
    with_google_offer;
    google_offer_percentage: number;
    with_customer_offer;
    customer_offer_percentage: number;
    checked;
    is_withdraw: number;
    updated_licence:number;
    updated_domain:string='';
    updated_email:string;
    updated_alternate_email:string;
    updated_phone:string;
    updated_plan;
    updated_sku;
    updated_renewal_type:string;
    reject_reason:string;
    reject_date_string:string;
    reject_date:Date;
    rejecter_email:string;
    currencyPeriodText:string;
    execution_note:string;
    is_renewal_order:number;
    currency_rfid: string;
    currency_code: string;
    transfer_relation_id:string;
    order_policy_names:string;
    order_policy_percentages:string;
    order_policy_amount:string;
    order_policy_exist:string;
    order_policy_gpm_check:string;
    orderEmailSubjectAction:string;
    
    customer_details: CustomerModel;

    constructor() {
        super();
    }
}