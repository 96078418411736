import { OrderModel } from './../models/order.model';
import { CurrencyModel } from './../models/currency.model';
import { filter, map } from 'rxjs/operators';
import { UserViewModel } from './../models/vw.user';
import { Permissions } from './Endpoint';
import { CountryModel, CustomerModel } from './../models/customer.model';
import { OrderViewModel } from './../models/vw_order';
import { UserRoleDataModel } from './../models/user.role.data';
import {
  Endpoint,
  OrderStatus,
  Status,
  Unicode,
  Group,
} from 'src/shared/Endpoint';
import { Injectable } from '@angular/core';
import { KJUR } from 'jsrsasign';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { element } from 'protractor';
import { Base64 } from 'js-base64';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Injectable()
export class Utilities {
  constructor() {}

  public static isNullOrEmpty(value): boolean {
    return (
      !value ||
      value == undefined ||
      value == 'undefined' ||
      value == null ||
      value == 'null' ||
      value == 'Null' ||
      value == 'NULL'
    ); // or tolowerCase()
  }

  public static isTrue(value) {
    if (typeof value == 'boolean') return value == true;
    else {
      return Number.parseInt(value + '') == 1;
    }
  }

  public static randomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public static getToken(
    http: HttpClient,
    email: string,
    scopes: string,
    privateKey,
    clientEmail
  ) {
    return new Promise((resolve, reject) => {
      var pClaim = { aud: '', scope: '', iss: '', exp: 0, iat: 0, sub: '' };
      pClaim.aud = 'https://www.googleapis.com/oauth2/v3/token';
      pClaim.scope = scopes;
      pClaim.iss = clientEmail;
      pClaim.exp = KJUR.jws.IntDate.get('now + 1hour');
      pClaim.iat = KJUR.jws.IntDate.get('now');
      pClaim.sub = email.trim();

      var sClaim = JSON.stringify(pClaim);
      var sJWS = KJUR.jws.JWS.sign(
        null,
        Endpoint.KJUR_P_HEDAER,
        sClaim,
        privateKey
      );

      var urlEncodedData = '';
      var urlEncodedDataPairs = [];

      urlEncodedDataPairs.push(
        encodeURIComponent('grant_type') +
          '=' +
          encodeURIComponent('urn:ietf:params:oauth:grant-type:jwt-bearer')
      );
      urlEncodedDataPairs.push(
        encodeURIComponent('assertion') + '=' + encodeURIComponent(sJWS)
      );
      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      var headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      http
        .post('https://www.googleapis.com/oauth2/v3/token', urlEncodedData, {
          headers: headers,
        })
        .toPromise()
        .then((resp: any) => {
          resolve(resp.access_token);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  public static mapCustomerModelToRequestModel(customer: CustomerModel) {
    return {
      customerDomain: customer.domain,
      alternateEmail: customer.alternate_email,
      phoneNumber: customer.phone,
      // customerId: this.id,
      postalAddress: {
        organizationName: customer.organisation_name,
        contactName: customer.first_name + ' ' + customer.last_name,
        // firstName:customer.first_name,
        // lastName:customer.last_name,
        region: customer.region ? customer.region.name : customer.region_name,
        postalCode: customer.postal_code,
        locality: customer.locality,
        countryCode: customer.region
          ? customer.region.iso2
          : customer.country_code,
        addressLine1: customer.address_line1,
        addressLine2: customer.address_line2,
        addressLine3: customer.address_line3,
      },
    };
  }

  public static mapFromApiToDbTable(customer) {
    return {
      id: customer.customerId ?? '',
      first_name: customer.first_name
        ? customer.first_name
        : customer.postalAddress.contactName
        ? customer.postalAddress.contactName
        : '',
      last_name: customer.last_name ?? '',
      email: customer.email ?? '',
      alternate_email: customer.alternateEmail ?? '',
      domain: customer.customerDomain ?? '',
      organisation_name: customer.postalAddress
        ? customer.postalAddress?.organizationName
        : '',
      phone: customer.phoneNumber ?? '',
      password: customer.password ?? '',
      encryptedPassword: customer.encryptedPassword ?? '',
      locality: customer.postalAddress
        ? customer.postalAddress?.locality ?? ''
        : '',
      region_name: customer.postalAddress?.region ?? '',
      region: new CountryModel(
        customer.postalAddress ? customer.postalAddress?.region ?? '' : '',
        customer.postalAddress ? customer.postalAddress?.countryCode : ''
      ),
      postal_code: customer.postalAddress
        ? customer.postalAddress?.postalCode ?? ''
        : '',
      country_code: customer.postalAddress
        ? customer.postalAddress?.countryCode
        : '',
      address_line1: customer.postalAddress
        ? customer.postalAddress?.addressLine1 ?? ''
        : '',
      address_line2: customer.postalAddress
        ? customer.postalAddress?.addressLine2 ?? ''
        : '',
      address_line3: customer.postalAddress
        ? customer.postalAddress?.addressLine ?? ''
        : '',
    };
  }

  public static generatePassword(passwordLength) {
    var numberChars = '0123456789';
    var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return Utilities.shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      })
    ).join('');
  }

  private static shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  public static setOrder(order: OrderViewModel, products, plans, localStorag?) {
    order.checked = order.checked == 1;
    order.statusText =
      order.order_status.toUpperCase() == OrderStatus.Draft
        ? OrderStatus.Submitted
        : order.order_status;
    if (!products)
      products = JSON.parse(localStorag.get(Unicode.GOOGLE_PRODUCTS));
    if (!plans) plans = JSON.parse(localStorag.get(Unicode.PLANS));
    if (Number.parseInt(order.is_new_customer) == 1) {
      order.action = 'New Customer';
      var tab = order.new_subscription_values.split(';');
      if (tab.length == 3) {
        order.product_name =
          products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
      }
    } else if (Number.parseInt(order.is_new_customer) == 3) {
      order.action = 'Transfer Customer';
      var tab = order.new_subscription_values.split(';');
      if (tab.length == 3) {
        order.product_name =
          products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
      }
    } else if (
      order.subscription_is_new == 1 &&
      order.new_subscription_values
    ) {
      order.action = 'New Subscription';
      var tab = order.new_subscription_values.split(';');
      if (tab.length == 3) {
        order.product_name =
          products.find((x) => x.sku_id == tab[0])?.sku_name ?? '';
      }
    } else {
      if (!Utilities.isNullOrEmpty(order.old_sku_id))
        order.product_name = products.find(
          (x) => x.sku_id == order.old_sku_id
        )?.sku_name;
      var action = '';
      order.licences = order.old_licence_number + '';
      var oldplan = plans.find((x) => x.id == order.old_plan_id);
      order.plan_code = oldplan?.code;
      if (Number.parseInt(order.plan_is_updated + '') == 1) {
        var newplan = plans.find((x) => x.id == order.new_plan_rfid);
        action =
          action + 'Plan updated:' + oldplan?.name + '->' + newplan?.name + ';';
      }
      if (Number.parseInt(order.licences_is_updated + '') == 1) {
        var diff = order.new_licence_number - order.old_licence_number;
        if (diff <= 0)
          action =
            action +
            'licenses updated: old licenses:' +
            order.old_licence_number +
            ';';
        else
          action =
            action +
            'licenses updated: old licenses:' +
            order.old_licence_number +
            '   additional licenses:' +
            diff +
            ';';
      }
      if (Number.parseInt(order.subscription_status_is_updated + '') == 1) {
        if (order.new_subscription_status == Status.Active)
          action = action + 'Activate subscription;';
        else action = action + 'Suspend subscription;';
      }
      if (
        !Utilities.isNullOrEmpty(order.new_sku_id) &&
        order.new_sku_id !== '0' &&
        order.old_sku_id !== order.new_sku_id
      ) {
        var tab = order.new_sku_id.split(';');
        var old_sku = products.find((x) => x.sku_id == order.old_sku_id);
        var txt = '';
        if (old_sku && old_sku != null && old_sku != undefined)
          txt = '(old sku=' + old_sku.sku_name + ')';
        if (tab.length == 2) {
          var new_sku_name = products.find((x) => x.sku_id == tab[0])?.sku_name;
          if (tab[1] == '1')
            action =
              'Subscription updated to ' + new_sku_name + ' ' + txt + ';';
          else
            action =
              'Subscription updated to ' + new_sku_name + ' ' + txt + ';';
        }
      }
      if (Number.parseInt(order.start_paid_service + '') == 1) {
        action = action + 'Start paid Service;';
      }
      if (Number.parseInt(order.transfer_to_direct + '') == 1) {
        action = action + 'Transfer to Google directly;';
      }
      if (Number.parseInt(order.update_renewel_settings + '') == 1) {
        action = action + 'Update Renewal settings;';
      }
      if (Number.parseInt(order.is_renewal_order + '') == 1) {
        action = 'Renewal Order;';
      }

      order.action = action.slice(0, action.length - 1);
    }
  }

  public static setOrderEmailSubjectAction(order): string {
    var action = 'New Order- ';
    if (this.isTrue(order.is_renewal_order)) {
      action += 'Renewal';
    } else {
      if (Utilities.isNullOrEmpty(order.subscription_rfid)) {
        if (Number(order.is_new_customer) == 1) {
          action += 'New Customer';
        } else {
          action += 'New Service';
        }
      } else {
        if (
          !Utilities.isNullOrEmpty(order.old_sku_id) &&
          !Utilities.isNullOrEmpty(order.new_sku_id) &&
          order.old_sku_id.toLowerCase() !== order.new_sku_id.toLowerCase()
        ) {
          var tab = order.new_sku_id.split(';');
          if (tab && tab.length == 2 && !Utilities.isNullOrEmpty(tab[1])) {
            if (tab[1] == '1') {
              action += 'Downgrade';
            } else if (tab[1] == '0') {
              action += 'Upgrade';
            }
          } else {
            action += 'Upgrade/Downgrade';
          }
        } else {
          if (this.isTrue(order.subscription_status_is_updated)) {
            if (order.new_subscription_status == Status.Active)
              action += 'Activate subscription';
            else action += 'Suspend subscription';
          } else {
            var licensesUpdated = this.isOrderToUpdateLicensesOnly(
              order.licences_is_updated,
              order.plan_is_updated,
              order.old_sku_id,
              order.new_sku_id,
              order.new_subscription_values
            );
            if (licensesUpdated) {
              action += 'Update licenses';
            } else {
              var planIsUpdated = this.isTrue(order.plan_is_updated);
              if (planIsUpdated) action += 'Update plan';
            }
          }
        }
      }
    }
    return action;
  }

  public static async sendMail(
    http,
    from: string,
    toEmails: string[],
    bodyText: string,
    subject: string,
    cc: string[] = null
  ) {
    toEmails = Array.from(new Set(toEmails));
    toEmails = toEmails.filter((x) => !Utilities.isNullOrEmpty(x));
    toEmails = toEmails.filter((x) => x.toLowerCase() !== from.toLowerCase());
    subject = !Utilities.isNullOrEmpty(subject) ? subject : 'New Order';
    var headers;
    await Utilities.getToken(
      http,
      from,
      'https://mail.google.com/',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var userId = 'me';
    var url =
      'https://www.googleapis.com/gmail/v1/users/' + userId + '/messages/send';
    var text = bodyText;
    toEmails = toEmails.filter((x) => !Utilities.isNullOrEmpty(x));
    // var text = '<html><h4>Click the button</h4><a href="http://localhost/reseller_app_services/test_email.php`">Go To the app</a></html>';
    var strArray = [
      'Content-Type: text/html; charset=UTF-8',
      'MIME-Version: 1.0\n',
      'Content-Transfer-Encoding: message/rfc2822\n',
      'to: ' + toEmails + '\n',
      'from:' + from + '\n',
    ];
    if (!this.isNullOrEmpty(cc)) strArray.push('cc: ' + cc + '\n');
    strArray.push('subject: ' + subject + ' \n\n');
    strArray.push(text);
    var str = strArray.join('');
    var base64EncodedEmail = btoa(str)
      .toString()
      .replace(/\+/g, '-')
      .replace(/\//g, '_'); // Buffer.from(str).toString("base64").replace(/\+/g, '-').replace(/\//g, '_');
    var body = {
      raw: base64EncodedEmail,
    };
    http
      .post(url, JSON.stringify(body), { headers: headers })
      .subscribe((resp: any) => {
        console.log(resp);
      });
  }

  public static async sendMailWithFile(
    http,
    fileData,
    from: string,
    to: string[],
    cc: string[],
    subject: string,
    htmlBody: string,
    fileName: string
  ) {
    to = Array.from(new Set(to));
    to = to.filter((x) => !Utilities.isNullOrEmpty(x));
    to = to.filter((x) => x.toLowerCase() !== from.toLowerCase());
    cc = Array.from(new Set(cc));
    cc = cc.filter((x) => !Utilities.isNullOrEmpty(x));
    var mail = [
      'Content-Type: multipart/mixed; boundary="foo_bar_baz"\r\n',
      'MIME-Version: 1.0\r\n',
      'to: ' + to + '\r\n',
      'from: ' + from + '\r\n',
      'cc: ' + cc + '\r\n',
      'subject: ' + subject + '\r\n\r\n',

      '--foo_bar_baz\r\n',
      'Content-Type: text/html; charset="UTF-8"\r\n',
      'MIME-Version: 1.0\r\n',
      'Content-Transfer-Encoding: 7bit\r\n\r\n',

      htmlBody + '\r\n\r\n',

      '--foo_bar_baz\r\n',
      'Content-Type: application/pdf\r\n',
      'MIME-Version: 1.0\r\n',
      'Content-Transfer-Encoding: base64\r\n',
      'Content-Disposition: attachment; filename="' + fileName + '"\r\n\r\n',

      fileData,
      '\r\n\r\n',

      '--foo_bar_baz--',
    ].join('');
    mail =
      //btoa(mail) => 1 : error on length
      // btoa(   2 => work but change the name
      //   mail.replace(/[\u00A0-\u2666]/g, function (c) {
      //     return '&#' + c.charCodeAt(0) + ';';
      //   })
      // )
      Base64.encode(mail) // = > 3: from js-base64 library
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');
    var headers: HttpHeaders;
    await Utilities.getToken(
      http,
      from,
      'https://mail.google.com/',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    headers.append('Content-Type', 'application/json');
    var rs = await http
      .post(
        'https://gmail.googleapis.com/gmail/v1/users/me/messages/send',
        { raw: mail },
        { headers: headers }
      )
      .toPromise();
    console.log(rs);
  }

  public static createMimeMessage_(files: any[], from, to, subject, bodyText) {
    var nl = '\n';
    var boundary = '__ctrlq_dot_org__';

    var mimeBody = [
      'MIME-Version: 1.0',
      'To: ' + to,
      'From: ' + from,
      'Subject: ' + subject,

      'Content-Type: multipart/mixed; boundary=' + boundary + nl,
      '--' + boundary,

      'Content-Type: text/html; charset=UTF-8',
      'Content-Transfer-Encoding: base64' + nl,
      bodyText + nl,
    ];

    for (var i = 0; i < files.length; i++) {
      var attachment = [
        '--' + boundary,
        'Content-Type: ' +
          files[i].mimeType +
          '; name="' +
          files[i].fileName +
          '"',
        'Content-Disposition: attachment; filename="' + files[i].fileName + '"',
        'Content-Transfer-Encoding: base64' + nl,
        files[i].bytes,
      ];
      mimeBody.push(attachment.join(nl));
    }

    mimeBody.push('--' + boundary + '--');

    return mimeBody.join(nl);
  }

  public static setDatePickerDate(datePipe, date) {
    if (date) {
      var dt = datePipe.transform(date, 'yyyy-MM-dd');
      var tab = dt.split('-');
      return {
        year: Number(tab[0]),
        month: Number(tab[1]),
        day: Number(tab[2]),
      };
    }
    else return null;
  }

  public static setDBDate(datePipe, datePickerDate) {
    return datePipe.transform(
      new Date(
        datePickerDate.year,
        datePickerDate.month - 1,
        datePickerDate.day
      ),
      'yyyy-MM-dd hh:mm'
    );
  }

  public static calculateDiffDays(start, end) {
    return Math.floor(
      (Date.UTC(end.getFullYear(), end.getMonth(), end.getDate()) -
        Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  }

  public static async importCustomerChannelApi(
    http: HttpClient,
    httpRequestService: HttpRequestService,
    domain: string,
    authToken: string
  ): Promise<any> {
    var headers;
    await Utilities.getToken(
      http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url =
      'https://cloudchannel.googleapis.com/v1/' +
      Endpoint.RSELLER_ACCOUNTS +
      '/customers:import';
    var body = {
      overwriteIfExists: false,
      authToken: authToken,
      domain: domain,
    };
    var result = await httpRequestService.post(url, body, headers);
    if (result.success) {
      return result.data;
    } else {
      console.log(result.errorMessage);
      alert(result.errorMessage);
      return null;
    }
  }

  public static async customerApi(
    http,
    httpRequestService: HttpRequestService,
    add: boolean,
    customer: CustomerModel
  ): Promise<string> {
    var headers;
    await Utilities.getToken(
      http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order https://www.googleapis.com/auth/admin.directory.user',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    customer.domain = customer.domain
      ?.replace(/['"]+/g, '')
      .replace(/(\r\n|\n|\r)/gm, '');
    customer.organisation_name = customer.organisation_name
      ?.replace(/['"]+/g, '')
      ?.replace(/(\r\n|\n|\r)/gm, '');
    customer.address_line1 =
      customer.address_line1
        ?.replace(/['"]+/g, '')
        ?.replace(/(\r\n|\n|\r)/gm, '') ?? '';
    customer.address_line2 =
      customer.address_line2
        ?.replace(/['"]+/g, '')
        ?.replace(/(\r\n|\n|\r)/gm, '') ?? '';
    customer.address_line3 =
      customer.address_line3
        ?.replace(/['"]+/g, '')
        ?.replace(/(\r\n|\n|\r)/gm, '') ?? '';
    if (add) {
      //CHANNEL API
      var channelUrl =
        'https://cloudchannel.googleapis.com/v1/' +
        Endpoint.RSELLER_ACCOUNTS +
        '/customers';
      var channelBody = {
        orgDisplayName: customer.organisation_name,
        orgPostalAddress: {
          regionCode: customer.region
            ? customer.region.iso2
            : customer.country_code,
          postalCode: customer.postal_code ?? '',
          administrativeArea: customer.region
            ? customer.region.name
            : customer.region_name,
          locality: customer.locality,
          addressLines: [
            customer.address_line1 ?? '',
            customer.address_line2 ?? '',
            customer.address_line3 ?? '',
          ],
          organization: customer.organisation_name,
        },
        alternateEmail: customer.alternate_email,
        domain: customer.domain,
        languageCode: 'en',
        primaryContactInfo: {
          firstName: customer.first_name,
          lastName: customer.last_name,
          email: customer.email,
          phone: customer.phone,
        },
      };
      var channelResult = await httpRequestService.post(
        channelUrl,
        channelBody,
        headers
      );
      if (channelResult.success) {
        console.log(channelResult.data);
      } else {
        console.log(channelResult.errorMessage);
      }
    }
    //

    var customerAdded = false;
    var body = Utilities.mapCustomerModelToRequestModel(customer);
    var url = '';
    if (add) {
      url = 'https://reseller.googleapis.com/apps/reseller/v1/customers';
      var result = await httpRequestService.post(url, body, headers);
      if (result.success) {
        console.log('add cust: ' + result.data);
        customer.id = result.data?.customerId;
        customerAdded = true;
      } else {
        alert(result.errorMessage);
      }
    } else {
      url =
        'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
        customer.id;
      var result = await httpRequestService.put(url, body, headers);
      if (result.success) {
        customer.id = result.data?.customerId;
        customerAdded = true;
      } else {
        alert(result.errorMessage);
      }
    }
    //USER
    if (customerAdded) {
      var user_body = {};
      if (add) {
        user_body = {
          name: {
            familyName: customer.last_name,
            givenName: customer.first_name,
            fullName: customer.first_name + ' ' + customer.last_name,
          },
          password: customer.password,
          primaryEmail: customer.email,
          customerId: customer.id,
        };
        url = 'https://admin.googleapis.com/admin/directory/v1/users';
        var addUserResult = await httpRequestService.post(
          url,
          user_body,
          headers
        );
        if (addUserResult.success) {
          console.log('add user: ' + addUserResult.data);
          //MAKE ADMIN
          var url =
            'https://admin.googleapis.com/admin/directory/v1/users/' +
            customer.email +
            '/makeAdmin';
          var adminResult = await httpRequestService.post(
            url,
            { status: true },
            headers
          );
          if (adminResult.success) {
          } else {
            console.log('make admin err: ' + adminResult.errorMessage);
          }
        } else {
          console.log('add user err: ' + addUserResult.errorMessage);
        }
      } else {
        user_body = {
          name: {
            familyName: customer.last_name,
            givenName: customer.first_name,
            fullName: customer.first_name + ' ' + customer.last_name,
          },
          primaryEmail: customer.email,
        };
        url =
          'https://admin.googleapis.com/admin/directory/v1/users/' +
          customer.email;
        var editUserResult = await httpRequestService.put(
          url,
          user_body,
          headers
        );
        if (editUserResult.success) {
          console.log('add user: ' + editUserResult.data);
        } else {
          //alert(err);
          console.log('add user err: ' + editUserResult.errorMessage);
        }
      }
      return customer.id;
    }
    return null;
  }

  public static async getCustomerByIdDB(
    http,
    customerId: string,
    withSales: boolean = false
  ) {
    var params = new HttpParams().set(Unicode.CUSTOMER_ID, customerId);
    if (withSales) params = params.set(Unicode.WITH_SALES, '1');
    var resp: any = await http
      .get(Endpoint.GLOBAL_URL + Endpoint.GET_CUSTOMER_BY_ID_FILE_NAME, {
        params: params,
      })
      .toPromise();
    if (resp) {
      if (resp && !resp.msg && resp.data) {
        return resp.data;
      }
      return null;
    }
  }

  public static async getCustomerByIdAPI(
    http,
    httpRequestService: HttpRequestService,
    customerKey: string /** ID OR DOMAIN **/
  ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      var headers;
      await Utilities.getToken(
        http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order https://www.googleapis.com/auth/admin.directory.user',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then((token) => {
        headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
      });
      var url =
        'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
        customerKey;
      var result = await httpRequestService.get(url, headers);
      if (result.success) {
        var cust = result.data;
        resolve(cust);
        // url = 'https://admin.googleapis.com/admin/directory/v1/users?customer=' + add_resp.customerId + "&domain=" + add_resp.customerDomain + "&query=isAdmin=true";
        // await http.get(url, { headers: headers }).toPromise()
        //   .then(async (addU_resp: any) => {
        //     if (addU_resp && addU_resp.users && addU_resp.users.length > 0) {
        //       cust.password = "";
        //       cust.encryptedPassword = "";
        //       cust.email = addU_resp.users[0].primaryEmail;
        //       cust.first_name = addU_resp.users[0].name.givenName;
        //       cust.last_name = addU_resp.users[0].name.familyName;
        //       resolve(cust);
        //     }
        //     else reject();
        //   })
        //   .catch((err) => {
        //     alert(err?.error?.error?.message)
        //     reject();
        //   });
      } else {
        var msg =
          result.errorMessage &&
          result.errorMessage.toLowerCase().indexOf('not found') >= 0
            ? 'Not Found in the Console'
            : result.errorMessage;
        alert(msg);
        reject();
      }
    });
  }

  public static setOrderStatusEmailReceived(
    localStrg,
    user: UserViewModel
  ): UserRoleDataModel {
    var resp = new UserRoleDataModel();
    resp.receiverEmail = [];
    resp.toExecute = false;
    var grpPerms = localStrg.get(Unicode.GROUP_PERMISSIONS);
    grpPerms = grpPerms ? JSON.parse(grpPerms) : [];
    if (
      user.permissions.find(
        (x) => x.code && x.code.toUpperCase() == Permissions.can_view_all
      )
    )
      resp.canViewAll = true;
    else resp.canViewAll = false;
    //EMAILs
    if (!Utilities.isNullOrEmpty(user.manager_rfid)) {
      resp.receiverEmail = [user.manager_email];
      if (!Utilities.isNullOrEmpty(user.manager_rfid2)) {
        resp.receiverEmail.push(user.manager2_email);
      }
    }
    if (
      user.groups.find(
        (x) => x.name.toLowerCase().trim() == Group.GPM.toLowerCase().trim()
      )
    ) {
      var manangers = [];
      manangers = this.receiveEmailAfterCreationGrp(localStrg);
      resp.receiverEmail = manangers;
      resp.orderStatus = OrderStatus.Approved_by_GPM;
      resp.GPMApproverEmail = user.user_email;
    } else if (
      user.permissions.find(
        (y) => y.code && y.code.toUpperCase() == Permissions.FINAL_EXECUTION
      )
    ) {
      resp.receiverEmail = this.receiveEmailOnExecGrp(localStrg);
      resp.toExecute = true;
      resp.orderStatus = OrderStatus.Executed;
      resp.lastApproverEmail = user.user_email;
    } else if (user.groups.find((x) => x.name == Group.Accounting)) {
      var gp = localStrg.get(Unicode.GPM_EMAILS);
      if (!Utilities.isNullOrEmpty(gp)) {
        resp.receiverEmail = JSON.parse(gp);
      }
      resp.orderStatus = OrderStatus.Approved2;
      resp.approver2Email = user.user_email;
    }
    if (resp.receiverEmail.length == 0 && (user.approve1 || user.approve2)) {
      var gp = localStrg.get(Unicode.GPM_EMAILS);
      if (!Utilities.isNullOrEmpty(gp)) {
        resp.receiverEmail = JSON.parse(gp);
      }
    }
    //ORDER STATUS
    if (Utilities.isNullOrEmpty(resp.orderStatus)) {
      if (user.approve2) {
        resp.orderStatus = OrderStatus.Approved2;
        resp.approver2Email = user.user_email;
      } else if (user.approve1) {
        resp.orderStatus = OrderStatus.Approved1;
        resp.approver1Email = user.user_email;
      } else if (user.isJustSale || user.isSuspenser) {
        resp.orderStatus = OrderStatus.Pending;
      }
    }
    return resp;
  }

  public static async writeLogEntry(http, data, logType: string) {
    // SHOULD BE USED THE PRO PROJECT BUT I DON"T HAVE ACCESS TO SEE THE LOGS < SO I AM USING THE TEST PROJECT ONLY TO STORE LOGS FOR THE REQUESTS
    var headers;
    var PRIVATE_KEY =
      '-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDPdKsG0NVJR5A1\nBs/sOgZK1+U1BGs3ncOlP0jBnF+97rHwNa9lVu95ygZ9yPdzvx8oXmyvBO2JLstR\njkS8acttfNaeGFPsNK0P+EWtYKpGr0i1Xmxcm589bnFdbObcYJn1HiO9Cd5ldRnS\nU5jI/IwXzZXAfWMGpp2TPkiwB4sknczt9aUH4af9ooKAhFBuOlsqIAN7xXQcv2YB\nAEEc5DNHdoTNaLzfqsU7HxFzdg3kgxwAjeaDsDOrn6/5tYe0ttuyQ6K6stTNmUMZ\nGsT9k5E99HfJNlrL2SRZ4bfb0G553YV2Mn6NoXkQfVxG5uN6na4vB42ZQrWon4EV\nFUU4lNXpAgMBAAECggEAW8TJtPmjJ8fS2Eobpu0iBHCNcU7zmMYemhYs+oxQY+XX\na6Fr5aG3zIGcLn88S0TTpRlj7WX6J5F+GiUbbddEyDmNfKDZWucQWTyjoebdW+hE\n+wn6WFr07YlR95hpZToMULxU5dYcPXg4BGy7bMketZbZNC2jJPmGQfCu2cN0fyzM\nwMPxhwMxxLszcsMc8EAN3elPsd+NFPXD1oxINlA1pfxJXKW+gYV4fKTiEjuw/H/n\nLBBRd+vsqIiCq775PIuAAkW5Ls0csa30AzqM7XMY+GuCwQxe+ofjnM1g5HEcKo6Q\nHhEm3DSEk+nxdLThuWfdjDO4FWL93aTOzrVad6SVQwKBgQDwAYu9MhqH1zgv6Fgc\nZxuBwtzz9RS/wG5qsa47rqQjGb52WUiolnEEeRhn2Eyk/wLADaiXu6PElGL6/OPm\nCIvPh/uPSUbWWeHJwRlB1OBsowL0OcElPvAZE43RBWR+WCjvJx8PtS4sEMv6B8G3\nxWCQFqYiBaAUYpjLRTDwa3k+lwKBgQDdR9IW5KD1MMBtUxmhhubqe1hmCEQ7ODba\npKTSg2ZiJnO6QW24SarWBCreuGx0Bo8s2gv+aKG8DHwKssdISS0oU9uZK+6lNWhU\nQjGuZJwlC+8Y5GAC6bTis45yMWXKggfaRGE8DKhCUsdYqOwqi1HVkwd1CsMelw/e\nKs8h08mffwKBgAM2MhWFDx/UPHc/p7YxlwekgOpu1ClAvPmGiStZSe8CUC8rQLKo\n2LhTmoUBOv1WQEYrqJ7nLujZh32OVspkQOK1EiO3uk/yAfh+MgVUgPBTOh3ki/Yg\nonvjbBlo9hBOw7V3k43VQdnrG5+r7Oa1qHYlJ3H7G0pKi3rk4SPGfGffAoGBALSs\nHYd/qURJR/R7c2QFh6lQiL7WZQ+NOIyQ/dq8Wu4swzUCkxlzXL9dq7sqDbM2X9SZ\nPE2/FL872lPJ4RjOVsakoZqQ2kueLc8ONQ5VK4C8G77RSOtX8s9fGCF438nXHZ5D\nUrj7BK8Sc52aXynlky203NPds67YXKZjgbf2+pr1AoGBAMYvAdaAtauoDima2jxs\ntEehGGHR6STcVCG74pQcPFZjrpIYXz3Npko3JPb5qRseahouqcXt0tRYDP7znBhQ\ndxQ2tW9ZfUM6fZ3Nn0+xCj9dcjjfX62YtcsoUtxEqLmVjtBq6oPPwsrSDcSsSBOS\nkDK1U/kJ2xJUa7ZSZ1sDDBlG\n-----END PRIVATE KEY-----\n';
    await Utilities.getToken(
      http,
      'admin@goog-test.dev.premier.isolutions-sa.com',
      'https://www.googleapis.com/auth/logging.write',
      PRIVATE_KEY,
      'resellet-test-sa@reseller-304410.iam.gserviceaccount.com'
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url = 'https://logging.googleapis.com/v2/entries:write';
    var bdy = {
      entries: [
        {
          logName:
            'projects/' + 'reseller-304410' + '/logs/' + Endpoint.LOG_NAME,
          resource: {
            labels: {
              project_id: 'reseller-304410',
              app: 'app-test',
            },
            type: 'cloud_debugger_resource',
          },
          jsonPayload: {
            data: data,
          },
          severity: logType,
        },
      ],
    };
    var body = JSON.stringify(bdy);
    var resp: any = await http
      .post(url, body, { headers: headers })
      .toPromise();
    console.log(resp);
  }

  public static getSubscriptionById(
    http: HttpClient,
    httpRequestService: HttpRequestService,
    order
  ) {
    return new Promise(async (resolve, reject) => {
      var headers;
      await Utilities.getToken(
        http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then(async (token) => {
        headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          order.customer_rfid +
          '/subscriptions/' +
          order.subscription_rfid;
        var result = await httpRequestService.get(url, headers);
        if (result.success) {
          resolve(result.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  public static getSubscriptionByCustomerId(
    http: HttpClient,
    httpRequestService: HttpRequestService,
    cusomerId
  ) {
    return new Promise(async (resolve, reject) => {
      var headers;
      await Utilities.getToken(
        http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then(async (token) => {
        headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?customerId=' +
          cusomerId;
        var result = await httpRequestService.get(url, headers);
        if (result.success) {
          resolve(result.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  public static async getCurrencies(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<CurrencyModel[]> {
    var currencies: CurrencyModel[] = [];
    var currJson = localStorage.get(Unicode.CURRENCIES);
    if (!Utilities.isNullOrEmpty(currJson)) {
      currencies = JSON.parse(currJson);
    } else {
      var params = new HttpParams().set(Unicode.ACTION, Unicode.GET_ALL);
      await http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.CURRENCY_FILE_NAME, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.currencies) {
            currencies = resp.currencies;
            localStorage.save(Unicode.CURRENCIES, JSON.stringify(currencies));
          }
        });
    }
    return currencies;
  }

  public static async getGPMemails(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<string[]> {
    var gpmEmails = [];
    var gp = localStorage.get(Unicode.GPM_EMAILS);
    if (!Utilities.isNullOrEmpty(gp)) {
      gpmEmails = JSON.parse(gp);
    } else {
      var resp: any = await http
        .get(Endpoint.GLOBAL_URL + Endpoint.GLOBAL_DATA_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        localStorage.save(Unicode.COUNTRIES, JSON.stringify(resp.countries));
        localStorage.save(
          Unicode.GOOGLE_PRODUCTS,
          JSON.stringify(resp.products)
        );
        localStorage.save(Unicode.PLANS, JSON.stringify(resp.plans));
        localStorage.save(
          Unicode.GROUP_PERMISSIONS,
          JSON.stringify(resp.groups_permissions)
        );
        localStorage.save(
          Unicode.DEFAULT_CURRENCY_ID,
          resp.defaultCurrencyId ?? '1'
        );
        if (Utilities.isNullOrEmpty(resp.gpm_emails))
          localStorage.save(Unicode.GPM_EMAILS, JSON.stringify([]));
        else
          localStorage.save(
            Unicode.GPM_EMAILS,
            JSON.stringify(resp.gpm_emails.map((x) => x.email))
          );
        gp = localStorage.get(Unicode.GPM_EMAILS);
        gpmEmails = JSON.parse(gp);
      }
    }
    return gpmEmails;
  }

  public static async getPrices(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<CurrencyModel[]> {
    var prices = [];
    var pricesJson = localStorage.get(Unicode.PRICES);
    if (!Utilities.isNullOrEmpty(pricesJson)) {
      prices = JSON.parse(pricesJson);
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.SKUS_PRICES_FILE_NAME, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.prices) {
            prices = resp.prices;
            localStorage.save(Unicode.PRICES, JSON.stringify(prices));
          }
        });
    }
    return prices;
  }

  public static groupByCustomer(array) {
    return array.reduce((r, a) => {
      r[a.customerDomain] = r[a.customerDomain] || [];
      r[a.customerDomain].push(a);
      return r;
    }, Object.create(null));
  }

  public static async getGoogleIncentivesPolicy(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var policies = [];
    var po = localStorage.get(Unicode.GOOGLE_INCENTIVES_POLICY);
    if (!Utilities.isNullOrEmpty(po)) {
      policies = JSON.parse(po);
    } else {
      var parms = new HttpParams().set(Unicode.ACTION, Unicode.POLICIES);
      await http
        .get(
          Endpoint.GLOBAL_URL + Endpoint.GOOGLE_INCENTIVES_POLICY_FILE_NAME,
          { params: parms }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp && !resp.msg) {
            policies = resp?.policies ?? [];
            localStorage.save(
              Unicode.GOOGLE_INCENTIVES_POLICY,
              JSON.stringify(policies)
            );
          }
        });
    }
    return policies;
  }

  public static getRandomColor() {
    var color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  public static generateCustomerEmailBody(
    domain: string,
    salespersonEmail: string
  ): string {
    var res =
      '<html><div>Dear Client,</div><br/>' +
      '<div>Thank you for your recent order. This email confirms the order for the domain ' +
      domain +
      ' has been processed and licenses have been provisioned . </div>' +
      '<div>Attached is the sales invoice for the order processed , You will find instructions for how to make a payment on your invoice.</div><br/>' +
      '<div>Thank you for your business and please contact us at ' +
      salespersonEmail +
      ' if you have any questions regarding this invoice statement.</div><br/><br/>' +
      '<div>Sincerely yours,</div>' +
      '<div>Order Team,</div>' +
      '<div>iSolutions</div></html>';
    return res;
  }

  public static generateCustomerEmailBodyWithoutFile(
    domain: string,
    salespersonEmail: string
  ): string {
    var res =
      '<html><div>Dear Client,</div><br/>' +
      '<div>Thank you for your recent order. This email confirms the order for the domain ' +
      domain +
      ' has been processed and licenses have been provisioned . </div>' +
      '<div>Thank you for your business and please contact us at ' +
      salespersonEmail +
      ' if you have any questions regarding this invoice statement.</div><br/><br/>' +
      '<div>Sincerely yours,</div>' +
      '<div>Order Team,</div>' +
      '<div>iSolutions</div></html>';
    return res;
  }

  public static receiveEmailOnExecGrp(
    localStorage: LocalStorageService
  ): string[] {
    var gpPerms: any = localStorage.get(Unicode.GROUP_PERMISSIONS);
    gpPerms = gpPerms ? JSON.parse(gpPerms) : [];
    var result = [];
    result = gpPerms
      .filter(
        (x) =>
          !Utilities.isNullOrEmpty(x.permission_code) &&
          x.permission_code.toLowerCase().trim() ==
            Permissions.receive_email_on_execution.toLowerCase().trim()
      )
      .map((y) => y.user_email);
    result.push(
      ...gpPerms
        .filter(
          (x) =>
            !Utilities.isNullOrEmpty(x.permission_code) &&
            x.permission_code.toLowerCase().trim() ==
              Permissions.receive_email_on_execution.toLowerCase().trim()
        )
        .map((y) => y.group_email)
    );
    return result.filter((x) => !this.isNullOrEmpty(x));
  }
  public static receiveEmailAfterCreationGrp(
    localStorage: LocalStorageService
  ): string[] {
    var gpPerms: any = localStorage.get(Unicode.GROUP_PERMISSIONS);
    gpPerms = gpPerms ? JSON.parse(gpPerms) : [];
    var result = [];
    result = gpPerms
      .filter(
        (x) =>
          !Utilities.isNullOrEmpty(x.permission_code) &&
          x.permission_code.toLowerCase().trim() ==
            Permissions.receive_email_after_creation.toLowerCase().trim()
      )
      .map((y) => y.user_email);
    result.push(
      ...gpPerms
        .filter(
          (x) =>
            !Utilities.isNullOrEmpty(x.permission_code) &&
            x.permission_code.toLowerCase().trim() ==
              Permissions.receive_email_after_creation.toLowerCase().trim()
        )
        .map((y) => y.group_email)
    );
    return result.filter((x) => !this.isNullOrEmpty(x));
  }
  public static executorsGrp(localStorage: LocalStorageService): string[] {
    var gpPerms: any = localStorage.get(Unicode.GROUP_PERMISSIONS);
    gpPerms = gpPerms ? JSON.parse(gpPerms) : [];
    var result = [];
    result = gpPerms
      .filter(
        (x) =>
          !Utilities.isNullOrEmpty(x.permission_code) &&
          x.permission_code.toLowerCase().trim() ==
            Permissions.FINAL_EXECUTION.toLowerCase().trim()
      )
      .map((y) => y.user_email);
    result.push(
      ...gpPerms
        .filter(
          (x) =>
            !Utilities.isNullOrEmpty(x.permission_code) &&
            x.permission_code.toLowerCase().trim() ==
              Permissions.FINAL_EXECUTION.toLowerCase().trim()
        )
        .map((y) => y.group_email)
    );
    return result.filter((x) => !this.isNullOrEmpty(x));
  }

  public static isOrderToUpdateLicensesOnly(
    licences_is_updated,
    plan_is_updated,
    old_sku_id,
    new_sku_id,
    new_subscription_values
  ): boolean {
    return (
      this.isTrue(licences_is_updated) &&
      !this.isTrue(plan_is_updated) &&
      old_sku_id + '' == new_sku_id &&
      new_subscription_values.indexOf(';') < 0
    );
  }

  public static async getPermissions(
    http: HttpClient,
    localStorage: LocalStorageService
  ): Promise<any[]> {
    var permissions = [];
    if (localStorage.get(Unicode.PERMISSIONS)) {
      permissions = JSON.parse(localStorage.get(Unicode.PERMISSIONS)) ?? [];
    } else {
      var params = new HttpParams();
      await http
        .get(Endpoint.GLOBAL_URL + '' + Endpoint.PERMS_FILE_NAME, {
          params: params,
        })
        .toPromise()
        .then((resp: any) => {
          if (resp && resp.permissions) {
            permissions = resp.permissions ?? [];
            localStorage.save(Unicode.PERMISSIONS, JSON.stringify(permissions));
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return permissions;
  }

  public static async updateUsersGroupsPermissions(
    http: HttpClient,
    localStorage: LocalStorageService
  ) {
    var resp: any = await http
      .get(Endpoint.GLOBAL_URL + Endpoint.GROUPS_PERMISSIONS_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg && resp.groups_permissions) {
      localStorage.save(
        Unicode.GROUP_PERMISSIONS,
        JSON.stringify(resp.groups_permissions)
      );
    }
  }

  public static getLicensesByPlan(subsc): number {
    if (subsc.plan.planName.toLowerCase().trim().indexOf('annual') >= 0)
      return subsc.seats.numberOfSeats;
    else return subsc.seats.maximumNumberOfSeats;
  }
}
