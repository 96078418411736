<div id="order_content">
  <div class="row col-12 order-header">
    <div class="p-0 m-t-5">
      <label class="page_title">{{ pageTitle }}</label>
    </div>
    <div class="row col-12">
      <div class="dash_date_p">
        <span (click)="fdate.toggle()" class="dash_date_p_span">
          <img src="../../../assets/calendar icon.png" width="20" />
        </span>
        <input
          name="dp"
          [(ngModel)]="fromSelectedDate"
          ngbDatepicker
          placeholder="Date(from)"
          #fdate="ngbDatepicker"
          (ngModelChange)="selectDate()"
          class="my-dp"
        />
      </div>
      <div class="dash_date_p">
        <span (click)="tdate.toggle()" class="dash_date_p_span">
          <img src="../../../assets/calendar icon.png" width="20" />
        </span>
        <input
          name="dp"
          placeholder="To"
          [(ngModel)]="toSelectedDate"
          ngbDatepicker
          placeholder="Date(to)"
          #tdate="ngbDatepicker"
          (ngModelChange)="selectDate()"
          class="my-dp"
        />
      </div>
    </div>
  </div>

  <ul class="nav nav-tabs" id="myTab" role="tablist">
    <li class="nav-item" (click)="searchForOrders(true)">
      <a
        class="nav-link active"
        id="all-tab"
        data-toggle="tab"
        href="#all"
        role="tab"
        aria-controls="all"
        aria-selected="true"
        >All</a
      >
    </li>
    <li class="nav-item" (click)="searchForOrders(false)">
      <a
        class="nav-link"
        id="pending-tab"
        data-toggle="tab"
        href="#all"
        role="tab"
        aria-controls="all"
        aria-selected="false"
        >Pending</a
      >
    </li>
    <li class="nav-item" (click)="getRenewalOrders()" role="presentation">
      <a
        class="nav-link"
        id="renewal-tab"
        data-toggle="tab"
        href="#renewal"
        role="tab"
        aria-controls="renewal"
        aria-selected="false"
        >Renewal</a
      >
    </li>
    <li class="nav-item" (click)="(DateService)" role="presentation">
      <a
        class="nav-link"
        id="flex-tab"
        data-toggle="tab"
        href="#flex"
        role="tab"
        aria-controls="flex"
        aria-selected="false"
        >Flex Customers</a
      >
    </li>
  </ul>

  <!-- Tab panes -->
  <div class="tab-content">
    <div
      class="tab-pane active"
      id="all"
      role="tabpanel"
      aria-labelledby="all-tab"
    >
      <div class="div_table">
        <ngx-datatable
          id="order-table"
          class="bootstrap row"
          [rows]="GetData"
          [columnMode]="'force'"
          [headerHeight]="50"
          [footerHeight]="50"
          rowHeight="auto"
          [externalPaging]="true"
          [count]="page.totalElements"
          [offset]="page.pageNumber"
          [limit]="page.size"
          (page)="pageChanged($event)"
        >
          <ngx-datatable-column
            prop="customer_domain"
            [sortable]="true"
            [draggable]="true"
            [canAutoResize]="true"
            name="Domain"
            headerClass="col-lg-3 col-md-4 col-6 col-sm-5 cs_domain"
            cellClass="col-lg-3 col-md-4 col-6 col-sm-5 cs_domain"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.customer_domain }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            prop="order_date"
            [sortable]="true"
            [draggable]="true"
            [canAutoResize]="true"
            name="Order Date"
            headerClass="col-2 tbl_order_visibility"
            cellClass="tbl_order_visibility col-2"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.order_date | date : "dd/MM/yyyy HH:mm a" }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            prop="product_name"
            [sortable]="true"
            [draggable]="true"
            [canAutoResize]="true"
            name="SKU"
            headerClass="col-lg-2 col-md-2 col-3 tbl_order_visibility"
            cellClass="col-lg-2 col-md-2 col-3 tbl_order_visibility"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.product_name }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            prop="order_status"
            [sortable]="true"
            [draggable]="true"
            [canAutoResize]="true"
            name="Status"
            headerClass="col-lg-1 col-md-1 col-2 tbl_order_visibility"
            cellClass="text-lowercase col-lg-1 col-md-1 col-2 tbl_order_visibility"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.statusText }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            prop="action"
            [sortable]="true"
            [draggable]="true"
            [canAutoResize]="true"
            name="Action"
            headerClass="col-lg-3 col-md-2 col-4 col-sm-5"
            cellClass="col-lg-3 col-md-2 col-4 col-sm-5"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.action }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column headerClass="tbl_sub_edit_col">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div (click)="viewOrder(row.order_id)" class="ic_details"></div>
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>

    <div
      class="tab-pane"
      id="renewal"
      role="tabpanel"
      aria-labelledby="renewal-tab"
    >
      <div class="">
        <div class="row col-12 m-0 p-0 justify-content-between">
          <label class="p_title">
            The clients that have renewals during this month
          </label>
          <button
            (click)="exportRenewalList()"
            class="btn_blue_bg m-0 h-fit-content"
          >
            Export
          </button>
        </div>
        <div class="col-12 row cust_title p-15">
          <span class="col-lg-3 col-md-2 col-3">Domain</span>
          <div class="col-lg-2 col-md-2 col-2">SKU</div>
          <span class="col-2 tbl_order_visibility">Plan</span>
          <span class="col-lg-2 col-md-2 col-3">Expiry Date</span>
          <span class="col-lg-2 col-md-2 col-4 tbl_order_visibility"
            >Sales Team</span
          >
          <span class="col-lg-1 col-md-1 col-1">Created</span>
        </div>
        <div class="col-12 row p-15" *ngFor="let sub of GetRenewal">
          <span class="col-lg-3 col-md-2 col-3">{{ sub.customerDomain }}</span>
          <span
            class="col-lg-2 col-md-2 col-3"
            [ngClass]="{
              color_red: !sub.status.toLowerCase().includes('active'),
              color_green: sub.status.toLowerCase().includes('active')
            }"
          >
            {{ sub.skuName }}</span
          >
          <span class="col-2 tbl_order_visibility">{{
            sub.plan.planName
          }}</span>
          <span
            class="col-lg-2 col-md-2 col-3"
            *ngIf="sub.plan.commitmentInterval"
            >{{ sub.plan.commitmentInterval.endTime | date : "longDate" }}</span
          >
          <div class="col-lg-2 col-md-2 col-4 tbl_order_visibility">
            <ng-select
              appendTo="body"
              [searchable]="true"
              [clearable]="false"
              class="col-12 p-0"
              [(ngModel)]="sub.sales[0]"
            >
              <ng-option
                [value]="sale"
                *ngFor="let sale of sub.sales"
                [disabled]="true"
              >
                {{ sale }}
              </ng-option>
            </ng-select>
          </div>
          <label
            class="created_renewal_order col-lg-1 col-md-2 col-1"
            *ngIf="sub.created"
            >&#10003;</label
          >
          <label
            class="created_renewal_order col-lg-1 col-md-2 col-1"
            *ngIf="!sub.created"
            >X</label
          >
        </div>
      </div>
    </div>

    <div class="tab-pane" id="flex" role="tabpanel" aria-labelledby="flex-tab">
      <div class="">
        <div class="row col-12 m-0 p-0 justify-content-end">
          <button
            (click)="exportFlexList()"
            class="btn_blue_bg m-0 h-fit-content"
          >
            Export
          </button>
        </div>

        <div class="brands-name">
          <ng-container
            *ngFor="let data of GetFlex; let i = index"
            class="m-15"
          >
            <div class="col-12 row m-10">
              <label class="col-3 f-18">{{ data.customerDomain }}</label>
              <label class="col-2 f-18">{{ data.salesUsers }}</label>
              <input
                class="col-5"
                [(ngModel)]="data.flexNotes"
                (change)="valuechange(i, data.customerDomain, $event)"
                placeholder="flex notes"
              />
              <span
                class="expand_arrow col-1"
                (click)="Collaps(i, data.customerDomain)"
                [ngClass]="{
                  'fa-angle-down': !expandableAreasState[i],
                  'fa-angle-up': expandableAreasState[i],
                  fa: i >= 0
                }"
              ></span>
            </div>
            <div *ngIf="i === expandedIndex">
              <div
                *ngFor="let subdata of data.child; let j = index"
                class="m-10"
              >
                <div
                  class="row"
                  style="margin-left: 0px; margin-right: 0px"
                  *ngIf="subdata.skuName.toUpperCase().indexOf('DRIVE') < 0"
                >
                  <div class="col-1"></div>
                  <div
                    class="col-4"
                    [ngClass]="{
                      color_red: !subdata.status
                        .toLowerCase()
                        .includes('active'),
                      color_green: subdata.status
                        .toLowerCase()
                        .includes('active')
                    }"
                  >
                    {{ subdata.skuName }}
                  </div>
                  <div class="col-2">{{ subdata.plan?.planName }}</div>
                  <div class="col-2">
                    {{ subdata.seats?.licensedNumberOfSeats
                    }}{{ subdata.licenses }}
                  </div>
                  <div
                    class="col-2"
                    *ngIf="
                      subdata.plan.commitmentInterval &&
                      subdata.plan.commitmentInterval.endTime
                    "
                  >
                    {{
                      subdata.plan.commitmentInterval.endTime
                        | date : "dd-MMM-yyyy"
                    }}
                  </div>
                  <div
                    class="col-2"
                    *ngIf="subdata.plan && !subdata.plan.commitmentInterval"
                  >
                    <input
                      type="date"
                      class="px-2 rad-10"
                      placeholder="Expiry Date"
                      [(ngModel)]="subdata.expiryDate"
                      (ngModelChange)="
                        selectExpiryDate(
                          $event,
                          subdata.plan.planName,
                          subdata.skuId,
                          subdata.customerId
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <div
            class="txt-end"
            *ngIf="GetFlex && GetFlex.length > 0 && !currentUser.isSuspenser"
          >
            <button
              class="btn_blue_bg"
              (click)="saveFlexChanges()"
              [ngClass]="{ btn_disabled: !canViewAll }"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
