import { OrderViewModel } from 'src/models/vw_order';
import { OrderStatus, Permissions, PlanValues } from './../shared/Endpoint';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { UserViewModel } from 'src/models/vw.user';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Group, Endpoint } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DatePipe } from '@angular/common';
import { RequestResultModel } from 'src/models/request_result.model';
import { HttpRequestService } from 'src/servcies/http-request-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'reseller app';

  currentUser: UserViewModel;
  currentUserRoleData: UserRoleDataModel;
  headers;
  interval;
  gpmEmails = [];
  plans = [];
  receiveEmailOnExecGrp: string[] = [];
  receiveEmailAfterCreationGrp: string[] = [];

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private httpRequestService: HttpRequestService
  ) {}

  async ngOnInit(): Promise<void> {
    var gp = await Utilities.getGPMemails(this.http, this.localStorage);
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.receiveEmailOnExecGrp = Utilities.receiveEmailOnExecGrp(
      this.localStorage
    );
    this.receiveEmailAfterCreationGrp = Utilities.receiveEmailAfterCreationGrp(
      this.localStorage
    );
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
    if (!Utilities.isNullOrEmpty(this.currentUser)) {
      this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
        this.localStorage,
        this.currentUser
      );
      // await this.checkRenewalOrders();todo // FOR THE FIRST EXCEUTION, TO NOT WAIT 3H
      // this.interval = setInterval(async () => {
      //   await this.checkRenewalOrders();
      // }, 10800000); // 3h
      // console.log("backg_interv: " + this.interval);
    }
  }

  ngOnDestroy() {
    if (this.interval) clearInterval(this.interval);
  }

  async checkRenewalOrders() {
    var ordersToBeUpdated = [];
    var confirmedOrders = await this.getOrders();
    if (confirmedOrders && confirmedOrders.length > 0) {
      await Utilities.getToken(
        this.http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then((token) => {
        this.headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
      });
      for (var i = 0; i < confirmedOrders.length; i++) {
        var order = await this.renewOrder(confirmedOrders[i]);
        if (order) {
          ordersToBeUpdated.push({
            id: order.order_id,
            status: order.order_status,
            email: this.currentUser.user_email,
            creator: order.usr_email,
            date: order.commitment_expiry_date,
            approver1_email: order.approval1_email ?? null,
            approver2_email: order.approval2_email ?? null,
          });
        }
      }
      if (ordersToBeUpdated.length > 0) this.updateOrderDB(ordersToBeUpdated);
    }
  }

  async getOrders() {
    var day = this.datePipe.transform(new Date(), 'dd-MM-yyyy');
    var prs = new HttpParams()
      .set(Unicode.ORDER_STATUS, OrderStatus.CONFIRMED)
      .set(Unicode.ONLY_REWEWAL, '1')
      .set(Unicode.SPECIFIC_DAY, day); //COMMITMENT_EXPIRY_DAY <= TODAY
    if (
      !this.currentUserRoleData.toExecute &&
      !this.currentUser.isGPM &&
      !this.currentUser.approve2 &&
      !this.currentUser.groups.find((x) => x.name == Group.Accounting)
    ) {
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    }
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      return resp.data;
    }
  }

  async renewOrder(order: OrderViewModel): Promise<any> {
    var toRenew = await this.shouldRenewIt(order);
    if (toRenew) {
      if (
        !Utilities.isNullOrEmpty(order.old_sku_id) &&
        !Utilities.isNullOrEmpty(order.new_sku_id) &&
        order.old_sku_id !== order.new_sku_id &&
        order.new_sku_id.split(';').length > 0
      ) {
        var newPlan = this.plans.find((x) => order.new_plan_rfid == x.id);
        var isFlex = false;
        if (newPlan && newPlan.code == Unicode.FLEXIBLE_PLAN) isFlex = true;
        var seats;
        if (isFlex) seats = { maximumNumberOfSeats: order.new_licence_number };
        else seats = { numberOfSeats: order.new_licence_number };
        var sub = {
          plan: { planName: newPlan?.code ?? '' },
          seats: seats,
          purchaseOrderId: order.po_id,
          //subscriptionId: order.subscription_rfid,
          skuId: order.new_sku_id.split(';')[0] ?? '',
          customerId: order.customer_rfid,
          renewalSettings: { renewalType: Unicode.DEFAULT_RENEWEL_TYPE },
        };
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          order.customer_rfid +
          '/subscriptions';
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          sub,
          this.headers
        );
        if (result.success) {
          order.order_status = OrderStatus.Executed;
          console.log(result.data);
          if (
            result.data &&
            result.data.trialSettings &&
            result.data.trialSettings.isInTrial &&
            result.data.trialSettings.trialEndTime
          ) {
            order.trial_expiry_date = this.datePipe.transform(
              new Date(Number(result.data.trialSettings.trialEndTime)),
              'yyyy-MM-dd hh:mm'
            );
          }
          if (
            result.data &&
            result.data.plan &&
            result.data.plan.commitmentInterval &&
            result.data.plan.commitmentInterval.endTime
          ) {
            order.commitment_expiry_date = this.datePipe.transform(
              new Date(Number(result.data.plan.commitmentInterval.endTime)),
              'yyyy-MM-dd hh:mm'
            );
          }
          return order;
        } else {
          order.order_status = OrderStatus.NOT_Executed;
          return order;
        }
      } else {
        var newPlan = this.plans.find((x) => order.new_plan_rfid == x.id);
        var isFlex = false;
        if (newPlan && newPlan.code == Unicode.FLEXIBLE_PLAN) isFlex = true;
        var seats;
        if (isFlex) seats = { maximumNumberOfSeats: order.new_licence_number };
        else seats = { numberOfSeats: order.new_licence_number };
        var planBody = {
          planName: newPlan?.code ?? '',
          seats: seats,
          purchaseOrderId: order.po_id,
        };
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          order.customer_rfid +
          '/subscriptions/' +
          order.subscription_rfid +
          '/changePlan';
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          planBody,
          this.headers
        );
        if (result.success) {
          order.order_status = OrderStatus.Executed;
          console.log(result.data);
          if (
            result.data &&
            result.data.trialSettings &&
            result.data.trialSettings.isInTrial &&
            result.data.trialSettings.trialEndTime
          ) {
            order.trial_expiry_date = this.datePipe.transform(
              new Date(Number(result.data.trialSettings.trialEndTime)),
              'yyyy-MM-dd hh:mm'
            );
          }
          if (
            result.data &&
            result.data.plan &&
            result.data.plan.commitmentInterval &&
            result.data.plan.commitmentInterval.endTime
          ) {
            order.commitment_expiry_date = this.datePipe.transform(
              new Date(Number(result.data.plan.commitmentInterval.endTime)),
              'yyyy-MM-dd hh:mm'
            );
          }
          var renewelBody = {
            renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
          };
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            order.customer_rfid +
            '/subscriptions/' +
            order.subscription_rfid +
            '/changeRenewalSettings';
          var renewalResult: RequestResultModel =
            await this.httpRequestService.post(url, renewelBody, this.headers);
          if (renewalResult.success) {
            console.log(renewalResult.data);
            if (
              renewalResult.data &&
              renewalResult.data.trialSettings &&
              renewalResult.data.trialSettings.isInTrial &&
              renewalResult.data.trialSettings.trialEndTime
            ) {
              order.trial_expiry_date = this.datePipe.transform(
                new Date(Number(renewalResult.data.trialSettings.trialEndTime)),
                'yyyy-MM-dd hh:mm'
              );
            }
            if (
              renewalResult.data &&
              renewalResult.data.plan &&
              renewalResult.data.plan.commitmentInterval &&
              renewalResult.data.plan.commitmentInterval.endTime
            ) {
              order.commitment_expiry_date = this.datePipe.transform(
                new Date(
                  Number(renewalResult.data.plan.commitmentInterval.endTime)
                ),
                'yyyy-MM-dd hh:mm'
              );
            }
            return order;
          } else {
            console.log(renewalResult.errorMessage);
            return order;
          }
        } else {
          console.log(result.errorMessage);
          order.order_status = OrderStatus.NOT_Executed;
          return order;
        }
      }
    } else return null;
  }

  async updateOrderDB(orders: any[]) {
    var resp: any = await this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.EXECUTE_ORDERS_FILE_NAME,
        JSON.stringify({ orders: orders })
      )
      .toPromise();
    console.log(resp);
    for (var i = 0; i < orders.length; i++) {
      if (orders[i].order_status.toUpperCase() == OrderStatus.Executed) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          orders[i].id;
        var body =
          '<html><label>Hello,</label><br/><label> a renewal order is executed</label>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        var receivers = [];
        receivers.push(...this.receiveEmailOnExecGrp);
        receivers.push(...this.gpmEmails);
        receivers.push(orders[i].creator);
        receivers.push(orders[i].approver1_email);
        receivers.push(orders[i].approver2_email);
        var subject = 'Renewal Order';
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          receivers,
          body,
          subject
        );
      }
    }
  }

  async shouldRenewIt(order: OrderViewModel): Promise<boolean> {
    var sub: any = await Utilities.getSubscriptionById(
      this.http,
      this.httpRequestService,
      order
    );
    if (
      sub &&
      sub.plan &&
      sub.plan.planName.toUpperCase() == PlanValues.FLEXIBLE
    )
      return true;
    else return false;
  }
}
