import { Utilities } from './../shared/utilities';
import { Injectable } from '@angular/core';
import { Endpoint, Unicode } from '../shared/Endpoint';

@Injectable({
    providedIn: 'root'
})

export class StorageService {

    constructor() { }

    public save(key: string, value: string) {
        sessionStorage.setItem(key, value)
    }

    public get(key: string) {
        return sessionStorage.getItem(key);
    }

    public logout() {
        sessionStorage.clear();
    }

    public isAuthenticated(): boolean {
        return !Utilities.isNullOrEmpty(this.getLoggedinUser());
    }
    public registerUser(userJsonString: string) {
        this.save(Unicode.CURRENT_USR, userJsonString);
    }

    public getLoggedinUser() {
        return this.get(Unicode.CURRENT_USR);
    }

    public clear() {
        sessionStorage.clear();
    }

    public remove(key: string) {
        sessionStorage.removeItem(key);
    }
    public resetDataOnly() {
        var user = this.getLoggedinUser();
        this.clear();
        this.registerUser(user);
    }

}