import { BaseModel } from './base.model';

export class CustomerModel extends BaseModel {
  first_name: string = null;
  last_name: string = '';
  email: string = null;
  alternate_email: string = null;
  password: string = null;
  encryptedPassword: string = null;
  domain: string = null;
  organisation_name: string = null;
  phone: string = null;
  locality: string = null;
  region_name: string = null;
  region: CountryModel;
  postal_code: string = null;
  country_code: string = null;
  address_line1: string = null;
  address_line2: string = null;
  address_line3: string = null;
  deleted: boolean;
  status;
  auth_token: string;
  is_offline;
  is_new_trial: boolean = false;
  is_new_customer: boolean = false;
  customer_created_date: string;
  channel_name: string;

  sales: string; // FROM DB : id_name,id_name
  customerSaleId: string;
  customerSales: any[] = [];
  mustTransferAll: boolean;
  allTransferableSubs: any[] = [];
  transferredSubsSelected: any[] = [];
  subValues: string = '';
  POValues: string = '';
  invoiceValues: string = '';

  constructor(id?, name?, email?, org?, phone?) {
    super();
    this.id = id;
    this.first_name = name ?? null;
    this.email = email ?? null;
    this.organisation_name = org ?? null;
    this.phone = phone ?? null;
  }

  map_list(jsonData: any[]): CustomerModel[] {
    var list: CustomerModel[] = [];
    Object.entries(jsonData).map((o) => {
      list.push(new CustomerModel('', '', '', ''));
    });
    return list;
  }

  map(jsonData: any): CustomerModel {
    return new CustomerModel('', '', '', '');
  }
}

export class CountryModel {
  name: string;
  iso2: string;
  iso3: string;

  constructor(name?: string, code?: string) {
    this.name = name;
    this.iso2 = code;
  }
}
