import { UserViewModel } from './../../../../src/models/vw.user';
import { OrderViewModel } from './../../../../src/models/vw_order';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { StorageService } from 'src/servcies/storage-service';
import {
  Unicode,
  Endpoint,
  Status,
  Group,
  OrderStatus,
  PlanValues,
  Permissions,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { DatePipe } from '@angular/common';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { HttpRequestService } from 'src/servcies/http-request-service';

@Component({
  selector: 'lib-orders',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  orders: OrderViewModel[] = [];
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  subdata: OrderViewModel[] = [];
  static subdataStatic: OrderViewModel[] = [];
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  static pageSizeStatic: number = Unicode.ITEMS_PER_PAGE;
  plans = [];
  products = [];
  currentUser: UserViewModel;
  fromSelectedDate;
  toSelectedDate;
  static staticFromSelectedDate;
  static staticToSelectedDate;
  statusSearchValue: string;
  static staticStatusSearchValue: string;
  pageTitle: string = '';
  static pageStatic;
  currentUserRoleData: UserRoleDataModel;
  static renewalOrders = [];
  static flexCustomers = [];
  static tempFlexCustomers = [];
  static pageToken: string = '';
  static flexPageToken: string = '';
  static renewalToken;
  static flexToken;
  static renewalTab: boolean = false;
  static flexTab: boolean = false;
  static stopNormalProcess: boolean = false;
  expandedIndex = -1;
  ChildList = [];
  expandableAreasState = [];
  static _httpRequestService: HttpRequestService;
  static _http: HttpClient;
  flexCustomersUpdated = { notes: [], dates: [] };
  static currentUserCustomers = [];
  isAccountant: boolean;
  static canViewAllCustomers: boolean;
  static currentUserId;
  static dataDbInputs = [];
  static dataDbOutputs = { dates: [], notes: [] };

  public get DateService() {
    return OrdersComponent.getFlexCUstomers();
  }

  public get canViewAll() {
    return OrdersComponent.canViewAllCustomers;
  }

  constructor(
    private router: Router,
    http: HttpClient,
    private datePipe: DatePipe,
    httpRequestService: HttpRequestService,
    private confirmDialogService: ConfirmDialogService,
    private localStorage: LocalStorageService,
    private route: ActivatedRoute
  ) {
    OrdersComponent._httpRequestService = httpRequestService;
    OrdersComponent._http = http;
    this.route.queryParams.subscribe((params) => {
      this.statusSearchValue = params['status'];
      OrdersComponent.staticStatusSearchValue = this.statusSearchValue;
    });
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    OrdersComponent.pageStatic = this.page;
    var current = new Date();
    this.fromSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      current.setMonth(current.getMonth() - 1)
    );
    this.toSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      current.setMonth(current.getMonth() + 2)
    );
    OrdersComponent.staticFromSelectedDate = this.fromSelectedDate;
    OrdersComponent.staticToSelectedDate = this.toSelectedDate;
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    OrdersComponent.currentUserId = this.currentUser.usr_id;
    OrdersComponent.canViewAllCustomers = this.currentUserRoleData.canViewAll;
    this.isAccountant =
      this.currentUser.groups.find((x) => x.name == Group.Accounting) !=
      undefined;
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.products = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.getOrders({ offset: 0 }, '');
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'orders';
  }

  searchCustomer(customer) {
    this.getOrders({ offset: 0 }, null, customer.target.value);
  }

  async searchForOrders(all: boolean) {
    OrdersComponent.renewalTab = false;
    OrdersComponent.flexTab = false;
    this.statusSearchValue = all
      ? ''
      : this.currentUserRoleData.toExecute
      ? OrderStatus.Pending +
        '_' +
        OrderStatus.Approved1 +
        '_' +
        OrderStatus.Approved2 +
        '_' +
        OrderStatus.Approved_by_GPM
      : OrderStatus.Pending;
    OrdersComponent.staticStatusSearchValue = this.statusSearchValue;
    this.getOrders({ offset: 0 }, '');
  }

  async getOrders(pageInfo, search, customerSearch?) {
    var from_string = this.datePipe.transform(
      new Date(
        this.fromSelectedDate.year,
        this.fromSelectedDate.month - 1,
        this.fromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = this.datePipe.transform(
      new Date(
        this.toSelectedDate.year,
        this.toSelectedDate.month - 1,
        this.toSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.page.size + '')
      .set(Unicode.LIMIT, this.pageSize + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string);
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.SALES_FILTER, search);
    customerSearch = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!Utilities.isNullOrEmpty(this.statusSearchValue))
      prs = prs.set(Unicode.ORDER_STATUS, this.statusSearchValue);
    if (!this.currentUserRoleData.canViewAll) {
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    }
    var resp = await OrdersComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.page.totalElements = <number>resp['total'];
      if (resp.data)
        this.setPage({ offset: pageInfo.offset, data: resp['data'] });
      else this.setPage({ offset: pageInfo.offset, data: [] });
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const pagedData = new PagedData<OrderViewModel>();
    this.page.totalPages = this.page.totalElements / this.page.size;
    pagedData.data.push(...pageInfo.data);
    pagedData.page = this.page;
    this.page = pagedData.page;
    this.subdata = pagedData.data;
    this.subdata.forEach((order) => {
      Utilities.setOrder(order, this.products, this.plans);
    });
    OrdersComponent.pageStatic = this.page;
    OrdersComponent.subdataStatic = this.subdata;
  }

  pageChanged(pageInfo) {
    this.getOrders(pageInfo, '');
  }

  viewOrder(order_id: number) {
    var order = this.subdata.find((x) => x.order_id == order_id);
    if (!order)
      var order = OrdersComponent.subdataStatic.find(
        (x) => x.order_id == order_id
      );
    this.localStorage.save(Unicode.ORDER, JSON.stringify(order));
    if (Utilities.isNullOrEmpty(order.transfer_relation_id))
      this.router.navigate(['/orders/order-details']);
    else {
      this.router.navigate(['/customers/transferable-subscriptions'], {
        queryParams: {
          viewTransferredOrderDetails: true,
          orderTransferRelationID: order.transfer_relation_id,
          orderTransferCustomerID: order.customer_rfid,
          orderTransferID: order.order_id,
        },
      });
    }
  }

  selectDate() {
    OrdersComponent.staticFromSelectedDate = this.fromSelectedDate;
    OrdersComponent.staticToSelectedDate = this.toSelectedDate;
    if (!OrdersComponent.renewalTab)
      this.getOrders(
        { offset: 0 },
        null,
        (<HTMLInputElement>document.getElementById('nav-search-input')).value
      );
    else {
      OrdersComponent.renewalOrders = [];
      OrdersComponent.pageToken = '';
      OrdersComponent.stopNormalProcess = false;
      this.getRenewalOrders();
    }
  }

  public static searchGlobal(
    datePip,
    localS,
    status,
    searchValue,
    user?,
    userRoleData?,
    http?
  ) {
    if (this.renewalTab) {
      this.renewalOrders = [];
      this.pageToken = '';
      OrdersComponent.stopNormalProcess = true;
      this.getRenewalOrdersStatic(datePip, user, http, searchValue);
    } else if (this.flexTab) {
      OrdersComponent.getFlexCUstomers();
    } else {
      this.getOrdersStatic(
        datePip,
        localS,
        status,
        { offset: 0 },
        null,
        user,
        userRoleData,
        http,
        searchValue
      );
    }
  }

  static async getOrdersStatic(
    datePip,
    localS,
    status,
    pageInfo,
    search,
    user,
    userRoleData,
    http: HttpClient,
    customerSearch?
  ) {
    var from_string = datePip.transform(
      new Date(
        OrdersComponent.staticFromSelectedDate.year,
        OrdersComponent.staticFromSelectedDate.month - 1,
        OrdersComponent.staticFromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = datePip.transform(
      new Date(
        OrdersComponent.staticToSelectedDate.year,
        OrdersComponent.staticToSelectedDate.month - 1,
        OrdersComponent.staticToSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.pageStatic.size + '')
      .set(Unicode.LIMIT, this.pageSizeStatic + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string);
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.SALES_FILTER, search);
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!Utilities.isNullOrEmpty(status))
      prs = prs.set(Unicode.ORDER_STATUS, status);
    if (!Utilities.isNullOrEmpty(OrdersComponent.staticStatusSearchValue))
      prs = prs.set(
        Unicode.ORDER_STATUS,
        OrdersComponent.staticStatusSearchValue
      );
    if (!userRoleData.canViewAll) {
      prs = prs.set(Unicode.USER_ID, user.usr_id + '');
    }
    var resp = await http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.pageStatic.totalElements = <number>resp['total'];
      pageInfo.data = resp['data'] ?? [];
      this.pageStatic.pageNumber = pageInfo.offset;
      const pagedData = new PagedData<OrderViewModel>();
      this.pageStatic.totalPages =
        this.pageStatic.totalElements / this.pageStatic.size;
      pagedData.data.push(...pageInfo.data);
      pagedData.page = this.pageStatic;
      this.subdataStatic = pagedData.data;
      this.pageStatic = pagedData.page;
      this.subdataStatic.forEach((order) => {
        Utilities.setOrder(order, null, null, localS);
      });
    }
  }

  async getRenewalOrders() {
    OrdersComponent.renewalOrders = [];
    OrdersComponent.pageToken = '';
    OrdersComponent.renewalTab = true;
    OrdersComponent.flexTab = false;
    var from_ = new Date(
      this.fromSelectedDate.year,
      this.fromSelectedDate.month - 1,
      this.fromSelectedDate.day
    );
    var to_ = new Date(
      this.toSelectedDate.year,
      this.toSelectedDate.month - 1,
      this.toSelectedDate.day + 1
    );
    var createdRenewalOrders = [];
    //GET CREATED RENEWAL ORDERS
    var prs = new HttpParams()
      .set(Unicode.ONLY_REWEWAL, '1')
      .set(Unicode.WITH_SALES_CUSTOMERS, '1'); //.set(Unicode.FROM_DATE, from_string).set(Unicode.TO_DATE, to_string)
    var customerSearch = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!OrdersComponent.canViewAllCustomers)
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    var resp = await OrdersComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg && resp.data)
      createdRenewalOrders = resp.data;
    if (resp && !resp.success && !resp.msg && resp.userCustomers)
      OrdersComponent.currentUserCustomers = resp.userCustomers;
    await this.getRenewalOrdersAPI(
      customerSearch,
      createdRenewalOrders,
      from_,
      to_
    );
  }

  async getRenewalOrdersAPI(
    customerSearch: string,
    createdRenewalOrders: any[],
    from_: Date,
    to_: Date
  ) {
    if (OrdersComponent.stopNormalProcess) return;
    var headers;
    if (Utilities.isNullOrEmpty(OrdersComponent.renewalToken)) {
      await Utilities.getToken(
        OrdersComponent._http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then((token) => {
        OrdersComponent.renewalToken = token;
      });
    }
    headers = new HttpHeaders({
      Authorization: `Bearer ${OrdersComponent.renewalToken}`,
    });
    var url = 'https://reseller.googleapis.com/apps/reseller/v1/subscriptions';
    var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set('customerNamePrefix', customerSearch);
    if (!Utilities.isNullOrEmpty(OrdersComponent.pageToken))
      prs = prs.set('pageToken', OrdersComponent.pageToken);
    var result = await OrdersComponent._httpRequestService.get(
      url,
      headers,
      prs
    );
    if (result.success) {
      if (result.data && result.data.subscriptions) {
        var subs = result.data.subscriptions.filter(
          (sub) =>
            sub.plan.commitmentInterval &&
            sub.plan.commitmentInterval.endTime > from_.getTime() &&
            sub.plan.commitmentInterval.endTime < to_.getTime()
        );
        subs.forEach((element) => {
          if (
            OrdersComponent.canViewAllCustomers ||
            (OrdersComponent.currentUserCustomers.length > 0 &&
              OrdersComponent.currentUserCustomers.find(
                (c) =>
                  c.customer_rfid.toLowerCase().trim() ==
                  element.customerId.toLowerCase().trim()
              ))
          ) {
            //
            if (
              createdRenewalOrders.find(
                (x) =>
                  x.customer_domain.toLowerCase() ==
                    element.customerDomain.toLowerCase() &&
                  x.new_sku_id.toLowerCase() == element.skuId.toLowerCase()
              )
            )
              element.created = true;
            else element.created = false;
            //
            var sales = OrdersComponent.currentUserCustomers
              .filter(
                (c) =>
                  c.customer_rfid.toLowerCase().trim() ==
                  element.customerId.toLowerCase().trim()
              )
              .map((x) => x.usr_name);
            element.sales = Array.from(new Set(sales));
            //
            OrdersComponent.renewalOrders.push(element);
            OrdersComponent.renewalOrders.sort((a, b) =>
              a.plan.commitmentInterval.endTime.localeCompare(
                b.plan.commitmentInterval.endTime
              )
            );
          }
        });
        if (result.data.nextPageToken) {
          OrdersComponent.pageToken = result.data.nextPageToken;
          this.getRenewalOrdersAPI(
            customerSearch,
            createdRenewalOrders,
            from_,
            to_
          );
        }
      }
    } else {
      console.log(result.errorMessage);
    }
  }

  static async getRenewalOrdersStatic(
    datePip,
    user,
    http: HttpClient,
    customerSearch
  ) {
    OrdersComponent.renewalTab = true;
    OrdersComponent.flexTab = false;
    var from_ = new Date(
      this.staticFromSelectedDate.year,
      this.staticFromSelectedDate.month - 1,
      this.staticFromSelectedDate.day
    );
    var to_ = new Date(
      this.staticToSelectedDate.year,
      this.staticToSelectedDate.month - 1,
      this.staticToSelectedDate.day + 1
    );
    var from_string = datePip.transform(from_, 'yyyy-MM-dd');
    var to_string = datePip.transform(to_, 'yyyy-MM-dd');
    var createdRenewalOrders = [];
    //GET CREATED RENEWAL ORDERS
    var prs = new HttpParams()
      .set(Unicode.ONLY_REWEWAL, '1')
      .set(Unicode.WITH_SALES_CUSTOMERS, '1');
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!OrdersComponent.canViewAllCustomers)
      prs = prs.set(Unicode.USER_ID, OrdersComponent.currentUserId + '');
    var resp = await http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg && resp.data)
      createdRenewalOrders = resp.data;
    if (resp && !resp.success && !resp.msg && resp.userCustomers)
      OrdersComponent.currentUserCustomers = resp.userCustomers;
    await this.getRenewalOrdersAPIStatic(
      http,
      customerSearch,
      createdRenewalOrders,
      from_,
      to_
    );
  }

  static async getRenewalOrdersAPIStatic(
    http,
    customerSearch: string,
    createdRenewalOrders: any[],
    from_: Date,
    to_: Date
  ) {
    if (!OrdersComponent.stopNormalProcess) return;
    var headers;
    if (Utilities.isNullOrEmpty(this.renewalToken)) {
      await Utilities.getToken(
        OrdersComponent._http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then((token) => {
        this.renewalToken = token;
      });
    }
    headers = new HttpHeaders({
      Authorization: `Bearer ${this.renewalToken}`,
    });
    var url = 'https://reseller.googleapis.com/apps/reseller/v1/subscriptions';
    var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set('customerNamePrefix', customerSearch);
    if (!Utilities.isNullOrEmpty(OrdersComponent.pageToken))
      prs = prs.set('pageToken', OrdersComponent.pageToken);
    http
      .get(url, { headers: headers, params: prs })
      .toPromise()
      .then(async (resp: any) => {
        if (resp && resp.subscriptions) {
          var subs = resp.subscriptions.filter(
            (sub) =>
              sub.plan.commitmentInterval &&
              sub.plan.commitmentInterval.endTime > from_.getTime() &&
              sub.plan.commitmentInterval.endTime < to_.getTime()
          );
          subs.forEach((element) => {
            if (
              OrdersComponent.canViewAllCustomers ||
              (OrdersComponent.currentUserCustomers.length > 0 &&
                OrdersComponent.currentUserCustomers.find(
                  (c) =>
                    c.customer_rfid.toLowerCase().trim() ==
                    element.customerId.toLowerCase().trim()
                ))
            ) {
              if (
                createdRenewalOrders.find(
                  (x) =>
                    x.customer_domain.toLowerCase() ==
                      element.customerDomain.toLowerCase() &&
                    x.new_sku_id.toLowerCase() == element.skuId.toLowerCase()
                )
              )
                element.created = true;
              else element.created = false;
              //
              var sales = OrdersComponent.currentUserCustomers
                .filter(
                  (c) =>
                    c.customer_rfid.toLowerCase().trim() ==
                    element.customerId.toLowerCase().trim()
                )
                .map((x) => x.usr_name);
              element.sales = Array.from(new Set(sales));
              //
              OrdersComponent.renewalOrders.push(element);
              OrdersComponent.renewalOrders.sort((a, b) =>
                a.plan.commitmentInterval.endTime.localeCompare(
                  b.plan.commitmentInterval.endTime
                )
              );
            }
          });
          if (resp.nextPageToken) {
            OrdersComponent.pageToken = resp.nextPageToken;
            this.getRenewalOrdersAPIStatic(
              http,
              customerSearch,
              createdRenewalOrders,
              from_,
              to_
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  static async getFlexCUstomers() {
    OrdersComponent.flexCustomers = [];
    OrdersComponent.tempFlexCustomers = [];
    OrdersComponent.dataDbInputs = [];
    OrdersComponent.dataDbOutputs = { dates: [], notes: [] };
    OrdersComponent.flexTab = true;
    OrdersComponent.renewalTab = false;
    this.flexPageToken = '';
    //if (Utilities.isNullOrEmpty(OrdersComponent.flexToken))
    await Utilities.getToken(
      OrdersComponent._http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      OrdersComponent.flexToken = token;
    });
    var customers = [];
    if (!OrdersComponent.canViewAllCustomers) {
      var prs = new HttpParams().set(
        Unicode.USER_ID,
        OrdersComponent.currentUserId
      );
      var resp = await OrdersComponent._http
        .get<any>(
          Endpoint.GLOBAL_URL + '' + Endpoint.SALES_CUSTOMERS_FILE_NAME,
          {
            params: prs,
          }
        )
        .toPromise();
      if (resp && !resp.success && resp.userCustomers) {
        customers = resp.userCustomers ?? [];
      }
    }
    OrdersComponent.getFlexCUstomersSub(customers);
  }

  static async getFlexCUstomersSub(userCustomers: any[]) {
    var phaseInputs = [];
    var headers = new HttpHeaders({
      Authorization: `Bearer ${OrdersComponent.flexToken}`,
    });
    var url = 'https://reseller.googleapis.com/apps/reseller/v1/subscriptions';
    var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
    // prs = prs.set("maxResults", "100");
    var customerFilter = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    if (!Utilities.isNullOrEmpty(customerFilter))
      prs = prs.set('customerNamePrefix', customerFilter);
    if (!Utilities.isNullOrEmpty(OrdersComponent.flexPageToken))
      prs = prs.set('pageToken', OrdersComponent.flexPageToken);
    var result = await OrdersComponent._httpRequestService.get(
      url,
      headers,
      prs
    );
    if (result.success) {
      if (result.data && result.data.subscriptions) {
        result.data.subscriptions.forEach((element) => {
          if (
            this.canViewAllCustomers ||
            userCustomers.find(
              (x) =>
                x.customer_rfid.toLowerCase().trim() ==
                element.customerId.toLowerCase().trim()
            )
          ) {
            var cust;
            element.licenses =
              element.plan?.planName
                ?.toUpperCase()
                .indexOf(Unicode.TRIAL_PLAN) >= 0 ||
              element.plan?.planName
                ?.toUpperCase()
                .indexOf(Unicode.FLEXIBLE_PLAN) >= 0
                ? '/' + element.seats?.maximumNumberOfSeats ?? 0
                : '/' + element.seats?.numberOfSeats ?? 0;
            if (
              (cust = OrdersComponent.tempFlexCustomers.find(
                (x) => x.customerDomain == element.customerDomain
              ))
            ) {
              if (
                cust.child &&
                cust.child.findIndex((x) => x.skuId == element.skuId) < 0
              ) {
                cust.child.push(element);
              } else {
                cust.child = [element];
              }
            } else {
              OrdersComponent.tempFlexCustomers.push({
                customerDomain: element.customerDomain,
                customerId: element.customerId,
                child: [element],
              });
              if (
                phaseInputs.findIndex((x) => x == element.customerId) < 0 &&
                OrdersComponent.dataDbInputs.findIndex(
                  (y) => y == element.customerId
                ) < 0
              )
                phaseInputs.push(element.customerId);
            }
            if (element.licenses.toLowerCase().indexOf('undefined') >= 0)
              element.licenses = '';
          }
        });
        //DB  => TO ADD THE FLEX NOTES
        var ids = "'" + phaseInputs.join("';'") + "'";
        if (phaseInputs.find((x) => x == 'C02s7t6x9')) {
          console.log('sdf');
        }
        var params = new HttpParams()
          .set(Unicode.ACTION, Unicode.GET)
          .set(Unicode.WITHOUT_LOADER, '1');
        await OrdersComponent._http
          .post(
            Endpoint.GLOBAL_URL + '' + Endpoint.FLEX_CUSTOMER_FILE_NAME,
            JSON.stringify({ ids: ids }),
            { params: params }
          )
          .toPromise() // POST: BCZ OF URL LENGTH
          .then((resp: any) => {
            if (resp) {
              if (resp.notes)
                OrdersComponent.dataDbOutputs.notes.push(...resp.notes);
              if (resp.dates)
                OrdersComponent.dataDbOutputs.dates.push(...resp.dates);
              //notes
              OrdersComponent.dataDbOutputs.notes.forEach((dt) => {
                var upCust = OrdersComponent.tempFlexCustomers.find(
                  (x) =>
                    x.customerDomain.toLowerCase().trim() ==
                    dt.domain.toLowerCase().trim()
                );
                if (upCust) {
                  upCust.flexNotes = dt.flex_notes;
                  var su = dt.sales_users ?? '';
                  su = Array.from(new Set(su.split(',')));
                  su = su.join(',');
                  console.log(su);
                  upCust.salesUsers = su;
                }
              });
              //dates
              OrdersComponent.dataDbOutputs.dates.forEach((dt) => {
                var upCust = OrdersComponent.tempFlexCustomers.find(
                  (x) =>
                    x.customerId.toLowerCase().trim() ==
                    dt.customer_rfid.toLowerCase().trim()
                );
                if (upCust) {
                  var sku = upCust.child.find((c) => c.skuId == dt.sku_rfid);
                  if (sku) sku.expiryDate = dt.expiry_date;
                }
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
        OrdersComponent.dataDbInputs.push(...phaseInputs);
        //
        OrdersComponent.flexCustomers =
          OrdersComponent.tempFlexCustomers.filter(
            (x) =>
              x.child &&
              x.child.find(
                (y) =>
                  y.plan &&
                  y.plan.planName
                    .toUpperCase()
                    .trim()
                    .indexOf(PlanValues.FLEXIBLE) >= 0 &&
                  y.status.toLowerCase() == 'active' &&
                  y.skuId
                    .toLowerCase()
                    .trim()
                    .indexOf(Unicode.GOOGLE_DRIVE_SKU_ID.toLowerCase().trim()) <
                    0
              )
          );
      }
      if (result.data.nextPageToken) {
        OrdersComponent.flexPageToken = result.data.nextPageToken;
        this.getFlexCUstomersSub(userCustomers);
      } else {
      }
    } else {
      console.log(result.errorMessage);
    }
  }

  get GetData() {
    return OrdersComponent.subdataStatic;
  }

  get GetRenewal() {
    return OrdersComponent.renewalOrders;
  }

  get GetFlex() {
    return OrdersComponent.flexCustomers;
  }

  valuechange(i: number, domain: string, event) {
    var index = this.flexCustomersUpdated.notes.findIndex(
      (x) => x.domain == domain
    );
    if (index >= 0) this.flexCustomersUpdated.notes.splice(index, 1);
    this.flexCustomersUpdated.notes.push({
      domain: domain,
      flexNotes: event.target.value,
    });
  }

  selectExpiryDate(event, planCode: string, skuId: string, customerId: string) {
    if (!Utilities.isNullOrEmpty(event)) {
      var index = this.flexCustomersUpdated.dates.findIndex(
        (x) => x.customerId == customerId && x.skuId == skuId
      );
      if (index >= 0) this.flexCustomersUpdated.dates.splice(index, 1);
      this.flexCustomersUpdated.dates.push({
        customerId: customerId,
        planId: this.plans.find(
          (x) => x.code.toLowerCase() == planCode.toLowerCase()
        )?.id,
        skuId: skuId,
        expiryDate: event,
      });
    }
  }

  async saveFlexChanges() {
    var params = new HttpParams().set(Unicode.ACTION, Unicode.EDIT);
    await OrdersComponent._http
      .post(
        Endpoint.GLOBAL_URL + '' + Endpoint.FLEX_CUSTOMER_FILE_NAME,
        JSON.stringify({ data: this.flexCustomersUpdated }),
        { params: params }
      )
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        if (resp) {
          alert('Saved');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  Collaps(index, domain: string) {
    //ARROW
    this.expandableAreasState[index] = !this.expandableAreasState[index];
    for (var i = 0; i < this.expandableAreasState.length; i++) {
      if (i !== index) this.expandableAreasState[i] = false;
    }
    //
    this.ChildList = [];
    this.expandedIndex = index === this.expandedIndex ? -1 : index;
    var groups = this.GetFlex;
    if (groups) {
      var subs = groups[domain];
      if (subs && subs.length > 0) {
        subs.forEach((sub) => {
          this.ChildList.push(sub);
        });
      }
    }
  }

  async getCustomers() {
    var prs = new HttpParams();
    if (!this.currentUserRoleData.canViewAll)
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    var resp = await OrdersComponent._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_CUSTOMER_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      if (resp.data) return resp.data;
    }
  }

  async exportRenewalList() {
    var headers;
    await Utilities.getToken(
      OrdersComponent._http,
      this.currentUser.user_email,
      'https://www.googleapis.com/auth/drive',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url = ' https://sheets.googleapis.com/v4/spreadsheets';
    var sheetName =
      'Renewal list ' +
      this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    var sheetBody = {
      properties: {
        title: sheetName,
      },
      sheets: [
        {
          data: [
            {
              rowData: [],
            },
          ],
        },
      ],
    };
    sheetBody.sheets[0].data[0].rowData.push({
      values: [
        {
          userEnteredValue: {
            stringValue: 'Domain',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'SKU',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Status',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Licenses',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Plan',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Expiry Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Account Manager ',
          },
        },
      ],
    });
    OrdersComponent.renewalOrders.forEach((order) => {
      sheetBody.sheets[0].data[0].rowData.push({
        values: [
          {
            userEnteredValue: {
              stringValue: order.customerDomain ?? '',
            },
          },
          {
            userEnteredValue: {
              stringValue: order.skuName + '' ?? '',
            },
          },
          {
            userEnteredValue: {
              stringValue: order.status ?? '',
            },
          },
          {
            userEnteredValue: {
              stringValue:
                order.plan?.planName
                  ?.toUpperCase()
                  .indexOf(Unicode.TRIAL_PLAN) >= 0 ||
                order.plan?.planName
                  ?.toUpperCase()
                  .indexOf(Unicode.FLEXIBLE_PLAN) >= 0 ||
                order.plan?.planName?.toUpperCase().indexOf(PlanValues.FREE) >=
                  0
                  ? order.seats?.maximumNumberOfSeats + '' ?? '0'
                  : order.seats?.numberOfSeats + '' ?? '0',
            },
          },
          {
            userEnteredValue: {
              stringValue: order.plan?.planName ?? '',
            },
          },
          {
            userEnteredValue: {
              stringValue: order.plan?.commitmentInterval?.endTime
                ? this.datePipe.transform(
                    new Date(
                      Number.parseInt(order.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd'
                  )
                : '',
            },
          },
          {
            userEnteredValue: {
              stringValue: order.sales?.join(',') ?? '',
            },
          },
        ],
      });
    });
    //
    OrdersComponent._http
      .post(url, JSON.stringify(sheetBody), { headers: headers })
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        window.open(resp.spreadsheetUrl);
      })
      .catch((error) => {
        console.log(error);
        alert(error.error.error.message);
      });
  }

  async exportFlexList() {
    var headers;
    await Utilities.getToken(
      OrdersComponent._http,
      this.currentUser.user_email,
      'https://www.googleapis.com/auth/drive',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url = ' https://sheets.googleapis.com/v4/spreadsheets';
    var sheetName =
      'Flex Customers ' +
      this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    var sheetBody = {
      properties: {
        title: sheetName,
      },
      sheets: [
        {
          data: [
            {
              rowData: [],
            },
          ],
        },
      ],
    };
    sheetBody.sheets[0].data[0].rowData.push({
      values: [
        {
          userEnteredValue: {
            stringValue: 'Domain',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Notes',
          },
        },
      ],
    });
    this.GetFlex.forEach((cust) => {
      sheetBody.sheets[0].data[0].rowData.push({
        values: [
          {
            userEnteredValue: {
              stringValue: cust.customerDomain ?? '',
            },
          },
          {
            userEnteredValue: {
              stringValue: cust.flexNotes ?? '',
            },
          },
        ],
      });
    });
    //
    OrdersComponent._http
      .post(url, JSON.stringify(sheetBody), { headers: headers })
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        window.open(resp.spreadsheetUrl);
      })
      .catch((error) => {
        console.log(error);
        alert(error.error.error.message);
      });
  }
}
