<div class="container-fluid">
  <div class="row">
    <a href="javascript:void(0)" (click)="cancel()">&#60; Back </a>
  </div>

  <div class="row">
    <div class="col-lg-6 col-md-6 col-12 ordr_cust_info m-t-20">
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Created By</div>
          <div>{{ order?.usr_name }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Status</div>
          <div>{{ statusText }}</div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Customer Domain</div>
          <div>
            <input
              [(ngModel)]="order.updated_domain"
              class="col-12 m-t-5"
              [disabled]="!canUpdateDomain"
            />
          </div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Customer Name</div>
          <div>{{ order?.customer_name }}</div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Customer Region</div>
          <div>{{ order?.customer_region }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Customer Address</div>
          <div>{{ order?.customer_address }}</div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Customer Email</div>
          <div>
            <input
              [(ngModel)]="order.updated_email"
              class="col-12 m-t-5"
              [disabled]="!canUpdateDomain"
            />
          </div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Alternate Email</div>
          <div>
            <input
              [(ngModel)]="order.updated_alternate_email"
              class="col-12 m-t-5"
              [disabled]="!canUpdateDomain"
            />
          </div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Phone Number</div>
          <div>
            <input
              [(ngModel)]="order.updated_phone"
              class="col-12 m-t-5"
              [disabled]="!canUpdateDomain"
            />
          </div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Action</div>
          <div [ngClass]="{ cs_renewal: order.is_renewal_order == 1 }">
            {{ order?.action }}
          </div>
        </div>
      </div>
      <!-- <div class="row ordr_details_row" *ngIf="order.action.toLowerCase().indexOf('renewal') >= 0">
                <div class="col-12 p-15 border-right-grey">
                    <div class="f-w-600">Change Renewal Settings</div>
                    <button class="btn_white_bg m-t-5" (click)="updateRenewal()">Change</button>
                </div>
            </div> -->
      <div
        class="row ordr_details_row"
        *ngIf="order.order_status?.toLowerCase() == 'rejected'"
      >
        <div class="col-12 p-15 border-right-grey">
          <div class="f-w-600">Rejection Reason</div>
          <div>{{ order?.reject_reason }}</div>
        </div>
      </div>
      <div
        class="row ordr_details_row"
        *ngIf="
          order.approval1_name &&
          order.approval1_name != '' &&
          order.approved_date1
        "
      >
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Approver name1</div>
          <div>{{ order?.approval1_name }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Approval Date1</div>
          <div>{{ order?.approved_date1 }}</div>
        </div>
      </div>
      <div
        class="row ordr_details_row"
        *ngIf="
          order.approval2_name &&
          order.approval2_name != '' &&
          order.approved_date2
        "
      >
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Approver name2</div>
          <div>{{ order?.approval2_name }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Approval Date2</div>
          <div>{{ order?.approved_date2 }}</div>
        </div>
      </div>
      <div
        class="row ordr_details_row"
        *ngIf="
          order.gpm_approval_email &&
          order.gpm_approval_email != '' &&
          order.gpm_approval_date
        "
      >
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">GPM name</div>
          <div>{{ order?.gpm_approval_name }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">GPM Approval Date</div>
          <div>{{ order?.gpm_approval_date }}</div>
        </div>
      </div>
      <div
        class="row ordr_details_row"
        *ngIf="order.last_approval_email && order.last_approval_email != ''"
      >
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Executor name</div>
          <div>{{ order?.last_approval_name }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Execution Date</div>
          <div>{{ order?.last_approval_date }}</div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div
          class="col-6 p-15 border-right-grey"
          *ngIf="order.execution_note && order.execution_note != ''"
        >
          <div class="f-w-600">Approval notes</div>
          <div>{{ order?.execution_note }}</div>
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">With Google offer</div>
          <input
            style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
            type="checkbox"
            [disabled]="!currentUserRoleData?.toExecute"
            (change)="calculatePrices()"
            [(ngModel)]="order.with_google_offer"
          />
        </div>
        <div class="col-6 p-15" *ngIf="order.with_google_offer">
          <div class="f-w-600">Google Discount</div>
          <input
            type="number"
            min="0"
            [disabled]="!currentUserRoleData?.toExecute"
            (change)="calculatePrices()"
            max="100"
            [(ngModel)]="order.google_offer_percentage"
          />
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">With Customer offer</div>
          <input
            style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
            type="checkbox"
            [disabled]="!currentUserRoleData?.toExecute"
            (change)="calculatePrices()"
            [(ngModel)]="order.with_customer_offer"
          />
        </div>
        <div class="col-6 p-15" *ngIf="order.with_customer_offer">
          <div class="f-w-600">Customer Discount</div>
          <input
            type="number"
            min="0"
            [disabled]="!currentUserRoleData?.toExecute"
            (change)="calculatePrices()"
            max="100"
            [(ngModel)]="order.customer_offer_percentage"
          />
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Start Date</div>
          <div>
            <input
              (click)="fdate.toggle()"
              name="dp"
              [disabled]="!currentUserRoleData?.toExecute"
              [(ngModel)]="order.start_date"
              ngbDatepicker
              (ngModelChange)="calculatePrices()"
              placeholder="From"
              #fdate="ngbDatepicker"
              class="my-dp"
            />
          </div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">End Date</div>
          <input
            (click)="tdate.toggle()"
            name="dp"
            [disabled]="!currentUserRoleData?.toExecute"
            [(ngModel)]="order.commitment_expiry_date"
            ngbDatepicker
            (ngModelChange)="calculatePrices()"
            placeholder="From"
            #tdate="ngbDatepicker"
            class="my-dp"
          />
        </div>
      </div>
      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Selling Price</div>
          <input
            type="text"
            class="col-12"
            [disabled]="!currentUserRoleData?.toExecute"
            [(ngModel)]="order.selling_price"
          />
          <span class="">&#10;{{ order.currencyPeriodText }}</span>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Cost Price</div>
          <input
            type="text"
            [disabled]="!currentUserRoleData?.toExecute"
            class="col-12"
            [(ngModel)]="order.cost_price"
          />
        </div>
      </div>
      <div
        class="row ordr_details_row price_error"
        *ngIf="isSellingLess()"
      >
        Selling price is less than the cost pricce
      </div>

      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">P.O.#</div>
          <div>{{ order?.po_id }}</div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Sale invoice #</div>
          <input
            class="col-12"
            [(ngModel)]="order.order_sale_invoice_nbr"
            placeholder=" "
            autofocus
          />
        </div>
      </div>
      <div class="row ordr_details_row">
        <div
          class="col-6 p-15 border-right-grey"
          *ngIf="order.opportunity_nbr && order.opportunity_nbr != ''"
        >
          <div class="f-w-600">Opportunity Nbr.</div>
          <div>{{ order?.opportunity_nbr }}</div>
        </div>
        <div
          class="col-6 p-15"
          *ngIf="order.opportunity_source && order.opportunity_source != ''"
        >
          <div class="f-w-600">Source of opportunity</div>
          <div>{{ order?.opportunity_source }}</div>
        </div>
      </div>

      <div class="row ordr_details_row">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">Opportunity Status</div>
          <div class="">
            <ng-select
              bindLabel="status"
              appendTo="body"
              [searchable]="true"
              class="col-12"
              [clearable]="true"
              style="padding: 0 !important"
              [(ngModel)]="order.opportunity_status"
            >
              <ng-option
                [value]="status"
                *ngFor="let status of opportunityStatus"
              >
                {{ status }}
              </ng-option>
            </ng-select>
          </div>
        </div>
        <div class="col-6 p-15">
          <div class="f-w-600">Google Rep.</div>
          <input type="text" [(ngModel)]="order.google_rep" class="col-12" />
        </div>
      </div>

      <div class="row ordr_details_row">
        <div class="col-12 p-15">
          <div class="f-w-600">Description</div>
          <div>{{ order?.description }}</div>
        </div>
      </div>
      <div class="row ordr_details_row_last">
        <div class="col-6 p-15 border-right-grey">
          <div class="f-w-600">ID</div>
          <div>{{ order?.order_id }}</div>
        </div>
        <div
          class="col-6 p-15"
          *ngIf="order.billing_method && order.billing_method !== ''"
        >
          <div class="f-w-600">Contract</div>
          <div>{{ order?.billing_method }}</div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-12 m-t-20">
      <div class="ordr_cust_info p-15 ordr_details_section2">
        <div class="col-12 input-group p-0">
          <span class="border-lable-flt col-12 p-0">
            <ng-select
              bindLabel="product.sku_name"
              appendTo="body"
              [searchable]="true"
              [clearable]="false"
              class="col-12 p-0"
              placeholder="SKUs"
              [(ngModel)]="order.updated_sku"
            >
              <ng-option [value]="product" *ngFor="let product of products">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.sku_name }}">{{ item.sku_name }}</div>
                </ng-template>
                {{ product.sku_name }}
              </ng-option>
            </ng-select>
            <label for="label-name">Product</label>
          </span>
        </div>
        <div class="col-12 input-group p-0 m-t-20">
          <span class="border-lable-flt col-12 p-0">
            <ng-select
              bindLabel="plan.name"
              appendTo="body"
              [searchable]="true"
              [clearable]="false"
              class="col-12 p-0"
              placeholder="Plans"
              [(ngModel)]="order.updated_plan"
            >
              <ng-option [value]="plan" *ngFor="let plan of plans">
                <ng-template ng-option-tmp let-item="item">
                  <div title="{{ item.name }}">{{ item.name }}</div>
                </ng-template>
                {{ plan.name }}
              </ng-option>
            </ng-select>
            <label for="label-name">Plan</label>
          </span>
        </div>

        <div class="col-12 p-0 m-t-20">
          <span class="border-lable-flt col-2 p-0">
            <input
              type="number"
              class="col-lg-3 col-md-3 col-5 col-sm-5 p-0"
              (change)="calculatePrices()"
              [(ngModel)]="order.updated_licence"
              placeholder=" "
              autofocus
            />
            <label for="label-name">Seats</label>
          </span>
        </div>
      </div>

      <div
        class="p-15 m-t-10"
        id="div_policies"
        *ngIf="displayPolicies && googlePolicies && googlePolicies.length > 0"
      >
        <table>
          <tr class="font-weight-bold">
            <td colspan="4">Sales</td>
            <td rowspan="2">GPM Check</td>
            <td rowspan="2">Finance Check</td>
            <td rowspan="2">Comments</td>
          </tr>
          <tr class="font-weight-bold">
            <td>Yes</td>
            <td>No</td>
            <td>Description</td>
            <td>Amount</td>
          </tr>
          <ng-container *ngFor="let policy of googlePolicies">
            <tr>
              <td>
                <input type="checkbox" [(ngModel)]="policy.exist" />
              </td>
              <td>
                <input type="checkbox" [(ngModel)]="!policy.exist" />
              </td>
              <td class="f-14">
                <span>{{ policy.name }}</span>
                <span *ngIf="policy.percentage_value"
                  >({{ policy.percentage_value }}%)</span
                >
              </td>
              <td>
                <input style="border: none" [(ngModel)]="policy.amount" />
              </td>
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="policy.GPM_check"
                  [disabled]="currentUserRoleData.toExecute"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  [(ngModel)]="policy.finance_check"
                  [disabled]="currentUser.isGPM"
                />
              </td>
              <td>
                <input style="border: none" [(ngModel)]="policy.comments" />
              </td>
            </tr>
          </ng-container>
        </table>
        <button
          *ngIf="!canUpdate && !canSave"
          class="btn_blue_bg col-lg-2 col-md-3 col-5 col-sm-2"
          (click)="savePolicies()"
        >
          Save
        </button>
      </div>
      <div class="row justify-content-end m-t-15">
        <!-- <button class="btn_blue_bg" (click)="cancel()" type="button">Cancel</button> -->
        <button
          class="btn_blue_bg col-lg-2 col-md-3 col-5 col-sm-2"
          (click)="save()"
          type="submit"
          *ngIf="canSave"
        >
          {{ txtSave }}
        </button>
        <button
          class="btn_blue_bg col-lg-2 col-md-3 col-5 col-sm-2"
          (click)="withdrawOrder()"
          type="button"
          *ngIf="canWithdraw"
        >
          Withdraw
        </button>
        <button
          class="btn_blue_bg col-lg-2 col-md-3 col-5 col-sm-2"
          (click)="updateOrder()"
          type="button"
          *ngIf="canUpdate"
        >
          Update
        </button>
        <button
          class="btn_blue_bg col-lg-2 col-md-3 col-5 col-sm-2"
          (click)="delete()"
          type="button"
          *ngIf="canDelete"
        >
          {{ txtDelete }}
        </button>

        <div
          class="d-inline-block p-0"
          ngbDropdown
          #myDrop="ngbDropdown"
          *ngIf="canSave && currentUserRoleData?.toExecute"
        >
          <button
            class="btn_blue_bg"
            id="dropdownManual"
            ngbDropdownAnchor
            (focus)="myDrop.open()"
          >
            Place it as
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownManual" appendTo="body">
            <button ngbDropdownItem (click)="placeOrder(true)">
              Offline Order
            </button>
            <button ngbDropdownItem (click)="placeOrder(false)">
              Order with Offer
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- REJECTION POPUP-->
<div
  class="modal fade"
  bsModal
  #rejectModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="rejectModal.hide()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <ng-select
            bindLabel="rs.label"
            appendTo="body"
            [searchable]="true"
            class="col-12"
            (change)="selectRejectionReason()"
            [clearable]="true"
            placeholder="Reason"
            style="padding: 0 !important"
            [(ngModel)]="rejectReasonSelected"
          >
            <ng-option [value]="rs.id" *ngFor="let rs of rejectReasons">
              {{ rs.label }}
            </ng-option>
          </ng-select>
        </div>

        <div class="m-t-10">
          <textarea
            *ngIf="rejectReasonSelected && rejectReasonSelected == 2"
            rows="4"
            placeholder="enter the reason of rejection"
            class="col-12"
            [(ngModel)]="rejectReason"
          ></textarea>
          <button class="btn_blue_bg float-right" (click)="confirmRejection()">
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- EXECUTION POPUP-->
<div
  class="modal fade"
  bsModal
  #executionModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="executionModal.hide()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-12 p-0 m-0">
          <label class="col-4 p-0 font-weight-bold">Customer Email</label
          ><input
            class="col-8"
            placeholder="Customer Email"
            [(ngModel)]="customerEmail"
          />
        </div>
        <div style="font-size: 13px; font-weight: bold; margin-left: 10px">
          {{ fileHint }}
        </div>
        <div class="">
          <label class="font-weight-bold">Notes:</label>
          <textarea
            rows="4"
            class="col-12"
            [(ngModel)]="exectionNotes"
          ></textarea>
          <button class="btn_blue_bg float-right" (click)="execute()">
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- RENEWAL POPUP -->
<div
  class="modal fade"
  bsModal
  #renewalModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="renewalModal.hide()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div id="grp1">
          <div class="">
            <input
              class=""
              type="radio"
              value="AUTO_RENEW_YEARLY_PAY"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title"> AUTO_RENEW_YEARLY_PAY</span>
            <div class="">
              At the end of an annual commitment plan's interval, automatically
              renew the subscription's plan as ANNUAL_YEARLY_PAY with the same
              numberOfSeats
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="AUTO_RENEW_MONTHLY_PAY"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title"> AUTO_RENEW_MONTHLY_PAY</span>
            <div>
              At the end of an annual commitment plan's interval, automatically
              renew the subscriptions's plan as ANNUAL_MONTHLY_PAY with the same
              numberOfSeats
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="RENEW_CURRENT_USERS_YEARLY_PAY"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title">
              RENEW_CURRENT_USERS_YEARLY_PAY</span
            >
            <div>
              At the end of an annual commitment plan's interval, renew the
              subscription's plan as ANNUAL_YEARLY_PAY but use the total number
              of current active user licenses. This is the default setting for
              active annual commitment plans (paid yearly).
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="RENEW_CURRENT_USERS_MONTHLY_PAY"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title">
              RENEW_CURRENT_USERS_MONTHLY_PAY</span
            >
            <div>
              At the end of an annual commitment plan's interval, renew the
              subscription's plan as ANNUAL_MONTHLY_PAY but use the total number
              of current active user licenses. This is the default setting for
              active annual commitment plans (paid monthly).
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="SWITCH_TO_PAY_AS_YOU_GO"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title"> SWITCH_TO_PAY_AS_YOU_GO</span>
            <div>
              At the end of an annual commitment plan's interval, change the
              annual commitment plan to a flexible plan.
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="CANCEL"
              name="grp1"
              [(ngModel)]="order.updated_renewal_type"
            /><span class="spn_renewal_title"> CANCEL</span>
            <div>
              At the end of an annual commitment plan interval, the subscription
              is suspended. To understand how to lift a suspension.
            </div>
          </div>
          <div class="m-t-10 text-center">
            <button class="btn_blue_bg" (click)="saveRenwal()">save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
