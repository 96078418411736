import { NgSelectModule } from '@ng-select/ng-select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SaleDetailsRoutingModule } from './sale-details-routing.module';
import { SaleDetailsComponent } from './sale-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [SaleDetailsComponent],
  imports: [
    CommonModule,
    SaleDetailsRoutingModule,
    NgSelectModule,
    FormsModule,ReactiveFormsModule
  ]
})
export class SaleDetailsModule { }
