<div id='usr_content'>
    <div class="row col-12 m-0 p-0 justify-content-between h-55">
        <label class="page_title">{{pageTitle}}</label>
        <button (click)="AddNewSale()" class="btn_blue_bg m-0 h-fit-content" data-toggle="tooltip"
            title="create new order">
            <img class='ic_plus_white' src="../../assets/+.png" />
            Add an User
        </button>
    </div>
    <div class="div_table m-0 p-0">
        <ngx-datatable id="cust-table" class="bootstrap" [rows]="GetData" [columnMode]="'force'" [headerHeight]="50"
            [footerHeight]="50" rowHeight="auto" [externalPaging]="true" [count]="page.totalElements"
            [offset]="page.pageNumber" [limit]="page.size" (page)="pageChanged($event)">

            <ngx-datatable-column prop="user_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Name" headerClass="col-lg-3 col-md-5 col-sm-4 col-5" cellClass="col-lg-3 col-md-5 col-sm-4 col-5">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.user_name}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="user_email" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Email" headerClass="col-lg-3 col-md-3 col-sm-3 col-3 tbl_visibility tbl_visibility_tab" cellClass="col-lg-3 col-md-3 col-sm-3 col-3 tbl_visibility tbl_visibility_tab">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.user_email}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="country" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Country" headerClass="col-lg-2 col-md-2 col-sm-2 col-2 tbl_visibility tbl_visibility_tab" cellClass="col-lg-2 col-md-2 col-sm-2 col-2 tbl_visibility tbl_visibility_tab">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.country}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column prop="group_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                name="Group" headerClass="col-lg-2 col-md-4 col-sm-3 col-3 tbl_visibility" cellClass="col-lg-2 col-md-4 col-sm-3 col-3 tbl_visibility">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    {{row.group_name}}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column headerClass="tbl_cust_edit_col" headerClass="col-lg-2 col-md-3 col-sm-4 col-6" cellClass="col-lg-2 col-md-3 col-sm-4 col-6">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <div class="float-right">
                        <img class="m-r-20" src="../../assets/edit-icon-normal.png"
                            onmouseover="this.src='../../assets/edit-icon-hover.png'"
                            onmouseout="this.src='../../assets/edit-icon-normal.png'" (click)="editSale(row.usr_id)" />
                        <img class="m-r-20" src="../../assets/delete-icon-normal.png"
                            onmouseover="this.src='../../assets/delete-icon-hover.png'"
                            onmouseout="this.src='../../assets/delete-icon-normal.png'"
                            (click)="deleteSale(row.usr_id)" />
                    </div>
                </ng-template>
            </ngx-datatable-column>

        </ngx-datatable>
    </div>
</div>