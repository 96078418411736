import { element } from 'protractor';
import { UserViewModel } from './../../../../src/models/vw.user';
import { UserModel } from './../../../../src/models/user.model';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CustomerModel } from 'src/models/customer.model';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { StorageService } from 'src/servcies/storage-service';
import { Endpoint, Group, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'sales-sales',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  sales: UserViewModel[] = [];
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  static pageStatic;
  subdata: UserViewModel[] = [];
  static subdataStatic: UserViewModel[] = [];
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  currentUser: UserViewModel;
  static pageSizeStatic: number = Unicode.ITEMS_PER_PAGE;
  pageTitle: string = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private confirmDialogService: ConfirmDialogService,
    private localStorage: LocalStorageService
  ) {
    if (this.localStorage.get('reload') == '1') {
      this.localStorage.save('reload', '0');
      document.location.reload();
    }
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    SalesComponent.pageStatic = this.page;
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
  }

  ngOnInit(): void {
    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'Users';
    this.getSales({ offset: 0 }, '');
  }

  async getSales(pageInfo, search) {
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.page.size + '')
      .set(Unicode.LIMIT, this.pageSize + '');
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.SALES_FILTER, search);
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_SALE_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.page.totalElements = <number>resp['total'];
      if (resp.data)
        this.setPage({ offset: pageInfo.offset, data: resp['data'] });
      else this.setPage({ offset: pageInfo.offset, data: [] });
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const pagedData = new PagedData<UserViewModel>();
    this.page.totalPages = this.page.totalElements / this.page.size;
    pagedData.data.push(...pageInfo.data);
    pagedData.page = this.page;
    this.page = pagedData.page;
    SalesComponent.pageStatic = this.page;
    this.subdata = pagedData.data;
    this.subdata.forEach((user: UserViewModel) => {
      var tab = user.group_names?.split(',') ?? '';
      var set = new Set(tab);
      user.group_name = [...set].join(',');
    });
    SalesComponent.subdataStatic = this.subdata;
  }

  pageChanged(pageInfo) {
    this.getSales(pageInfo, '');
  }

  searchSale(event) {
    const val = event.target.value.toLowerCase();
    this.getSales({ offset: 0 }, val);
  }

  async AddNewSale() {
    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.GROUPS))) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.GROUPS_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.GROUPS, JSON.stringify(resp.groups));
      }
    }
    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.SALE_MANAGERS))) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SALE_MANAGERS_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(
          Unicode.SALE_MANAGERS,
          JSON.stringify(resp.saleManagers)
        );
      }
    }
    this.router.navigate(['/sales/sale-details'], {
      queryParams: { model: JSON.stringify(new CustomerModel()) },
      skipLocationChange: true,
    });
  }

  async editSale(id) {
    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.GROUPS))) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.GROUPS_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.GROUPS, JSON.stringify(resp.groups));
      }
    }
    if (Utilities.isNullOrEmpty(this.localStorage.get(Unicode.SALE_MANAGERS))) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SALE_MANAGERS_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(
          Unicode.SALE_MANAGERS,
          JSON.stringify(resp.saleManagers)
        );
      }
    }
    var user = this.subdata.find((x) => x.usr_id == id);
    if (!user) user = SalesComponent.subdataStatic.find((x) => x.usr_id == id);
    this.router.navigate(['/sales/sale-details'], {
      queryParams: { model: JSON.stringify(user) },
      skipLocationChange: true,
    });
  }

  deleteSale(id) {
    var that = this;
    var sale = this.subdata.find((x) => x.usr_id == id);
    if (!sale) sale = SalesComponent.subdataStatic.find((x) => x.usr_id == id);
    this.confirmDialogService.confirmThis(
      'Are you sure to delete ' + sale.user_name + '?',
      async function () {
        var params = new HttpParams().set(Unicode.USER_ID, id);
        var resp: any = await that.http
          .post(Endpoint.GLOBAL_URL + Endpoint.DELETE_SALE_FILE_NAME, null, {
            params: params,
          })
          .toPromise();
        console.log(resp);
        if (resp && !resp.msg) {
          alert('User Deleted');
          var users = JSON.parse(that.localStorage.get(Unicode.USERS) ?? '[]');
          var index = users.findIndex((x) => x.id == id);
          users.splice(index, 1);
          that.localStorage.save(Unicode.USERS, JSON.stringify(users));
          window.location.reload();
        } else alert(resp.msg);
      },
      function () {}
    );
  }

  public static searchGlobal(searchValue, user?, http?) {
    this.getSalesStatic({ offset: 0 }, searchValue, user, http);
  }

  static async getSalesStatic(pageInfo, search, user, http: HttpClient) {
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.pageStatic.size + '')
      .set(Unicode.LIMIT, this.pageSizeStatic + '');
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.SALES_FILTER, search);
    var resp = await http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_SALE_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.pageStatic.totalElements = <number>resp['total'];
      //  this.zone.run(() => {
      pageInfo.data = resp['data'] ?? [];
      this.pageStatic.pageNumber = pageInfo.offset;
      const pagedData = new PagedData<UserViewModel>();
      this.pageStatic.totalPages =
        this.pageStatic.totalElements / this.pageStatic.size;
      pagedData.data.push(...pageInfo.data);
      pagedData.page = this.pageStatic;
      this.pageStatic = pagedData.page;
      this.subdataStatic = pagedData.data;
      this.subdataStatic.forEach((user: UserViewModel) => {
        var tab = user.group_names?.split(',') ?? '';
        var set = new Set(tab);
        user.group_name = [...set].join(',');
      });
      // });
    }
  }
  get GetData() {
    return SalesComponent.subdataStatic;
  }
}
