import { Utilities } from './../../../../../../src/shared/utilities';
import { Unicode } from './../../../../../../src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { GroupModel } from 'src/models/group.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint } from 'src/shared/Endpoint';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';

@Component({
  selector: 'settings-group-list',
  templateUrl: './group-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./group-list.component.scss'],
})
export class GroupListComponent implements OnInit {
  groups: GroupModel[] = [];
  @ViewChild('AddGrpModal')
  AddGrpModal: ModalDirective;
  @ViewChild('AddGrpMsgModal')
  AddGrpMsgModal: ModalDirective;
  AddGrpForm: FormGroup;
  newGrpModel: GroupModel = new GroupModel();
  updateAddGrpMsg: string = '';
  permissions: any[];

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private httpRequestService: HttpRequestService,
    private fb: FormBuilder,
    private router: Router,
    private confirmDialogService: ConfirmDialogService
  ) {}

  async ngOnInit(): Promise<void> {
    var params = new HttpParams();
    this.http
      .get(Endpoint.GLOBAL_URL + '' + Endpoint.GRPS_FILE_NAME, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp && resp.groups) this.groups = resp.groups;
      })
      .catch((err) => {
        console.error(err);
      });
    //ADDING GRP FORM
    this.AddGrpForm = this.fb.group({
      name: ['', Validators.required],
      email: [''],
      grpPermissions: [''],
      id: [''],
    });
    this.permissions = await Utilities.getPermissions(
      this.http,
      this.localStorage
    );
  }

  AddNewGroup() {
    this.newGrpModel = new GroupModel();
    this.AddGrpForm.reset();
    this.AddGrpModal.show();
  }

  manageMembers(grp: GroupModel) {
    this.localStorage.save(Unicode.CURRENT_GRP, JSON.stringify(grp));
    this.router.navigate(['settings/groups/group-members']);
  }

  addGroup() {
    if (this.AddGrpForm.valid) {
      this.newGrpModel.name = this.AddGrpForm.value.name;
      this.newGrpModel.email = this.AddGrpForm.value.email;
      var isAdd = Utilities.isNullOrEmpty(this.newGrpModel.id);
      var params = new HttpParams().set(
        Unicode.IS_ADD_OPERATION,
        isAdd ? '1' : '0'
      );
      var newGrpPerms = [];
      var deletedGrpPerms = [];
      if (isAdd) {
        newGrpPerms.push(...(this.grpPermissions.value ?? []));
      } else {
        newGrpPerms =  this.grpPermissions.value ? 
          this.grpPermissions.value.filter((x) => this.newGrpModel.initPermissions.indexOf(x) < 0) : [];
        deletedGrpPerms =this.newGrpModel.initPermissions ?
          this.newGrpModel.initPermissions.filter((x) => this.grpPermissions.value.indexOf(x) < 0) : [];
      }
      this.newGrpModel.permissions = newGrpPerms;
      this.newGrpModel.deletedPermissions = deletedGrpPerms;
      this.http
        .post(
          Endpoint.GLOBAL_URL + '' + Endpoint.GRPS_FILE_NAME,
          JSON.stringify(this.newGrpModel),
          {
            params: params,
          }
        )
        .toPromise()
        .then(async (resp: any) => {
          if (resp && !resp.msg && resp.groupId) {
            this.AddGrpModal.hide();
            this.newGrpModel.id = resp.groupId;
            this.updateAddGrpMsg = isAdd
              ? 'Created successfully'
              : 'Updated successfully';
            await Utilities.updateUsersGroupsPermissions(
              this.http,
              this.localStorage
            );
            if (isAdd) {
              this.groups.push(this.newGrpModel);
              var newGrp = new GroupModel();
              newGrp.id = this.newGrpModel.id+"";
              newGrp.name = this.newGrpModel.name;
              newGrp.email = this.newGrpModel.email;
              var allGrps = JSON.parse(
                this.localStorage.get(Unicode.GROUPS) ?? '[]'
              );
              allGrps.push(newGrp);
              this.localStorage.save(Unicode.GROUPS, JSON.stringify(allGrps));
              this.AddGrpMsgModal.show();
            } else alert(this.updateAddGrpMsg);
          } else {
            alert(resp.msg ?? '');
          }
        });
    }
  }

  deleteGroup(grpId) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Are you sure you want to delete this group and remove users from it?',
      async function () {
        var params = new HttpParams().set(Unicode.GROUP_ID, grpId + '');
        that.http
          .delete(Endpoint.GLOBAL_URL + '' + Endpoint.GRPS_FILE_NAME, {
            params: params,
          })
          .toPromise()
          .then((resp: any) => {
            if (resp && !resp.msg) {
              var index = that.groups.findIndex((x) => x.id == grpId);
              that.groups.splice(index, 1);
              that.localStorage.save(
                Unicode.GROUPS,
                JSON.stringify(that.groups)
              );
              alert('Deleted');
            } else {
              alert(resp.msg ?? '');
            }
          });
      },
      function () {}
    );
  }

  editGroup(grp: GroupModel) {
    var grpPerms = []; // GET THE UPDATE VALUES/ EACH TIME
    var grpPermisJson = this.localStorage.get(Unicode.GROUP_PERMISSIONS);
    if (grpPermisJson) grpPerms = JSON.parse(grpPermisJson);
    this.newGrpModel = grp;
    var perms = grpPerms
      .filter(
        (x) => !Utilities.isNullOrEmpty(x.group_id) && x.group_id == grp.id
      )
      .map((y) => y.permission_id);
    this.newGrpModel.permissions = perms;
    this.newGrpModel.initPermissions = perms;
    this.AddGrpForm.controls.name.setValue(grp.name);
    this.AddGrpForm.controls.email.setValue(grp.email);
    this.AddGrpForm.controls.id.setValue(grp.id);
    this.AddGrpForm.controls.grpPermissions.setValue(perms);
    this.AddGrpModal.show();
  }

  get name() {
    return this.AddGrpForm.get('name');
  }
  get email() {
    return this.AddGrpForm.get('email');
  }
  get grpPermissions() {
    return this.AddGrpForm.get('grpPermissions');
  }
}
