<div id="trans-sub-div">
  <div class="row" *ngIf="viewDetails">
    <a href="javascript:void(0)" (click)="cancel()">&#60; Back </a>
  </div>
  <div class="row" *ngIf="viewDetails">
    <div class="col-lg-3 col-md-3 col-12 col-sm-6 p-15">
      <div class="f-w-600">Created By</div>
      <div>{{ order?.usr_name }}</div>
    </div>
    <div class="col-lg-3 col-md-3 col-12 col-sm-6 p-15">
      <div class="f-w-600">Status</div>
      <div>{{ statusText }}</div>
    </div>
    <div class="col-lg-3 col-md-3 col-12 col-sm-6 p-15">
      <div class="f-w-600">Customer Domain</div>
      <div>{{ order?.customer_domain }}</div>
    </div>
    <div class="col-lg-3 col-md-3 col-12 col-sm-6 p-15">
      <div class="f-w-600">Customer Auth Token</div>
      <div>{{ order?.auth_token }}</div>
    </div>
  </div>

  <div class="">
    <label class="lb_sub_tran m-b-15">Transferable Subscriptions</label>
    <ng-container *ngFor="let sub of model.transferableSubscriptions">
      <div class="sub-transf-card">
        <label class="f-18 col-12 p-b-5">{{ sub.productName }}</label>
        <div class="row col-12">
          <!-- <div class="col-lg-6 col-md-4 col-6 input-group p-0"> -->
          <a
            *ngIf="
              !viewDetails && sub.productName.toLowerCase().indexOf('drive') < 0
            "
            href="javascript:void(0)"
            (click)="upgrade(sub.subscriptionId)"
            data-toggle="tooltip"
            title="upgrade"
          >
            <img
              class="m-t-5"
              src="../../assets/upgrade-icon-normal.png"
              onmouseover="this.src='../../assets/upgrade-icon-hover.png'"
              onmouseout="this.src='../../assets/upgrade-icon-normal.png'"
              width="25"
            />
          </a>
          <ng-select
            [hidden]="!sub.upgrade"
            bindLabel="product.sku_name"
            appendTo="body"
            [searchable]="true"
            [clearable]="false"
            class="col-4 p-0"
            placeholder="SKUs"
            [(ngModel)]="sub.newSkuId"
          >
            <ng-option
              [value]="product.sku_id"
              *ngFor="let product of sub.products"
            >
              {{ product.sku_name }}
            </ng-option>
          </ng-select>
          <!-- </div> -->

          <div class="col-lg-3 col-md-4 col-6 input-group">
            <span class="border-lable-flt col-12 p-0">
              <ng-select
                bindLabel="plan.name"
                appendTo="body"
                [searchable]="true"
                [clearable]="true"
                class="col-12 p-0"
                placeholder="Plans"
                [(ngModel)]="sub.newPlan"
              >
                <ng-option [value]="plan" *ngFor="let plan of plans">
                  <ng-template ng-option-tmp let-item="item">
                    <div title="{{ item.name }}">{{ item.name }}</div>
                  </ng-template>
                  {{ plan.name }}
                </ng-option>
              </ng-select>
              <label for="label-name col-6">Plan</label>
            </span>
          </div>
          <div class="col-4">
            <span class="border-lable-flt col-2 p-0">
              <input
                type="number"
                class="col-lg-3 col-md-6 col-5 col-sm-5 p-0 inp-trans"
                [(ngModel)]="sub.seatsNbr"
                placeholder=" "
                autofocus
              />
              <label for="label-name">Seats</label>
            </span>
          </div>
        </div>
        <div class="row col-12">
          <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
            <input
              placeholder="Selling price/user *"
              [(ngModel)]="sub.sellingPrice"
              class="col-11 input-sty"
            />
          </div>
          <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
            <input
              placeholder="Cost Price *"
              [(ngModel)]="sub.costPrice"
              class="col-11 input-sty"
            />
          </div>
          <div class="col-1 grid-div m-t-15">
            <input
              style="width: 25px; margin: 0 10px 0 10px; height: 30px"
              type="checkbox"
              [(ngModel)]="sub.withOffer"
              data-toggle="tooltip"
              title="with offer"
            />
          </div>
          <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
            <ng-select
              bindLabel="currency.code"
              appendTo="body"
              [searchable]="true"
              class="col-12"
              [clearable]="true"
              placeholder="Currency *"
              style="padding: 0 !important"
              [(ngModel)]="sub.currencyRfId"
            >
              <ng-option
                [value]="currency.id"
                *ngFor="let currency of currencies"
              >
                {{ currency.code }}
              </ng-option>
            </ng-select>
          </div>
          <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
            <input
              placeholder="P.O# *"
              type="text"
              style="width: 100% !important"
              [(ngModel)]="sub.purchaseOrderId"
              class="col-12 input-sty"
            />
          </div>
        </div>
      </div>
    </ng-container>
    <label class="lb_sub_tran dis-block m-0">Order Details</label>
    <div class="row" *ngIf="viewDetails">
      <div
        class="col-3 p-15"
        *ngIf="order.approval1_name && order.approval1_name != ''"
      >
        <div class="f-w-600">Approver name 1</div>
        <div>{{ order?.approval1_name }}</div>
      </div>
      <div class="col-3 p-15" *ngIf="order.approved_date1">
        <div class="f-w-600">Approval Date1</div>
        <div>{{ order?.approved_date1 }}</div>
      </div>
      <div
        class="col-3 p-15"
        *ngIf="order.approval2_name && order.approval2_name != ''"
      >
        <div class="f-w-600">Approver name 2</div>
        <div>{{ order?.approval2_name }}</div>
      </div>
      <div class="col-3 p-15" *ngIf="order.approved_date2">
        <div class="f-w-600">Approval Date2</div>
        <div>{{ order?.approved_date2 }}</div>
      </div>
      <div
        class="col-3 p-15"
        *ngIf="order.gpm_approval_name && order.gpm_approval_name != ''"
      >
        <div class="f-w-600">GPM name</div>
        <div>{{ order?.gpm_approval_name }}</div>
      </div>
      <div class="col-3 p-15" *ngIf="order.gpm_approval_date">
        <div class="f-w-600">GPM Approval Date</div>
        <div>{{ order?.gpm_approval_date }}</div>
      </div>
      <div
        class="col-3 p-15"
        *ngIf="order.last_approval_name && order.last_approval_name != ''"
      >
        <div class="f-w-600">Executor name</div>
        <div>{{ order?.last_approval_name }}</div>
      </div>
      <div class="col-3 p-15" *ngIf="order.last_approval_date">
        <div class="f-w-600">Execution Date</div>
        <div>{{ order?.last_approval_date }}</div>
      </div>
      <div
        class="col-3 p-15"
        *ngIf="order.execution_note && order.execution_note != ''"
      >
        <div class="f-w-600">Execution Note</div>
        <div>{{ order?.execution_note }}</div>
      </div>
      <div class="col-3 p-15" *ngIf="order.reject_reason">
        <div class="f-w-600">Rejection Reason</div>
        <div>{{ order?.reject_reason }}</div>
      </div>
      <div class="col-3 p-15">
        <div class="f-w-600">ID</div>
        <div>{{ order?.order_id }}</div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
        <input
          placeholder="Sale invoice# *"
          style="width: 100% !important"
          type="text"
          [(ngModel)]="model.invoiceNbr"
          class="col-12 input-sty"
        />
      </div>
      <div class="col-lg-3 col-md-3 col-6 grid-div m-t-15">
        <input
          style="width: 100% !important"
          type="text"
          [(ngModel)]="model.customerEmailForSending"
          placeholder="Customer email to send on execution"
          class="col-12 input-sty"
        />
      </div>
      <div class="col-lg-2 col-md-4 col-6 grid-div m-t-15">
        <input
          style="width: 100% !important"
          type="text"
          [(ngModel)]="model.opportunityNbr"
          placeholder="Opportunity# *"
          class="col-12 input-sty"
        />
      </div>
      <div class="col-lg-3 col-md-3 col-6 grid-div m-t-15">
        <ng-select
          bindLabel="source"
          appendTo="body"
          [searchable]="true"
          class="col-11"
          [clearable]="true"
          placeholder="Source of Opportunity *"
          style="padding: 0 !important"
          [(ngModel)]="model.opportunitySource"
        >
          <ng-option [value]="source" *ngFor="let source of opportunitySources">
            {{ source }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-lg-2 col-md-3 col-6 grid-div m-t-15">
        <ng-select
          bindLabel="status"
          appendTo="body"
          [searchable]="true"
          class="col-12"
          [clearable]="true"
          placeholder="Opportunity status *"
          style="padding: 0 !important"
          [(ngModel)]="model.opportunityStatus"
        >
          <ng-option [value]="status" *ngFor="let status of opportunityStatus">
            {{ status }}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-lg-2 col-md-3 col-6 m-t-15">
        <input
          type="text"
          [(ngModel)]="model.googleRep"
          placeholder="Google Rep."
          class="col-12 input-sty"
        />
      </div>
      <div class="col-lg-4 col-3 grid-div m-t-15">
        <textarea
          rows="5"
          class="txt_desc"
          placeholder="Description"
          [(ngModel)]="model.description"
        ></textarea>
      </div>
      <div class="col-lg-3 grid-div m-t-15" *ngIf="!viewDetails">
        <div class="input-group">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              (change)="uploadDocument($event)"
            />
            <label class="custom-file-label" for="inputGroupFile01">{{
              uploadText
            }}</label>
          </div>
        </div>
      </div>
    </div>

    <!-- <div
      class="p-15 m-t-10"
      id="div_policies"
      *ngIf="displayPolicies && googlePolicies && googlePolicies.length > 0"
    >
      <table class="w-100">
        <tr class="font-weight-bold">
          <td colspan="4">Sales</td>
          <td rowspan="2">GPM Check</td>
          <td rowspan="2">Finance Check</td>
          <td rowspan="2">Comments</td>
        </tr>
        <tr class="font-weight-bold">
          <td>Yes</td>
          <td>No</td>
          <td>Description</td>
          <td>Amount</td>
        </tr>
        <ng-container *ngFor="let policy of googlePolicies">
          <tr>
            <td>
              <input type="checkbox" [(ngModel)]="policy.exist" />
            </td>
            <td>
              <input type="checkbox" [(ngModel)]="!policy.exist" />
            </td>
            <td class="f-14">
              <span>{{ policy.name }}</span>
              <span *ngIf="policy.percentage_value"
                >({{ policy.percentage_value }}%)</span
              >
            </td>
            <td>
              <input style="border: none" [(ngModel)]="policy.amount" />
            </td>
            <td>
              <input
                type="checkbox"
                [(ngModel)]="policy.GPM_check"
                [disabled]="currentUserRoleData.toExecute"
              />
            </td>
            <td>
              <input
                type="checkbox"
                [(ngModel)]="policy.finance_check"
                [disabled]="currentUser.isGPM"
              />
            </td>
            <td>
              <input style="border: none" [(ngModel)]="policy.comments" />
            </td>
          </tr>
        </ng-container>
      </table>
    </div> -->

    <div class="div_confirm" *ngIf="!viewDetails">
      <button class="btn_blue_bg" (click)="transferConfirmOrder()">
        Transfer
      </button>
      <button class="btn_blue_bg" (click)="transferConfirmOrder(true)">
        Save as draft
      </button>
      <button class="btn_blue_bg" (click)="cancelOrder()">Cancel order</button>
    </div>
    <div class="div_confirm row justify-content-end m-t-15" *ngIf="viewDetails">
      <button
        class="btn_blue_bg col-lg-1 col-md-2 col-5 col-sm-2"
        (click)="ConfirmOrder()"
        type="submit"
        *ngIf="canSave"
      >
        {{ txtSave }}
      </button>
      <button
        class="btn_blue_bg col-lg-1 col-md-2 col-5 col-sm-2"
        (click)="withdrawOrder()"
        type="button"
        *ngIf="canWithdraw"
      >
        Withdraw
      </button>
      <button
        class="btn_blue_bg col-lg-1 col-md-2 col-5 col-sm-2"
        (click)="updateOrder()"
        type="button"
        *ngIf="canUpdate"
      >
        Update
      </button>
      <button
        class="btn_blue_bg col-lg-1 col-md-2 col-5 col-sm-2"
        (click)="delete()"
        type="button"
        *ngIf="canDelete"
      >
        {{ txtDelete }}
      </button>
    </div>
  </div>
</div>

<!-- EXECUTION POPUP-->
<div
  class="modal fade"
  bsModal
  #executionModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="executionModal.hide()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="">
          <label class="font-weight-bold">Notes:</label>
          <textarea
            rows="4"
            class="col-12"
            [(ngModel)]="exectionNote"
          ></textarea>
          <button class="btn_blue_bg float-right" (click)="executePopupClick()">
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- REJECTION POPUP-->
<div
  class="modal fade"
  bsModal
  #rejectModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="rejectModal.hide()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <ng-select
            bindLabel="rs.label"
            appendTo="body"
            [searchable]="true"
            class="col-12"
            (change)="selectRejectionReason()"
            [clearable]="true"
            placeholder="Reason"
            style="padding: 0 !important"
            [(ngModel)]="rejectReasonSelected"
          >
            <ng-option [value]="rs.id" *ngFor="let rs of rejectReasons">
              {{ rs.label }}
            </ng-option>
          </ng-select>
        </div>

        <div class="m-t-10">
          <textarea
            *ngIf="rejectReasonSelected && rejectReasonSelected == 2"
            rows="4"
            placeholder="enter the reason of rejection"
            class="col-12"
            [(ngModel)]="rejectReason"
          ></textarea>
          <button class="btn_blue_bg float-right" (click)="confirmRejection()">
            Reject
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
