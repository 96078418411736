<div class="m-20">
  <ul class="nav nav-tabs m-b-10" id="report_tabs">
    <ng-container *ngFor="let tab of tabs">
      <li class="nav-item">
        <a
          style="color: white"
          class="nav-link"
          routerLink="{{ tab.link }}"
          [routerLinkActive]="['active']"
          [routerLinkActiveOptions]="{ exact: true }"
          >{{ tab.name }}</a
        >
      </li>
    </ng-container>
  </ul>
  <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs m-b-10">
    <ng-container *ngFor="let link of list">
      <li [ngbNavItem]="link.id">
        <a
          ngbNavLink
          [routerLink]="link.link"
          routerLinkActive
          #rla="routerLinkActive"
          active="rla.isActive"
        >
          {{ link.name }}
        </a>
      </li>
    </ng-container>
  </ul> -->

  <div #reseller_report_child>
    <router-outlet></router-outlet>
  </div>
</div>
