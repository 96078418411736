<div class="container-fluid">
  <div>
    <a href="javascript:void(0)" (click)="cancel()">&#60; Back </a>
  </div>
  <div class="usr_div col-lg-9 col-md-11 col-12">
    <label class="cust_title">Edit User</label>
    <form
      (ngSubmit)="onSubmit()"
      id="sale-form"
      [formGroup]="saleForm"
      role="form"
      #formDir="ngForm"
      novalidate
      class="ng-untouched ng-pristine ng-invalid row"
    >
      <!-- <div class="form-group input-group">
                <span class="border-lable-flt">
                    <input type="text" class="form-control" id="label-name" placeholder="Enter your name" required
                        autofocus>
                    <label for="label-name">Your Name</label>
                </span>
            </div> -->

      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <!-- <input type="text" name="name" formControlName="firstName" class="form-control"
                    [(ngModel)]="model.first_name" placeholder="First Name*"
                    [ngClass]="{ 'is-invalid': formDir.submitted && firstName.invalid }" required>
                <div class="invalid-feedback" *ngIf="formDir.submitted && firstName.invalid">
                    <p *ngIf="firstName.errors.required">First Name is required</p> 
                </div> -->
        <span class="border-lable-flt col-12 p-0">
          <input
            type="text"
            name="name"
            formControlName="firstName"
            class="form-control"
            [(ngModel)]="model.first_name"
            placeholder=" "
            [ngClass]="{ 'is-invalid': formDir.submitted && firstName.invalid }"
            required
            autofocus
          />
          <label for="label-name"
            >First Name<span class="spn_required"> *</span></label
          >
        </span>
        <div
          class="invalid-feedback"
          *ngIf="formDir.submitted && firstName.invalid"
        >
          <p *ngIf="firstName.errors.required">First Name is required</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <input
            type="text"
            name="name"
            class="form-control"
            formControlName="lastName"
            [(ngModel)]="model.last_name"
            placeholder=" "
            [ngClass]="{ 'is-invalid': formDir.submitted && lastName.invalid }"
            required
            autofocus
          />
          <label for="label-name"
            >Last Name<span class="spn_required"> *</span></label
          >
        </span>
        <div
          class="invalid-feedback"
          *ngIf="formDir.submitted && lastName.invalid"
        >
          <p *ngIf="lastName.errors.required">Last Name is required</p>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <input
            type="text"
            class="form-control"
            formControlName="email"
            [(ngModel)]="model.user_email"
            placeholder=" "
            [ngClass]="{ 'is-invalid': formDir.submitted && email.invalid }"
            required
            autofocus
          />
          <label for="label-name"
            >Email<span class="spn_required"> *</span></label
          >
        </span>
        <div
          class="invalid-feedback"
          *ngIf="formDir.submitted && email.invalid"
        >
          <p *ngIf="email.errors.required">Email is required</p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <input
            type="text"
            class="form-control"
            formControlName="country"
            [(ngModel)]="model.country"
            placeholder=" "
            autofocus
          />
          <label for="label-name">Country</label>
        </span>
      </div>
      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <input
            type="text"
            class="form-control"
            formControlName="phone"
            [(ngModel)]="model.phone"
            placeholder=" "
            autofocus
          />
          <label for="label-name">Phone</label>
        </span>
      </div>
      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <ng-select
            [multiple]="true"
            [(ngModel)]="selectedGroups"
            class="form-control text-capitalize"
            formControlName="group"
            [ngClass]="{ 'is-invalid': formDir.submitted && group.invalid }"
            placeholder=" "
            required
            style="padding: 0 !important; border: none !important"
            autofocus
          >
            <ng-option *ngFor="let group of groups" [value]="group.id">{{
              group.name
            }}</ng-option>
          </ng-select>
          <label for="label-name"
            >Groups<span class="spn_required"> *</span></label
          >
        </span>
        <div
          class="invalid-feedback"
          *ngIf="formDir.submitted && group.invalid"
        >
          <p *ngIf="group.errors.required">Group is required</p>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-12 m-t-15 input-group">
        <span class="border-lable-flt col-12 p-0">
          <ng-select
            [(ngModel)]="model.manager_rfid"
            class="form-control text-capitalize"
            placeholder=" "
            formControlName="manager"
            style="padding: 0 !important; border: none !important"
            autofocus
          >
            <ng-option *ngFor="let mang of saleManagers" [value]="mang.id">{{
              mang.user_name
            }}</ng-option>
          </ng-select>
          <label for="label-name">Manager</label>
        </span>
      </div>

      <ng-conatiner formArrayName="usrPermissions"  class="row col-12">
        <ng-container *ngFor="let permForm of usrPermissions.controls; let index = index;">
          <div [formGroupName]="index"  class="col-12 col-lg-4 col-md-4 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
            <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;"
              formControlName="checked"
              id="checked"
              type="checkbox"
              class="form-control mb-4"
            />
           <label class="m-t-5 p-0">{{permForm.value.name}}</label>
          </div>
        </ng-container>
      </ng-conatiner>

      <div class="row col-12">
        <!-- <div *ngFor="let perm of test.controls; let i = index" formArrayName="test" >
               <div class="col-12 col-lg-4 col-md-4 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
                    <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;" type="checkbox" class="form-control"
                    formControlName="name" id="{{'name'+i}}" >
                  <label class="m-t-5 p-0">{{perm.name}}</label>
                </div>        
             </div> -->
        <!-- <div class="col-12 col-lg-4 col-md-4 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
                <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;" type="checkbox" class="form-control"
                    formControlName="approve1" [(ngModel)]="model.approve1">
                <label class="m-t-5 p-0">Approve/Level 1</label>
            </div>
            <div class="col-12 col-lg-4 col-md-4 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
                <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;" type="checkbox" class="form-control"
                    formControlName="approve2" [(ngModel)]="model.approve2">
                <label class="m-t-5 p-0">Approve/Level 2</label>
            </div>
            <div class="col-12 col-lg-4 col-md-4 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
                <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;" type="checkbox" class="form-control"
                    formControlName="canExecute" [(ngModel)]="model.canExecute">
                <label class="m-t-5 p-0">Execution</label>
            </div>
            <div class="col-12 col-lg-8 col-md-6 col-sm-6 m-t-15 row" style="display: flex;margin-top: 32px;">
                <input style="width: 25px!important;margin: 0 10px 0 10px;height: 30px!important;" type="checkbox" class="form-control"
                    formControlName="receiveEmailOnExecution" [(ngModel)]="model.receiveEmailOnExecution">
                <label class="m-t-5 p-0 col-9">Receive email on execution(user created the order and his managers will receive it automatically,this persmission is for the others)</label>
            </div> -->
      </div>

      <div class="div_btns p-0">
        <button class="btn_blue_bg col-lg-1 col-md-1 col-sm-2 col-2">
          Save
        </button>
      </div>
    </form>
  </div>
</div>
