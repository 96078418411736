import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'settings-permissions',
  templateUrl: './permissions.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./permissions.component.scss'],
})
export class PermissionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
