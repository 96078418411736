import { RequestResultModel } from './../../../../../src/models/request_result.model';
import { Permissions } from './../../../../../src/shared/Endpoint';
import { UserViewModel } from './../../../../../src/models/vw.user';
import { Utilities } from './../../../../../src/shared/utilities';
import {
  Endpoint,
  Unicode,
  Status,
  OrderStatus,
  PlanValues,
  Group,
} from 'src/shared/Endpoint';
import { Router, ActivatedRoute } from '@angular/router';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  NgZone,
  ViewChild,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OrderViewModel } from 'src/models/vw_order';
import { StorageService } from 'src/servcies/storage-service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Encryption } from 'src/servcies/Encryption';
import { ModalDirective } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { DatePipe } from '@angular/common';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { GooglePolicyModel } from 'src/models/google.policy.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'lib-order-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  order: OrderViewModel = new OrderViewModel();
  plans = [];
  products = [];
  txtSave: string = 'Confirm';
  txtDelete: string = 'Delete';
  currentUser: UserViewModel;
  canDelete: boolean;
  canSave: boolean;
  canWithdraw: boolean;
  canUpdate: boolean = false;
  executorEmails = [];
  gpmEmails = [];
  canUpdateDomain: boolean;
  @ViewChild('rejectModal')
  rejectModal: ModalDirective;
  @ViewChild('renewalModal')
  renewalModal: ModalDirective;
  @ViewChild('executionModal')
  executionModal: ModalDirective;
  rejectReason: string;
  exectionNotes: string;
  statusText: string;
  currentUserRoleData: UserRoleDataModel;
  popupUpdate: boolean;
  usrs: any;
  receiveEmailOnExecGrp: string[] = [];
  receiveEmailAfterCreationGrp: string[] = [];
  currentStatus;
  customerEmail: string;
  fileHint: string;
  displayPolicies: boolean;
  viewPolicies: boolean = true;
  googlePolicies: GooglePolicyModel[] = [];
  initialGooglePolicies: GooglePolicyModel[] = [];
  opportunityStatus: string[] = [];
  rejectReasons = [
    { id: 1, label: 'Processed manually' },
    { id: 2, label: 'Others' },
  ];
  rejectReasonSelected;
  prices = [];
  placeOrderFlag: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private storage: StorageService,
    private httpRequestService: HttpRequestService,
    private http: HttpClient,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe
  ) {
    this.opportunityStatus = ['Submitted', 'Approved'];
  }

  async ngOnInit(): Promise<void> {
    this.gpmEmails = await Utilities.getGPMemails(this.http, this.localStorage);
    this.receiveEmailOnExecGrp = Utilities.receiveEmailOnExecGrp(
      this.localStorage
    );
    this.receiveEmailAfterCreationGrp = Utilities.receiveEmailAfterCreationGrp(
      this.localStorage
    );
    this.executorEmails = Utilities.executorsGrp(this.localStorage);

    this.usrs = this.localStorage.get(Unicode.USERS);
    if (this.usrs) {
      this.usrs = JSON.parse(this.usrs);
    } else this.usrs = [];
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.products = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    await this.route.queryParams
      .pipe(take(1))
      .toPromise()
      .then(async (params) => {
        var orderId = params['orderId'];
        if (orderId) {
          var reqParams = new HttpParams().set(Unicode.ORDER_ID, orderId);
          var resp = await this.http
            .get<any>(
              Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME,
              {
                params: reqParams,
              }
            )
            .toPromise();
          if (resp && !resp.success && !resp.msg) {
            this.order = resp;
          }
        } else {
          this.order = JSON.parse(this.localStorage.get(Unicode.ORDER));
        }
      });

    this.zone.run(() => {
      this.order.updated_domain = this.order.customer_domain;
      Utilities.setOrder(this.order, this.products, this.plans);
      this.order.orderEmailSubjectAction = Utilities.setOrderEmailSubjectAction(
        this.order
      );
      this.setLayout();
      if (
        !Utilities.isNullOrEmpty(this.order.new_plan_rfid) &&
        this.order.new_plan_rfid !== 0
      )
        this.order.updated_plan = this.plans.find(
          (x) => this.order.new_plan_rfid == x.id
        );
      else
        this.order.updated_plan = this.plans.find(
          (x) => this.order.old_plan_id == x.id
        );
      if (!Utilities.isNullOrEmpty(this.order.new_subscription_values)) {
        this.order.updated_sku = this.products.find(
          (x) => this.order.new_subscription_values.split(';')[0] == x.sku_id
        );
      } else {
        this.order.updated_sku = this.products.find((x) =>
          this.order.new_sku_id.indexOf(';') < 0
            ? x.sku_id == this.order.new_sku_id
            : x.sku_id == this.order.new_sku_id.split(';')[0]
        );
      }
      this.order.updated_licence = this.order.new_licence_number;
      this.order.updated_email = this.order.customer_email;
      this.order.updated_alternate_email = this.order.customer_alternate_email;
      this.order.updated_phone = this.order.customer_phone;
      this.order.updated_renewal_type = this.order.renewel_type;
      if (this.order.is_withdraw == 0) {
        this.statusText =
          this.order.order_status.toUpperCase() == OrderStatus.Draft
            ? OrderStatus.Submitted
            : this.order.order_status;
      } else {
        this.statusText = OrderStatus.Withdraw;
      }
      this.canUpdateDomain = Number.parseInt(this.order.is_new_customer) == 1;
      this.order.currencyPeriodText =
        ' /' + (this.order.currency_code ?? '') + '/day';
      this.currentStatus = this.order.order_status;
      this.order.with_google_offer =
        this.order.with_google_offer == '1' || this.order.with_google_offer == 1
          ? true
          : false; // FOR CHECKBOX MAPPING
      this.order.with_customer_offer =
        this.order.with_customer_offer == '1' ||
        this.order.with_customer_offer == 1
          ? true
          : false;
      this.order.commitment_expiry_date = Utilities.setDatePickerDate(
        this.datePipe,
        this.order.commitment_expiry_date
      );
      this.order.start_date = Utilities.setDatePickerDate(
        this.datePipe,
        this.order.start_date
      );
      this.customerEmail = this.order.email_to_send_on_execution;
      this.fileHint = Utilities.isNullOrEmpty(this.order.invoice_file_rfid)
        ? '(Without invoice file)'
        : '(With invoice file)';
      //POLICIES
      if (
        (this.currentUser.isGPM || this.currentUserRoleData.toExecute) &&
        !Utilities.isOrderToUpdateLicensesOnly(
          this.order.licences_is_updated,
          this.order.plan_is_updated,
          this.order.old_sku_id,
          this.order.new_sku_id,
          this.order.new_subscription_values
        ) &&
        Number.parseInt(this.order.subscription_status_is_updated + '') == 0
      ) {
        this.displayPolicies = true;
        var parms = new HttpParams()
          .set(Unicode.ACTION, Unicode.ORDER_POLICIES)
          .set(Unicode.ORDER_ID, this.order.order_id + '');
        this.http
          .get(
            Endpoint.GLOBAL_URL + Endpoint.GOOGLE_INCENTIVES_POLICY_FILE_NAME,
            { params: parms }
          )
          .toPromise()
          .then(async (resp: any) => {
            if (resp && !resp.msg) {
              this.googlePolicies = resp?.policies ?? [];
              if (this.googlePolicies.length == 0) {
                this.googlePolicies = await Utilities.getGoogleIncentivesPolicy(
                  this.http,
                  this.localStorage
                );
                this.googlePolicies.forEach((element) => {
                  element.order_rfid = this.order.order_id;
                  if (!element.GPM_check) element.GPM_check = false;
                  if (!element.finance_check) element.finance_check = false;
                  if (!element.exist) element.exist = false;
                  if (!element.amount) element.amount = 0;
                  if (!element.comments) element.comments = '';
                  element.user_email = this.currentUser.user_email;
                });
              } else {
                this.googlePolicies.forEach((element) => {
                  element.order_rfid = this.order.order_id;
                  element.exist = Number.parseInt(element.exist + '') == 1;
                  element.finance_check =
                    Number.parseInt(element.finance_check + '') == 1;
                  element.GPM_check =
                    Number.parseInt(element.GPM_check + '') == 1;
                  if (!element.amount) element.amount = 0;
                  if (!element.comments) element.comments = '';
                  element.user_email = this.currentUser.user_email;
                });
              }
              this.initialGooglePolicies = JSON.parse(
                JSON.stringify(this.googlePolicies)
              );
            }
          });
      }
    });
  }

  setLayout() {
    if (this.currentUser.isSuspenser) {
      this.canSave = false;
      this.canDelete = false;
      this.canWithdraw = false;
      this.canUpdate = false;
    } else if (this.currentUser.isGPM) {
      if (
        (this.order.is_withdraw == 0 &&
          this.order.order_status == OrderStatus.Approved2) ||
        (this.order.is_withdraw == 0 &&
          this.order.order_status == OrderStatus.Approved1)
      ) {
        this.canSave = true;
        this.canUpdate = true;
        this.canDelete = true;
        this.canWithdraw = false;
        this.txtSave = 'Approve';
        this.txtDelete = 'Reject';
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canDelete = false;
        this.canWithdraw = false;
      }
    } else if (this.currentUserRoleData.toExecute) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Confirm';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
        this.canWithdraw = false;
      } else if (
        [
          OrderStatus.Pending.toString(),
          OrderStatus.Approved1,
          OrderStatus.Approved2,
          OrderStatus.Approved_by_GPM,
          OrderStatus.CONFIRMED,
        ].indexOf(this.order.order_status.toUpperCase()) >= 0
      ) {
        if (this.order.is_withdraw == 1) {
          this.canWithdraw = false;
          this.canSave = false;
          this.canUpdate = false;
          this.txtDelete = 'Cancel';
          this.canDelete = true;
        } else {
          this.txtSave = 'Execute';
          this.canSave = true;
          this.canDelete = true;
          this.canUpdate = true;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canDelete = false;
        this.canWithdraw = false;
        this.canUpdate = false;
      }
    } else if (this.currentUser.approve2) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Approve';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.txtSave = 'Approve';
          this.canSave = true;
          this.canUpdate = true;
          this.canDelete = true;
          this.canWithdraw = false;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Approved1) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.canSave = true;
          this.txtSave = 'Approve';
          this.canWithdraw = false;
          this.canDelete = true;
          this.txtDelete = 'Reject';
          this.canUpdate = true;
          this.canWithdraw = false;
        }
      } else if (this.order.order_status == OrderStatus.Approved2) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = true;
          this.canDelete = false;
        } else {
          this.canSave = false;
          this.canWithdraw = true;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canWithdraw = false;
        this.canDelete = false;
      }
    } else if (this.currentUser.approve1) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Approve';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.txtSave = 'Approve';
          this.canSave = true;
          this.canUpdate = true;
          this.canDelete = true;
          this.canWithdraw = false;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Approved1) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = true;
          this.canDelete = false;
        } else {
          this.canSave = false;
          this.canWithdraw = true;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Approved2) {
        this.canSave = false;
        this.canWithdraw = false;
        this.canDelete = false;
        this.canUpdate = false;
        this.canWithdraw = false;
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canWithdraw = false;
        this.canDelete = false;
      }
    } else if (this.currentUser.isJustSale) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Send for approval';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canWithdraw = false;
          this.canSave = false;
          this.canUpdate = true;
          this.canDelete = true;
        } else {
          this.canWithdraw = true;
          this.canSave = false;
          this.canUpdate = false;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canDelete = false;
        this.canUpdate = false;
        this.canDelete = false;
      }
    }

    if (
      this.order.subscription_is_new == 1 &&
      this.order.new_subscription_values
    ) {
      var tab = this.order.new_subscription_values.split(';');
      if (tab.length == 3) {
        this.order.product_name = this.products.find(
          (x) => x.sku_id == tab[0]
        ).sku_name;
        this.order.plan_code =
          tab[2] == PlanValues.ANNUAL ? PlanValues.ANNUAL_MONTHLY_PAY : tab[2];
        this.order.licences = tab[1];
      }
    }
  }

  async save() {
    if (typeof this.order.plan_is_updated == 'boolean')
      this.order.plan_is_updated = this.order.plan_is_updated ? '1' : '0';
    if (typeof this.order.licences_is_updated == 'boolean')
      this.order.licences_is_updated = this.order.licences_is_updated
        ? '1'
        : '0';
    if (typeof this.order.subscription_status_is_updated == 'boolean')
      this.order.subscription_status_is_updated = this.order
        .subscription_status_is_updated
        ? '1'
        : '0';
    this.order.is_withdraw = 0;

    if (this.currentUserRoleData.toExecute) {
      if (!Utilities.isNullOrEmpty(this.order.gpm_approval_email)) {
        if (
          this.order.is_renewal_order == 0 ||
          this.order.is_renewal_order == null
        ) {
          this.popupUpdate = false;
          this.executionModal.show();
        } else {
          var sub;
          await Utilities.getSubscriptionById(
            this.http,
            this.httpRequestService,
            this.order
          ).then((resp) => {
            sub = resp;
          });
          if (
            sub &&
            sub.plan &&
            sub.plan.planName.toUpperCase() == PlanValues.FLEXIBLE
          ) {
            // && (new Date().getTime()) >= sub.plan.commitmentInterval.endTime ??
            this.popupUpdate = false;
            this.executionModal.show();
          } else {
            this.offerFieldsTypes();
            var params = new HttpParams()
              .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
              .set(Unicode.ORDER_ID, this.order.order_id + '')
              .set(Unicode.STATUS_PARAM, OrderStatus.CONFIRMED)
              .set(Unicode.LAST_APPROVAL_EMAIL, this.currentUser.user_email)
              .set(Unicode.WITH_GOOGLE_OFFER, this.order.with_google_offer)
              .set(
                Unicode.GOOGLE_OFFER_PERCENTAFE,
                this.order.google_offer_percentage + ''
              )
              .set(Unicode.WITH_CUSTOMER_OFFER, this.order.with_customer_offer)
              .set(
                Unicode.CUSTOMER_OFFER_PERCENTAFE,
                this.order.customer_offer_percentage + ''
              )
              .set(Unicode.STARTDATE, this.order.start_date)
              .set(Unicode.ENDDATE, this.order.commitment_expiry_date)
              .set(Unicode.DESCRIPTION, this.order.description)
              .set(Unicode.NOTES, '');
            if (this.googlePolicies && this.googlePolicies.length > 0) {
              this.setOrderPoliciesAction();
              params = params.set(
                Unicode.POLICIES,
                JSON.stringify(this.googlePolicies)
              );
            }
            var resp: any = await this.http
              .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
                params: params,
              })
              .toPromise();
            console.log(resp);
            if (resp && !resp.msg) {
              var link_url =
                Endpoint.URL_EMAIL +
                'orders/order-details?fromEmail=1&orderId=' +
                this.order.order_id;
              var body2 =
                '<html><h4>' +
                this.currentUser.user_name +
                ' confirmed the order ' +
                "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
                "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.action +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.product_name +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.plan_code +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.new_licence_number +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.po_id +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.order_sale_invoice_nbr +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.customer_domain +
                '</td></tr></table>' +
                "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
                link_url +
                "'>here</a><label> to view the details</label></div></html>";
              // var to = !Utilities.isNullOrEmpty(this.order.approval1_email)
              //   ? this.order.approval1_email
              //   : this.currentUser.user_email.toLowerCase() !==
              //     this.order.usr_email.toLowerCase()
              //   ? this.order.usr_email
              //   : '';
              var TOs = [
                this.order.usr_email,
                this.order.approval1_email,
                this.order.approval2_email,
              ];
              TOs.push(...this.gpmEmails);
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                TOs,
                body2,
                this.order.orderEmailSubjectAction
              );
              alert('Sent');
              this.router.navigate(['orders']);
            }
          }
        }
      } else {
        var lastUsr = !Utilities.isNullOrEmpty(this.order.approval2_email)
          ? this.order.approval2_email
          : !Utilities.isNullOrEmpty(this.order.approval1_email)
          ? this.order.approval1_email
          : this.order.usr_email;
        var usrMang = this.usrs.find(
          (x) =>
            x.user_email.toLowerCase().trim() == lastUsr.toLowerCase().trim()
        )?.manager_email;
        var usrMang2 = !Utilities.isNullOrEmpty(usrMang)
          ? this.usrs.find(
              (x) =>
                x.user_email.toLowerCase().trim() ==
                usrMang.toLowerCase().trim()
            )?.manager_email
          : null;
        if (
          (!Utilities.isNullOrEmpty(usrMang) &&
            usrMang.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2) ||
          (!Utilities.isNullOrEmpty(usrMang2) &&
            usrMang2.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2)
        ) {
          var params = new HttpParams()
            .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
            .set(Unicode.ORDER_ID, this.order.order_id + '')
            .set(Unicode.STATUS_PARAM, OrderStatus.Approved2)
            .set(Unicode.APPROVAL2_EMAIL, this.currentUser.user_email);
          var resp: any = await this.http
            .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
              params: params,
            })
            .toPromise();
          console.log(resp);
          if (resp && !resp.msg) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              this.order.order_id;
            var body2 =
              '<html><h4>' +
              this.currentUser.user_name +
              ' confirmed the order ' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.action +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.product_name +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.plan_code +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.new_licence_number +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.customer_domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            // var to = !Utilities.isNullOrEmpty(this.order.approval1_email)
            //   ? this.order.approval1_email
            //   : this.currentUser.user_email.toLowerCase() !==
            //     this.order.usr_email.toLowerCase()
            //   ? this.order.usr_email
            //   : '';
            var TOs = [
              this.order.usr_email,
              this.order.approval1_email,
              this.order.approval2_email,
            ];
            TOs.push(...this.gpmEmails);
            await Utilities.sendMail(
              this.http,
              this.currentUser.user_email,
              TOs,
              body2,
              this.order.orderEmailSubjectAction
            );
            alert('Sent');
            this.router.navigate(['orders']);
          } else {
            alert(resp.msg);
          }
        } else if (
          Utilities.isOrderToUpdateLicensesOnly(
            this.order.licences_is_updated,
            this.order.plan_is_updated,
            this.order.old_sku_id,
            this.order.new_sku_id,
            this.order.new_subscription_values
          ) ||
          Number.parseInt(this.order.subscription_status_is_updated + '') == 1
        ) {
          this.popupUpdate = false;
          this.executionModal.show();
        } else {
          alert('GPM approval is required!!');
        }
      }
    } else if (this.currentUser.isGPM) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '')
        .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus)
        .set(Unicode.GPM_APPROVAL_EMAIL, this.currentUser.user_email);
      if (this.googlePolicies.length > 0) {
        this.setOrderPoliciesAction();
        params = params.set(
          Unicode.POLICIES,
          JSON.stringify(this.googlePolicies)
        );
      }
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      console.log(resp);
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' confirm an order ' +
          "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.new_licence_number +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        // var mang = !Utilities.isNullOrEmpty(this.order.approval2_email)
        //   ? this.order.approval2_email
        //   : !Utilities.isNullOrEmpty(this.order.approval1_email)
        //   ? this.order.approval1_email
        //   : this.order.usr_email;
        var toEmails = [
          this.order.usr_email,
          this.order.approval1_email,
          this.order.approval2_email,
        ];
        toEmails.push(...this.currentUserRoleData.receiverEmail);
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          toEmails,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        alert('Sent');
        this.router.navigate(['orders']);
      } else {
        alert(resp.msg);
      }
    } else if (
      this.currentUser.isJustSale &&
      !this.currentUser.approve1 &&
      !this.currentUser.approve2
    ) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '')
        .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus);
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' create an order ' +
          "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.new_licence_number +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          this.currentUserRoleData.receiverEmail,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        alert('Sent');
        this.router.navigate(['orders']);
      } else {
        alert(resp.msg);
      }
    } else if (
      this.currentUser.approve2 ||
      (!this.currentUser.isJustSale &&
        this.currentUser.groups.find((x) => x.name == Group.Accounting))
    ) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '')
        .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus)
        .set(Unicode.APPROVAL2_EMAIL, this.currentUser.user_email);
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      console.log(resp);
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var body2 =
          '<html><h4>' +
          this.currentUser.user_name +
          ' confirmed the order you created ' +
          "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.new_licence_number +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        // var to = !Utilities.isNullOrEmpty(this.order.approval1_email)
        //   ? this.order.approval1_email
        //   : this.currentUser.user_email.toLowerCase() !==
        //     this.order.usr_email.toLowerCase()
        //   ? this.order.usr_email
        //   : '';
        var TOs = [
          this.order.usr_email,
          this.order.approval1_email,
          this.order.approval2_email,
        ];
        TOs.push(...this.currentUserRoleData.receiverEmail);
        TOs.push(...this.gpmEmails);
        if (
          Utilities.isOrderToUpdateLicensesOnly(
            this.order.licences_is_updated,
            this.order.plan_is_updated,
            this.order.old_sku_id,
            this.order.new_sku_id,
            this.order.new_subscription_values
          ) ||
          Number.parseInt(this.order.subscription_status_is_updated + '') == 1
        )
          TOs.push(...this.receiveEmailAfterCreationGrp);
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          TOs,
          body2,
          this.order.orderEmailSubjectAction
        );
        alert('Sent');
        this.router.navigate(['orders']);
      } else {
        alert(resp.msg);
      }
    } else if (this.currentUser.approve1) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '')
        .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus)
        .set(Unicode.APPROVAL1_EMAIL, this.currentUser.user_email);
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      console.log(resp);
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' confirm an order ' +
          "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.new_licence_number +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        // var to =
        //   !Utilities.isNullOrEmpty(this.order.usr_email) &&
        //   this.currentUser.user_email.toLowerCase() !==
        //     this.order.usr_email.toLowerCase()
        //     ? this.order.usr_email
        //     : '';
        var TOs = [
          this.order.usr_email,
          this.order.approval1_email,
          this.order.approval2_email,
        ];
        TOs.push(...this.currentUserRoleData.receiverEmail);
        if (TOs.find((x) => x == this.gpmEmails[0])) {
          if (
            Utilities.isOrderToUpdateLicensesOnly(
              this.order.licences_is_updated,
              this.order.plan_is_updated,
              this.order.old_sku_id,
              this.order.new_sku_id,
              this.order.new_subscription_values
            ) ||
            Number.parseInt(this.order.subscription_status_is_updated + '') == 1
          )
            TOs.push(...this.receiveEmailAfterCreationGrp);
        }
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          TOs,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        alert('Sent');
        this.router.navigate(['orders']);
      } else {
        alert(resp.msg);
      }
    }
  }

  async execute() {
    this.executionModal.hide();
    this.order.execution_note = this.exectionNotes ?? '';
    var initCustomerId = this.order.customer_rfid;
    if (typeof this.order.plan_is_updated == 'boolean')
      this.order.plan_is_updated = this.order.plan_is_updated ? '1' : '0';
    if (typeof this.order.licences_is_updated == 'boolean')
      this.order.licences_is_updated = this.order.licences_is_updated
        ? '1'
        : '0';
    if (typeof this.order.subscription_status_is_updated == 'boolean')
      this.order.subscription_status_is_updated = this.order
        .subscription_status_is_updated
        ? '1'
        : '0';
    if (this.currentUserRoleData.toExecute) {
      //BY DEFAULT FOR THIS FUNCTION SO NO NEED TO ADD THIS IF STATMENT
      var executionSucces = true;
      if (!this.placeOrderFlag) {
        var headers;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        if (
          Number.parseInt(this.order.subscription_is_new + '') == 0 &&
          !Utilities.isNullOrEmpty(this.order.subscription_rfid)
        ) {
          //SKU
          if (
            !Utilities.isNullOrEmpty(this.order.new_sku_id) &&
            this.order.new_sku_id !== '0' &&
            this.order.old_sku_id !== this.order.new_sku_id
          ) {
            if (
              Utilities.isNullOrEmpty(this.order.new_plan_rfid) ||
              this.order.new_plan_rfid == 0
            )
              new_plan_name = this.plans.find(
                (x) => x.id == this.order.old_plan_id
              ).code;
            else
              new_plan_name = this.plans.find(
                (x) => x.id == this.order.new_plan_rfid
              ).code;
            if (
              new_plan_name == Unicode.FLEXIBLE_PLAN ||
              new_plan_name == Unicode.TRIAL_PLAN
            )
              isFlexibleOrTrialPlan = true;
            var newSKU =
              this.order.new_sku_id.indexOf(';') < 0
                ? this.order.new_sku_id
                : this.order.new_sku_id.split(';')[0];
            var subsc = {
              purchaseOrderId: this.order.po_id,
              customerId: this.order.customer_rfid,
              skuId: newSKU,
              seats: {},
              customerDomain: this.order.customer_domain,
              plan: {
                planName:
                  new_plan_name == PlanValues.ANNUAL
                    ? PlanValues.ANNUAL_MONTHLY_PAY
                    : new_plan_name,
              },
              renewalSettings: {
                renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
              },
            };
            if (isFlexibleOrTrialPlan)
              subsc.seats = {
                maximumNumberOfSeats:
                  this.order.new_licence_number == 0
                    ? this.order.old_licence_number
                    : this.order.new_licence_number,
              };
            else
              subsc.seats = {
                numberOfSeats:
                  this.order.new_licence_number == 0
                    ? this.order.old_licence_number
                    : this.order.new_licence_number,
              };
            var url =
              'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
              this.order.customer_rfid +
              '/subscriptions';
            if (
              subsc.skuId.indexOf(Unicode.GOOGLE_DRIVE_SKU_ID) >= 0 &&
              subsc.plan.planName !== PlanValues.FLEXIBLE
            ) {
              alert(
                'Google Drive  is only available as a monthly post-pay FLEXIBLE plan'
              );
              return;
            }
            // }
            var result: RequestResultModel = await this.httpRequestService.post(
              url,
              subsc,
              headers
            );
            if (result.success) {
              console.log(result.data);
              await Utilities.sendMail(
                this.http,
                'web@isolutions.sa',
                ['zaynab.mama@ismena.com'],
                JSON.stringify({ user: this.currentUser, body: subsc }),
                this.order.orderEmailSubjectAction
              );
              if (
                result.data &&
                result.data.trialSettings &&
                result.data.trialSettings.isInTrial
              ) {
                this.order.trial_expiry_date = this.datePipe.transform(
                  new Date(Number(result.data.trialSettings.trialEndTime)),
                  'yyyy-MM-dd hh:mm'
                );
              }
              if (
                result.data &&
                result.data.plan &&
                result.data.plan.isCommitmentPlan
              ) {
                this.order.commitment_expiry_date = this.datePipe.transform(
                  new Date(Number(result.data.plan.commitmentInterval.endTime)),
                  'yyyy-MM-dd hh:mm'
                );
              }
            } else {
              console.log(result.errorMessage);
              executionSucces = false;
              alert(result.errorMessage);
              return;
            }
          } else {
            //PLAN/SEATS
            var isFlexibleOrTrialPlan;
            var mustRenewOrder = false;
            if (this.order.is_renewal_order == 1) {
              var sub;
              await Utilities.getSubscriptionById(
                this.http,
                this.httpRequestService,
                this.order
              ).then((resp) => {
                sub = resp;
              });
              if (
                sub &&
                sub.plan &&
                sub.plan.planName.toUpperCase() == PlanValues.FLEXIBLE
              )
                mustRenewOrder = true;
            }
            if (
              Number.parseInt(this.order.plan_is_updated + '') == 1 ||
              mustRenewOrder
            ) {
              var new_plan_name;
              if (
                Utilities.isNullOrEmpty(this.order.new_plan_rfid) ||
                this.order.new_plan_rfid == 0
              )
                new_plan_name = this.plans.find(
                  (x) => x.id == this.order.old_plan_id
                ).code;
              else
                new_plan_name = this.plans.find(
                  (x) => x.id == this.order.new_plan_rfid
                ).code;
              if (
                new_plan_name == Unicode.FLEXIBLE_PLAN ||
                new_plan_name == Unicode.TRIAL_PLAN
              )
                isFlexibleOrTrialPlan = true;
              var planBody = {
                planName: 'ANNUAL_MONTHLY_PAY',
                seats: {},
                purchaseOrderId: this.order.po_id,
              };
              if (isFlexibleOrTrialPlan) {
                planBody.seats = {
                  maximumNumberOfSeats:
                    this.order.new_licence_number == 0
                      ? this.order.old_licence_number
                      : this.order.new_licence_number,
                };
              } else {
                planBody.seats = {
                  numberOfSeats:
                    this.order.new_licence_number == 0
                      ? this.order.old_licence_number
                      : this.order.new_licence_number,
                };
              }
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions/' +
                this.order.subscription_rfid +
                '/changePlan';
              var result: RequestResultModel =
                await this.httpRequestService.post(url, planBody, headers);
              if (result.success) {
                console.log(result.data);
                if (
                  result.data &&
                  result.data.trialSettings &&
                  result.data.trialSettings.isInTrial
                ) {
                  this.order.trial_expiry_date = this.datePipe.transform(
                    new Date(Number(result.data.trialSettings.trialEndTime)),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                if (
                  result.data &&
                  result.data.plan &&
                  result.data.plan.isCommitmentPlan
                ) {
                  this.order.commitment_expiry_date = this.datePipe.transform(
                    new Date(
                      Number(result.data.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                var renewelBody = {
                  renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
                };
                var url =
                  'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                  this.order.customer_rfid +
                  '/subscriptions/' +
                  this.order.subscription_rfid +
                  '/changeRenewalSettings';
                var renewalResult: RequestResultModel =
                  await this.httpRequestService.post(url, renewelBody, headers);
                if (renewalResult.success) {
                  console.log(renewalResult.data);
                  await Utilities.sendMail(
                    this.http,
                    'web@isolutions.sa',
                    ['zaynab.mama@ismena.com'],
                    JSON.stringify({
                      user: this.currentUser,
                      msg: 'changeRenewalSettings with pla, order page',
                      body: renewelBody,
                      domain: this.order.updated_domain,
                    }),
                    this.order.orderEmailSubjectAction
                  );
                  if (
                    renewalResult.data &&
                    renewalResult.data.trialSettings &&
                    renewalResult.data.trialSettings.isInTrial
                  ) {
                    this.order.trial_expiry_date = this.datePipe.transform(
                      new Date(
                        Number(renewalResult.data.trialSettings.trialEndTime)
                      ),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                  if (
                    renewalResult.data &&
                    renewalResult.data.plan &&
                    renewalResult.data.plan.isCommitmentPlan
                  ) {
                    this.order.commitment_expiry_date = this.datePipe.transform(
                      new Date(
                        Number(
                          renewalResult.data.plan.commitmentInterval.endTime
                        )
                      ),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                } else {
                  console.log(renewalResult.errorMessage);
                  await Utilities.sendMail(
                    this.http,
                    'web@isolutions.sa',
                    ['zaynab.mama@ismena.com'],
                    JSON.stringify({
                      user: this.currentUser,
                      msg: 'changeRenewalSettings with plan , error,order page',
                      body: renewelBody,
                      domain: this.order.updated_domain,
                    }),
                    this.order.orderEmailSubjectAction
                  );
                }
              } else {
                console.log(result.errorMessage);
                executionSucces = false;
                alert(result.errorMessage);
              }
            } else if (
              Number.parseInt(this.order.plan_is_updated + '') == 0 &&
              Number.parseInt(this.order.licences_is_updated + '') == 1
            ) {
              var body = {};
              var plan_name = this.plans.find(
                (x) => x.id == this.order.old_plan_id
              ).code;
              if (
                plan_name == Unicode.FLEXIBLE_PLAN ||
                plan_name == Unicode.TRIAL_PLAN
              ) {
                body = {
                  maximumNumberOfSeats: this.order.new_licence_number,
                };
              } else {
                body = {
                  numberOfSeats: this.order.new_licence_number,
                };
              }
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions/' +
                this.order.subscription_rfid +
                '/changeSeats';
              var result: RequestResultModel =
                await this.httpRequestService.post(url, body, headers);
              if (result.success) {
                if (
                  result.data &&
                  result.data.trialSettings &&
                  result.data.trialSettings.isInTrial
                ) {
                  this.order.trial_expiry_date = this.datePipe.transform(
                    new Date(Number(result.data.trialSettings.trialEndTime)),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                if (
                  result.data &&
                  result.data.plan &&
                  result.data.plan.isCommitmentPlan
                ) {
                  this.order.commitment_expiry_date = this.datePipe.transform(
                    new Date(
                      Number(result.data.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd hh:mm'
                  );
                }
              } else {
                console.log(result.errorMessage);
                executionSucces = false;
                alert(result.errorMessage);
              }
            }
            //STATUS
            if (
              Number.parseInt(this.order.subscription_status_is_updated + '') ==
              1
            ) {
              if (this.order.new_subscription_status == Status.Active) {
                var url =
                  'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                  this.order.customer_rfid +
                  '/subscriptions/' +
                  this.order.subscription_rfid +
                  '/activate';
                var result: RequestResultModel =
                  await this.httpRequestService.post(url, null, headers);
                if (result.success) {
                  console.log(result.data);
                  if (
                    result.data &&
                    result.data.trialSettings &&
                    result.data.trialSettings.isInTrial
                  ) {
                    this.order.trial_expiry_date = this.datePipe.transform(
                      new Date(Number(result.data.trialSettings.trialEndTime)),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                  if (
                    result.data &&
                    result.data.plan &&
                    result.data.plan.isCommitmentPlan
                  ) {
                    this.order.commitment_expiry_date = this.datePipe.transform(
                      new Date(
                        Number(result.data.plan.commitmentInterval.endTime)
                      ),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                } else {
                  console.log(result.errorMessage);
                  executionSucces = false;
                  alert(result.errorMessage);
                }
              } else if (
                this.order.new_subscription_status == Status.Suspended
              ) {
                var url =
                  'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                  this.order.customer_rfid +
                  '/subscriptions/' +
                  this.order.subscription_rfid +
                  '/suspend';
                var result: RequestResultModel =
                  await this.httpRequestService.post(url, null, headers);
                if (result.success) {
                  console.log(result.data);
                  if (
                    result.data.trialSettings &&
                    result.data.trialSettings.isInTrial
                  ) {
                    this.order.trial_expiry_date = this.datePipe.transform(
                      new Date(Number(result.data.trialSettings.trialEndTime)),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                  if (result.data.plan && result.data.plan.isCommitmentPlan) {
                    this.order.commitment_expiry_date = this.datePipe.transform(
                      new Date(
                        Number(result.data.plan.commitmentInterval.endTime)
                      ),
                      'yyyy-MM-dd hh:mm'
                    );
                  }
                } else {
                  console.log(result.errorMessage);
                  executionSucces = false;
                  alert(result.errorMessage);
                }
              }
            }
            if (Number.parseInt(this.order.start_paid_service + '') == 1) {
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions/' +
                this.order.subscription_rfid +
                '/startPaidService';
              var result: RequestResultModel =
                await this.httpRequestService.post(url, null, headers);
              if (result.success) {
                console.log(result.data);
                if (
                  result.data &&
                  result.data.trialSettings &&
                  result.data.trialSettings.isInTrial
                ) {
                  this.order.trial_expiry_date = this.datePipe.transform(
                    new Date(Number(result.data.trialSettings.trialEndTime)),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                if (
                  result.data &&
                  result.data.plan &&
                  result.data.plan.isCommitmentPlan
                ) {
                  this.order.commitment_expiry_date = this.datePipe.transform(
                    new Date(
                      Number(result.data.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd hh:mm'
                  );
                }
              } else {
                console.log(result.errorMessage);
                executionSucces = false;
                alert(result.errorMessage);
              }
            }
            if (Number.parseInt(this.order.transfer_to_direct + '') == 1) {
              var params = new HttpParams().set(
                'deletionType',
                'TRANSFER_TO_DIRECT'
              );
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions/' +
                this.order.subscription_rfid;
              var result = await this.httpRequestService.delete(
                url,
                headers,
                params
              );
              if (result.success) {
                console.log(result.data);
              } else {
                console.log(result.errorMessage);
                executionSucces = false;
                alert(result.errorMessage);
              }
            }
            if (Number.parseInt(this.order.update_renewel_settings + '') == 1) {
              var renewelBody = {
                renewalType: this.order.renewel_type,
              };
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions/' +
                this.order.subscription_rfid +
                '/changeRenewalSettings';
              var result: RequestResultModel =
                await this.httpRequestService.post(url, renewelBody, headers);
              if (result.success) {
                console.log(result.data);
                await Utilities.sendMail(
                  this.http,
                  'web@isolutions.sa',
                  ['zaynab.mama@ismena.com'],
                  JSON.stringify({
                    user: this.currentUser,
                    msg: 'changeRenewalSettings as request from order details page',
                    body: renewelBody,
                    domain: this.order.updated_domain,
                  }),
                  this.order.orderEmailSubjectAction
                );
                if (
                  result.data &&
                  result.data.trialSettings &&
                  result.data.trialSettings.isInTrial
                ) {
                  this.order.trial_expiry_date = this.datePipe.transform(
                    new Date(Number(result.data.trialSettings.trialEndTime)),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                if (
                  result.data &&
                  result.data.plan &&
                  result.data.plan.isCommitmentPlan
                ) {
                  this.order.commitment_expiry_date = this.datePipe.transform(
                    new Date(
                      Number(result.data.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd hh:mm'
                  );
                }
              } else {
                console.log(result.errorMessage);
                await Utilities.sendMail(
                  this.http,
                  'web@isolutions.sa',
                  ['zaynab.mama@ismena.com'],
                  JSON.stringify({
                    user: this.currentUser,
                    msg: 'changeRenewalSettings error from renewal ,order page',
                    body: renewelBody,
                    domain: this.order.updated_domain,
                  }),
                  this.order.orderEmailSubjectAction
                );
                executionSucces = false;
                alert(result.errorMessage);
              }
            }
          }
        }

        //NEW SUBSc
        else if (!Utilities.isNullOrEmpty(this.order.new_subscription_values)) {
          var tab = this.order.new_subscription_values.split(';');
          if (tab[2] == Unicode.FLEXIBLE_PLAN || tab[2] == Unicode.TRIAL_PLAN)
            isFlexibleOrTrialPlan = true;
          var new_subs = {
            purchaseOrderId: this.order.po_id,
            customerId: this.order.customer_rfid,
            skuId: tab[0],
            seats: {},
            customerDomain: this.order.customer_domain,
            plan: {
              planName: tab[2],
            },
            renewalSettings: {
              renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
            },
          };
          if (isFlexibleOrTrialPlan)
            new_subs.seats = { maximumNumberOfSeats: tab[1] };
          else new_subs.seats = { numberOfSeats: tab[1] };
          if (
            this.order.is_new_customer != null &&
            Number.parseInt(this.order.is_new_customer) == 1
          ) {
            var customer: any = await Utilities.getCustomerByIdDB(
              this.http,
              this.order.customer_rfid
            );
            let encryption = new Encryption();
            customer.password = encryption.decrypt(
              customer.password,
              Endpoint.AES_KEY
            );
            if (customer) {
              customer.alternate_email = this.order.updated_alternate_email;
              customer.email = this.order.updated_email;
              customer.domain = this.order.updated_domain.trim();
              customer.phone = this.order.updated_phone;
              var customerApiId = await Utilities.customerApi(
                this.http,
                this.httpRequestService,
                true,
                customer
              );
              //if (customerApiId && !Utilities.isNullOrEmpty(customerApiId)) {
              if (Utilities.isNullOrEmpty(customerApiId))
                customerApiId = customer.id;
              this.order.customer_rfid = customerApiId;
              new_subs.customerId = customerApiId;
              if (Utilities.isNullOrEmpty(this.order.auth_token)) {
                var url =
                  'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                  this.order.customer_rfid +
                  '/subscriptions';
                if (
                  new_subs.skuId.indexOf(Unicode.GOOGLE_DRIVE_SKU_ID) >= 0 &&
                  new_subs.plan.planName !== PlanValues.FLEXIBLE
                ) {
                  alert(
                    'Google Drive  is only available as a monthly post-pay FLEXIBLE plan'
                  );
                  return;
                }
              }
              //  else
              //   var url =
              //     'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
              //     this.order.customer_rfid +
              //     '/subscriptions?customerAuthToken=' +
              //     this.order.auth_token;
              var result: RequestResultModel =
                await this.httpRequestService.post(url, new_subs, headers);
              if (result.success) {
                console.log(result.data);
                await Utilities.sendMail(
                  this.http,
                  'web@isolutions.sa',
                  ['zaynab.mama@ismena.com'],
                  JSON.stringify({
                    user: this.currentUser,
                    msg: 'create sub,order page',
                    body: new_subs,
                  }),
                  this.order.orderEmailSubjectAction
                );
                if (
                  result.data &&
                  result.data.trialSettings &&
                  result.data.trialSettings.isInTrial
                ) {
                  this.order.trial_expiry_date = this.datePipe.transform(
                    new Date(Number(result.data.trialSettings.trialEndTime)),
                    'yyyy-MM-dd hh:mm'
                  );
                }
                if (
                  result.data &&
                  result.data.plan &&
                  result.data.plan.isCommitmentPlan
                ) {
                  this.order.commitment_expiry_date = this.datePipe.transform(
                    new Date(
                      Number(result.data.plan.commitmentInterval.endTime)
                    ),
                    'yyyy-MM-dd hh:mm'
                  );
                }
              } else {
                console.log(result.errorMessage);
                executionSucces = false;
                alert(result.errorMessage);
                //return;
              }
              //  }
              // else executionSucces = false;
            }
          } else {
            if (Utilities.isNullOrEmpty(this.order.auth_token)) {
              var url =
                'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
                this.order.customer_rfid +
                '/subscriptions';
              if (
                new_subs.skuId.indexOf(Unicode.GOOGLE_DRIVE_SKU_ID) >= 0 &&
                new_subs.plan.planName !== PlanValues.FLEXIBLE
              ) {
                alert(
                  'Google Drive  is only available as a monthly post-pay FLEXIBLE plan'
                );
                return;
              }
            }
            // else
            //   var url =
            //     'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            //     this.order.customer_rfid +
            //     '/subscriptions?customerAuthToken=' +
            //     this.order.auth_token;
            var result: RequestResultModel = await this.httpRequestService.post(
              url,
              new_subs,
              headers
            );
            if (result.success) {
              console.log(result.data);
              await Utilities.sendMail(
                this.http,
                'web@isolutions.sa',
                ['zaynab.mama@ismena.com'],
                JSON.stringify({
                  user: this.currentUser,
                  msg: 'create sub,order page',
                  body: new_subs,
                }),
                this.order.orderEmailSubjectAction
              );
              if (
                result.data &&
                result.data.trialSettings &&
                result.data.trialSettings.isInTrial
              ) {
                this.order.trial_expiry_date = this.datePipe.transform(
                  new Date(Number(result.data.trialSettings.trialEndTime)),
                  'yyyy-MM-dd hh:mm'
                );
              }
              if (
                result.data &&
                result.data.plan &&
                result.data.plan.isCommitmentPlan
              ) {
                this.order.commitment_expiry_date = this.datePipe.transform(
                  new Date(Number(result.data.plan.commitmentInterval.endTime)),
                  'yyyy-MM-dd hh:mm'
                );
              }
            } else {
              console.log(result.errorMessage);
              executionSucces = false;
              alert(result.errorMessage);
              //return;
            }
          }
        }
      }
      this.placeOrderFlag = false;
      this.order.trial_expiry_date = this.order.trial_expiry_date ?? null;
      this.order.commitment_expiry_date =
        this.order.commitment_expiry_date ?? null;
      if (
        this.order.is_new_customer != null &&
        Number.parseInt(this.order.is_new_customer) == 1 &&
        executionSucces
      ) {
        this.offerFieldsTypes();
        var custBody = {
          orderId: this.order.order_id,
          newcustomerId: this.order.customer_rfid,
          oldcustomerId: initCustomerId,
          domain: this.order.updated_domain,
          email: this.order.updated_email ?? '',
          alternateEmail: this.order.updated_alternate_email ?? '',
          phone: this.order.updated_phone ?? '',
          status: this.currentUserRoleData.orderStatus,
          lastEmail: this.currentUser.user_email,
          notes: this.order.execution_note ?? '',
          costPrice: this.order.cost_price ?? 0,
          sellingPrice: this.order.selling_price ?? 0,
          invoiceNbr: this.order.order_sale_invoice_nbr ?? '',
          opportunityStatus: this.order.opportunity_status ?? '',
          googleRep: this.order.google_rep ?? '',
          withGoogleOffer: this.order.with_google_offer,
          googleOfferPercentage: this.order.google_offer_percentage,
          withCustomerOffer: this.order.with_customer_offer,
          customerOfferPercentage: this.order.customer_offer_percentage,
          description: this.order.description,
          values:
            this.order.updated_sku.sku_id +
            ';' +
            this.order.updated_licence +
            ';' +
            this.order.updated_plan.code,
          isNormalExecution: '1',
          trialExpDate: this.datePipe.transform(
            this.order.trial_expiry_date,
            'yyyy-MM-dd hh:mm'
          ),
          commitmentExpDate: this.order.commitment_expiry_date,
          startDate: this.order.start_date,
          hasInvoiceFile: '',
          policies: [],
        };
        if (!Utilities.isNullOrEmpty(this.order.invoice_file_rfid)) {
          custBody.hasInvoiceFile = '1';
        }
        var params = new HttpParams();
        if (this.googlePolicies && this.googlePolicies.length > 0) {
          this.setOrderPoliciesAction();
          custBody.policies = this.googlePolicies;
        }
        var resp: any = await this.http
          .post(
            Endpoint.GLOBAL_URL + Endpoint.CONFIRM_CUSTOMER_FILE_NAME,
            JSON.stringify(custBody),
            { params: params }
          )
          .toPromise();
        console.log(resp);
        if (resp && !resp.msg) {
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            this.order.order_id;
          var txt = Utilities.isNullOrEmpty(this.order.manager_email)
            ? 'the order you created '
            : 'the order created by ' + this.order.usr_email;
          var body2 =
            '<html><h4>' +
            this.currentUser.user_name +
            ' confirmed ' +
            txt +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.action +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.product_name +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.plan_code +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.new_licence_number +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.customer_domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          //  if (!fromUpdateFunction) {
          var to_emails = [];
          to_emails.push(...this.gpmEmails);
          to_emails.push(...this.receiveEmailOnExecGrp);
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.approval2_email);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            to_emails,
            body2,
            this.order.orderEmailSubjectAction
          );

          if (!Utilities.isNullOrEmpty(this.customerEmail)) {
            var customerEmailBody = '';
            var cc = this.order.manager_email;
            if (!Utilities.isNullOrEmpty(resp.fileContent)) {
              customerEmailBody = Utilities.generateCustomerEmailBody(
                this.order.updated_domain,
                this.order.usr_email
              );
              var fileData = resp.fileContent;
              var fileName = resp.fileName ?? '';
              Utilities.sendMailWithFile(
                this.http,
                fileData,
                this.order.usr_email,
                [this.customerEmail],
                [cc, this.currentUser.user_email],
                'Invoice',
                customerEmailBody,
                fileName
              );
            } else {
              customerEmailBody =
                Utilities.generateCustomerEmailBodyWithoutFile(
                  this.order.updated_domain,
                  this.order.usr_email
                );
              await Utilities.sendMail(
                this.http,
                this.order.usr_email,
                [this.customerEmail],
                customerEmailBody,
                'Invoice',
                [cc, this.currentUser.user_email]
              );
            }
          }
          alert('Done');
          this.router.navigate(['orders']);
          // } else {
          //   this.router.navigate(['orders']);
          // }
        } else {
          alert(resp.msg);
        }
      } else if (executionSucces) {
        // UPDATE ORDER STATUS IN DB
        var resp: any;
        if (!this.popupUpdate) {
          this.offerFieldsTypes();
          var params = new HttpParams()
            .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION) //xxxx
            .set(Unicode.ORDER_ID, this.order.order_id + '')
            .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus)
            .set(Unicode.LAST_APPROVAL_EMAIL, this.currentUser.user_email)
            .set(Unicode.NOTES, this.order.execution_note)
            .set(Unicode.iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT, '1')
            .set(Unicode.WITH_GOOGLE_OFFER, this.order.with_google_offer)
            .set(
              Unicode.GOOGLE_OFFER_PERCENTAFE,
              this.order.google_offer_percentage + ''
            )
            .set(Unicode.WITH_CUSTOMER_OFFER, this.order.with_customer_offer)
            .set(
              Unicode.CUSTOMER_OFFER_PERCENTAFE,
              this.order.customer_offer_percentage + ''
            )
            .set(Unicode.STARTDATE, this.order.start_date)
            .set(Unicode.DESCRIPTION, this.order.description)
            .set(Unicode.ENDDATE, this.order.commitment_expiry_date)
            .set(
              Unicode.TRIAL_EXPIRY_DATE,
              this.datePipe.transform(
                this.order.trial_expiry_date,
                'yyyy-MM-dd hh:mm'
              )
            );
          // .set(
          //   Unicode.COMMITMENT_EXPIRY_DATE,
          //   this.datePipe.transform(
          //     this.order.commitment_expiry_date,
          //     'yyyy-MM-dd hh:mm'
          //   )
          // );
          if (this.googlePolicies && this.googlePolicies.length > 0) {
            this.setOrderPoliciesAction();
            params = params.set(
              Unicode.POLICIES,
              JSON.stringify(this.googlePolicies)
            );
          }
          resp = await this.http
            .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
              params: params,
            })
            .toPromise();
        } else {
          this.offerFieldsTypes();
          const formData: FormData = new FormData();
          formData.append('model', JSON.stringify(this.order));
          var prms = new HttpParams().set(
            Unicode.iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT,
            '1'
          );
          if (this.googlePolicies && this.googlePolicies.length > 0) {
            this.setOrderPoliciesAction();
            prms = prms.set(
              Unicode.POLICIES,
              JSON.stringify(this.googlePolicies)
            );
          }
          var resp: any = await this.http
            .post(
              Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME,
              formData,
              {
                params: prms,
              }
            )
            .toPromise();
        }
        console.log(resp);
        if (resp && !resp.msg) {
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            this.order.order_id;
          var txt = Utilities.isNullOrEmpty(this.order.manager_email)
            ? 'the order you created '
            : 'the order created by ' + this.order.usr_email;
          var body2 =
            '<html><h4>' +
            this.currentUser.user_name +
            ' confirmed ' +
            txt +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.action +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.product_name +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.plan_code +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.new_licence_number +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.order.customer_domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          // if (!fromUpdateFunction) {
          var to_emails = [];
          to_emails.push(...this.gpmEmails);
          to_emails.push(...this.receiveEmailOnExecGrp);
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to_emails.push(this.order.approval2_email);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            to_emails,
            body2,
            this.order.orderEmailSubjectAction
          );

          if (!Utilities.isNullOrEmpty(this.customerEmail)) {
            var cc = this.order.manager_email;
            var customerEmailBody = '';
            if (!Utilities.isNullOrEmpty(resp.fileContent)) {
              customerEmailBody = Utilities.generateCustomerEmailBody(
                this.order.updated_domain,
                this.order.usr_email
              );
              var fileData = resp.fileContent;
              var fileName = resp.fileName ?? '';
              Utilities.sendMailWithFile(
                this.http,
                fileData,
                this.order.usr_email,
                [this.customerEmail],
                [cc, this.currentUser.user_email],
                'Invoice',
                customerEmailBody,
                fileName
              );
            } else {
              customerEmailBody =
                Utilities.generateCustomerEmailBodyWithoutFile(
                  this.order.updated_domain,
                  this.order.usr_email
                );
              await Utilities.sendMail(
                this.http,
                this.order.usr_email,
                [this.customerEmail],
                customerEmailBody,
                'Invoice',
                [cc, this.currentUser.user_email]
              );
            }
          }

          alert('Done');
          this.router.navigate(['orders']);
          // } else {
          //   this.router.navigate(['orders']);
          // }
        } else {
          alert(resp.msg);
        }
      }
      // else {
      //   if (this.currentUserRoleData.toExecute) {
      //     var params = new HttpParams()
      //       .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
      //       .set(Unicode.ORDER_ID, this.order.order_id + '')
      //       .set(Unicode.STATUS_PARAM, this.currentStatus);
      //     var resp: any = await this.http
      //       .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
      //         params: params,
      //       })
      //       .toPromise();
      //     console.log(resp);
      //   }
      // }
      this.popupUpdate = false; //RESET IT
    }
  }

  cancel() {
    this.router.navigate(['/orders']);
  }

  async delete() {
    if (
      this.order.order_status == OrderStatus.Draft ||
      (this.currentUser.canExecute && this.order.is_withdraw == 1)
    ) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.DELETE_ORDER_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '');
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        alert('Deleted');
        this.router.navigate(['orders']);
      } else alert(resp.msg);
    } else {
      this.rejectModal.show();
    }
  }

  async confirmRejection() {
    if (
      this.rejectReasonSelected &&
      (this.rejectReasonSelected == 1 ||
        !Utilities.isNullOrEmpty(this.rejectReason))
    ) {
      if (Utilities.isNullOrEmpty(this.rejectReason))
        this.rejectReason = 'order processed manually';
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(Unicode.ORDER_ID, this.order.order_id + '')
        .set(Unicode.STATUS_PARAM, OrderStatus.Rejected)
        .set(Unicode.REJECT_REASON, this.rejectReason)
        .set(Unicode.FILE_ID, this.order.invoice_file_rfid + '' ?? '')
        .set(Unicode.REJECTER_EMAIL, this.currentUser.user_email);
      if (
        this.rejectReasonSelected &&
        this.rejectReasonSelected == 1 && //  proceeded manually
        !Utilities.isNullOrEmpty(this.order.invoice_file_rfid)
      )
        params = params.set(Unicode.DELETE_FILE, '1');
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var to = [this.order.usr_email];
        if (this.currentUserRoleData.toExecute) {
          to.push(...this.gpmEmails);
          to.push(...this.receiveEmailOnExecGrp);
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval2_email);
        } else if (this.currentUser.isGPM) {
          to = [
            this.order.approval2_email,
            this.order.approval1_email,
            this.order.usr_email,
          ];
          to.push(...this.receiveEmailOnExecGrp);
        } else if (this.currentUser.approve2) {
          to = [this.order.usr_email, this.order.approval1_email];
          to.push(...this.gpmEmails);
          // to = !Utilities.isNullOrEmpty(this.order.approval1_email)
          //   ? [this.order.approval1_email, this.order.usr_email]
          //   : this.currentUser.user_email.toLowerCase() !==
          //     this.order.usr_email.toLowerCase()
          //   ? [this.order.usr_email]
          //   : [];
        } else if (this.currentUser.approve1) {
          to =
            this.currentUser.user_email.toLowerCase() !==
            this.order.usr_email.toLowerCase()
              ? [this.order.usr_email]
              : [];
        }

        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' rejected the order(the reason of rejection:' +
          this.rejectReason +
          ')' +
          "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.new_licence_number +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          to,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        var upCst =
          'domain:' +
          this.order.updated_domain +
          ' toexecute: ' +
          this.currentUserRoleData.toExecute +
          ' new: ' +
          this.order.is_new_customer;
        await Utilities.writeLogEntry(
          this.http,
          {
            test: 'check service',
            body: {
              test: upCst,
            },
          },
          'Info'
        );
        if (
          !this.currentUserRoleData.toExecute ||
          (this.currentUserRoleData.toExecute &&
            Number.parseInt(this.order.is_new_customer) !== 1)
        ) {
          alert('Rejected');
          this.router.navigate(['orders']);
        } else if (
          this.rejectReasonSelected &&
          this.rejectReasonSelected == 1
        ) {
          Utilities.getCustomerByIdAPI(
            this.http,
            this.httpRequestService,
            this.order.updated_domain
          ).then(async (respAPI) => {
            var datar = JSON.stringify(respAPI);
            await Utilities.writeLogEntry(
              this.http,
              {
                test: 'check service2',
                body: {
                  test: datar,
                  oldCustomerID: this.order.customer_rfid,
                },
              },
              'Info'
            );
            if (respAPI && respAPI.postalAddress) {
              var cust: any = Utilities.mapFromApiToDbTable(respAPI);
              cust.status = 1;
              cust.is_offline = 0;
              cust.email = !Utilities.isNullOrEmpty(cust.email)
                ? cust.email
                : 'admin@' + cust.domain;
              cust.oldId = this.order.customer_rfid;
              var params = new HttpParams().set(Unicode.IS_ADD_OPERATION, '0');
              await this.http
                .post(
                  Endpoint.GLOBAL_URL + Endpoint.UPDATE_CUSTOMER_FILE_NAME,
                  JSON.stringify(cust),
                  { params: params }
                )
                .toPromise();
            }
            alert('Rejected');
            this.router.navigate(['orders']);
          });
        }
      } else alert(resp.msg);
    } else alert('Please add reason for rejection');
  }

  selectRejectionReason() {
    if (
      this.rejectReasonSelected == 1 &&
      Number.parseInt(this.order.is_new_customer) == 1
    ) {
      alert(
        'Please make sure that you have manually proceeded with this order,from the console,before clicking reject button,' +
          'otherwise do that then reject it, to add it to our portal.'
      );
    }
  }

  async withdrawOrder() {
    var params = new HttpParams()
      .set(Unicode.ACTION, Unicode.WITHDRAW_ORDER_ACTION)
      .set(Unicode.ORDER_ID, this.order.order_id + '');
    //.set(Unicode.STATUS_PARAM, OrderStatus.Rejected);
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
        params: params,
      })
      .toPromise();
    if (resp && !resp.msg) {
      this.order.is_withdraw = 1;
      this.localStorage.save(Unicode.ORDER, JSON.stringify(this.order));
      window.location.reload();
    } else alert(resp.msg);
  }

  async updateOrder() {
    this.order.is_withdraw = 0;
    //!Utilities.isNullOrEmpty(this.order.new_sku_id) && this.order.new_sku_id !== '0' && this.order.old_sku_id !== this.order.new_sku_id
    if (
      Number.parseInt(this.order.plan_is_updated + '') == 0 &&
      this.order.old_plan_id !== this.order.updated_plan.id
    ) {
      this.order.plan_is_updated = true;
    } else if (
      Number.parseInt(this.order.plan_is_updated + '') == 1 &&
      this.order.old_plan_id == this.order.updated_plan.id
    ) {
      this.order.plan_is_updated = false;
    }
    if (
      Number.parseInt(this.order.licences_is_updated + '') == 0 &&
      this.order.old_licence_number !== this.order.updated_licence
    ) {
      this.order.licences_is_updated = true;
    }
    if (
      (Utilities.isNullOrEmpty(this.order.new_sku_id) ||
        this.order.new_sku_id == '0') &&
      this.order.old_sku_id == this.order.updated_sku.sku_id
    ) {
      this.order.new_sku_id = '0';
    } else if (
      (Utilities.isNullOrEmpty(this.order.new_sku_id) ||
        this.order.new_sku_id == '0') &&
      this.order.old_sku_id !== this.order.updated_sku.sku_id
    ) {
      this.order.new_sku_id = this.order.updated_sku.sku_id;
    } else if (
      !Utilities.isNullOrEmpty(this.order.new_sku_id) &&
      this.order.new_sku_id !== this.order.old_sku_id
    ) {
      this.order.new_sku_id =
        this.order.new_sku_id.indexOf(';') < 0
          ? this.order.updated_sku.sku_id
          : this.order.updated_sku.sku_id +
            ';' +
            this.order.new_sku_id.split(';')[1];
    }
    this.order.new_plan_rfid = this.order.updated_plan.id;
    this.order.new_licence_number = this.order.updated_licence ?? 0;
    if (
      !Utilities.isNullOrEmpty(this.order.updated_renewal_type) &&
      !Utilities.isNullOrEmpty(this.order.renewel_type) &&
      this.order.updated_renewal_type.toLowerCase().trim() !==
        this.order.renewel_type.toLowerCase().trim()
    ) {
      this.order.renewel_type = this.order.updated_renewal_type;
      this.order.update_renewel_settings = true;
    }
    if (!Utilities.isNullOrEmpty(this.order.new_subscription_values))
      this.order.new_subscription_values =
        this.order.updated_sku.sku_id +
        ';' +
        this.order.updated_licence +
        ';' +
        this.order.updated_plan.code;
    else this.order.new_subscription_values = '';
    this.order.updated_phone ?? '';
    this.order.order_status = this.currentUserRoleData.orderStatus;
    if (this.currentUserRoleData.toExecute)
      this.order.last_approval_email = this.currentUser.user_email;
    else if (this.currentUser.isGPM)
      this.order.gpm_approval_email = this.currentUser.user_email;
    else if (this.currentUser.approve2)
      this.order.approval2_email = this.currentUser.user_email;
    else if (this.currentUser.approve1)
      this.order.approval1_email = this.currentUser.user_email;
    if (this.currentUserRoleData.toExecute) {
      if (!Utilities.isNullOrEmpty(this.order.gpm_approval_email)) {
        if (
          this.order.is_renewal_order == 0 ||
          this.order.is_renewal_order == null
        ) {
          this.popupUpdate = true;
          this.executionModal.show();
        } else {
          var sub: any = await Utilities.getSubscriptionById(
            this.http,
            this.httpRequestService,
            this.order
          );
          if (
            sub.plan.commitmentInterval &&
            sub.plan.commitmentInterval.endTime &&
            sub.plan.planName.toUpperCase() == PlanValues.FLEXIBLE
          ) {
            // && (new Date().getTime()) >= sub.plan.commitmentInterval.endTime ??
            this.popupUpdate = true;
            this.executionModal.show();
          } else {
            this.offerFieldsTypes();
            var params = new HttpParams()
              .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
              .set(Unicode.ORDER_ID, this.order.order_id + '')
              .set(Unicode.STATUS_PARAM, OrderStatus.CONFIRMED)
              .set(Unicode.WITH_GOOGLE_OFFER, this.order.with_google_offer)
              .set(
                Unicode.GOOGLE_OFFER_PERCENTAFE,
                this.order.google_offer_percentage + ''
              )
              .set(Unicode.WITH_CUSTOMER_OFFER, this.order.with_customer_offer)
              .set(
                Unicode.CUSTOMER_OFFER_PERCENTAFE,
                this.order.customer_offer_percentage + ''
              )
              .set(Unicode.STARTDATE, this.order.start_date)
              .set(Unicode.ENDDATE, this.order.commitment_expiry_date)
              .set(Unicode.DESCRIPTION, this.order.description)
              .set(Unicode.LAST_APPROVAL_EMAIL, this.currentUser.user_email);
            if (this.googlePolicies && this.googlePolicies.length > 0) {
              this.setOrderPoliciesAction();
              params = params.set(
                Unicode.POLICIES,
                JSON.stringify(this.googlePolicies)
              );
            }
            var resp: any = await this.http
              .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
                params: params,
              })
              .toPromise();
            console.log(resp);
            if (resp && !resp.msg) {
              var link_url =
                Endpoint.URL_EMAIL +
                'orders/order-details?fromEmail=1&orderId=' +
                this.order.order_id;
              var body2 =
                '<html><h4>' +
                this.currentUser.user_name +
                ' confirmed the order ' +
                "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
                "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.action +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.product_name +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.plan_code +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.new_licence_number +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.po_id +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.order_sale_invoice_nbr +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.customer_domain +
                '</td></tr></table>' +
                "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
                link_url +
                "'>here</a><label> to view the details</label></div></html>";
              var TOs = [
                this.order.usr_email,
                this.order.approval1_email,
                this.order.approval2_email,
              ];
              TOs.push(...this.gpmEmails);
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                TOs,
                body2,
                this.order.orderEmailSubjectAction
              );
              alert('Sent');
              this.router.navigate(['orders']);
            }
          }
        }
      } else {
        var lastUsr = !Utilities.isNullOrEmpty(this.order.approval2_email)
          ? this.order.approval2_email
          : !Utilities.isNullOrEmpty(this.order.approval1_email)
          ? this.order.approval1_email
          : this.order.usr_email;
        var usrMang = this.usrs.find(
          (x) =>
            x.user_email.toLowerCase().trim() == lastUsr.toLowerCase().trim()
        )?.manager_email;
        var usrMang2 = !Utilities.isNullOrEmpty(usrMang)
          ? this.usrs.find(
              (x) =>
                x.user_email.toLowerCase().trim() ==
                usrMang.toLowerCase().trim()
            )?.manager_email
          : null;
        if (
          (!Utilities.isNullOrEmpty(usrMang) &&
            usrMang.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2) ||
          (!Utilities.isNullOrEmpty(usrMang2) &&
            usrMang2.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2)
        ) {
          this.order.order_status = OrderStatus.Approved2;
          this.order.approval2_email = this.currentUser.user_email;
          this.order.last_approval_email = '';
          const formData: FormData = new FormData();
          formData.append('model', JSON.stringify(this.order));
          var resp: any = await this.http
            .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData)
            .toPromise();
          if (resp && !resp.msg) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              this.order.order_id;
            var body2 =
              '<html><h4>' +
              this.currentUser.user_name +
              ' updated the order ' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.action +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.product_name +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.plan_code +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.new_licence_number +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.customer_domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            // var receivers = !Utilities.isNullOrEmpty(this.order.approval1_email)
            //   ? this.order.approval1_email
            //   : this.currentUser.user_email.toLowerCase() !==
            //     this.order.usr_email.toLowerCase()
            //   ? this.order.usr_email
            //   : '';
            var TOs = [
              this.order.usr_email,
              this.order.approval1_email,
              this.order.approval2_email,
            ];
            TOs.push(...this.gpmEmails);
            await Utilities.sendMail(
              this.http,
              this.currentUser.user_email,
              TOs,
              body2,
              this.order.orderEmailSubjectAction
            );
            alert('Sent');
            this.router.navigate(['orders']);
          } else {
            alert(resp.msg);
          }
        } else if (
          Utilities.isOrderToUpdateLicensesOnly(
            this.order.licences_is_updated,
            this.order.plan_is_updated,
            this.order.old_sku_id,
            this.order.new_sku_id,
            this.order.new_subscription_values
          ) ||
          Number.parseInt(this.order.subscription_status_is_updated + '') == 1
        ) {
          this.popupUpdate = true;
          this.executionModal.show();
        } else {
          alert('GPM approval is required!!');
        }
      }
    } else {
      const formData: FormData = new FormData();
      formData.append('model', JSON.stringify(this.order));
      var params = new HttpParams();
      if (this.currentUser.isGPM && this.googlePolicies.length > 0) {
        this.setOrderPoliciesAction();
        params = params.set(
          Unicode.POLICIES,
          JSON.stringify(this.googlePolicies)
        );
      }
      var resp: any = await this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var updated_sku = this.products.find(
          (x) => x.sku_id == this.order.updated_sku.sku_id.split(';')[0]
        ).sku_name;
        var updated_plan = this.plans.find(
          (x) => x.id == this.order.new_plan_rfid
        )?.code;
        var newDomain = Utilities.isNullOrEmpty(this.order.updated_domain)
          ? this.order.customer_domain
          : this.order.updated_domain;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' updated an order ' +
          "</h4><br><label>Old:</label><br/><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.updated_licence +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'>" +
          '<label>New:</label><br/>' +
          "<table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          updated_sku +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          updated_plan +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.updated_licence +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          newDomain +
          '</td></tr></table>' +
          +"<label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        var to = this.currentUserRoleData.receiverEmail;
        if (this.currentUser.isJustSale) to.push(this.order.manager_email);
        if (this.currentUserRoleData.toExecute) {
          // TO BE REMOVED
          to.push(...this.gpmEmails);
          to.push(...this.receiveEmailOnExecGrp);
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval2_email);
        }
        if (this.currentUser.isGPM) {
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval2_email);
        }
        if (this.currentUser.approve2) {
          var mangs = [this.order.usr_email, this.order.approval1_email];
          to.push(...mangs);
          to.push(...this.gpmEmails);
        }
        if (this.currentUser.approve1) {
          var usr =
            this.currentUser.user_email.toLowerCase() !==
            this.order.usr_email.toLowerCase()
              ? this.order.usr_email
              : '';
          to.push(usr);
          //  to.push(...this.gpmEmails);
        }
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          to,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        alert('Order updated and sent');
        this.order.customer_alternate_email =
          this.order.updated_alternate_email;
        this.order.customer_email = this.order.updated_email;
        this.order.customer_domain = this.order.updated_domain;
        this.order.customer_phone = this.order.updated_phone;

        this.localStorage.save(Unicode.ORDER, JSON.stringify(this.order));
        window.location.reload();
      } else {
        alert(resp.msg);
      }
    }
  }

  async updateFromExecutor() {}

  updateRenewal() {
    this.renewalModal.show();
  }

  saveRenwal() {
    this.renewalModal.hide();
  }

  setOrderPoliciesAction() {
    this.googlePolicies.forEach((element) => {
      element.order_rfid = this.order.order_id;
      if (!Utilities.isNullOrEmpty(element.google_policy_rfid)) {
        var initial = this.initialGooglePolicies.find(
          (x) =>
            x.order_rfid == element.order_rfid &&
            x.google_policy_rfid == element.google_policy_rfid
        );
        if (
          initial.amount !== element.amount ||
          initial.GPM_check !== element.GPM_check ||
          initial.exist !== element.exist ||
          initial.finance_check !== element.finance_check ||
          initial.comments !== element.comments
        ) {
          element.action = 'A';
        } else element.action = 'N';
      } else {
        element.action = 'A';
      }
    });
  }

  async savePolicies() {
    this.setOrderPoliciesAction();
    var resp: any = await this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.GOOGLE_INCENTIVES_POLICY_FILE_NAME,
        JSON.stringify({ policies: this.googlePolicies })
      )
      .toPromise();
    console.log(resp);
    if (resp && !resp.msg) {
      alert('Saved');
    } else {
      alert(resp?.msg);
    }
  }

  calculatePrices(checkValidation: boolean = true) {
    if (
      Utilities.isNullOrEmpty(this.order.start_date) ||
      Utilities.isNullOrEmpty(this.order.commitment_expiry_date)
    ) {
      if (checkValidation) alert('Choose From/To Date');
      return;
    }
    var from_ = new Date(
      this.order.start_date.year,
      this.order.start_date.month - 1,
      this.order.start_date.day
    );
    var to_ = new Date(
      this.order.commitment_expiry_date.year,
      this.order.commitment_expiry_date.month - 1,
      this.order.commitment_expiry_date.day
    );
    var skuId =
      this.order.new_sku_id.indexOf(';') >= 0
        ? this.order.new_sku_id.split(';')[0]
        : this.order.new_sku_id;
    var days = Utilities.calculateDiffDays(from_, to_);
    var priceObj = this.prices.find(
      (x) => x.sku_rfid == skuId && x.plan_rfid == this.order.new_plan_rfid
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costPrice: number = price * Unicode.COST_MARGIN * days;
      var sellingPrice: number = price * days;
      //OFFERS
      if (
        this.order.with_google_offer &&
        this.order.google_offer_percentage &&
        this.order.google_offer_percentage != 0
      ) {
        var offer = (100 - this.order.google_offer_percentage) / 100;
        costPrice *= offer;
      }
      if (
        this.order.with_customer_offer &&
        this.order.customer_offer_percentage &&
        this.order.customer_offer_percentage != 0
      ) {
        var offer = (100 - this.order.customer_offer_percentage) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = 0;
      var isUpdateLicenses = Utilities.isOrderToUpdateLicensesOnly(
        this.order.licences_is_updated,
        this.order.plan_is_updated,
        this.order.old_sku_id,
        this.order.new_sku_id,
        this.order.new_subscription_values
      );
      if (isUpdateLicenses) {
        if (
          (this.order.updated_plan?.code?.toUpperCase() ==
            PlanValues.FLEXIBLE &&
            this.order.updated_licence > this.order.old_licence_number) ||
          this.order.updated_plan?.code?.toUpperCase() !== PlanValues.FLEXIBLE
        ) {
          licencesNbr =
            this.order.updated_licence - this.order.old_licence_number;
        }
      } else {
        licencesNbr = this.order.updated_licence;
      }
      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        this.order.selling_price = Number.parseFloat(sellingPrice.toFixed(2));
        this.order.cost_price = Number.parseFloat(costPrice.toFixed(2));
      }
    }
  }

  async placeOrder(isOfflineOrOffer) {
    if (isOfflineOrOffer)
      this.order.description += '. (placed as Offline order).';
    else this.order.description += '. (placed as order with Offer).';
    this.order.is_withdraw = 0;
    if (
      Number.parseInt(this.order.plan_is_updated + '') == 0 &&
      this.order.old_plan_id !== this.order.updated_plan.id
    ) {
      this.order.plan_is_updated = true;
    } else if (
      Number.parseInt(this.order.plan_is_updated + '') == 1 &&
      this.order.old_plan_id == this.order.updated_plan.id
    ) {
      this.order.plan_is_updated = false;
    }
    if (
      Number.parseInt(this.order.licences_is_updated + '') == 0 &&
      this.order.old_licence_number !== this.order.updated_licence
    ) {
      this.order.licences_is_updated = true;
    }
    if (
      (Utilities.isNullOrEmpty(this.order.new_sku_id) ||
        this.order.new_sku_id == '0') &&
      this.order.old_sku_id == this.order.updated_sku.sku_id
    ) {
      this.order.new_sku_id = '0';
    } else if (
      (Utilities.isNullOrEmpty(this.order.new_sku_id) ||
        this.order.new_sku_id == '0') &&
      this.order.old_sku_id !== this.order.updated_sku.sku_id
    ) {
      this.order.new_sku_id = this.order.updated_sku.sku_id;
    } else if (
      !Utilities.isNullOrEmpty(this.order.new_sku_id) &&
      this.order.new_sku_id !== this.order.old_sku_id
    ) {
      this.order.new_sku_id =
        this.order.new_sku_id.indexOf(';') < 0
          ? this.order.updated_sku.sku_id
          : this.order.updated_sku.sku_id +
            ';' +
            this.order.new_sku_id.split(';')[1];
    }
    this.order.new_plan_rfid = this.order.updated_plan.id;
    this.order.new_licence_number = this.order.updated_licence ?? 0;
    if (
      !Utilities.isNullOrEmpty(this.order.updated_renewal_type) &&
      !Utilities.isNullOrEmpty(this.order.renewel_type) &&
      this.order.updated_renewal_type.toLowerCase().trim() !==
        this.order.renewel_type.toLowerCase().trim()
    ) {
      this.order.renewel_type = this.order.updated_renewal_type;
      this.order.update_renewel_settings = true;
    }
    if (!Utilities.isNullOrEmpty(this.order.new_subscription_values))
      this.order.new_subscription_values =
        this.order.updated_sku.sku_id +
        ';' +
        this.order.updated_licence +
        ';' +
        this.order.updated_plan.code;
    else this.order.new_subscription_values = '';
    this.order.updated_phone ?? '';
    this.order.order_status = this.currentUserRoleData.orderStatus;
    if (this.currentUserRoleData.toExecute)
      this.order.last_approval_email = this.currentUser.user_email;
    else if (this.currentUser.isGPM)
      this.order.gpm_approval_email = this.currentUser.user_email;
    else if (this.currentUser.approve2)
      this.order.approval2_email = this.currentUser.user_email;
    else if (this.currentUser.approve1)
      this.order.approval1_email = this.currentUser.user_email;
    if (this.currentUserRoleData.toExecute) {
      if (!Utilities.isNullOrEmpty(this.order.gpm_approval_email)) {
        if (
          this.order.is_renewal_order == 0 ||
          this.order.is_renewal_order == null
        ) {
          this.popupUpdate = true;
          this.placeOrderFlag = true;
          this.executionModal.show();
        } else {
          var sub: any = await Utilities.getSubscriptionById(
            this.http,
            this.httpRequestService,
            this.order
          );
          if (
            sub.plan.commitmentInterval &&
            sub.plan.commitmentInterval.endTime &&
            sub.plan.planName.toUpperCase() == PlanValues.FLEXIBLE
          ) {
            this.popupUpdate = true;
            this.placeOrderFlag = true;
            this.executionModal.show();
          } else {
            this.offerFieldsTypes();
            var params = new HttpParams()
              .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
              .set(Unicode.ORDER_ID, this.order.order_id + '')
              .set(Unicode.STATUS_PARAM, OrderStatus.CONFIRMED)
              .set(Unicode.WITH_GOOGLE_OFFER, this.order.with_google_offer)
              .set(
                Unicode.GOOGLE_OFFER_PERCENTAFE,
                this.order.google_offer_percentage + ''
              )
              .set(Unicode.WITH_CUSTOMER_OFFER, this.order.with_customer_offer)
              .set(
                Unicode.CUSTOMER_OFFER_PERCENTAFE,
                this.order.customer_offer_percentage + ''
              )
              .set(Unicode.STARTDATE, this.order.start_date)
              .set(Unicode.ENDDATE, this.order.commitment_expiry_date)
              .set(Unicode.DESCRIPTION, this.order.description)
              .set(Unicode.LAST_APPROVAL_EMAIL, this.currentUser.user_email);
            if (this.googlePolicies && this.googlePolicies.length > 0) {
              this.setOrderPoliciesAction();
              params = params.set(
                Unicode.POLICIES,
                JSON.stringify(this.googlePolicies)
              );
            }
            var resp: any = await this.http
              .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
                params: params,
              })
              .toPromise();
            console.log(resp);
            if (resp && !resp.msg) {
              var link_url =
                Endpoint.URL_EMAIL +
                'orders/order-details?fromEmail=1&orderId=' +
                this.order.order_id;
              var body2 =
                '<html><h4>' +
                this.currentUser.user_name +
                ' confirmed the order ' +
                "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
                "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.action +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.product_name +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.plan_code +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.new_licence_number +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.po_id +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.order_sale_invoice_nbr +
                "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
                this.order.customer_domain +
                '</td></tr></table>' +
                "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
                link_url +
                "'>here</a><label> to view the details</label></div></html>";
              var TOs = [
                this.order.usr_email,
                this.order.approval1_email,
                this.order.approval2_email,
              ];
              TOs.push(...this.gpmEmails);
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                TOs,
                body2,
                this.order.orderEmailSubjectAction
              );
              alert('Sent');
              this.router.navigate(['orders']);
            }
          }
        }
      } else {
        var lastUsr = !Utilities.isNullOrEmpty(this.order.approval2_email)
          ? this.order.approval2_email
          : !Utilities.isNullOrEmpty(this.order.approval1_email)
          ? this.order.approval1_email
          : this.order.usr_email;
        var usrMang = this.usrs.find(
          (x) =>
            x.user_email.toLowerCase().trim() == lastUsr.toLowerCase().trim()
        )?.manager_email;
        var usrMang2 = !Utilities.isNullOrEmpty(usrMang)
          ? this.usrs.find(
              (x) =>
                x.user_email.toLowerCase().trim() ==
                usrMang.toLowerCase().trim()
            )?.manager_email
          : null;
        if (
          (!Utilities.isNullOrEmpty(usrMang) &&
            usrMang.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2) ||
          (!Utilities.isNullOrEmpty(usrMang2) &&
            usrMang2.toLowerCase().trim() ==
              this.currentUser.user_email.toLowerCase().trim() &&
            this.order.order_status.toUpperCase().trim() !==
              OrderStatus.Approved2)
        ) {
          this.order.order_status = OrderStatus.Approved2;
          this.order.approval2_email = this.currentUser.user_email;
          this.order.last_approval_email = '';
          const formData: FormData = new FormData();
          formData.append('model', JSON.stringify(this.order));
          var resp: any = await this.http
            .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData)
            .toPromise();
          if (resp && !resp.msg) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              this.order.order_id;
            var body2 =
              '<html><h4>' +
              this.currentUser.user_name +
              ' updated the order ' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.action +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.product_name +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.plan_code +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.new_licence_number +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              this.order.customer_domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            // var receivers = !Utilities.isNullOrEmpty(this.order.approval1_email)
            //   ? this.order.approval1_email
            //   : this.currentUser.user_email.toLowerCase() !==
            //     this.order.usr_email.toLowerCase()
            //   ? this.order.usr_email
            //   : '';
            var TOs = [
              this.order.usr_email,
              this.order.approval1_email,
              this.order.approval2_email,
            ];
            TOs.push(...this.gpmEmails);
            await Utilities.sendMail(
              this.http,
              this.currentUser.user_email,
              TOs,
              body2,
              this.order.orderEmailSubjectAction
            );
            alert('Sent');
            this.router.navigate(['orders']);
          } else {
            alert(resp.msg);
          }
        } else if (
          Utilities.isOrderToUpdateLicensesOnly(
            this.order.licences_is_updated,
            this.order.plan_is_updated,
            this.order.old_sku_id,
            this.order.new_sku_id,
            this.order.new_subscription_values
          ) ||
          Number.parseInt(this.order.subscription_status_is_updated + '') == 1
        ) {
          this.popupUpdate = true;
          this.placeOrderFlag = true;
          this.executionModal.show();
        } else {
          alert('GPM approval is required!!');
        }
      }
    } else {
      const formData: FormData = new FormData();
      formData.append('model', JSON.stringify(this.order));
      var params = new HttpParams();
      if (this.currentUser.isGPM && this.googlePolicies.length > 0) {
        this.setOrderPoliciesAction();
        params = params.set(
          Unicode.POLICIES,
          JSON.stringify(this.googlePolicies)
        );
      }
      var resp: any = await this.http
        .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          this.order.order_id;
        var updated_sku = this.products.find(
          (x) => x.sku_id == this.order.updated_sku.sku_id.split(';')[0]
        ).sku_name;
        var updated_plan = this.plans.find(
          (x) => x.id == this.order.new_plan_rfid
        )?.code;
        var newDomain = Utilities.isNullOrEmpty(this.order.updated_domain)
          ? this.order.customer_domain
          : this.order.updated_domain;
        var bodyText =
          '<html><h4>' +
          this.currentUser.user_name +
          ' updated an order ' +
          "</h4><br><label>Old:</label><br/><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Action</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.action +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.product_name +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.plan_code +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.updated_licence +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.customer_domain +
          '</td></tr></table>' +
          "<div style='margin-top:15px;'>" +
          '<label>New:</label><br/>' +
          "<table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>licenses</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
          "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
          updated_sku +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          updated_plan +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.updated_licence +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.po_id +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          this.order.order_sale_invoice_nbr +
          "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
          newDomain +
          '</td></tr></table>' +
          +"<label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        var to = this.currentUserRoleData.receiverEmail;
        if (this.currentUser.isJustSale) to.push(this.order.manager_email);
        if (this.currentUserRoleData.toExecute) {
          // TO BE REMOVED
          to.push(...this.gpmEmails);
          to.push(...this.receiveEmailOnExecGrp);
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval2_email);
        }
        if (this.currentUser.isGPM) {
          if (
            this.order.usr_email.toLowerCase().trim() !==
            this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.usr_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval1_email) &&
            this.order.approval1_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval1_email);
          if (
            !Utilities.isNullOrEmpty(this.order.approval2_email) &&
            this.order.approval2_email.toLowerCase().trim() !==
              this.currentUser.user_email.toLowerCase().trim()
          )
            to.push(this.order.approval2_email);
        }
        if (this.currentUser.approve2) {
          var mangs = [this.order.usr_email, this.order.approval1_email];
          to.push(...mangs);
          to.push(...this.gpmEmails);
        }
        if (this.currentUser.approve1) {
          var usr =
            this.currentUser.user_email.toLowerCase() !==
            this.order.usr_email.toLowerCase()
              ? this.order.usr_email
              : '';
          to.push(usr);
          //  to.push(...this.gpmEmails);
        }
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          to,
          bodyText,
          this.order.orderEmailSubjectAction
        );
        alert('Order updated and sent');
        this.order.customer_alternate_email =
          this.order.updated_alternate_email;
        this.order.customer_email = this.order.updated_email;
        this.order.customer_domain = this.order.updated_domain;
        this.order.customer_phone = this.order.updated_phone;

        this.localStorage.save(Unicode.ORDER, JSON.stringify(this.order));
        window.location.reload();
      } else {
        alert(resp.msg);
      }
    }
  }

  offerFieldsTypes() {
    if (typeof this.order.with_google_offer == 'boolean') {
      this.order.with_google_offer = this.order.with_google_offer ? '1' : '0';
      try {
        this.order.commitment_expiry_date = Utilities.setDBDate(
          this.datePipe,
          this.order.commitment_expiry_date
        );
      } catch (e) {}
      try {
        this.order.start_date = Utilities.setDBDate(
          this.datePipe,
          this.order.start_date
        );
      } catch (e) {}
    }
    if (typeof this.order.with_customer_offer == 'boolean')
      this.order.with_customer_offer = this.order.with_customer_offer
        ? '1'
        : '0';
  }
  isSellingLess() {
    if (this.order)
      return (
        Number.parseFloat(this.order.selling_price + '') <
        Number.parseFloat(this.order.cost_price + '')
      );
    else return false;
  }
}
