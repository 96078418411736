import { PermissionsModule } from './permissions/permissions.module';
import { GroupsModule } from './groups/groups.module';
import { ExchangeRateModule } from './exchange-rate/exchange-rate.module';
import { CurrencyModule } from './currency/currency.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SettingsComponent } from './settings.component';

const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: '',
        loadChildren: () => CurrencyModule,
        redirectTo: 'currency',
        pathMatch: 'full',
      },
      { path: 'currency', loadChildren: () => CurrencyModule },
      { path: 'exchange_rate', loadChildren: () => ExchangeRateModule },
      { path: 'groups', loadChildren: () => GroupsModule },
      { path: 'permissions', loadChildren: () => PermissionsModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
