<div class="review_orders">
  <div class="part1">
    <div class="row col-12 part_ord">
      <label class="title col-lg-2 col-md-4">Customer domain</label>
      <label>{{ currentCustomer?.domain }}</label>
    </div>
    <hr />
    <div class="row col-12 part_ord">
      <label class="title col-lg-2 col-md-4">Confirmation email</label>
      <label>{{ currentCustomer?.alternate_email }}</label>
    </div>
    <hr />
    <div class="row col-12 part_ord p-0 m-0">
      <label class="title col-lg-2 col-md-4 col-4">Selected SKU</label>
      <div class="col-lg-1 col-md-1 p-0 col-1 p-0 info_visiblity">
        <img src="{{ productImgSelected }}" width="27" />
      </div>
      <label
        *ngIf="currentSubscription?.productName"
        class="sku_n col-lg-7 col-md-5 col-5 p-0"
        >{{ currentSubscription?.productName }}</label
      >
      <label
        [ngClass]="{ hidden: currentSubscription?.productName }"
        class="sku_n col-lg-7 col-md-5 p-0"
        >{{ currentSubscription?.skuName }}</label
      >
      <a
        href="javascript:void(0)"
        (click)="backToProduct()"
        class="href_go_back col-2 p-0"
        >Change</a
      >
    </div>
    <hr />
    <div class="row col-12 part_ord p-0 m-0">
      <label class="title col-lg-2 col-md-4 p-0">Plan</label>
      <label class="col-lg-5 col-md-5 col-4 p-0">{{
        currentSubscription?.displayedPlanName
      }}</label>
      <a
        href="javascript:void(0)"
        (click)="backToPlan()"
        class="href_go_back col-2 p-0"
        >Change</a
      >
    </div>
  </div>

  <div class="m-b-20 row">
    <div class="col-lg-2 col-md-4 col-6 grid-div m-t-20">
      <input
        placeholder="Customer P.O.#"
        type="text"
        style="width: 100% !important"
        [(ngModel)]="currentSubscription.purchaseOrderId"
        class="col-12"
      />
    </div>
    <div class="col-lg-2 col-md-4 col-6 grid-div m-t-20">
      <input
        placeholder="Sale invoice# *"
        style="width: 100% !important"
        type="text"
        [(ngModel)]="orderSaleInvoiceNbr"
        class="col-12"
      />
    </div>
    <div class="col-lg-2 col-md-4 col-6 grid-div m-t-20">
      <input
        placeholder="Licenses"
        type="number"
        min="0"
        (input)="calculatePrices()"
        [(ngModel)]="licences"
        class="col-12"
      />
    </div>

    <div class="col-lg-3 col-md-3 col-6 grid-div m-t-20">
      <input
        style="width: 100% !important"
        type="text"
        [(ngModel)]="currentSubscription.customerEmailForSending"
        placeholder="Customer email to send on execution"
        class="col-12"
      />
    </div>
    <div class="col-lg-2 col-md-4 col-6 grid-div m-t-20">
      <input
        style="width: 100% !important"
        type="text"
        [(ngModel)]="currentSubscription.opportunityNbr"
        placeholder="Opportunity# *"
        class="col-12"
      />
    </div>
    <div class="col-lg-3 col-md-3 col-6 grid-div m-t-20">
      <ng-select
        bindLabel="source"
        appendTo="body"
        [searchable]="true"
        class="col-11"
        [clearable]="true"
        placeholder="Source of Opportunity *"
        style="padding: 0 !important"
        [(ngModel)]="currentSubscription.opportunitySource"
      >
        <ng-option [value]="source" *ngFor="let source of opportunitySources">
          {{ source }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-lg-2 col-md-3 col-6 grid-div m-t-20">
      <ng-select
        bindLabel="status"
        appendTo="body"
        [searchable]="true"
        class="col-12"
        [clearable]="true"
        placeholder="Opportunity status *"
        style="padding: 0 !important"
        [(ngModel)]="currentSubscription.opportunityStatus"
      >
        <ng-option [value]="status" *ngFor="let status of opportunityStatus">
          {{ status }}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-lg-2 col-md-3 col-6 grid-div m-t-20">
      <input
        type="text"
        [(ngModel)]="currentSubscription.googleRep"
        placeholder="Google Rep."
        class="col-12"
      />
    </div>
  </div>

  <div class="m-b-20 row col-12">
    <div class="col-2" style="display: contents">
      <input
        style="width: 25px; height: 30px"
        type="checkbox"
        (change)="onOfferCheckboxChange($event)"
        [(ngModel)]="currentSubscription.withGoogleOffer"
      />
      <label class="title p-t-5 m-r-10">Google Offer</label>
    </div>
    <div
      class="col-lg-2 col-md-2 col-6 grid-div"
      *ngIf="currentSubscription.withGoogleOffer"
    >
      <input
        type="number"
        min="0"
        placeholder="Google Discount"
        (change)="calculatePrices()"
        max="100"
        [(ngModel)]="currentSubscription.googleOfferPercentage"
      />
    </div>
    <div class="col-2" style="display: contents">
      <input
        style="width: 25px; height: 30px"
        type="checkbox"
        (change)="onOfferCheckboxChange($event)"
        [(ngModel)]="currentSubscription.withCustomerOffer"
      />
      <label class="title p-t-5 m-r-10">Customer Offer</label>
    </div>
    <div
      class="col-lg-2 col-md-2 col-6 grid-div"
      *ngIf="currentSubscription.withCustomerOffer"
    >
      <input
        type="number"
        min="0"
        placeholder="Customer Discount"
        (change)="calculatePrices()"
        max="100"
        [(ngModel)]="currentSubscription.customerOfferPercentage"
      />
    </div>
    <div class="rv_date_p">
      <span (click)="fdate.toggle()" class="rv_date_p_span">
        <img src="../../../assets/calendar icon.png" width="20" />
      </span>
      <input
        name="dp"
        (ngModelChange)="calculatePrices()"
        [(ngModel)]="currentSubscription.startDate"
        ngbDatepicker
        placeholder="From"
        #fdate="ngbDatepicker"
        class="my-dp"
      />
    </div>
    <div class="rv_date_p">
      <span (click)="tdate.toggle()" class="rv_date_p_span">
        <img src="../../../assets/calendar icon.png" width="20" />
      </span>
      <input
        name="dp"
        placeholder="To"
        [(ngModel)]="currentSubscription.endDate"
        ngbDatepicker
        (ngModelChange)="calculatePrices()"
        placeholder="To"
        #tdate="ngbDatepicker"
        class="my-dp"
      />
    </div>
    <div
      class="col-lg-2 col-md-4 col-6 grid-div"
      *ngIf="currentSubscription.sellingPrice"
    >
      <label>Selling price={{ currentSubscription.sellingPrice }}</label>
    </div>
    <div
      class="col-lg-2 col-md-4 col-6 grid-div"
      *ngIf="currentSubscription.costPrice"
    >
      <label>Cost price={{ currentSubscription.costPrice }}</label>
    </div>
    <!-- <div
      class="col-lg-1 col-md-4 col-6 grid-div"
      *ngIf="currentSubscription.sellingPrice || currentSubscription.costPrice"
    >
      <ng-select
        bindLabel="currency.code"
        appendTo="body"
        [searchable]="true"
        class="col-12"
        [clearable]="true"
        placeholder="Currency *"
        style="padding: 0 !important"
        [(ngModel)]="currentSubscription.currencyRfId"
      >
        <ng-option [value]="currency.id" *ngFor="let currency of currencies">
          {{ currency.code }}
        </ng-option>
      </ng-select>
    </div> -->
  </div>

  <div class="m-b-20 row">
    <div class="col-lg-4 col-md-6 col-6 grid-div m-t-20 m-0">
      <div class="input-group">
        <div class="custom-file" style="margin: 10px 0">
          <input
            type="file"
            class="custom-file-input"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            (change)="uploadDocument($event)"
          />
          <label class="custom-file-label" for="inputGroupFile01">{{
            uploadText
          }}</label>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <textarea
        rows="5"
        class="txt_desc"
        placeholder="Description"
        [(ngModel)]="currentSubscription.description"
      ></textarea>
    </div>
  </div>

  <div class="div_confirm">
    <!-- <button class="btn_blue_bg" (click)="backToPlan()">&#60; Back </button> -->
    <button class="btn_blue_bg" (click)="confirmOrder()">
      {{ txtConfirmOrder }}
    </button>
    <button class="btn_blue_bg" (click)="saveOrder(false, true)">
      Save as draft
    </button>
    <button class="btn_blue_bg" (click)="cancelOrder()">Cancel order</button>
  </div>
</div>
