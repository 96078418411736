import { OrdersComponent } from './orders.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrderDetailsModule } from './order-details/order-details.module';

const routes: Routes = [
  {
    path: '', component: OrdersComponent,
  },
  { path: 'order-details', loadChildren: () => OrderDetailsModule }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
