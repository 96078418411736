<div class="container-fluid" id="subs_container">
  <div class="row">
    <div class="col_cust_subs">
      <div class="row col-12 m-0 p-0 justify-content-between h-55">
        <label class="page_title f-productSans">{{ pageTitle }}</label>
        <button
          *ngIf="!currentUser.isSuspenser"
          (click)="createNewOrder()"
          class="btn_blue_bg m-0 h-fit-content"
          data-toggle="tooltip"
          title="create new order"
        >
          <!-- <span class="fa fa-plus btn_plus_span"></span> -->
          <!-- <img src="../../assets/+.png"/> -->
          <img class="ic_plus_white" src="../../assets/+.png" />
          Create an Order
        </button>
      </div>
      <div id="div_subs">
        <ul class="p-0">
          <li class="row">
            <label class="col-lg-2 col-md-2 col-4 col-sm-3 cust_title"
              >SKU</label
            >
            <label class="col-lg-3 col-md-5 col-5 col-sm-4 cust_title"
              >Plan</label
            >
            <label class="col-lg-2 col-md-2 col-3 col-sm-3 cust_title"
              >Licenses</label
            >
            <label
              class="col-lg-2 col-md-2 col-1 col-sm-1 cust_title text-center sub_item_visibility"
              >Status</label
            >
            <label class="col-lg-3 col-md-5 col-7 col-sm-5"></label>
          </li>
          <li *ngFor="let sub of allSubcs" class="row sub_content li_step">
            <label
              class="col-lg-2 col-md-2 col-4 col-sm-3"
              [ngClass]="{
                color_red_sku: !sub.status.toLowerCase().includes('act'),
                color_green_sku: sub.status.toLowerCase().includes('act')
              }"
              >{{ sub.skuName }}</label
            >
            <div class="col-lg-3 col-md-5 col-5 col-sm-4">
              <label>{{ sub.displayedPlanName }}</label>
              <img
                src="../../assets/edit-icon-normal.png"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                onmouseover="this.src='../../assets/edit-icon-hover.png'"
                onmouseout="this.src='../../assets/edit-icon-normal.png'"
                width="25"
                (click)="changePlan(sub.subscriptionId)"
              />
            </div>
            <div class="col-lg-2 col-md-2 col-3 col-sm-3">
              <label
                data-toggle="tooltip"
                title="Purchased Licenses:{{
                  sub.seatsNbr
                }}&#10;Assigned Licenses:{{ sub.seats.licensedNumberOfSeats }}"
                >{{ sub.seats.licensedNumberOfSeats }}/{{ sub.seatsNbr }}
              </label>
              <img
                src="../../assets/edit-icon-normal.png"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                onmouseover="this.src='../../assets/edit-icon-hover.png'"
                onmouseout="this.src='../../assets/edit-icon-normal.png'"
                width="25"
                (click)="changeLicences(sub.subscriptionId)"
              />
            </div>
            <div
              class="col-lg-2 col-md-2 col-1 col-sm-1 font-weight-bold text-center sub_item_visibility"
            >
              <label
                class=""
                [ngClass]="{
                  color_red: !sub.status.toLowerCase().includes('act'),
                  color_green: sub.status.toLowerCase().includes('act')
                }"
                >{{ sub.status }}</label
              >
            </div>
            <div class="col-lg-3 col-md-5 col-7 col-sm-5 div_actions">
              <a
                href="javascript:void(0)"
                (click)="startPaidService(sub.subscriptionId)"
                data-toggle="tooltip"
                title="start paid service"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
              >
                <img
                  src="../../assets/start-paid-service-icon-normal.png"
                  onmouseover="this.src='../../assets/start-paid-service-icon-hover.png'"
                  onmouseout="this.src='../../assets/start-paid-service-icon-normal.png'"
                  width="25"
                />
              </a>
              <a
                *ngIf="sub.status.toLowerCase().includes('act')"
                href="javascript:void(0)"
                (click)="statusEvent(sub.subscriptionId)"
                data-toggle="tooltip"
                title="{{ sub.txtStatusEvent }}"
              >
                <img
                  src="../../assets/suspend-icon-normal.png"
                  onmouseover="this.src='../../assets/suspend-icon-hover.png'"
                  onmouseout="this.src='../../assets/suspend-icon-normal.png'"
                  width="25"
                />
              </a>
              <a
                *ngIf="!sub.status.toLowerCase().includes('act')"
                href="javascript:void(0)"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                (click)="statusEvent(sub.subscriptionId)"
                data-toggle="tooltip"
                title="{{ sub.txtStatusEvent }}"
              >
                <img
                  src="../../assets/activate-icon-normal.png"
                  onmouseover="this.src='../../assets/activate-icon-hover.png'"
                  onmouseout="this.src='../../assets/activate-icon-normal.png'"
                  width="25"
                />
              </a>
              <a
                href="javascript:void(0)"
                (click)="downUpGrade(sub.subscriptionId, false)"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                data-toggle="tooltip"
                title="upgrade"
              >
                <img
                  src="../../assets/upgrade-icon-normal.png"
                  onmouseover="this.src='../../assets/upgrade-icon-hover.png'"
                  onmouseout="this.src='../../assets/upgrade-icon-normal.png'"
                  width="25"
                />
              </a>
              <a
                href="javascript:void(0)"
                (click)="downUpGrade(sub.subscriptionId, true)"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                data-toggle="tooltip"
                title="downgrade"
              >
                <img
                  src="../../assets/downgrade-icon-normal.png"
                  onmouseover="this.src='../../assets/downgrade-icon-hover.png'"
                  onmouseout="this.src='../../assets/downgrade-icon-normal.png'"
                  width="25"
                />
              </a>
              <a
                href="javascript:void(0)"
                (click)="updateRenewalType(sub.subscriptionId)"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                *ngIf="!sub.isFlexible"
                data-toggle="tooltip"
                title="update renewal settings"
              >
                <img
                  src="../../assets/update-icon-normal.png"
                  onmouseover="this.src='../../assets/update-icon-hover.png'"
                  onmouseout="this.src='../../assets/update-icon-normal.png'"
                  width="25"
                />
              </a>
            </div>
            <div class="col-5 dv_exp font-weight-bold">
              <label
                *ngIf="sub.plan.isCommitmentPlan && sub.plan.commitmentInterval"
                data-toggle="tooltip"
                title="Start/End Date"
                >{{
                  sub.plan.commitmentInterval.startTime | date : "yyyy-MM-dd"
                }}=>{{
                  sub.plan.commitmentInterval.endTime | date : "yyyy-MM-dd"
                }}</label
              >
              <a
                style="padding: 5px"
                *ngIf="sub.plan.isCommitmentPlan && sub.plan.commitmentInterval"
                [ngClass]="{ not_clickable: currentUser.isSuspenser == true }"
                data-toggle="tooltip"
                title="renew this subscription"
                href="javascript:void(0)"
                (click)="newRenewalOrder(sub.subscriptionId)"
              >
                <img
                  src="../../assets/update-icon-normal.png"
                  onmouseover="this.src='../../assets/update-icon-hover.png'"
                  onmouseout="this.src='../../assets/update-icon-normal.png'"
                  width="25"
                />
              </a>
              <label
                *ngIf="sub.trialSettings.isInTrial"
                data-toggle="tooltip"
                title="Trial End Time"
                >{{
                  sub.trialSettings.trialEndTime | date : "yyyy-MM-dd"
                }}</label
              >
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="col_cust_info">
      <div class="row h-55">
        <label class="page_title col-10 f-productSans"
          >Customer Information</label
        >
      </div>
      <div id="div_info">
        <div class="cust_info_section">
          <label class="cust_title f-productSans">Name</label>
          <label class="f-15"
            >{{ currentCustomer?.first_name }}
            {{ currentCustomer?.last_name }}</label
          >
        </div>
        <div class="cust_info_section">
          <label class="cust_title f-productSans">Organisation</label>
          <label class="f-15">{{ currentCustomer?.organisation_name }}</label>
        </div>
        <div class="cust_info_section">
          <label class="cust_title f-productSans">Domain</label>
          <label class="f-15">{{ currentCustomer?.domain }}</label>
        </div>
        <div class="cust_info_section" style="border: none !important">
          <label class="cust_title f-productSans">Region</label>
          <label class="f-15"
            >{{ currentCustomer?.locality }},{{
              currentCustomer?.region_name
            }}</label
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- LICENCES FIRST POPUP-->
<div
  class="modal fade"
  bsModal
  #licences1Modal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content p-10">
      <div class="modal-header">
        <label
          style="font-size: 16px; font-weight: bold; display: block"
          class="f-productSans"
          >Change licence cap</label
        >
        <div (click)="licences1Modal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6" style="display: grid">
            <label class="cust_title">SKU</label>
            <label class="f-15">{{ changeLicencesProductName }}</label>
          </div>

          <div class="col-6" style="display: grid">
            <label class="cust_title">Current Nb. of Licenses</label>
            <label class="f-15">{{ changeLicencesOldLic }}</label>
          </div>
        </div>
        <div class="row col-12 m-t-15" style="display: grid">
          <div>Additional licenses needed</div>
          <input
            style="
              height: 36px;
              border-radius: 7px;
              padding: 10px;
              width: fit-content;
            "
            class="m-t-15"
            type="number"
            min="1"
            [(ngModel)]="changeLicencesAdditionalLic"
          />
        </div>
        <div class="txt-end m-t-100">
          <button class="btn_blue_bg" (click)="continueLicence1()">Next</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- LICENCES SECOND POPUP-->
<div
  class="modal fade"
  bsModal
  #licences2Modal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog sub_dialogs">
    <div class="modal-content p-10">
      <div class="modal-header">
        <label
          style="font-size: 16px; font-weight: bold; display: block"
          class="f-productSans"
          >Change licence cap</label
        >
        <div (click)="licences2Modal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-6" style="display: grid">
            <label class="cust_title m-0">SKU</label>
            <label class="f-15">{{ changeLicencesProductName }}</label>
          </div>

          <div class="col-6" style="display: grid">
            <label class="cust_title m-0">Current Nb. of licenses</label>
            <label class="f-15">{{ changeLicencesOldLic }}</label>
          </div>
        </div>
        <div class="row m-t-15">
          <div class="col-6" style="display: grid">
            <label class="cust_title m-0">Additional licenses</label>
            <label class="f-15">{{ changeLicencesAdditionalLic }}</label>
          </div>
          <div class="col-6" style="display: grid">
            <label class="cust_title m-0">Total of licenses</label>
            <label class="f-15">{{
              changeLicencesOldLic + changeLicencesAdditionalLic
            }}</label>
          </div>
        </div>
        <hr />
        <div class="row col-12">
          <div class="col-2" style="display: contents">
            <input
              style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
              type="checkbox"
              (change)="calculatePrices()"
              [(ngModel)]="updateLicenceSubModel.withGoogleOffer"
            />
            <label class="title p-t-5 m-r-28">Google Offer</label>
          </div>
          <div
            class="col-lg-6 col-md-5 col-6 p-0 grid-div"
            *ngIf="updateLicenceSubModel.withGoogleOffer"
          >
            <input
              type="number"
              min="0"
              (change)="calculatePrices()"
              max="100"
              placeholder="Google Discount"
              [(ngModel)]="updateLicenceSubModel.googleOfferPercentage"
            />
          </div>
        </div>
        <div class="row col-12 m-t-15">
          <div class="col-2" style="display: contents">
            <input
              style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
              type="checkbox"
              (change)="calculatePrices()"
              [(ngModel)]="updateLicenceSubModel.withCustomerOffer"
            />
            <label class="title p-t-5 m-r-10">Customer Offer</label>
          </div>
          <div
            class="col-lg-6 col-md-5 col-6 p-0 grid-div"
            *ngIf="updateLicenceSubModel.withCustomerOffer"
          >
            <input
              type="number"
              min="0"
              (change)="calculatePrices()"
              max="100"
              placeholder="Customer Discount"
              [(ngModel)]="updateLicenceSubModel.customerOfferPercentage"
            />
          </div>
        </div>
        <div class="row col-12 m-0 p-0">
          <div class="sb_date_p m-t-15">
            <span (click)="fdate.toggle()" class="sb_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              [(ngModel)]="updateLicenceSubModel.startDate"
              ngbDatepicker
              (ngModelChange)="calculatePrices()"
              placeholder="From"
              #fdate="ngbDatepicker"
              class="my-dp"
            />
          </div>
          <div class="sb_date_p m-t-15">
            <span (click)="tdate.toggle()" class="sb_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              placeholder="To"
              [(ngModel)]="updateLicenceSubModel.endDate"
              ngbDatepicker
              (ngModelChange)="calculatePrices()"
              placeholder="To"
              #tdate="ngbDatepicker"
              class="my-dp"
            />
          </div>
        </div>

        <div class="row col-12">
          <div
            class="col-4 grid-div m-t-20"
            *ngIf="updateLicenceSubModel.sellingPrice"
          >
            <label>Selling={{ updateLicenceSubModel.sellingPrice }}</label>
          </div>
          <div
            class="col-4 grid-div m-t-20"
            *ngIf="updateLicenceSubModel.costPrice"
          >
            <label>Cost={{ updateLicenceSubModel.costPrice }}</label>
          </div>
        </div>
        <hr />
        <div class="row m-t-15 col-12 m-0 p-0">
          <div class="col-6" style="padding: 0 7px 0 0">
            <input
              style="height: 36px; padding: 10px"
              class="col-12"
              [(ngModel)]="updateLicenceSubModel.changeLicencesPO"
              placeholder="P.O #"
            />
          </div>
          <div class="col-6" style="padding: 0 0px 0 7px">
            <input
              style="height: 36px; padding: 10px"
              class="col-12"
              [(ngModel)]="updateLicenceSubModel.orderSaleInvoiceNbr"
              placeholder="Sale invoice #"
            />
          </div>
        </div>

        <div class="row m-t-15 col-12">
          <div class="col-12" style="padding: 0 7px 0 0">
            <input
              style="height: 36px; padding: 10px"
              class="col-12"
              [(ngModel)]="updateLicenceSubModel.customerEmail"
              placeholder="Customer email for sending on execution"
            />
          </div>
        </div>

        <div class="row m-t-20 col-12">
          <div class="input-group">
            <div class="custom-file" style="margin: 10px 0">
              <input
                type="file"
                class="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                (change)="uploadDocument($event)"
              />
              <label class="custom-file-label" for="inputGroupFile01">{{
                uploadText
              }}</label>
            </div>
          </div>
        </div>

        <hr />
        <div class="txt-end">
          <div class="">
            <a href="javascript:void(0)" (click)="BackLic2()">&#60; Back </a>
            <button
              class="btn_blue_bg"
              (click)="confirmLic2()"
              style="margin-left: 20px !important"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- FLEXIBLE LICENCES POPUP-->
<div
  class="modal fade"
  bsModal
  #flexibleLicencesModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog sub_dialogs">
    <div class="modal-content p-10">
      <div class="modal-header">
        <label style="font-size: 16px; font-weight: bold; display: block"
          >Change licence cap</label
        >
        <div (click)="flexibleLicencesModal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div>
          The license cap for this subscription of
          {{ changeLicencesProductName }} is currently
          {{ changeLicencesOldLic }}. Please enter the new license cap you would
          like to change the subscription to.
        </div>
        <div class="m-t-25 input-group">
          <span class="border-lable-flt col-12 p-0">
            <input
              type="text"
              [(ngModel)]="changeLicencesAdditionalLic"
              placeholder=" "
              (change)="calculatePrices()"
              required
              autofocus
            />
            <label for="label-name"
              >License cap<span class="spn_required"> *</span></label
            >
          </span>
        </div>
        <hr />

        <div class="row col-12">
          <div class="col-3" style="display: contents">
            <input
              style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
              type="checkbox"
              (change)="calculatePrices()"
              [(ngModel)]="updateLicenceSubModel.withGoogleOffer"
            />
            <label class="title p-t-5 m-r-28">Google Offer</label>
          </div>
          <div
            class="col-lg-6 col-md-5 col-6 p-0 grid-div"
            *ngIf="updateLicenceSubModel.withGoogleOffer"
          >
            <input
              type="number"
              min="0"
              placeholder="Google Discount"
              (change)="calculatePrices()"
              max="100"
              [(ngModel)]="updateLicenceSubModel.googleOfferPercentage"
            />
          </div>
        </div>
        <div class="row col-12 m-t-15">
          <div class="col-3" style="display: contents">
            <input
              style="width: 25px; margin: 0px 10px 0 10px; height: 30px"
              type="checkbox"
              (change)="calculatePrices()"
              [(ngModel)]="updateLicenceSubModel.withCustomerOffer"
            />
            <label class="title p-t-5 m-r-10">Customer Offer</label>
          </div>
          <div
            class="col-lg-8 col-md-7 col-8 p-0 grid-div"
            *ngIf="updateLicenceSubModel.withCustomerOffer"
          >
            <input
              type="number"
              min="0"
              placeholder="Customer Dicount"
              (change)="calculatePrices()"
              max="100"
              [(ngModel)]="updateLicenceSubModel.customerOfferPercentage"
            />
          </div>
        </div>
        <div class="row col-12 m-0 p-0">
          <div class="sb_date_p m-t-15">
            <span (click)="ffdate.toggle()" class="sb_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              [(ngModel)]="updateLicenceSubModel.startDate"
              ngbDatepicker
              (ngModelChange)="calculatePrices()"
              placeholder="From"
              #ffdate="ngbDatepicker"
              class="my-dp"
            />
          </div>
          <div class="sb_date_p m-t-15">
            <span (click)="ftdate.toggle()" class="sb_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              placeholder="To"
              [(ngModel)]="updateLicenceSubModel.endDate"
              ngbDatepicker
              (ngModelChange)="calculatePrices()"
              placeholder="To"
              #ftdate="ngbDatepicker"
              class="my-dp"
            />
          </div>
        </div>

        <div class="row col-12">
          <div
            class="col-4 grid-div m-t-20"
            *ngIf="updateLicenceSubModel.sellingPrice"
          >
            <label>Selling={{ updateLicenceSubModel.sellingPrice }}</label>
          </div>
          <div
            class="col-4 grid-div m-t-20"
            *ngIf="updateLicenceSubModel.costPrice"
          >
            <label>Cost={{ updateLicenceSubModel.costPrice }}</label>
          </div>
          <div
            class="col-4 grid-div m-t-20"
            *ngIf="updateLicenceSubModel.margin"
          >
            <label>Margin={{ updateLicenceSubModel.margin }}</label>
          </div>
        </div>

        <hr />
        <div class="row m-t-15 col-12 m-0 p-0">
          <div class="m-t-15 col-6 p-0 input-group">
            <span class="border-lable-flt col-12 p-0">
              <input
                type="text"
                [(ngModel)]="updateLicenceSubModel.changeLicencesPO"
                placeholder=" "
                required
                autofocus
              />
              <label for="label-name"
                >P.O #<span class="spn_required"> *</span></label
              >
            </span>
          </div>
          <div class="m-t-15 col-6 p-0 input-group">
            <span class="border-lable-flt col-12 p-0">
              <input
                type="text"
                [(ngModel)]="updateLicenceSubModel.orderSaleInvoiceNbr"
                placeholder=" "
                required
                autofocus
              />
              <label for="label-name"
                >Sale invoice #<span class="spn_required"> *</span></label
              >
            </span>
          </div>

          <div class="row m-t-20 col-12">
            <div class="input-group">
              <span class="border-lable-flt col-12 p-0">
                <input
                  type="text"
                  [(ngModel)]="updateLicenceSubModel.customerEmail"
                  placeholder=" "
                  autofocus
                  class="col-12"
                  style="height: 36px"
                />
                <label for="label-name"
                  >Customer email for sending on execution</label
                >
              </span>
            </div>
          </div>

          <div class="row m-t-20 col-12">
            <div class="input-group">
              <div class="custom-file" style="margin: 10px 0">
                <input
                  type="file"
                  class="custom-file-input"
                  id="inputGroupFile01"
                  aria-describedby="inputGroupFileAddon01"
                  (change)="uploadDocument($event)"
                />
                <label class="custom-file-label" for="inputGroupFile01">{{
                  uploadText
                }}</label>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div class="txt-end m-t-100">
          <button class="btn_blue_bg" (click)="confirmFlexibleLicenses()">
            Confirm
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!-- RENEWAL POPUP -->
<div
  class="modal fade"
  bsModal
  #renewalModal="bs-modal"
  tabindex="-1"
  id=""
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <div (click)="hideRenewalModal()">
          <span class="fa fa-close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div id="grp1" *ngIf="updatedSubsc && updatedSubsc.renewalSettings">
          <div class="">
            <input
              class=""
              type="radio"
              value="AUTO_RENEW_YEARLY_PAY"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title"> AUTO_RENEW_YEARLY_PAY</span>
            <div class="">
              At the end of an annual commitment plan's interval, automatically
              renew the subscription's plan as ANNUAL_YEARLY_PAY with the same
              numberOfSeats
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="AUTO_RENEW_MONTHLY_PAY"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title"> AUTO_RENEW_MONTHLY_PAY</span>
            <div>
              At the end of an annual commitment plan's interval, automatically
              renew the subscriptions's plan as ANNUAL_MONTHLY_PAY with the same
              numberOfSeats
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="RENEW_CURRENT_USERS_YEARLY_PAY"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title">
              RENEW_CURRENT_USERS_YEARLY_PAY</span
            >
            <div>
              At the end of an annual commitment plan's interval, renew the
              subscription's plan as ANNUAL_YEARLY_PAY but use the total number
              of current active user licenses. This is the default setting for
              active annual commitment plans (paid yearly).
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="RENEW_CURRENT_USERS_MONTHLY_PAY"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title">
              RENEW_CURRENT_USERS_MONTHLY_PAY</span
            >
            <div>
              At the end of an annual commitment plan's interval, renew the
              subscription's plan as ANNUAL_MONTHLY_PAY but use the total number
              of current active user licenses. This is the default setting for
              active annual commitment plans (paid monthly).
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="SWITCH_TO_PAY_AS_YOU_GO"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title"> SWITCH_TO_PAY_AS_YOU_GO</span>
            <div>
              At the end of an annual commitment plan's interval, change the
              annual commitment plan to a flexible plan.
            </div>
          </div>
          <div class="m-t-10">
            <input
              class=""
              type="radio"
              value="CANCEL"
              name="grp1"
              [(ngModel)]="updatedSubsc.newRenewelType"
            /><span class="spn_renewal_title"> CANCEL</span>
            <div>
              At the end of an annual commitment plan interval, the subscription
              is suspended. To understand how to lift a suspension.
            </div>
          </div>
          <div class="m-t-10 text-center">
            <button class="btn_blue_bg" (click)="saveRenwal()">save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
