import { CurrencyModel } from './../../../../../../src/models/currency.model';
import { UserViewModel } from 'src/models/vw.user';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from './../../../../../../src/servcies/storage-service';
import { CustomerModel } from './../../../../../../src/models/customer.model';
import {
  RenewalSettings,
  SubscriptionModel,
} from 'src/models/subcription.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  Endpoint,
  OrderStatus,
  PlanValues,
  Permissions,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { OrderModel } from 'src/models/order.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { DatePipe } from '@angular/common';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { RequestResultModel } from 'src/models/request_result.model';
import { DomSanitizer } from '@angular/platform-browser';
import { time } from 'console';

@Component({
  selector: 'lib-review-order',
  templateUrl: './review-order.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./review-order.component.scss'],
})
export class ReviewOrderComponent implements OnInit {
  currentSubscription: SubscriptionModel = new SubscriptionModel();
  currentCustomer: CustomerModel;
  licences: number;
  currentUser: UserViewModel;
  currentUserRoleData: UserRoleDataModel;
  txtConfirmOrder: string = 'Send';
  changePlan: boolean;
  orderSaleInvoiceNbr: string;
  plans = [];
  products = [];
  opportunitySources = [];
  opportunityStatus = [];
  currentNewSubsc;
  productImgSelected: string;
  receiveEmailOnExecGrp: string[] = [];
  receiveEmailAfterCreationGrp: string[] = [];

  gpmEmails = [];
  currencies: CurrencyModel[] = [];
  uploadedFile;
  uploadText: string = 'Upload customer invoice';
  defaultCurrencyID: number;
  prices = [];

  constructor(
    private storage: StorageService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private confirmDialogService: ConfirmDialogService,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    this.opportunitySources = ['Google source', 'iSoultions source'];
    this.opportunityStatus = ['Submitted', 'Approved'];
    var chagplan = this.localStorage.get(Unicode.ORDER_CHANGE_PLAN);
    this.changePlan = chagplan && chagplan == '1' ? true : false;
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.products = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.defaultCurrencyID = Number.parseInt(
      localStorage.get(Unicode.DEFAULT_CURRENCY_ID) ?? '1'
    );
    this.currentNewSubsc = JSON.parse(this.localStorage.get(Unicode.NEW_SUBSC));
    this.productImgSelected = this.products.find(
      (x) => x.sku_id == this.currentNewSubsc.skuId
    )?.img_url;
  }

  async ngOnInit(): Promise<void> {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.gpmEmails = await Utilities.getGPMemails(this.http, this.localStorage);
    this.currencies = await Utilities.getCurrencies(
      this.http,
      this.localStorage
    );
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.receiveEmailOnExecGrp = Utilities.receiveEmailOnExecGrp(
      this.localStorage
    );
    this.receiveEmailAfterCreationGrp = Utilities.receiveEmailAfterCreationGrp(
      this.localStorage
    );
    this.currentSubscription = JSON.parse(
      this.localStorage.get(Unicode.NEW_SUBSC)
    );
    // var planID =
    //   this.currentSubscription.newPlanId ?? this.currentSubscription.oldPlanId;
    // var priceObj = this.prices.find(
    //   (x) =>
    //     x.sku_rfid == this.currentSubscription.skuId && x.plan_rfid == planID
    // );
    // if (priceObj) {
    //   this.currentSubscription.sellingPrice = priceObj.price;
    //   this.currentSubscription.costPrice = (
    //     this.currentSubscription.sellingPrice * Unicode.COST_SELLTING_RELATION
    //   ).toFixed(2);
    // } else {
    //   this.currentSubscription.sellingPrice = undefined;
    //   this.currentSubscription.costPrice = undefined;
    // }
    this.currentSubscription.endDate =
      this.currentSubscription.plan.commitmentInterval &&
      this.currentSubscription.plan.commitmentInterval.endTime
        ? Utilities.setDatePickerDate(
            this.datePipe,
            this.currentSubscription.plan.commitmentInterval.endTime
          )
        : null;
    this.currentSubscription.startDate = Utilities.setDatePickerDate(
      this.datePipe,
      new Date()
    );
    this.calculatePrices();

    this.currentCustomer = JSON.parse(
      this.localStorage.get(Unicode.CURRENT_CUSTOMER)
    );
    this.currentSubscription.customerEmailForSending =
      this.currentCustomer.email;
    if (this.currentUser.isJustSale || this.currentUser.approve1) {
      this.txtConfirmOrder = 'Submit for approval';
    } else {
      this.txtConfirmOrder = 'Confirm';
    }

    if (
      this.currentSubscription.subscriptionId &&
      this.currentSubscription.plan &&
      this.currentSubscription.seats
    ) {
      if (
        this.currentSubscription.plan.planName == Unicode.FLEXIBLE_PLAN ||
        this.currentSubscription.plan.planName == Unicode.TRIAL_PLAN
      )
        this.licences = this.currentSubscription.seats.maximumNumberOfSeats;
      else
        this.licences = this.currentSubscription.seats
          ? this.currentSubscription.seats.numberOfSeats
          : 0;
    }
    this.currentSubscription.displayedPlanName =
      this.currentSubscription.plan.planName.toUpperCase() == PlanValues.ANNUAL
        ? PlanValues.ANNUAL_MONTHLY_PAY
        : this.currentSubscription.plan.planName;
    this.currentSubscription.purchaseOrderId = '';
    this.currentSubscription.currencyRfId = this.defaultCurrencyID + '';
  }

  cancelOrder() {
    this.localStorage.remove(Unicode.NEW_SUBSC);
    this.localStorage.remove(Unicode.FROM_SUBS_PAGE);
    this.localStorage.remove(Unicode.IS_DOWNGRADE);
    this.localStorage.remove(Unicode.ORDER_CHANGE_PLAN);
    this.router.navigate(['/customers/customer-subscription-list'], {
      queryParams: { customerId: JSON.stringify(this.currentCustomer.id) },
    });
  }

  displayfile(x = null) {
    var fileBase64 = 'data:application/pdf;base64,' + x;
    var testSRC = this.sanitizer.bypassSecurityTrustResourceUrl(
      // iframe src
      fileBase64
    ) as any;
  }

  async uploadDocument(fileInput) {
    let fileReader = new FileReader();
    var file = fileInput.target.files[0];
    fileReader.readAsDataURL(file);
    fileReader.onload = async (e) => {
      this.uploadedFile = file;
      this.uploadText = file.name;
    };
  }

  async confirmOrder() {
    if (
      Utilities.isNullOrEmpty(this.orderSaleInvoiceNbr) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunityNbr) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunitySource) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunityStatus)
    ) {
      alert('Enter required Fields');
      return null;
    }
    if (
      Utilities.isNullOrEmpty(this.currentSubscription.startDate) ||
      Utilities.isNullOrEmpty(this.currentSubscription.endDate)
    ) {
      alert('Choose From/To Date');
      return null;
    }
    if (
      this.currentSubscription.withGoogleOffer &&
      (!this.currentSubscription.googleOfferPercentage ||
        this.currentSubscription.googleOfferPercentage == 0)
    ) {
      alert('Select Google Offer Percentage');
      return null;
    }
    if (
      this.currentSubscription.withCustomerOffer &&
      (!this.currentSubscription.customerOfferPercentage ||
        this.currentSubscription.customerOfferPercentage == 0)
    ) {
      alert('Select Customer Offer Percentage');
      return null;
    }
    // SOME RESTRICTIONS
    if (Utilities.isNullOrEmpty(this.currentSubscription.skuName))
      this.currentSubscription.skuName = this.products.find(
        (x) => x.sku_id == this.currentSubscription.skuId
      ).sku_name;
    if (
      this.currentSubscription.skuName.indexOf(Unicode.GOOGLE_DRIVE_SKU_ID) >=
        0 &&
      this.currentSubscription.plan.planName !== PlanValues.FLEXIBLE
    ) {
      alert(
        'Google Drive  is only available as a monthly post-pay FLEXIBLE plan'
      );
      return;
    }
    //NORMAL ORDER
    if (this.currentUserRoleData.toExecute) {
      var seats = {};
      this.currentSubscription.plan.planName =
        this.currentSubscription.plan.planName == PlanValues.ANNUAL
          ? PlanValues.ANNUAL_MONTHLY_PAY
          : this.currentSubscription.plan.planName;
      if (
        this.currentSubscription.plan.planName == Unicode.FLEXIBLE_PLAN ||
        this.currentSubscription.plan.planName == Unicode.TRIAL_PLAN
      )
        seats = { maximumNumberOfSeats: this.licences };
      else seats = { numberOfSeats: this.licences };

      var oldPlanName =
        !Utilities.isNullOrEmpty(this.currentSubscription.oldPlanId) &&
        this.currentSubscription.oldPlanId != 0
          ? this.plans.find((x) => x.id == this.currentSubscription.oldPlanId)
              .code
          : '';
      var mustRenewService: boolean;
      if (
        this.currentSubscription.isRenewalOrder &&
        oldPlanName.toUpperCase().trim() == PlanValues.FLEXIBLE
      ) {
        //&& (new Date().getTime()) >= (Number.parseFloat(this.currentSubscription.plan.commitmentInterval.endTime)) ??
        mustRenewService = true;
      }

      if (!this.changePlan && !this.currentSubscription.isRenewalOrder) {
        // ! FROM ORDER PAGE  && ! from renew icon
        if (Utilities.isNullOrEmpty(this.currentSubscription.id)) {
          this.currentSubscription.renewalSettings = new RenewalSettings();
          this.currentSubscription.renewalSettings.renewalType =
            Unicode.DEFAULT_RENEWEL_TYPE;
        }
        if (!this.localStorage.get(Unicode.IS_DOWNGRADE)) {
          this.currentSubscription.id = null;
        }
        this.currentSubscription.customerDomain = this.currentCustomer.domain;
        this.currentSubscription.customerId = this.currentCustomer.id;
        var headers: HttpHeaders;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        this.currentSubscription.seats = seats;
        if (!Utilities.isNullOrEmpty(this.currentCustomer.auth_token)) {
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            this.currentCustomer.id +
            '/subscriptions';
          // ?customerAuthToken=' +.currentCustomer.auth_token;
          var currentSubscriptionTransf = {
            plan: { planName: this.currentSubscription.plan.planName },
            seats: this.currentSubscription.seats,
            purchaseOrderId: this.currentSubscription.purchaseOrderId,
            //  subscriptionId: this.currentSubscription.subscriptionId,
            skuId: this.currentSubscription.skuId,
            customerId: this.currentSubscription.customerId,
            //  customerDomain: this.currentSubscription.customerDomain,
            renewalSettings: { renewalType: Unicode.DEFAULT_RENEWEL_TYPE },
          };
        } else {
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            this.currentCustomer.id +
            '/subscriptions';
        }
        var modelBody = currentSubscriptionTransf
          ? currentSubscriptionTransf
          : this.currentSubscription;
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          modelBody,
          headers
        );
        if (result.success) {
          console.log(result.data);
          if (result.data && result.data.trialSettings && result.data.plan) {
            this.currentSubscription.trialSettings = result.data.trialSettings;
            this.currentSubscription.plan = result.data.plan;
          }
          this.localStorage.remove(Unicode.NEW_SUBSC);
          this.localStorage.remove(Unicode.FROM_SUBS_PAGE);
          this.localStorage.remove(Unicode.IS_DOWNGRADE);
          var order = await this.saveOrder(true);
          if (order) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order?.id;
            var body =
              '<html><div>' +
              this.currentUser.user_name +
              ' created and order ' +
              '</div>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a>s<label> to view the details</label></div></html>";
            var receiversOnExec = [];
            receiversOnExec.push(...this.receiveEmailOnExecGrp);
            receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
            await Utilities.sendMail(
              this.http,
              this.currentUser.user_email,
              receiversOnExec,
              body,
              order.orderEmailSubjectAction
            );
            if (
              !Utilities.isNullOrEmpty(
                this.currentSubscription.customerEmailForSending
              )
            ) {
              var customerEmailBody = '';
              var cc = this.currentUser.manager_email;
              if (!Utilities.isNullOrEmpty(this.uploadedFile)) {
                customerEmailBody = Utilities.generateCustomerEmailBody(
                  this.currentCustomer.domain,
                  this.currentUser.user_email
                );
                var fileName = this.uploadedFile.name ?? '';
                let fileReader = new FileReader();
                fileReader.readAsDataURL(this.uploadedFile);
                fileReader.onload = async (e) => {
                  var tragRes: any = e.target.result;
                  var fileData = tragRes.split('base64,')[1];
                  Utilities.sendMailWithFile(
                    this.http,
                    fileData,
                    this.currentUser.user_email,
                    [this.currentSubscription.customerEmailForSending],
                    [cc],
                    'Invoice',
                    customerEmailBody,
                    fileName
                  );
                };
              } else {
                customerEmailBody =
                  Utilities.generateCustomerEmailBodyWithoutFile(
                    this.currentCustomer.domain,
                    this.currentUser.user_email
                  );
                await Utilities.sendMail(
                  this.http,
                  this.currentUser.user_email,
                  [this.currentSubscription.customerEmailForSending],
                  customerEmailBody,
                  'Invoice',
                  [cc]
                );
              }
            }
          }
          this.router.navigate(['/customers/customer-subscription-list'], {
            queryParams: {
              customerId: JSON.stringify(this.currentCustomer.id),
            },
          });
        } else {
          console.log(result.errorMessage);
          alert(result.errorMessage);
          if (!Utilities.isNullOrEmpty(this.currentSubscription.subscriptionId))
            this.router.navigate(['/customers/customer-subscription-list'], {
              queryParams: {
                customerId: JSON.stringify(this.currentCustomer.id),
              },
            });
          else return;
        }
      } else if (this.changePlan || mustRenewService) {
        var headers: HttpHeaders;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        if (
          this.currentSubscription.isplanChanged ||
          this.currentSubscription.isRenewalOrder
        ) {
          var reqSuccess = true;
          var planBody = {
            planName: this.currentSubscription.plan.planName,
            seats: seats,
            purchaseOrderId: this.currentSubscription.purchaseOrderId,
          };

          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            this.currentCustomer.id +
            '/subscriptions/' +
            this.currentSubscription.subscriptionId +
            '/changePlan';
          var result: RequestResultModel = await this.httpRequestService.post(
            url,
            planBody,
            headers
          );
          if (result.success) {
            console.log(result.data);
            if (result.data && result.data.trialSettings && result.data.plan) {
              this.currentSubscription.trialSettings =
                result.data.trialSettings;
              this.currentSubscription.plan = result.data.plan;
            }
            var renewelBody = {
              renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
            };
            var url =
              'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
              this.currentCustomer.id +
              '/subscriptions/' +
              this.currentSubscription.subscriptionId +
              '/changeRenewalSettings';
            var renewalResult: RequestResultModel =
              await this.httpRequestService.post(url, renewelBody, headers);
            if (renewalResult.success) {
              console.log(renewalResult.data);
              if (
                renewalResult.data &&
                renewalResult.data.trialSettings &&
                renewalResult.data.plan
              ) {
                this.currentSubscription.trialSettings =
                  renewalResult.data.trialSettings;
                this.currentSubscription.plan = renewalResult.data.plan;
              }
            } else {
              console.log(renewalResult.errorMessage);
            }
          } else {
            console.log(result.errorMessage);
            reqSuccess = false;
            alert(result.errorMessage);
            if (
              !Utilities.isNullOrEmpty(this.currentSubscription.subscriptionId)
            )
              this.router.navigate(['/customers/customer-subscription-list'], {
                queryParams: {
                  customerId: JSON.stringify(this.currentCustomer.id),
                },
              });
            else return;
          }
          if (reqSuccess) {
            this.localStorage.remove(Unicode.NEW_SUBSC);
            this.localStorage.remove(Unicode.FROM_SUBS_PAGE);
            this.localStorage.remove(Unicode.IS_DOWNGRADE);
            var order = await this.saveOrder(true);
            if (order) {
              var link_url =
                Endpoint.URL_EMAIL +
                'orders/order-details?fromEmail=1&orderId=' +
                order?.id;
              var body =
                '<html><div>' +
                this.currentUser.user_name +
                ' created and order ' +
                '</div>' +
                "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
                link_url +
                "'>here</a><label> to view the details</label></div></html>";
              var receiversOnExec = [];
              receiversOnExec.push(...this.receiveEmailOnExecGrp);
              receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                receiversOnExec,
                body,
                order.orderEmailSubjectAction
              );
              if (
                !Utilities.isNullOrEmpty(
                  this.currentSubscription.customerEmailForSending
                )
              ) {
                var customerEmailBody = '';
                var cc = this.currentUser.manager_email;
                if (!Utilities.isNullOrEmpty(this.uploadedFile)) {
                  customerEmailBody = Utilities.generateCustomerEmailBody(
                    this.currentCustomer.domain,
                    this.currentUser.user_email
                  );
                  var fileName = this.uploadedFile.name ?? '';
                  let fileReader = new FileReader();
                  fileReader.readAsDataURL(this.uploadedFile);
                  fileReader.onload = async (e) => {
                    var tragRes: any = e.target.result;
                    var fileData = tragRes.split('base64,')[1];
                    Utilities.sendMailWithFile(
                      this.http,
                      fileData,
                      this.currentUser.user_email,
                      [this.currentSubscription.customerEmailForSending],
                      [cc],
                      'Invoice',
                      customerEmailBody,
                      fileName
                    );
                  };
                } else {
                  customerEmailBody =
                    Utilities.generateCustomerEmailBodyWithoutFile(
                      this.currentCustomer.domain,
                      this.currentUser.user_email
                    );
                  await Utilities.sendMail(
                    this.http,
                    this.currentUser.user_email,
                    [this.currentSubscription.customerEmailForSending],
                    customerEmailBody,
                    'Invoice',
                    [cc]
                  );
                }
              }
              this.router.navigate(['/customers/customer-subscription-list'], {
                queryParams: {
                  customerId: JSON.stringify(this.currentCustomer.id),
                },
              });
            }
          }
        } else {
          alert('The plan has not changed');
        }
      } else if (this.currentSubscription.isRenewalOrder && !mustRenewService) {
        this.currentUserRoleData.orderStatus = OrderStatus.CONFIRMED;
        var order = await this.saveOrder(false);
        if (order) {
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order.id;
          var sku = '';
          if (this.currentSubscription.skuName)
            sku = this.currentSubscription.skuName;
          else if (!Utilities.isNullOrEmpty(this.currentSubscription.skuId))
            sku = this.products.find(
              (x) => x.sku_id == this.currentSubscription.skuId
            ).sku_name;
          else sku = this.currentSubscription.productName;
          var txtAction =
            this.currentUser.user_name + ' create a renewal order ';
          var bodyText =
            '<html><h4>' +
            txtAction +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentSubscription.subscriptionId +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            sku +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentSubscription.plan.planName +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentCustomer.domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receiversOnExc = [];
          receiversOnExc.push(...this.gpmEmails);
          receiversOnExc.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receiversOnExc,
            bodyText,
            order.orderEmailSubjectAction
          );
          alert('Order Send');
          this.router.navigate(['/customers/customer-subscription-list'], {
            queryParams: {
              customerId: JSON.stringify(this.currentCustomer.id),
            },
          });
        }
      }
    } else {
      var order = await this.saveOrder(false);
      if (order) {
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          order.id;
        if (this.changePlan) {
          var oldPlan = this.plans.find(
            (x) => x.id == this.currentSubscription.oldPlanId
          ).code;
          var bodyText =
            "<html><h4 style='text-transform: capitalize;'>" +
            this.currentUser.user_name +
            ' create an order(upated plan) ' +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>Old plan</th><th style='border: 1px solid;border-collapse: collapse;'>New Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentSubscription.subscriptionId +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            oldPlan +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentSubscription.plan.planName +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentCustomer.domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
        } else {
          var sku = '';
          if (this.currentSubscription.skuName)
            sku = this.currentSubscription.skuName;
          else if (!Utilities.isNullOrEmpty(this.currentSubscription.skuId))
            sku = this.products.find(
              (x) => x.sku_id == this.currentSubscription.skuId
            ).sku_name;
          else sku = this.currentSubscription.productName;
          var subid = this.currentSubscription.subscriptionId ?? 'is new';
          var txtAction = this.currentSubscription.isRenewalOrder
            ? ' create a renewal order '
            : ' create an order ';
          var bodyText =
            '<html><h4>' +
            this.currentUser.user_name +
            txtAction +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            subid +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            sku +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentSubscription.plan.planName +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentCustomer.domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
        }
        var receivers = this.currentUserRoleData.receiverEmail;
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          receivers,
          bodyText,
          order.orderEmailSubjectAction
        );
        alert('Order Send');
        if (Number.parseInt(this.currentCustomer.status) == 0)
          this.router.navigate(['/customers']);
        else
          this.router.navigate(['/customers/customer-subscription-list'], {
            queryParams: {
              customerId: JSON.stringify(this.currentCustomer.id),
            },
          });
      }
    }
  }

  async saveOrder(admin: boolean, isDraft: boolean = false) {
    if (
      Utilities.isNullOrEmpty(this.orderSaleInvoiceNbr) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunityNbr) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunitySource) ||
      Utilities.isNullOrEmpty(this.currentSubscription.opportunityStatus)
    ) {
      alert('Enter required Fields');
      return null;
    }
    if (
      Utilities.isNullOrEmpty(this.currentSubscription.startDate) ||
      Utilities.isNullOrEmpty(this.currentSubscription.endDate)
    ) {
      alert('Choose From/To Date');
      return null;
    }
    if (
      this.currentSubscription.withGoogleOffer &&
      (!this.currentSubscription.googleOfferPercentage ||
        this.currentSubscription.googleOfferPercentage == 0)
    ) {
      alert('Select Google Offer Percentage');
      return null;
    }
    if (
      this.currentSubscription.withCustomerOffer &&
      (!this.currentSubscription.customerOfferPercentage ||
        this.currentSubscription.customerOfferPercentage == 0)
    ) {
      alert('Select Customer Offer Percentage');
      return null;
    }
    var order = new OrderModel();
    order.invoice_file_rfid = null;
    order.is_renewal_order = this.currentSubscription.isRenewalOrder ?? false;
    order.email_to_send_on_execution = !Utilities.isNullOrEmpty(
      this.currentSubscription.customerEmailForSending
    )
      ? this.currentSubscription.customerEmailForSending
      : '';
    if (isDraft) {
      order.order_status = OrderStatus.Draft;
      isDraft = true;
    } else order.order_status = this.currentUserRoleData.orderStatus;
    if (Number.parseInt(this.currentCustomer.status) == 0)
      order.is_new_customer = 1;
    else
      order.is_new_customer =
        !Utilities.isNullOrEmpty(this.currentCustomer.is_new_customer) &&
        this.currentCustomer.is_new_customer == true
          ? 1
          : 0;
    order.order_sale_invoice_nbr = this.orderSaleInvoiceNbr;
    order.user_rfid = JSON.parse(
      this.localStorage.get(Unicode.CURRENT_USR)
    ).usr_id;
    order.customer_rfid = this.currentCustomer.id;
    order.billing_method =
      this.currentSubscription.billingMethod ?? Unicode.ONLINE;
    order.subscription_rfid = this.currentSubscription.subscriptionId ?? null;
    order.selling_price = this.currentSubscription.sellingPrice ?? 0;
    order.cost_price = this.currentSubscription.costPrice ?? 0;
    order.description = this.currentSubscription.description ?? '';
    order.currency_rfid =
      this.currentSubscription.currencyRfId ?? this.defaultCurrencyID + '';
    order.order_action = '';
    if (this.currentSubscription.endDate)
      order.commitment_expiry_date = this.datePipe.transform(
        new Date(
          this.currentSubscription.endDate.year,
          this.currentSubscription.endDate.month - 1,
          this.currentSubscription.endDate.day
        ),
        'yyyy-MM-dd hh:mm'
      );
    else order.commitment_expiry_date = null;
    if (this.currentSubscription.startDate)
      order.start_date = this.datePipe.transform(
        new Date(
          this.currentSubscription.startDate.year,
          this.currentSubscription.startDate.month - 1,
          this.currentSubscription.startDate.day
        ),
        'yyyy-MM-dd hh:mm'
      );
    else order.start_date = null;
    if (
      this.currentSubscription.trialSettings &&
      this.currentSubscription.trialSettings.isInTrial &&
      this.currentSubscription.trialSettings.trialEndTime
    )
      order.trial_expiry_date = this.datePipe.transform(
        new Date(Number(this.currentSubscription.trialSettings.trialEndTime)),
        'yyyy-MM-dd hh:mm'
      );
    else order.trial_expiry_date = null;
    order.with_google_offer = this.currentSubscription.withGoogleOffer ?? false;
    order.google_offer_percentage = order.with_google_offer
      ? this.currentSubscription.googleOfferPercentage
      : 0;
    order.with_customer_offer =
      this.currentSubscription.withCustomerOffer ?? false;
    order.customer_offer_percentage = order.with_customer_offer
      ? this.currentSubscription.customerOfferPercentage ?? 0
      : 0;
    order.renewel_type =
      this.currentSubscription.renewalSettings &&
      this.currentSubscription.renewalSettings.renewalType
        ? this.currentSubscription.renewalSettings.renewalType
        : '';
    order.old_plan_id = this.currentSubscription.oldPlanId ?? 0;
    order.old_sku_id = this.currentSubscription.oldSkuId
      ? this.currentSubscription.oldSkuId
      : this.currentSubscription.skuId
      ? this.currentSubscription.skuId
      : null;
    order.po_id = this.currentSubscription.purchaseOrderId ?? null;
    order.opportunity_nbr = this.currentSubscription.opportunityNbr ?? null;
    order.opportunity_status =
      this.currentSubscription.opportunityStatus ?? null;
    order.opportunity_source =
      this.currentSubscription.opportunitySource ?? null;
    order.google_rep = this.currentSubscription.googleRep ?? null;
    order.approval1_email = this.currentUserRoleData.approver1Email ?? '';
    order.approval2_email = this.currentUserRoleData.approver2Email ?? '';
    order.gpm_approval_email = this.currentUserRoleData.GPMApproverEmail ?? '';
    order.last_approval_email =
      this.currentUserRoleData.lastApproverEmail ?? '';
    order.rejecter_email = '';
    if (Utilities.isNullOrEmpty(this.currentSubscription.subscriptionId)) {
      order.subscription_is_new = true;
      order.old_licence_number = order.new_licence_number = this.licences;
      order.old_sku_id = order.new_sku_id = this.currentSubscription.skuId;
      order.old_plan_id = order.new_plan_rfid = this.plans.find(
        (x) => x.code == this.currentSubscription.plan.planName
      )?.id;
      if (
        !order.old_plan_id &&
        (this.currentSubscription.plan.planName ==
          PlanValues.ANNUAL_MONTHLY_PAY ||
          this.currentSubscription.plan.planName == PlanValues.ANNUAL)
      ) {
        order.old_plan_id = order.new_plan_rfid = this.plans.find(
          (x) => x.code == PlanValues.ANNUAL
        )?.id;
      }
      order.new_subscription_values =
        this.currentSubscription.skuId +
        ';' +
        this.licences +
        ';' +
        this.currentSubscription.plan.planName;
    } else {
      order.subscription_is_new = false;
      //
      var oldSeatNbr;
      var oldPlanCode = this.plans.find(
        (x) => x.id == this.currentSubscription.oldPlanId
      )?.code;
      if (
        oldPlanCode == Unicode.FLEXIBLE_PLAN ||
        oldPlanCode == Unicode.TRIAL_PLAN
      )
        oldSeatNbr = this.currentSubscription.seats.maximumNumberOfSeats;
      else
        oldSeatNbr = this.currentSubscription.seats
          ? this.currentSubscription.seats.numberOfSeats
          : 0;
      order.old_licence_number = oldSeatNbr;
      order.new_licence_number = this.licences;
      if (oldSeatNbr != this.licences) {
        order.licences_is_updated = true;
      } else order.licences_is_updated = false;
      //
      if (this.currentSubscription.isplanChanged) {
        order.plan_is_updated = true;
        order.old_licence_number =
          this.currentSubscription.seatsNbr ?? oldSeatNbr;
        order.new_plan_rfid =
          typeof this.currentSubscription.newPlanId == 'object'
            ? this.currentSubscription.newPlanId.id
            : this.currentSubscription.newPlanId;
      } else {
        order.plan_is_updated = false;
        order.new_plan_rfid = order.old_plan_id;
      }
      if (this.currentSubscription.isProductChanged) {
        order.new_sku_id = this.currentSubscription.newSkuId;
      } else order.new_sku_id = order.old_sku_id;
    }
    if (order.is_renewal_order) {
      order.licences_is_updated = false;
      order.plan_is_updated = false;
    }
    const formData: FormData = new FormData();
    if (
      this.uploadedFile &&
      this.uploadedFile != null &&
      !this.currentUserRoleData.toExecute
    )
      formData.append('file', this.uploadedFile);
    formData.append('model', JSON.stringify(order));
    var resp: any = await this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData)
      .toPromise();
    console.log(resp);
    if (resp && !resp.msg) {
      if (isDraft) {
        this.localStorage.remove(Unicode.NEW_SUBSC);
        this.localStorage.remove(Unicode.FROM_SUBS_PAGE);
        this.localStorage.remove(Unicode.IS_DOWNGRADE);
        this.router.navigate(['/customers/customer-subscription-list'], {
          queryParams: { customerId: JSON.stringify(this.currentCustomer.id) },
        });
        return null;
      } else {
        this.localStorage.remove(Unicode.NEW_SUBSC);
        this.localStorage.remove(Unicode.FROM_SUBS_PAGE);
        this.localStorage.remove(Unicode.IS_DOWNGRADE);
        order.id = resp['id'];
        order.orderEmailSubjectAction =
          Utilities.setOrderEmailSubjectAction(order);
        return order;
      }
    } else if (!admin) {
      alert(resp.msg);
      return null;
    } else return null;
  }

  backToPlan() {
    this.router.navigate(['customers/new-subscription/plans']);
  }

  backToProduct() {
    this.router.navigate(['customers/new-subscription/products']);
  }

  onOfferCheckboxChange(e) {
    this.calculatePrices();
  }

  calculatePrices() {
    if (
      !Utilities.isNullOrEmpty(this.currentSubscription.startDate) &&
      !Utilities.isNullOrEmpty(this.currentSubscription.endDate)
    ) {
      var from_ = new Date(
        this.currentSubscription.startDate.year,
        this.currentSubscription.startDate.month - 1,
        this.currentSubscription.startDate.day
      );
      var to_ = new Date(
        this.currentSubscription.endDate.year,
        this.currentSubscription.endDate.month - 1,
        this.currentSubscription.endDate.day
      );
      var days = Utilities.calculateDiffDays(from_, to_);
      var planID =
        this.currentSubscription.newPlanId ??
        this.currentSubscription.oldPlanId;
      if (planID == 0) {
        planID = this.plans.find(
          (x) => x.code == this.currentSubscription.plan.planName
        )?.id;
      }
      var skuId =
        this.currentSubscription.skuId.indexOf(';') >= 0
          ? this.currentSubscription.skuId.split(';')[0]
          : this.currentSubscription.skuId;
      var priceObj = this.prices.find(
        (x) => x.sku_rfid == skuId && x.plan_rfid == planID
      );
      if (priceObj) {
        var price = priceObj.price / 30;
        var costPrice: number = price * Unicode.COST_MARGIN * days;
        var sellingPrice: number = price * days;
        //OFFERS
        if (
          this.currentSubscription.withGoogleOffer &&
          this.currentSubscription.googleOfferPercentage &&
          this.currentSubscription.googleOfferPercentage != 0
        ) {
          var offer =
            (100 - this.currentSubscription.googleOfferPercentage) / 100;
          costPrice *= offer;
        }
        if (
          this.currentSubscription.withCustomerOffer &&
          this.currentSubscription.customerOfferPercentage &&
          this.currentSubscription.customerOfferPercentage != 0
        ) {
          var offer =
            (100 - this.currentSubscription.customerOfferPercentage) / 100;
          sellingPrice *= offer;
        }
        //LICENSES
        var licencesNbr = this.licences ?? 1;
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;
        this.currentSubscription.sellingPrice = Number.parseFloat(
          sellingPrice.toFixed(2)
        );
        this.currentSubscription.costPrice = Number.parseFloat(
          costPrice.toFixed(2)
        );
      }
    }
  }
}
