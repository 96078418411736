<div class="sid-nav-menu" id="wrapper">
    <!--class = d-flex -->
    <!-- Sidebar -->

    <!-- /#sidebar-wrapper -->

    <!-- Page Content --> 
    <div id="page-content-wrapper">

        <nav class="navbar navbar-expand-lg navbar-header border-bottom col-12">
            <!-- <button class="btn fa fa-align-justify" id="menu-toggle" (click)="openNav()"></button> -->
            <div class=''>
                <img id="menu-toggle" (click)="openNav()" src="../../assets/menu-hamburger-button-normal.png"
                    onmouseover="src='../../assets/menu-hamburger-button-hover.png'"
                    onmouseout="src='../../assets/menu-hamburger-button-normal.png'" />
            </div>
            <div class="ic_logo">
                <img src="../../assets/isolutions-logo.png" (click)="refreshLocalData()" data-toggle="tooltip" title="click to refresh the data"/>
            </div>
            <div class="col-3">
                <!-- <label class="txt_username">{{currentUser.first_name}} {{currentUser.last_name}}</label> -->
            </div>
            <div class="input-group col-lg-5 col-md-5 col-sm-6 col-7">
                <div class="input-group-prepend">
                    <span class="input-group-text fa fa-search" id="search_icon"></span>
                </div>
                <input type="text" class="form-control" placeholder="Search" aria-label="Username"
                    aria-describedby="basic-addon1" id="nav-search-input" (keyup.enter)="search($event)">
            </div>

            <div *ngIf="!currentUser.isSuspenser">
                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                    <button class="" id="dropdownManual" ngbDropdownAnchor (focus)="myDrop.open()">
                        <div class="ic_add_header">
                        </div>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual" appendTo="body">
                        <button ngbDropdownItem (click)="addUser()" *ngIf="!currentUser.isJustSale">Add an User</button>
                        <button ngbDropdownItem (click)="createCustomer()">Create a Customer</button>
                        <button ngbDropdownItem (click)="createOrder()">Create an Order</button>
                    </div>
                </div>
            </div>


            <!-- <div class="img_usr_profile">
                <img src="{{currentUser?.img}}" class="rounded-circle img_dft_usr float-right" (click)="logout()"
                    data-toggle="tooltip" title="{{currentUser.user_name}}&#10;{{currentUser.user_email}}" />
            </div> -->
            <div class="img_usr_profile">
                <div class="d-inline-block float-right" ngbDropdown #dropProfile="ngbDropdown" placement="bottom-right"
                    display="dynamic">
                    <button class="" id="dropdownManual" ngbDropdownAnchor (focus)="dropProfile.open()"
                        data-toggle="tooltip" title="{{currentUser.user_name}}&#10;{{currentUser.user_email}}">
                        <img src="{{currentUser?.img}}" class="rounded-circle img_dft_usr float-right" />
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual" appendTo="body" id="drop_profile">
                        <div class="prof_info">
                            <img src="{{currentUser?.img}}" class="rounded-circle" width="55" />
                            <div class="f-productSans">{{currentUser.user_name}}</div>
                            <div class="f-productSans popup_prof_email">{{currentUser.user_email}}</div>
                        </div>
                        <hr />
                        <label class="f-productSans" (click)="logout()"
                            style="cursor: pointer;float: right;padding:0 10px 0 10px">Logout</label>
                    </div>
                </div>
            </div>

            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button> -->
        </nav>

        <div class="row col-12 m-0">
            <div [ngClass]="{ 'w-15': isMenuOpened ,'hidden': !isMenuOpened }" class="main-side-menu">
                <div class="border-right" class="side-navbar" id="sidebar-wrapper">
                    <div class="list-group list-group-flush">
                        <!-- <ng-container *ngFor="let menu of menus">
                            <a class="list-group-item list-group-item-action " [routerLink]='menu.link' [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active-link">{{menu.name}}</a>
                        </ng-container> -->
                        <ul class="nav main-nav">
                            <li class="nav-item col-12 row" *ngFor="let menu of menus" routerLink="{{menu.link}}"
                                (click)='menuClick(menu.id)' [routerLinkActive]="['active-link']"
                                [routerLinkActiveOptions]="{exact:true}">
                                <div class="col-1">
                                    <img src="{{menu.img}}" class="menu_img" />
                                </div>
                                <a style="color: white;" class="nav-link f-productSans">{{menu.name}}</a>
                            </li>
                            <li routerLink="/help" class="li_help" >
                                <a> Help and Guidance</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="container-fluid" [ngClass]="{ 'w-85': isMenuOpened ,'w-100': !isMenuOpened }"
                style="padding-top: 17px;">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>

</div>