<div id="customers_report_content">
  <div class="row justify-content-end">
    <button
      (click)="startExporting"
      class="btn_blue_bg col-lg-1 col-md-2 col-2 h-fit-content m-t-5"
    >
      Export
    </button>
  </div>

  <div class="div_table m-0">
    <ngx-datatable
      id="order-table"
      class="bootstrap row"
      [rows]="GetData"
      [columnMode]="'force'"
      [reorderable]="true"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [externalPaging]="true"
      [count]="GetPage.totalElements"
      [offset]="GetPage.pageNumber"
      [limit]="GetPage.size"
      (page)="pageChanged($event)"
    >
      <ngx-datatable-column
        prop="domain"
        [sortable]="true"
        [draggable]="true"
        [canAutoResize]="true"
        name="Domain"
        headerClass="col-lg-2 col-md-3 col-3"
        cellClass="col-lg-2 col-md-3 col-3"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <label>{{ row.domain }}</label>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column
        prop="totalNbrOfUsers"
        [sortable]="true"
        [draggable]="true"
        [canAutoResize]="true"
        name="Total nbr. of users"
        headerClass="col-lg-2 col-md-2 col-2"
        cellClass="col-lg-2 col-md-2 col-2"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <label>{{ row.totalNbrOfUsers }}</label>
        </ng-template>
      </ngx-datatable-column>
`
      <ngx-datatable-column
        [sortable]="true"
        [draggable]="true"
        [canAutoResize]="true"
        name="Details"
        headerClass="col-lg-7 col-md-7 col-7"
        cellClass="col-lg-7 col-md-7 col-7"
      >
        <ng-template let-row="row" ngx-datatable-cell-template>
          <div [innerHtml]="row.details"></div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>
