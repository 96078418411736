import { Utilities } from 'src/shared/utilities';
import { RequestResultModel } from './../models/request_result.model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserViewModel } from 'src/models/vw.user';
import { LocalStorageService } from './localstorage-service';

@Injectable({
  providedIn: 'root',
})
export class HttpRequestService {
  currentUser: UserViewModel;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService
  ) {
    var usr = this.localStorage.getLoggedinUser();
    if (!Utilities.isNullOrEmpty(usr)) this.currentUser = JSON.parse(usr);
  }

  public async get(
    url: string,
    headers: HttpHeaders,
    params: HttpParams = null
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel();
    if (!params) params = new HttpParams();
    await this.http
      .get(url, { headers: headers, params: params })
      .toPromise()
      .then((resp: any) => {
        result.success = true;
        result.data = resp;
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message ?? '';
        // try{
        // await Utilities.writeLogEntry(this.http, { error: "reseller api/get request", url: url, body: { request: Array.from(params.keys()), response: err } }, "Error");
        // }catch(e){
        //     await Utilities.sendMail(this.http,"web@isolutions.sa",["zaynab.mama@ismena.com"],
        //     JSON.stringify({ error: "reseller api/get request", url: url, body: { request: Array.from(params.keys()), response: err }}),'');
        // }
      });
    return result;
  }

  public async post(
    url: string,
    body,
    headers: HttpHeaders
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel();
    await this.http
      .post(url, !Utilities.isNullOrEmpty(body) ? JSON.stringify(body) : null, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              success: 'reseller api/post request',
              url: url,
              body: { user: this.currentUser?.user_email, request: body },
            },
            'INFO'
          );
        } catch (e) {}
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              error: 'reseller api/post request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: body,
                response: err,
              },
            },
            'Error'
          );
        } catch (e) {
          await Utilities.sendMail(
            this.http,
            'web@isolutions.sa',
            ['zaynab.mama@ismena.com'],
            JSON.stringify({
              error: 'reseller api/post request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: body,
                response: err,
              },
            }),
            ''
          );
        }
      });
    return result;
  }

  public async put(
    url: string,
    body,
    headers: HttpHeaders
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel();
    await this.http
      .put(url, !Utilities.isNullOrEmpty(body) ? JSON.stringify(body) : null, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              success: 'reseller api/put request',
              url: url,
              body: { user: this.currentUser?.user_email, request: body },
            },
            'INFO'
          );
        } catch (e) {}
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              error: 'reseller api/put request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: body,
                response: err,
              },
            },
            'Error'
          );
        } catch (e) {
          await Utilities.sendMail(
            this.http,
            'web@isolutions.sa',
            ['zaynab.mama@ismena.com'],
            JSON.stringify({
              error: 'reseller api/put request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: body,
                response: err,
              },
            }),
            ''
          );
        }
      });
    return result;
  }

  public async delete(
    url: string,
    headers: HttpHeaders,
    params: HttpParams
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel();
    await this.http
      .delete(url, { headers: headers, params: params })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              success: 'reseller api/delete request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: Array.from(params.keys()),
              },
            },
            'INFO'
          );
        } catch (e) {}
      })
      .catch(async (err) => {
        result.success = false;
        result.errorMessage = err?.error?.error?.message;
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              error: 'reseller api/delete request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: Array.from(params.keys()),
                response: err,
              },
            },
            'Error'
          );
        } catch (e) {
          await Utilities.sendMail(
            this.http,
            'web@isolutions.sa',
            ['zaynab.mama@ismena.com'],
            JSON.stringify({
              error: 'reseller api/delete request',
              url: url,
              body: {
                user: this.currentUser?.user_email,
                request: Array.from(params.keys()),
                response: err,
              },
            }),
            ''
          );
        }
      });
    return result;
  }

  public async batch(
    url: string,
    body,
    headers: HttpHeaders
  ): Promise<RequestResultModel> {
    var result: RequestResultModel = new RequestResultModel();
    await this.http
      .post(url, body, {
        headers: headers,
      })
      .toPromise()
      .then(async (resp: any) => {
        result.success = true;
        result.data = resp;
        await Utilities.writeLogEntry(
          this.http,
          {
            success: 'reseller api/bacth request',
            url: url,
            body: { user: this.currentUser, request: body },
          },
          'Info'
        );
      })
      .catch(async (err) => {
        result.success = false;
        result.data = err;
        result.errorMessage = '';
        try {
          await Utilities.writeLogEntry(
            this.http,
            {
              error: 'reseller api/bacth request',
              url: url,
              body: { user: this.currentUser, request: body, response: err },
            },
            'Error'
          );
        } catch (e) {
          await Utilities.sendMail(
            this.http,
            'web@isolutions.sa',
            ['zaynab.mama@ismena.com'],
            JSON.stringify({
              error: 'reseller api/bacth request',
              url: url,
              body: { user: this.currentUser, request: body, response: err },
            }),
            ''
          );
        }
      });
    return result;
  }
}