import { PlanValues } from './../../../../shared/Endpoint';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { UserViewModel } from 'src/models/vw.user';
import { OrderViewModel } from 'src/models/vw_order';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Unicode, Endpoint, OrderStatus, Group } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./orders.component.scss'],
})
export class ReportOrdersComponent implements OnInit {
  orders: OrderViewModel[] = [];
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  subdata: OrderViewModel[] = [];
  static subdataStatic: OrderViewModel[] = [];
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  static pageSizeStatic: number = Unicode.ITEMS_PER_PAGE;
  plans = [];
  products = [];
  currentUser: UserViewModel;
  fromSelectedDate;
  toSelectedDate;
  static staticFromSelectedDate;
  static staticToSelectedDate;
  sales = [];
  selectedSaleId: string;
  static staticSelectedSaleId: string;
  selectedStatus;
  static staticSelectedStatus;
  static pageStatic;
  htmlStatus;
  pageTitle: string = '';
  canCheck: boolean = false;
  currentUserRoleData: UserRoleDataModel;
  url: string = Endpoint.URL_EMAIL;
  static notChecked: boolean = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private datePipe: DatePipe,
    private datepipe: DatePipe,
    private confirmDialogService: ConfirmDialogService,
    private localStorage: LocalStorageService
  ) {
    this.htmlStatus = OrderStatus;
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    ReportOrdersComponent.pageStatic = this.page;
    var current = new Date();
    this.fromSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      current.setMonth(current.getMonth() - 1)
    );
    this.toSelectedDate = Utilities.setDatePickerDate(datePipe, new Date());
    ReportOrdersComponent.staticFromSelectedDate = this.fromSelectedDate;
    ReportOrdersComponent.staticToSelectedDate = this.toSelectedDate;
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.products = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.canCheck = this.currentUser.groups.find(
      (x) => x.name == Group.Accounting
    );
    this.getOrders({ offset: 0 }, '');
  }

  async ngOnInit(): Promise<void> {
    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'Reports';
    if (
      this.localStorage.get(Unicode.SALES) == 'undefined' ||
      !this.localStorage.get(Unicode.SALES)
    ) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SALES_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.SALES, JSON.stringify(resp.sales));
      }
    }
    this.sales = JSON.parse(this.localStorage.get(Unicode.SALES));
  }

  async getOrders(pageInfo, saleId?: string) {
    var from_string = this.datePipe.transform(
      new Date(
        this.fromSelectedDate.year,
        this.fromSelectedDate.month - 1,
        this.fromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = this.datePipe.transform(
      new Date(
        this.toSelectedDate.year,
        this.toSelectedDate.month - 1,
        this.toSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.page.size + '')
      .set(Unicode.LIMIT, this.pageSize + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string)
      .set(
        Unicode.ONLY_NOT_CHECKED_ORDERS,
        ReportOrdersComponent.notChecked + ''
      );
    if (!Utilities.isNullOrEmpty(saleId))
      prs = prs.set(Unicode.USER_ID, saleId);
    if (!Utilities.isNullOrEmpty(this.selectedStatus))
      prs = prs.set(Unicode.ORDER_STATUS, this.selectedStatus);
    var customerSearchvalue = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    if (!Utilities.isNullOrEmpty(customerSearchvalue))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearchvalue);
    if (!this.currentUserRoleData.canViewAll) {
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    }
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.REPORTS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.page.totalElements = <number>resp['total'];
      if (resp.data)
        this.setPage({ offset: pageInfo.offset, data: resp['data'] });
      else this.setPage({ offset: pageInfo.offset, data: [] });
    }
  }

  public static searchGlobal(
    datePip,
    localS,
    status,
    searchValue,
    user?,
    userRoleData?,
    http?
  ) {
    this.getOrdersStatic(
      datePip,
      localS,
      status,
      { offset: 0 },
      null,
      user,
      userRoleData,
      http,
      searchValue
    );
  }

  static async getOrdersStatic(
    datePip,
    localS,
    status,
    pageInfo,
    search,
    user,
    userRoleData,
    http: HttpClient,
    customerSearch?
  ) {
    var from_string = datePip.transform(
      new Date(
        ReportOrdersComponent.staticFromSelectedDate.year,
        ReportOrdersComponent.staticFromSelectedDate.month - 1,
        ReportOrdersComponent.staticFromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = datePip.transform(
      new Date(
        ReportOrdersComponent.staticToSelectedDate.year,
        ReportOrdersComponent.staticToSelectedDate.month - 1,
        ReportOrdersComponent.staticToSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var prs = new HttpParams()
      .set(
        Unicode.PAGE,
        pageInfo.offset * ReportOrdersComponent.pageStatic.size + ''
      )
      .set(Unicode.LIMIT, ReportOrdersComponent.pageSizeStatic + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string);
    if (!Utilities.isNullOrEmpty(ReportOrdersComponent.staticSelectedSaleId))
      prs = prs.set(
        Unicode.USER_ID,
        ReportOrdersComponent.staticSelectedSaleId
      );
    if (!Utilities.isNullOrEmpty(ReportOrdersComponent.staticSelectedStatus))
      prs = prs.set(
        Unicode.ORDER_STATUS,
        ReportOrdersComponent.staticSelectedStatus
      );
    if (!Utilities.isNullOrEmpty(customerSearch))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearch);
    if (!userRoleData.canViewAll) {
      prs = prs.set(Unicode.USER_ID, user.usr_id + '');
    }
    var resp = await http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.REPORTS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      ReportOrdersComponent.pageStatic.totalElements = <number>resp['total'];
      // if (resp.data)
      //   this.setPage({ offset: pageInfo.offset, data: resp['data'] });
      // else this.setPage({ offset: pageInfo.offset, data: [] });
      this.pageStatic.totalElements = <number>resp['total'];
      pageInfo.data = resp['data'] ?? [];
      this.pageStatic.pageNumber = pageInfo.offset;
      const pagedData = new PagedData<OrderViewModel>();
      this.pageStatic.totalPages =
        this.pageStatic.totalElements / this.pageStatic.size;
      pagedData.data.push(...pageInfo.data);
      pagedData.page = this.pageStatic;
      this.subdataStatic = pagedData.data;
      this.pageStatic = pagedData.page;
      this.subdataStatic.forEach((order) => {
        Utilities.setOrder(order, null, null, localS);
      });
    }
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    const pagedData = new PagedData<OrderViewModel>();
    this.page.totalPages = this.page.totalElements / this.page.size;
    pagedData.data.push(...pageInfo.data);
    pagedData.page = this.page;
    this.page = pagedData.page;
    ReportOrdersComponent.pageStatic = this.page;
    this.subdata = pagedData.data;
    this.subdata.forEach((order) => {
      Utilities.setOrder(order, this.products, this.plans);
    });
    ReportOrdersComponent.subdataStatic = this.subdata;
    ReportOrdersComponent.pageStatic = this.page;
  }

  get GetData() {
    return ReportOrdersComponent.subdataStatic;
  }

  pageChanged(pageInfo) {
    this.getOrders(pageInfo, this.selectedSaleId);
  }

  selectSale(value) {
    this.selectedSaleId = value;
    ReportOrdersComponent.staticSelectedSaleId = value;
    this.getOrders({ offset: 0 }, value);
  }

  selectStatus() {
    ReportOrdersComponent.staticSelectedStatus = this.selectedStatus;
    this.getOrders({ offset: 0 }, this.selectedSaleId);
  }

  viewOrder(order_id: number) {
    this.localStorage.save(
      Unicode.ORDER,
      JSON.stringify(this.subdata.find((x) => x.order_id == order_id))
    );
    this.router.navigate(['/orders/order-details']);
  }

  selectDate() {
    ReportOrdersComponent.staticFromSelectedDate = this.fromSelectedDate;
    ReportOrdersComponent.staticToSelectedDate = this.toSelectedDate;
    this.getOrders({ offset: 0 }, this.selectedSaleId);
  }

  selectNotChecked(event) {
    ReportOrdersComponent.notChecked = event.target.checked;
    this.getOrders({ offset: 0 }, this.selectedSaleId);
  }

  async export() {
    var headers;
    await Utilities.getToken(
      this.http,
      this.currentUser.user_email,
      'https://www.googleapis.com/auth/drive',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url = ' https://sheets.googleapis.com/v4/spreadsheets';
    var sheetName =
      'reports_' + this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    var sheetBody = {
      properties: {
        title: sheetName,
      },
      sheets: [
        {
          data: [
            {
              rowData: [],
            },
          ],
        },
      ],
    };
    sheetBody.sheets[0].data[0].rowData.push({
      values: [
        {
          userEnteredValue: {
            stringValue: 'ID',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Cutomer Domain',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Creator',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Auth Token',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'P.O#',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Sale invoice #',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'With Google Offer',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'With Customer Offer',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Description',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'opportunity #',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'opportunity Status',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'opportunity Source',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Google Rep',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Status',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Reason for rejection',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Date for rejection',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Action',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Old Plan',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'New Plan',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Old SKU',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'New SKU',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Old licenses',
          },
        },
        ,
        {
          userEnteredValue: {
            stringValue: 'Additional licenses',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'New licenses',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Selling Price',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Cost Price',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'approval/level1 Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'approval Name',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'approval/level2 Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'approval Name',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'GPM approval Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'GPM Name',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'execution Date',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Executer Name',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Google Incentives Policy',
          },
        },
      ],
    });
    var allOrders = [];
    var from_string = this.datePipe.transform(
      new Date(
        this.fromSelectedDate.year,
        this.fromSelectedDate.month - 1,
        this.fromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = this.datePipe.transform(
      new Date(
        this.toSelectedDate.year,
        this.toSelectedDate.month - 1,
        this.toSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var total = this.page.totalElements;
    var prs = new HttpParams()
      .set(Unicode.PAGE, '0')
      .set(Unicode.LIMIT, total + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string);
    if (!Utilities.isNullOrEmpty(this.selectedSaleId))
      prs = prs.set(Unicode.USER_ID, this.selectedSaleId);
    if (!Utilities.isNullOrEmpty(this.selectedStatus))
      prs = prs.set(Unicode.ORDER_STATUS, this.selectedStatus);
    var customerSearchvalue = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    if (!Utilities.isNullOrEmpty(customerSearchvalue))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearchvalue);
    if (!this.currentUserRoleData.canViewAll) {
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    }
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.REPORTS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      allOrders = resp['data'];
    }
    allOrders = allOrders ?? [];
    allOrders.forEach((order: OrderViewModel) => {
      Utilities.setOrder(order, this.products, this.plans);
      var additionalLicences =
        order.new_licence_number - order.old_licence_number;
      var values = [
        {
          userEnteredValue: {
            stringValue: order.order_id,
          },
        },
        {
          userEnteredValue: {
            stringValue: order.customer_domain,
          },
        },
        {
          userEnteredValue: {
            stringValue: order.usr_name,
          },
        },
        {
          userEnteredValue: {
            stringValue: order.auth_token ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.po_id ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.order_sale_invoice_nbr ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              order.with_google_offer && order.with_google_offer + '' == '1'
                ? 'Yes:' + order.google_offer_percentage + '%'
                : 'No',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              order.with_customer_offer && order.with_customer_offer + '' == '1'
                ? 'Yes:' + order.customer_offer_percentage + '%'
                : 'No',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.string_date ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.description ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.opportunity_nbr ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.opportunity_status ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.opportunity_source ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.google_rep ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.order_status ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.reject_reason ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.reject_date_string ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.action ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              this.plans.find((x) => x.id == order.old_plan_id)?.name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              this.plans.find((x) => x.id == order.new_plan_rfid)?.name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              this.products.find((x) => x.sku_id == order.old_sku_id)
                ?.sku_name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue:
              order.new_sku_id.indexOf(';') >= 0
                ? this.products.find(
                    (x) => x.sku_id == order.new_sku_id.split(';')[0]
                  )?.sku_name ?? ''
                : this.products.find((x) => x.sku_id == order.new_sku_id)
                    ?.sku_name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.old_licence_number + '',
          },
        },
        {
          userEnteredValue: {
            stringValue: additionalLicences + '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.new_licence_number ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.selling_price ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.cost_price ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.approved_date1 ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.approval1_name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.approved_date2 ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.approval2_name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.gpm_approval_date ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.gpm_approval_name ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.last_approval_date ?? '',
          },
        },
        {
          userEnteredValue: {
            stringValue: order.last_approval_name ?? '',
          },
        },
      ];
      if (order.order_policy_names) {
        var lg = order.order_policy_names.split(';').length;
        for (var p = 0; p < lg; p++) {
          values.push({
            userEnteredValue: {
              stringValue:
                order.order_policy_names.split(';')[p] +
                (!Utilities.isNullOrEmpty(
                  order.order_policy_percentages.split(';')[p]
                ) && order.order_policy_percentages.split(';')[p] != '0'
                  ? '(' + order.order_policy_percentages.split(';')[p] + '%)'
                  : '') +
                ' ' +
                (order.order_policy_gpm_check[p] &&
                Number.parseInt(order.order_policy_gpm_check.split(';')[p]) == 1
                  ? 'Checked by GPM'
                  : 'Not checked by GPM') +
                ' , ' +
                (order.order_policy_exist[p] &&
                Number.parseInt(order.order_policy_exist.split(';')[p]) == 1
                  ? 'exist(Yes)'
                  : 'Not exist(No)') +
                ' ,amount=' +
                order.order_policy_amount.split(';')[p],
            },
          });
        }
      }
      sheetBody.sheets[0].data[0].rowData.push({
        values: values,
      });
    });
    //
    this.http
      .post(url, JSON.stringify(sheetBody), { headers: headers })
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        window.open(resp.spreadsheetUrl);
      })
      .catch((error) => {
        console.log(error);
        alert(error.error.error.message);
      });
  }

  private async updateOrders(orderIds: string) {
    var params = new HttpParams().set(Unicode.ORDER_IDS, orderIds);
    await this.http
      .post(
        Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_EXPORTED_ORDERS_FILE_NAME,
        null,
        { params: params }
      )
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
      });
  }

  async checkOrder(id, event) {
    var isChecked = event.currentTarget.checked; //just for confirmation
    if (isChecked) {
      var params = new HttpParams().set(Unicode.ORDER_IDS, id + '');
      await this.http
        .post(
          Endpoint.GLOBAL_URL + '' + Endpoint.UPDATE_CHECKED_ORDERS_FILE_NAME,
          null,
          { params: params }
        )
        .toPromise()
        .then((resp: any) => {
          console.log(resp);
        });
    }
  }
}
