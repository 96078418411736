import { filter } from 'rxjs/operators';
import { UserRoleDataModel } from './../../../models/user.role.data';
import { UserViewModel } from 'src/models/vw.user';
import { Router } from '@angular/router';
import { OrderViewModel } from './../../../models/vw_order';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  NgZone,
  ViewChild,
} from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { Endpoint, Unicode, OrderStatus, Group } from 'src/shared/Endpoint';
import { DatePipe } from '@angular/common';
import { Utilities } from 'src/shared/utilities';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { PagedData } from 'src/models/paged-data';
import { Page } from 'src/models/page';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-dashboard',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  chartOptions: ChartOptions;
  statuschartOptions: ChartOptions;
  customersChartOptions: ChartOptions;
  lineChartLegend = true;
  chartLabels: Label[] = ['Created Orders'];
  chartData: ChartDataSets[];
  orderLineChartType: ChartType = 'bar';
  statuschartLabels: Label[] = [
    OrderStatus.Executed,
    OrderStatus.Pending,
    OrderStatus.Rejected,
  ];
  customerschartLabels: Label[] = [];
  statuschartData: number[] = [];
  customerschartData: number[] = [];
  statusLineChartType: ChartType = 'pie';
  pieChartColors = [
    {
      backgroundColor: [
        'rgba(40,191,239,1)',
        'rgba(0,81,125,1)',
        'rgba(178,41,31,1)',
      ],
    },
  ];
  chartColors = [];
  customerchartColors = [];

  totalCustomers: number = 0;
  totalOrders: number = 0;
  totalUsers: number = 0;
  totalPending: number = 0;
  totalExecuted: number;
  totalRejected: number = 0;
  fromSelectedDate: NgbDateStruct;
  toSelectedDate: NgbDateStruct;
  currentUser: UserViewModel;
  currentUserRoleData: UserRoleDataModel;
  totalOrdersChart2: string = '';
  onlyExecutedOrders: boolean = false;
  pageToken: string = '';
  totalLicenses: number = 0;
  summarySkuData = [];
  summaryFromSelectedDate: NgbDateStruct;
  summaryToSelectedDate: NgbDateStruct;
  static subdataStatic = [];
  static httpRequestService;
  static page = new Page();
  pageLimit = 5;
  summaryCustomerSearch: string = '';

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router,
    private zone: NgZone,
    private httpRequestService: HttpRequestService
  ) {
    DashboardComponent.page.pageNumber = 0;
    DashboardComponent.page.size = this.pageLimit;
    DashboardComponent.httpRequestService = httpRequestService;

    if (this.localStorage.get('reload') == '1') {
      this.localStorage.save('reload', '0');
      document.location.reload();
    }
    var current = new Date();
    this.fromSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      current.setMonth(current.getMonth() - 1)
    );
    this.toSelectedDate = Utilities.setDatePickerDate(datePipe, new Date());
    this.summaryFromSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      current.setMonth(current.getMonth() - 1)
    );
    this.summaryToSelectedDate = Utilities.setDatePickerDate(
      datePipe,
      new Date()
    );
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    for (var i = 0; i < 32; i++) {
      this.chartColors[i] = { backgroundColor: [] };
      this.chartColors[0].backgroundColor[i] = 'rgba(40,191,239,1)';
    }
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  async ngOnInit(): Promise<void> {
    this.getSummaryData({ offset: 0 });
    //
    this.setCharts();
    var prs = new HttpParams();
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.DASHBOARD_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.totalOrders = resp['total_orders'];
      this.totalCustomers = resp['total_customers'];
      //this.totalUsers = resp['total_users'];
      this.totalPending = resp['total_pending_orders'];
      this.totalExecuted = resp['total_executed_orders'];
      this.totalRejected = resp['total_rejected_orders'];
      this.statuschartData[0] = Number.parseFloat(
        ((this.totalExecuted * 100) / this.totalOrders).toFixed(2)
      ); //this.totalExecuted
      this.statuschartData[1] = Number.parseFloat(
        ((this.totalPending * 100) / this.totalOrders).toFixed(2)
      );
      this.statuschartData[2] = Number.parseFloat(
        ((this.totalRejected * 100) / this.totalOrders).toFixed(2)
      );
    }
    //
    this.getOrders();
    let labels = $('.pieChart');
    for (let label of labels) {
      label.innerHTML += '%';
    }
    await this.getCustomersByCountry();
    await Utilities.getToken(
      this.http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then(async (token) => {
      var headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
      await this.getAllSubscriptions(headers);
    });
  }

  async getSummaryData(pageInfo, skuSummary = true, custSummary = true) {
    var from_string = this.datePipe.transform(
      new Date(
        this.summaryFromSelectedDate.year,
        this.summaryFromSelectedDate.month - 1,
        this.summaryFromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = this.datePipe.transform(
      new Date(
        this.summaryToSelectedDate.year,
        this.summaryToSelectedDate.month - 1,
        this.summaryToSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var sskuParams = new HttpParams()
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string)
      .set(Unicode.WITH_CUSTOMER_SUMMARY, custSummary + '')
      .set(Unicode.WITH_SKU_SUMMARY, skuSummary + '')
      .set(Unicode.PAGE, pageInfo.offset * DashboardComponent.page.size + '')
      .set(Unicode.LIMIT, this.pageLimit + '');
    if (!Utilities.isNullOrEmpty(this.summaryCustomerSearch))
      sskuParams = sskuParams.set(
        Unicode.CUSTOMER_FILTER,
        this.summaryCustomerSearch
      );
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.SKU_SUMMARY_FILE_NAME, {
        params: sskuParams,
      })
      .toPromise();
    if (resp && resp.skuSummaryData) {
      this.summarySkuData = resp.skuSummaryData;
    }
    if (resp && resp.customersSummaryData) {
      DashboardComponent.page.totalElements = resp['total']
        ? <number>resp['total']
        : DashboardComponent.page.totalElements; 
      var customersOrders =resp.customersSummaryData;
      //FOR THE CUSTOMERS NOT CRAETED FROM OUR APP(GET THE FIRST CRAETED SUBSC AS FIRST ORDER)
      // var apiCusts = customersOrders.filter(
      //   (x) => x.retreive_first_order == true
      // );
      // for (var i = 0; i < apiCusts.length; i++) {
      //   var cust = apiCusts[i];
      //   await Utilities.getSubscriptionByCustomerId(
      //     this.http,
      //     this.httpRequestService,
      //     cust.domain
      //   ).then((resp: any) => {
      //     if (resp && resp.subscriptions && resp.subscriptions.length > 0) {
      //       var subs = resp.subscriptions.sort(
      //         (a, b) => a.creationTime.rendered > b.creationTime.rendered
      //       );
      //       cust.orders.push({
      //         order_id: '',
      //         domain: cust.domain,
      //         execution_date: subs[0].creationTime,
      //         product_name: subs[0].skuName,
      //         licenses: Utilities.getLicensesByPlan(subs[0]),
      //         cost_price: '',
      //         total: '',
      //         commitment_expiry_date:
      //           subs[0].isCommitmentPlan &&
      //           subs[0].commitmentInterval &&
      //           subs[0].commitmentInterval.endTime
      //             ? subs[0].commitmentInterval.endTime
      //             : '',
      //         commitment_start_date:
      //           subs[0].isCommitmentPlan &&
      //           subs[0].commitmentInterval &&
      //           subs[0].commitmentInterval.startTime
      //             ? subs[0].commitmentInterval.startTime
      //             : '',
      //       });
      //     }
      //   });
      // }
      this.setPage({
        offset: pageInfo.offset,
        data: customersOrders ?? [],
      });
    }
  }

  async getOrders() {
    this.chartLabels = [];
    this.chartData[0].data = [];
    var from_string = this.datePipe.transform(
      new Date(
        this.fromSelectedDate.year,
        this.fromSelectedDate.month - 1,
        this.fromSelectedDate.day
      ),
      'yyyy-MM-dd'
    );
    var to_string = this.datePipe.transform(
      new Date(
        this.toSelectedDate.year,
        this.toSelectedDate.month - 1,
        this.toSelectedDate.day + 1
      ),
      'yyyy-MM-dd'
    );
    var prs = new HttpParams()
      .set(Unicode.PAGE, '0')
      .set(Unicode.LIMIT, this.totalOrders + '')
      .set(Unicode.FROM_DATE, from_string)
      .set(Unicode.TO_DATE, to_string);
    // if (!this.currentUserRoleData.toExecute && !this.currentUser.isGPM && !this.currentUser.approve2) {
    //   prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + "");
    // }
    if (this.onlyExecutedOrders) {
      prs = prs.set(Unicode.ORDER_STATUS, OrderStatus.Executed);
    }
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_ORDERS_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      var orders: OrderViewModel[] = resp['data'];
      if (orders) {
        this.zone.run(() => {
          this.totalOrdersChart2 = orders.length + '';
        });
        var start = new Date(
          this.fromSelectedDate.year,
          this.fromSelectedDate.month - 1,
          this.fromSelectedDate.day
        );
        var end = new Date(
          this.toSelectedDate.year,
          this.toSelectedDate.month - 1,
          this.toSelectedDate.day
        );
        var current = start;
        var count = 0;
        while (current.getTime() <= end.getTime()) {
          var current_string = this.datePipe.transform(current, 'yyyy-MM-dd');
          var chartDate = this.datePipe.transform(current, 'MMM d yyyy');
          var nb = orders.filter((x) => x.string_date == current_string).length;
          this.chartLabels[count] = chartDate;
          this.chartData[0].data[count] = nb;

          count++;
          current = new Date(current.setDate(current.getDate() + 1));
        }
      }
    }
  }

  selectDate() {
    this.getOrders();
  }

  summarySelectDate() {
    this.getSummaryData({ offset: 0 }, true, false);
  }

  async getCustomersByCountry() {
    var resp = await this.http
      .get<any>(
        Endpoint.GLOBAL_URL + '' + Endpoint.CUSTOMER_DASHBOARD_FILE_NAME
      )
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      var customers: OrderViewModel[] = resp['data'];
      if (customers) {
        for (var i = 0; i < customers.length; i++) {
          var cust: any = customers[i];
          this.customerschartLabels[i] = cust.name;
          this.customerschartData[i] = cust.total;
          this.customerchartColors[i] = { backgroundColor: [] };
          this.customerchartColors[0].backgroundColor[i] =
            Utilities.getRandomColor();
        }
      }
    }
  }

  async getAllSubscriptions(headers) {
    var url = 'https://reseller.googleapis.com/apps/reseller/v1/subscriptions';
    if (!Utilities.isNullOrEmpty(this.pageToken))
      url =
        'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?pageToken=' +
        this.pageToken;
    var prs = new HttpParams();
    prs = prs.set(Unicode.WITHOUT_LOADER, '1');
    var result = await this.httpRequestService.get(url, headers, prs);
    if (result.success) {
      if (result.data && result.data.subscriptions) {
        this.totalLicenses =
          this.totalLicenses +
          result.data.subscriptions
            .filter((s) => s.seats && s.seats.licensedNumberOfSeats)
            .map((x) => x.seats.licensedNumberOfSeats)
            .reduce((a, b) => a + b, 0);
        this.totalUsers = this.totalLicenses;
        if (result.data.nextPageToken) {
          this.pageToken = result.data.nextPageToken;
          await this.getAllSubscriptions(headers);
        }
      }
    } else {
      console.log(result.errorMessage);
    }
  }

  chartClicked(e) {
    if (e.active.length > 0) {
      const chart = e.active[0]._chart;
      const activePoints = chart.getElementAtEvent(e.event);
      if (activePoints.length > 0) {
        // get the internal index of slice in pie chart
        const clickedElementIndex = activePoints[0]._index;
        const statusValue = chart.data.labels[clickedElementIndex];
        // get value by index
        const value = chart.data.datasets[0].data[clickedElementIndex]; // number value
        var statusVal = statusValue;
        statusVal =
          statusValue.toUpperCase() == OrderStatus.Pending
            ? statusValue.toLowerCase() +
              '_' +
              OrderStatus.Approved1.toLowerCase() +
              '_' +
              OrderStatus.Approved2.toLowerCase()
            : statusValue.toLowerCase();
        this.router.navigate(['orders'], {
          queryParams: { status: statusVal },
        });
      }
    }
  }

  setCharts() {
    var scales = {
      yAxes: [
        {
          ticks: {
            min: 0,
            stepSize: 2,
          },
        },
      ],
      //  unit : 'day',
      //   stepsize: 2,
      //   unitStepSize: 5
    };
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: 'right',
      },
      title: {
        text: this.totalOrdersChart2,
        display: true,
        fontSize: 20,
      },
      scales: scales,
    };
    this.chartData = [{ data: [], label: 'Created Order' }];
    this.statuschartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 15,
          fontSize: 14,
          padding: 30,
          usePointStyle: true,
          fontStyle: 'bold',
        },
      },
      plugins: {
        labels: {
          render: 'label',
          arc: true,
          showActualPercentages: false,
        },
      },
      tooltips: {
        enabled: true,
        mode: 'single',
        callbacks: {
          label: function (tooltipItems, data) {
            return data.datasets[0].data[tooltipItems.index] + '%';
          },
        },
      },
    };
    this.customersChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        position: 'bottom',
        labels: {
          boxWidth: 15,
          fontSize: 14,
          padding: 30,
          usePointStyle: true,
          fontStyle: 'bold',
        },
      },
      plugins: {
        labels: {
          render: 'label',
          arc: true,
          showActualPercentages: false,
        },
      },
      tooltips: {
        enabled: true,
        mode: 'single',
      },
    };
  }

  get GetData() {
    return DashboardComponent.subdataStatic;
  }

  get GetPage() {
    return DashboardComponent.page;
  }

  setPage(pageInfo) {
    this.zone.run(() => {
      DashboardComponent.page.pageNumber = pageInfo.offset;
      const pagedData = new PagedData<any>();
      DashboardComponent.page.totalPages =
        DashboardComponent.page.totalElements / DashboardComponent.page.size;
      pagedData.data.push(...pageInfo.data);
      pagedData.page = DashboardComponent.page;
      DashboardComponent.page = pagedData.page;
      DashboardComponent.subdataStatic = pagedData.data;
    });
  }

  pageChanged(pageInfo) {
    this.getSummaryData(pageInfo, false, true);
  }

  search(event) {
    this.getSummaryData({ offset: 0 }, false, true);
  }
}
