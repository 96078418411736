import { BaseModel } from './base.model';

export class RequestResultModel extends BaseModel{
   
    success:boolean;
    data;
    errorMessage:string;

    constructor(){
        super();
    }
}