import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ReviewOrderComponent } from './review-order.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReviewOrderRoutingModule } from './review-order-routing.module';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [ReviewOrderComponent],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgbDropdownModule,
    NgbModule,
    ReviewOrderRoutingModule,
  ],
})
export class ReviewOrderModule {}
