<div>
  <div id="add_head_grp">
    <div class="p-0 m-0">
      <button
        (click)="AddNewGroup()"
        class="btn_blue_bg float-right m-0"
        data-toggle="tooltip"
        title="add new group"
      >
        <img class="ic_plus_white p-0" src="../../assets/+.png" />
      </button>
    </div>
  </div>
  <div id="grps">
    <ul>
      <li class="row col-12">
        <span class="col-2 cust_title">Name</span>
        <span class="col-3 cust_title">Email</span>
        <span class="col-3"></span>
        <span class="col-2"></span>
        <span class="col-2"></span>
      </li>
      <li class="row col-12" *ngFor="let grp of groups">
        <span class="col-2">{{ grp.name }}</span>
        <span class="col-3">{{ grp.email }}</span>
        <button class="col-3 btn_mang_members" (click)="manageMembers(grp)">
          Manage Members
        </button>
        <button class="col-2 btn_mang_members" (click)="editGroup(grp)">
          Edit
        </button>
        <button class="col-2 btn_rmv_grp" (click)="deleteGroup(grp.id)">
          Delete
        </button>
      </li>
    </ul>
  </div>
</div>

<!--- NEW GROUP POPUP --->
<div
  class="modal fade"
  bsModal
  #AddGrpModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content p-10">
      <div class="modal-header">
        <label class="font-weight-bold text-uppercase">Add new group</label>
        <div (click)="AddGrpModal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div>
          <form
            (ngSubmit)="addGroup()"
            id="add-grp-form"
            [formGroup]="AddGrpForm"
            role="form"
            #formDir="ngForm"
            novalidate
            class="ng-untouched ng-pristine ng-invalid row"
          >
            <div class="col-12 m-t-15 input-group">
              <span class="border-lable-flt col-12 p-0">
                <input
                  type="text"
                  name="name"
                  placeholder=" "
                  class="form-control"
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid': formDir.submitted && name.invalid
                  }"
                  required
                  autofocus
                />
                <label for="label-name"
                  >Name<span class="spn_required"> *</span></label
                >
              </span>
              <div
                class="invalid-feedback"
                *ngIf="formDir.submitted && name.invalid"
              >
                <p *ngIf="name.errors.required">Name is required</p>
              </div>
            </div>
            <div class="col-12 m-t-15 input-group">
              <span class="border-lable-flt col-12 p-0">
                <input
                  type="text"
                  name="email"
                  placeholder=" "
                  class="form-control"
                  formControlName="email"
                />
                <label for="label-name">Email</label>
              </span>
            </div>
            <div class="col-12 m-t-15" style="color: #28bfef">
              Add permissions to this group:
            </div>
            <div class="col-12">
              <ng-select
                [multiple]="true"
                class="form-control text-capitalize"
                formControlName="grpPermissions"
                style="padding: 0 !important; border: none !important"
              >
                <ng-option *ngFor="let perm of permissions" [value]="perm.id">{{
                  perm.name
                }}</ng-option>
              </ng-select>
            </div>

            <div class="col-12 div_btns float-right p-0 text-right m-t-15">
              <button class="btn_blue_bg">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

<!--- ADDING GROUP CONFIRMATION MSG POPUP --->
<div
  class="modal fade"
  bsModal
  #AddGrpMsgModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content p-10">
      <div class="modal-header">
        <div (click)="AddGrpMsgModal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="creation_msg m-b-25">{{ updateAddGrpMsg }}</div>
        <div>
          <label>Click</label
          ><a href="javascript:void(0)" (click)="manageMembers(newGrpModel)">
            here </a
          ><label>to manage members</label>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>
