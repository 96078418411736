import { CurrencyModel } from './../../../../../src/models/currency.model';
import { Unicode } from './../../../../../src/shared/Endpoint';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Endpoint } from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'settings-currency',
  templateUrl: './currency.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {

  currencies: CurrencyModel[] = [];
  pageTitle: string = '';
  @ViewChild('AddModal')
  AddModal: ModalDirective;
  currencyForm: FormGroup;
  newCurrencyModel: CurrencyModel = new CurrencyModel();


  constructor(private http: HttpClient, private fb: FormBuilder, private localStorage: LocalStorageService) {
    this.currencyForm = fb.group({
      name: ['', Validators.required],
      code: ['', Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    var params = new HttpParams().set(Unicode.ACTION, Unicode.GET_ALL);
    await this.http.get(Endpoint.GLOBAL_URL + "" + Endpoint.CURRENCY_FILE_NAME, { params: params }).toPromise()
      .then((resp: any) => {
        if (resp && resp.currencies) {
          this.currencies = resp.currencies;
          this.localStorage.save(Unicode.CURRENCIES, JSON.stringify(this.currencies));
        }
      });
  }

  AddNewCurrency() {
    this.AddModal.show();
  }

  async onSubmit() {
    if (this.currencyForm.valid) {
      this.newCurrencyModel.name = this.name.value;
      this.newCurrencyModel.code = this.code.value;
      if (this.currencies.find(x => x.name.toLowerCase().trim() == this.newCurrencyModel.name.toLowerCase().trim())) {
        alert("Currency already exits");
      }
      else {
        var params = new HttpParams().set(Unicode.ACTION, Unicode.ADD);
        await this.http.post(Endpoint.GLOBAL_URL + "" + Endpoint.CURRENCY_FILE_NAME, JSON.stringify(this.newCurrencyModel), { params: params }).toPromise()
          .then((resp: any) => {
            if (resp && !resp.msg) {
              alert("successfully added");
              this.newCurrencyModel.id = resp.id;
              this.currencies.push(this.newCurrencyModel);
              this.localStorage.save(Unicode.CURRENCIES, JSON.stringify(this.currencies));
              this.AddModal.hide();
              this.currencyForm.reset();
            }
            else {
              alert(resp.msg);
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    }
  }

  get name() { return this.currencyForm.get('name'); }
  get code() { return this.currencyForm.get('code'); }
}
