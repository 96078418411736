import { Router } from '@angular/router';
import { StorageService } from './../../../../../../src/servcies/storage-service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Unicode } from 'src/shared/Endpoint';
import { Plan, SubscriptionModel } from 'src/models/subcription.model';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'lib-plans',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

  plans = [];
  googleProducts = [];
  currentNewSubsc = new SubscriptionModel();
  productNameSelected:string;
  productImgSelected:string;

  constructor(private storage: StorageService, private router: Router, private localStorage: LocalStorageService) { }

  ngOnInit(): void {
    this.googleProducts = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.currentNewSubsc = JSON.parse(this.localStorage.get(Unicode.NEW_SUBSC));
    this.productNameSelected = this.currentNewSubsc.productName;
    this.productImgSelected = this.googleProducts.find(x=>x.sku_id==this.currentNewSubsc.skuId)?.img_url;
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    if (this.currentNewSubsc.plan && this.currentNewSubsc.plan.planName)
      this.currentNewSubsc.oldPlanId = this.plans.find(x => x.code == this.currentNewSubsc.plan.planName).id;
    else this.currentNewSubsc.oldPlanId = 0;
  }

  selectPlan(planName: string) {
    // if (this.currentNewSubsc.subscriptionId && this.currentNewSubsc.plan)
    //   this.currentNewSubsc.oldPlanId = this.plans.find(x => x.code == this.currentNewSubsc.plan.planName).id;
   // else this.currentNewSubsc.oldPlanId = 0;
    if (this.currentNewSubsc.plan && this.currentNewSubsc.plan.planName && this.currentNewSubsc.plan.planName !== planName) {
      this.currentNewSubsc.isplanChanged = true;
      this.currentNewSubsc.newPlanId = this.plans.find(x => x.code == planName).id;
    }
    this.currentNewSubsc.plan = this.currentNewSubsc.plan ? this.currentNewSubsc.plan : new Plan();
    this.currentNewSubsc.plan.planName = planName;
    this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(this.currentNewSubsc));
    this.router.navigate(['customers/new-subscription/review-order']);
  }

  goBack(){
    this.router.navigate(['customers/new-subscription/products'])
  }

}
