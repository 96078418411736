import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'settings-permission-list',
  templateUrl: './permission-list.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./permission-list.component.scss'],
})
export class PermissionListComponent implements OnInit {
  permissions: any[] = [];
  @ViewChild('AddPermModal')
  AddPermModal: ModalDirective;
  AddPermForm: FormGroup;
  newPermModel: any = {};

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private fb: FormBuilder,
    private router: Router,
    private confirmDialogService: ConfirmDialogService
  ) {}

  ngOnInit(): void {
    var params = new HttpParams();
    this.http
      .get(Endpoint.GLOBAL_URL + '' + Endpoint.PERMS_FILE_NAME, {
        params: params,
      })
      .toPromise()
      .then((resp: any) => {
        if (resp && resp.permissions) this.permissions = resp.permissions;
      })
      .catch((err) => {
        console.error(err);
      });
    //ADDING PERMISSION FORM
    this.AddPermForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      code: [''],
      id: [''],
    });
  }

  AddNewPermission() {
    this.newPermModel = {};
    this.AddPermForm.reset();
    this.AddPermModal.show();
  }

  addPermission() {
    if (this.AddPermForm.valid) {
      this.newPermModel.name = this.AddPermForm.value.name;
      this.newPermModel.description = this.AddPermForm.value.description;
      var isAdd = Utilities.isNullOrEmpty(this.newPermModel.id);
      var params = new HttpParams().set(
        Unicode.IS_ADD_OPERATION,
        isAdd ? '1' : '0'
      );
      if (Utilities.isNullOrEmpty(this.newPermModel.code)) {
        var name = this.newPermModel.name;
        var list = name.split(' ');
        var codeList = list.map((element) =>
          element.substr(0, 3).toUpperCase()
        );
        this.newPermModel.code = codeList.join('_');
      }
      this.http
        .post(
          Endpoint.GLOBAL_URL + '' + Endpoint.PERMS_FILE_NAME,
          JSON.stringify(this.newPermModel),
          {
            params: params,
          }
        )
        .toPromise()
        .then((resp: any) => {
          if (resp && !resp.msg && resp.permissionId) {
            this.AddPermModal.hide();
            this.newPermModel.id = resp.permissionId + '';
            var updateAddPermMsg = isAdd
              ? 'Created successfully'
              : 'Updated successfully';
            if (isAdd) this.permissions.push(this.newPermModel);
            this.localStorage.save(
              Unicode.PERMISSIONS,
              JSON.stringify(this.permissions)
            );
            alert(updateAddPermMsg);
          } else {
            alert(resp.msg ?? '');
          }
        });
    }
  }

  deletePermission(permId) {
    var that = this;
    this.confirmDialogService.confirmThis(
      'Are you sure you want to delete this permission and remove it from users/groups?',
      async function () {
        var params = new HttpParams().set(Unicode.PERMISSION_ID, permId + '');
        that.http
          .delete(Endpoint.GLOBAL_URL + '' + Endpoint.PERMS_FILE_NAME, {
            params: params,
          })
          .toPromise()
          .then((resp: any) => {
            if (resp && !resp.msg) {
              var index = that.permissions.findIndex((x) => x.id == permId);
              that.permissions.splice(index, 1);
              that.localStorage.save(
                Unicode.PERMISSIONS,
                JSON.stringify(that.permissions)
              );
              alert('Deleted');
            } else {
              alert(resp.msg ?? '');
            }
          });
      },
      function () {}
    );
  }

  editPermission(perm: any) {
    this.newPermModel = perm;
    this.AddPermForm.controls.name.setValue(perm.name);
    this.AddPermForm.controls.id.setValue(perm.id);
    this.AddPermForm.controls.description.setValue(perm.description);
    this.AddPermForm.controls.code.setValue(perm.code);
    this.AddPermModal.show();
  }

  get name() {
    return this.AddPermForm.get('name');
  }
  get code() {
    return this.AddPermForm.get('code');
  }
  get description() {
    return this.AddPermForm.get('description');
  }
}
