import { SalesRoutingModule } from './sales-routing.module';
import { NgModule } from '@angular/core';
import { SalesComponent } from './sales.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';



@NgModule({
  declarations: [SalesComponent],
  imports: [
    SalesRoutingModule,
    FormsModule,ReactiveFormsModule,
    NgbDropdownModule,
    NgxDatatableModule
  ],
  exports: [SalesComponent]
})
export class SalesModule { }
