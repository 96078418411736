import { BaseModel } from './base.model';

export class UserRoleDataModel extends BaseModel {

    userID: number;
    orderStatus:string;
    toExecute:boolean;
    canViewAll:boolean;
    receiverEmail : string[];
    approver1Email:string= '';
    approver2Email:string = '';
    GPMApproverEmail:string= '';
    lastApproverEmail:string= '';

    constructor() {
        super();
    }
}