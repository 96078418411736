import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersRoutingModule } from './orders-routing.module';
import { ReportOrdersComponent } from './orders.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [ReportOrdersComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbModule,
    ModalModule.forRoot(),
    OrdersRoutingModule
  ]
})
export class OrdersModule { }
