<div id="full-container">
  <div id="outPopUp">
    <div class="text-section">
      <div class="welcome">
        <p
          style="
            font: normal normal normal 36px/50px Product Sans Thin;
            padding-top: 50px;
          "
        >
          Welcome to
        </p>
        <p
          style="
            font: normal normal bold 54px/50px Product Sans;
            color: #000000;
            margin-top: -20px;
          "
        >
          Reseller App
        </p>
      </div>

      <p
        style="
          font: normal normal normal 36px/44px Product Sans;
          letter-spacing: -0.72px;
          padding-top: 30px;
        "
      >
        Sign in
      </p>
      <p
        style="
          font: normal normal normal 20px/24px Product Sans Light;
          letter-spacing: 0.12px;
        "
      >
        Simply sign in using your Google Account
      </p>

      <div id="customBtn" class="row"></div>
    </div>
  </div>
  <div class="footer">
    <p>Copyright 2023 ©iSolutions. All Rights Reserved</p>
  </div>
</div>
