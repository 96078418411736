import { ConfirmDialogService } from './../servcies/confirm-dialog.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from 'src/auth/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogComponent } from 'src/shared-components/confirm-dialog/confirm-dialog.component';
import { DatePipe } from '@angular/common';
import { MyLoaderComponent } from './my-loader/my-loader.component';
import { LoaderService } from 'src/servcies/loader.service';
import { LoaderInterceptor } from 'src/servcies/loader-interceptor.service';

@NgModule({
  declarations: [
    AppComponent, ConfirmDialogComponent, MyLoaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    AppRoutingModule,
  ],
  providers: [AuthGuard, HttpClient, ConfirmDialogService, DatePipe,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
