import { Unicode } from './../../../../../src/shared/Endpoint';
import { LocalStorageService } from './../../../../../src/servcies/localstorage-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-subscriptions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {

  cusomerId: string;
  changePlan;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private localStorage: LocalStorageService) {
  }

  async ngOnInit(): Promise<void> {
      this.changePlan = this.localStorage.get(Unicode.ORDER_CHANGE_PLAN);
  }

  test() {
    var x = this.localStorage.get(Unicode.ORDER_CHANGE_PLAN);
  }

}
