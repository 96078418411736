<div class="container">
    <div class="plan_header row">
        <div class="col-lg-3 col-md-4 col-sm-5 col-5">
            <label class="lbl_select_sku">Selected SKU</label>
            <img src="{{productImgSelected}}" width="47" class="img_plan_sku info_visiblity" />
        </div>
        <label class="lbl_sku col-lg-7 col-md-5 col-sm-5 col-5">{{productNameSelected}}</label>
        <a href="javascript:void(0)" (click)="goBack()" class="href_go_back">Go back & change</a>
    </div>

    <div class="row plans">
        <ng-container *ngFor="let plan of plans">
            <div class="col-lg-4 col-md-6 col-12 col-sm-6">
                <div class="plan_card"
                    [ngClass]="{'blue-border': currentNewSubsc.plan && currentNewSubsc.plan.planName == plan.code }">
                    <div class="div_name">
                        <label class="f-productSans">{{plan.name}}</label>
                    </div>
                    <div class="div_desc">
                        <label>{{plan.description}}</label>
                    </div>
                    <div class="div_select">
                        <label class="btn_select" (click)="selectPlan(plan.code)">Select</label>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

</div>