<div>
    <div class="row products justify-content-center">
        <div *ngFor="let pro of googleProducts" class="col-lg-2 col-md-3 col-3 col-sm-3 product_card">
            <div class="m-t-25">
                <img src="{{pro.img_url}}" width="55" class="sku_logo">
            </div>
            <div  class="m-t-20 sku_name f-productSans">{{pro.sku_name}}</div>
            <div class="div_btn_select">
                <!-- <button class="btn" (click)="selectProduct(pro.sku_id,pro.sku_name)">Select</button> -->
                <label class="btn_select" (click)="selectProduct(pro.sku_id,pro.sku_name)">Select</label>
            </div>
        </div> 
    </div>

</div>