import { SettingsModule } from './../../../projects/settings/src/lib/settings.module';
import { HelpModule } from './help/help.module';
import { SalesModule } from './../../../projects/sales/src/lib/sales.module';
import { CustomersModule } from './../../../projects/customers/src/lib/customers.module';
import { OrdersModule } from './../../../projects/orders/src/lib/orders.module';
import { ReportsModule } from './reports/reports.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LayoutsComponent } from './layouts.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', component: LayoutsComponent,
    children: [
      { path: '', loadChildren: () => DashboardModule, redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => DashboardModule },
      { path: 'reports', loadChildren: () => ReportsModule },
      { path: 'orders', loadChildren: () => OrdersModule },
      { path: 'customers', loadChildren: () => CustomersModule },
      { path: 'sales', loadChildren: () => SalesModule },
      { path: 'settings', loadChildren: () => SettingsModule },
      { path: 'help', loadChildren: () => HelpModule },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutsRoutingModule { }
