import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablePagerComponent } from './pager.component';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers.component';
import { CustomersRoutingModule } from './customers.routing.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CustomersComponent, DataTablePagerComponent],
  imports: [
    CustomersRoutingModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgbDropdownModule, NgbModule, NgSelectModule,
    NgxDatatableModule
  ],
  exports: [CustomersComponent]
})
export class CustomersModule { }
