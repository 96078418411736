import { Utilities } from '../shared/utilities';
import { Injectable } from '@angular/core';
import { Endpoint, Unicode } from '../shared/Endpoint';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    constructor() { }

    public save(key: string, value: string) {
        localStorage.setItem(key, value)
    }

    public get(key: string) {
        return localStorage.getItem(key);
    }

    public logout() {
        localStorage.clear();
    }

    public isAuthenticated(): boolean {
        return !Utilities.isNullOrEmpty(this.getLoggedinUser());
    }
    public registerUser(userJsonString: string) {
        this.save(Unicode.CURRENT_USR, userJsonString);
    }

    public getLoggedinUser() {
        return this.get(Unicode.CURRENT_USR);
    }

    public clear() {
        localStorage.clear();
    }

    public remove(key: string) {
        localStorage.removeItem(key);
    }
    public resetDataOnly() {
        var user = this.getLoggedinUser();
        this.clear();
        this.registerUser(user);
    }

}