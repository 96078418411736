import { ReportsComponent } from './reports.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CustomersModule } from './customers/customers.module';
import { OrdersModule } from './orders/orders.module';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent,
    children: [
      {
        path: '',
        loadChildren: () => OrdersModule,
        redirectTo: 'orders-report',
        pathMatch: 'full',
      },
      { path: 'customers-report', loadChildren: () => CustomersModule },
      { path: 'orders-report', loadChildren: () => OrdersModule },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReportsRoutingModule {}
