import { UserViewModel } from './../../../../src/models/vw.user';
import { ConfirmDialogService } from './../../../../src/servcies/confirm-dialog.service';
import { Utilities } from './../../../../src/shared/utilities';
import { StorageService } from './../../../../src/servcies/storage-service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CustomerModel } from 'src/models/customer.model';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import {
  Endpoint,
  Unicode,
  Group,
  PlanValues,
  Permissions,
} from 'src/shared/Endpoint';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { Base64 } from 'js-base64';

@Component({
  selector: 'lib-customers',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, AfterViewInit {
  customers: CustomerModel[] = [];
  @ViewChild(DatatableComponent) public table: DatatableComponent;
  page = new Page();
  static pageStatic;
  subdata: CustomerModel[] = [];
  static subdataStatic: CustomerModel[] = [];
  pageSize: number = Unicode.ITEMS_PER_PAGE;
  static pageSizeStatic: number = Unicode.ITEMS_PER_PAGE;
  currentUser: UserViewModel;
  currentUserRoleData: UserRoleDataModel;
  canCreateCustomer: boolean;
  pageTitle = '';
  static canViewAllCustomers: boolean;
  static httpRequestService;

  constructor(
    private router: Router,
    private storage: StorageService,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private confirmDialogService: ConfirmDialogService,
    private httpRequestService: HttpRequestService
  ) {
    if (this.localStorage.get('reload') == '1') {
      this.localStorage.save('reload', '0');
      document.location.reload();
    }
    this.page.pageNumber = 0;
    this.page.size = this.pageSize;
    CustomersComponent.pageStatic = this.page;
    CustomersComponent.httpRequestService = httpRequestService;
  }

  ngAfterViewInit(): void {}

  async ngOnInit(): Promise<void> {
    this.currentUser = JSON.parse(this.localStorage.get(Unicode.CURRENT_USR));
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    // var gpmEmails = await Utilities.getGPMemails(this.http, this.localStorage);
    CustomersComponent.canViewAllCustomers =
      this.currentUserRoleData.canViewAll;

    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'customers';
    this.canCreateCustomer = !this.currentUser.isSuspenser;
    this.getCustomers({ offset: 0 }, '');
  }

  async getCustomers(pageInfo, search, user?) {
    if (!this.currentUser) this.currentUser = user;
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.page.size + '')
      .set(Unicode.LIMIT, this.pageSize + '');
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.CUSTOMER_FILTER, search);
    if (!CustomersComponent.canViewAllCustomers)
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_CUSTOMER_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      this.page.totalElements = <number>resp['total'];
      if (resp.data)
        this.setPage({ offset: pageInfo.offset, data: resp['data'] });
      else this.setPage({ offset: pageInfo.offset, data: [] });
      //FIRST TIME "NO CSUTOMERS" => IMPORT THEM FROM API
      if (
        pageInfo.offset == 0 &&
        this.currentUserRoleData.toExecute &&
        Utilities.isNullOrEmpty(search) &&
        (!resp['data'] || resp['data'].length == 0)
      ) {
        var that = this;
        that.confirmDialogService.confirmThis(
          'Do you want to import your customers from your Google Console?',
          async function () {
            alert('it will take few minutes');
            var headers;
            await Utilities.getToken(
              that.http,
              Endpoint.ADMIN_EMAIL,
              'https://www.googleapis.com/auth/apps.order',
              Endpoint.PRIVATE_KEY,
              Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
            ).then((token) => {
              headers = new HttpHeaders({
                Authorization: `Bearer ${token}`,
              });
            });
            var pageToken = '';
            while (pageToken != null) {
              var url =
                'https://cloudchannel.googleapis.com/v1/' +
                Endpoint.RSELLER_ACCOUNTS +
                '/customers?pageSize=50&pageToken=' +
                pageToken;
              var result = await CustomersComponent.httpRequestService.get(
                url,
                headers
              );
              if (result.success && result.data?.customers) {
                var resp = await that.http
                  .post<any>(
                    Endpoint.GLOBAL_URL +
                      '' +
                      Endpoint.IMPORT_CUSTOMERS_FILE_NAME,
                    JSON.stringify({ customers: result.data.customers })
                  )
                  .toPromise();
                if (resp && !resp.success && !resp.msg) {
                }
                if (result.data?.nextPageToken) {
                  pageToken = result.data?.nextPageToken;
                } else pageToken = null;
              }
            }
            window.location.reload();
          },
          function () {}
        );
      }
    }
  }

  setPage(pageInfo) {
    this.zone.run(() => {
      this.page.pageNumber = pageInfo.offset;
      const pagedData = new PagedData<CustomerModel>();
      this.page.totalPages = this.page.totalElements / this.page.size;
      pagedData.data.push(...pageInfo.data);
      pagedData.page = this.page;
      this.page = pagedData.page;
      this.subdata = pagedData.data;
      CustomersComponent.subdataStatic = this.subdata;

      CustomersComponent.pageStatic = this.page;
    });
  }

  pageChanged(pageInfo) {
    this.getCustomers(pageInfo, '');
  }

  searchCustomer(event) {
    const val = event.target.value.toLowerCase();
    this.getCustomers({ offset: 0 }, val);
  }

  async AddNewCustomer() {
    this.localStorage.remove(Unicode.CURRENT_CUSTOMER_SUBSCS);
    if (!this.localStorage.get(Unicode.SALES)) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SALES_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.SALES, JSON.stringify(resp.sales));
      }
    }
    this.router.navigate(['/customers/customer-details'], {
      queryParams: { customerID: '' },
    });
  }

  async editCustomer(id) {
    this.localStorage.remove(Unicode.CURRENT_CUSTOMER_SUBSCS);
    if (!this.localStorage.get(Unicode.SALES)) {
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.SALES_FILE_NAME)
        .toPromise();
      if (resp && !resp.msg) {
        this.localStorage.save(Unicode.SALES, JSON.stringify(resp.sales));
      }
    }
    var customer = this.subdata.find((x) => x.id == id);
    if (!customer)
      customer = CustomersComponent.subdataStatic.find((x) => x.id == id);
    if (!customer) customer = await this.getCustomer(id);
    this.router.navigate(['/customers/customer-details'], {
      queryParams: { customerID: customer.id },
    });
  }

  deleteCustomer(id) {
    var that = this;
    var customer = this.subdata.find((x) => x.id == id);
    this.confirmDialogService.confirmThis(
      'Are you sure to delete ' + customer.first_name + '?',
      async function () {
        var params = new HttpParams().set(Unicode.CUSTOMER_ID, id);
        //this.model.id = "42322";
        var resp: any = await that.http
          .post(
            Endpoint.GLOBAL_URL + Endpoint.DELETE_CUSTOMER_FILE_NAME,
            null,
            { params: params }
          )
          .toPromise();
        if (resp && !resp.msg) {
          // var index = that.subdata.indexOf(customer);
          // that.subdata.splice(index, 1);
          // that.setPage({ offset:  that.page.pageNumber, data: that.subdata });
          alert('customer Deleted');
          window.location.reload();
        } else alert(resp.msg);
      },
      function () {
        // alert("No clicked");
      }
    );
  }

  async viewSubscByCustomerId(id) {
    var cust = this.subdata.find((x) => x.id == id);
    if (!cust) cust = CustomersComponent.subdataStatic.find((x) => x.id == id);
    if (!cust) cust = await this.getCustomer(id);
    this.localStorage.save(Unicode.CURRENT_CUSTOMER, JSON.stringify(cust));
    this.router.navigate(['/customers/customer-subscription-list'], {
      queryParams: { customerId: JSON.stringify(id) },
    });
  }

  public test(val, user) {
    console.log('test ' + val);
    this.getCustomers({ offset: 0 }, val, user);
  }

  public static searchGlobal(searchValue, user?, http?) {
    this.getCustomersStatic({ offset: 0 }, searchValue, user, http);
  }

  static async getCustomersStatic(pageInfo, search, user, http: HttpClient) {
    var prs = new HttpParams()
      .set(Unicode.PAGE, pageInfo.offset * this.pageStatic.size + '')
      .set(Unicode.LIMIT, this.pageSizeStatic + '');
    if (!Utilities.isNullOrEmpty(search))
      prs = prs.set(Unicode.CUSTOMER_FILTER, search);
    if (!CustomersComponent.canViewAllCustomers)
      prs = prs.set(Unicode.USER_ID, user.usr_id + '');
    var resp = await http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_CUSTOMER_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    var toBeAdded: boolean;
    var oldCustomerId;
    if (resp && !resp.success && !resp.msg) {
      if (resp['data'] && resp['data'].length > 0) {
        this.pageStatic.totalElements = <number>resp['total'];
        pageInfo.data = resp['data'];
        this.pageStatic.pageNumber = pageInfo.offset;
        const pagedData = new PagedData<CustomerModel>();
        this.pageStatic.totalPages =
          this.pageStatic.totalElements / this.pageStatic.size;
        pagedData.data.push(...pageInfo.data);
        pagedData.page = this.pageStatic;
        this.pageStatic = pagedData.page;
        this.subdataStatic = pagedData.data;
      } else if (CustomersComponent.canViewAllCustomers) {
        toBeAdded = true;
      } else {
        this.pageStatic.totalElements = 0;
        pageInfo.data = [];
        this.pageStatic.pageNumber = pageInfo.offset;
        const pagedData = new PagedData<CustomerModel>();
        this.pageStatic.totalPages =
          this.pageStatic.totalElements / this.pageStatic.size;
        pagedData.data.push(...pageInfo.data);
        pagedData.page = this.pageStatic;
        this.pageStatic = pagedData.page;
        this.subdataStatic = pagedData.data;
      }
      //IF CUSTOMER NOT EXIST IN DB && THE USER CAN SEE ALL CUSTOMERS  => CHECK CUSTOMER FROM API AND ADD IT
      if (toBeAdded) {
        Utilities.getCustomerByIdAPI(
          http,
          CustomersComponent.httpRequestService,
          search.trim()
        )
          .then(async (respAPI) => {
            if (respAPI && respAPI.postalAddress) {
              var cust: any = Utilities.mapFromApiToDbTable(respAPI);
              cust.status = 1;
              cust.is_offline = 0;
              cust.email = !Utilities.isNullOrEmpty(cust.email)
                ? cust.email
                : 'admin@' + cust.domain;
              //ADD IN DB
              var params = new HttpParams();
              var respRq;
              params = params.set(Unicode.IS_ADD_OPERATION, '1');
              respRq = await http
                .post(
                  Endpoint.GLOBAL_URL + Endpoint.UPDATE_CUSTOMER_FILE_NAME,
                  JSON.stringify(cust),
                  { params: params }
                )
                .toPromise();
              console.log(respRq);
              if (respRq && !respRq.msg) {
                var ar = [cust];
                this.pageStatic.totalElements = 1;
                pageInfo.data = ar;
                this.pageStatic.pageNumber = pageInfo.offset;
                const pagedData = new PagedData<CustomerModel>();
                this.pageStatic.totalPages =
                  this.pageStatic.totalElements / this.pageStatic.size;
                pagedData.data.push(...pageInfo.data);
                pagedData.page = this.pageStatic;
                this.pageStatic = pagedData.page;
                this.subdataStatic = pagedData.data;
              }
            } else {
              this.pageStatic.totalElements = 1;
              pageInfo.data = resp['data'] ?? [];
              this.pageStatic.pageNumber = pageInfo.offset;
              const pagedData = new PagedData<CustomerModel>();
              this.pageStatic.totalPages =
                this.pageStatic.totalElements / this.pageStatic.size;
              pagedData.data.push(...pageInfo.data);
              pagedData.page = this.pageStatic;
              this.pageStatic = pagedData.page;
              this.subdataStatic = pagedData.data;
            }
          })
          .catch((err) => {
            this.pageStatic.totalElements = 1;
            pageInfo.data = resp['data'] ?? [];
            this.pageStatic.pageNumber = pageInfo.offset;
            const pagedData = new PagedData<CustomerModel>();
            this.pageStatic.totalPages =
              this.pageStatic.totalElements / this.pageStatic.size;
            pagedData.data.push(...pageInfo.data);
            pagedData.page = this.pageStatic;
            this.pageStatic = pagedData.page;
            this.subdataStatic = pagedData.data;
          });
      }
    }
  }

  async getCustomer(id: string) {
    var prs = new HttpParams().set(Unicode.PAGE, '0').set(Unicode.LIMIT, '1');
    prs = prs.set(Unicode.CUSTOMER_FILTER, id);
    if (
      this.currentUser.isJustSale ||
      this.currentUser.groups.filter(
        (x) => x.name == Group.Manager || x.name == Group.Accounting
      ).length == 0
    )
      prs = prs.set(Unicode.USER_ID, this.currentUser.usr_id + '');
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_CUSTOMER_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && !resp.msg) {
      return resp['data'][0];
    }
  }

  get GetData() {
    return CustomersComponent.subdataStatic;
  }
}
