import { UserViewModel } from './../../../../models/vw.user';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RequestResultModel } from 'src/models/request_result.model';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { SubscriptionModel } from 'src/models/subcription.model';
import { Page } from 'src/models/page';
import { PagedData } from 'src/models/paged-data';
import { DatePipe } from '@angular/common';
import { retry } from 'rxjs/operators';
import { LocalStorageService } from 'src/servcies/localstorage-service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class ReportCustomersComponent implements OnInit {
  resellerApiToken: string;
  static _headers: HttpHeaders;
  static customersDataReport = [];
  static subDataReport = [];
  static page = new Page();
  static _http: HttpClient;
  static _httpRequestService: HttpRequestService;
  static _datePipe: DatePipe;
  static cancelled: boolean;
  static allData = [];
  static currentUser: UserViewModel;

  constructor(
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private datePipe: DatePipe,
    private localStorage: LocalStorageService
  ) {
    ReportCustomersComponent._http = http;
    ReportCustomersComponent._httpRequestService = httpRequestService;
    ReportCustomersComponent._datePipe = datePipe;
  }

  async ngOnInit(): Promise<void> {
    ReportCustomersComponent.currentUser = JSON.parse(
      this.localStorage.getLoggedinUser()
    );
    ReportCustomersComponent.page.pageNumber = 0;
    ReportCustomersComponent.page.size = Unicode.ITEMS_PER_PAGE;
    await Utilities.getToken(
      this.http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      ReportCustomersComponent._headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    ReportCustomersComponent.getReportData(
      {
        offset: ReportCustomersComponent.page.pageNumber,
      },
      false,
      false
    );
  }

  static async getReportData(
    pageInfo,
    fromSearchpart: boolean = false,
    toExport: boolean = false
  ) {
    var customerSearchvalue = (<HTMLInputElement>(
      document.getElementById('nav-search-input')
    )).value;
    ReportCustomersComponent.subDataReport = [];
    ReportCustomersComponent.page.pageNumber = pageInfo.offset;
    var dbOffset = -1;
    if (!toExport) dbOffset = pageInfo.offset * this.page.size;
    await this.getCUstomers(dbOffset, customerSearchvalue)
      .then(async (resp) => {
        if (resp && resp.data) {
          ReportCustomersComponent.page.totalElements = <number>resp['total'];
          ReportCustomersComponent.page.totalPages =
            ReportCustomersComponent.page.totalElements /
            ReportCustomersComponent.page.size;
          var customers = resp.data;
          await ReportCustomersComponent.getSubscriptions(
            customers,
            pageInfo,
            fromSearchpart,
            toExport
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  static async getSubscriptions(
    customers: any[],
    pageInfo,
    fromSearchpart: boolean,
    toExport: boolean
  ) {
    var sub = [];
    for (var i = 0; i < customers.length; i++) {
      if (!toExport) {
        if (ReportCustomersComponent.cancelled && !fromSearchpart) {
          this.cancelled = false;
          break;
        }
        if (ReportCustomersComponent.cancelled && fromSearchpart) {
          this.cancelled = false;
        }
      }
      var customer = customers[i];
      var customerInfo = {
        domain: customer.domain,
        totalNbrOfUsers: 0,
        subscriptions: [],
        details: '',
      };
      if (Number.parseInt(customer.status ?? 0 + '') == 1) {
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?customerId=' +
          customer.id;
        var prs = new HttpParams().set(Unicode.WITHOUT_LOADER, '1');
        var result: RequestResultModel = await this._httpRequestService.get(
          url,
          this._headers,
          prs
        );
        if (result.success && result.data) {
          var subs: SubscriptionModel[] = result.data?.subscriptions ?? [];
          customerInfo.totalNbrOfUsers = subs.reduce(
            (accumulator, current) =>
              accumulator +
              ((current.plan?.planName ?? '') == Unicode.FLEXIBLE_PLAN ||
              (current.plan?.planName ?? '') == Unicode.TRIAL_PLAN
                ? current.seats?.maximumNumberOfSeats ?? 0
                : current.seats?.numberOfSeats ?? 0),
            0
          );
          customerInfo.subscriptions = subs;
          customerInfo.details = subs
            .map(
              (x) =>
                x.skuName +
                '/' +
                x.plan?.planName +
                '/' +
                ((x.plan?.planName ?? '') == Unicode.FLEXIBLE_PLAN ||
                (x.plan?.planName ?? '') == Unicode.TRIAL_PLAN
                  ? x.seats?.maximumNumberOfSeats ?? 0
                  : x.seats?.numberOfSeats ?? 0) +
                (x.plan?.commitmentInterval && x.plan.commitmentInterval.endTime
                  ? '/' +
                    ReportCustomersComponent._datePipe.transform(
                      new Date(
                        Number.parseInt(x.plan.commitmentInterval.endTime)
                      ),
                      'yyyy-MM-dd'
                    )
                  : '')
            )
            .join(toExport ? '    ' : '<br/>');
        }
        if (!toExport) {
          sub.push(customerInfo);
          ReportCustomersComponent.setPage({
            offset: pageInfo.offset,
            data: sub,
          });
        } else {
          ReportCustomersComponent.allData.push(customerInfo);
        }
      }
    }
  }

  public static async searchGlobal() {
    this.cancelled = true;
    await new Promise((resolve) => setTimeout(resolve, 1000));
    this.getReportData({ offset: 0 }, true, false);
  }

  static async getCUstomers(offset: number, customerSearchvalue: string) {
    var prs = new HttpParams();
    if (offset >= 0) {
      prs = prs
        .set(Unicode.PAGE, offset + '')
        .set(Unicode.LIMIT, ReportCustomersComponent.page.size + '');
    }
    if (!Utilities.isNullOrEmpty(customerSearchvalue))
      prs = prs.set(Unicode.CUSTOMER_FILTER, customerSearchvalue);
    return await this._http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.READ_CUSTOMER_FILE_NAME, {
        params: prs,
      })
      .toPromise();
  }

  static setPage(pageInfo) {
    // ReportCustomersComponent.page.pageNumber = pageInfo.offset;
    const pagedData = new PagedData<any>();
    // ReportCustomersComponent.page.totalPages =
    //   ReportCustomersComponent.page.totalElements /
    //   ReportCustomersComponent.page.size;
    pagedData.data.push(...pageInfo.data);
    pagedData.page = ReportCustomersComponent.page;
    ReportCustomersComponent.page = pagedData.page;
    ReportCustomersComponent.subDataReport = pagedData.data;
  }

  get GetData() {
    return ReportCustomersComponent.subDataReport;
  }
  get GetPage() {
    return ReportCustomersComponent.page;
  }
  pageChanged(pageInfo) {
    ReportCustomersComponent.getReportData(pageInfo, false, false);
  }

  static async exportReport() {
    alert('it will take time(~10mins), to export data for all customers');
    await ReportCustomersComponent.getReportData({ offset: -1 }, false, true);
    var headers;
    await Utilities.getToken(
      ReportCustomersComponent._http,
      this.currentUser.user_email,
      'https://www.googleapis.com/auth/drive',
      Endpoint.OUR_PRIVATE_KEY,
      Endpoint.OUR_SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var url = ' https://sheets.googleapis.com/v4/spreadsheets';
    var sheetName =
      'customers report ' +
      ReportCustomersComponent._datePipe.transform(
        new Date(),
        'yyyy-MM-dd hh:mm:ss'
      );
    var sheetBody = {
      properties: {
        title: sheetName,
      },
      sheets: [
        {
          data: [
            {
              rowData: [],
            },
          ],
        },
      ],
    };
    sheetBody.sheets[0].data[0].rowData.push({
      values: [
        {
          userEnteredValue: {
            stringValue: 'Customer Domain',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Total Number of Users',
          },
        },
        {
          userEnteredValue: {
            stringValue: 'Details',
          },
        },
      ],
    });
    ReportCustomersComponent.allData.forEach((row: any) => {
      sheetBody.sheets[0].data[0].rowData.push({
        values: [
          {
            userEnteredValue: {
              stringValue: row.domain,
            },
          },
          {
            userEnteredValue: {
              stringValue: row.totalNbrOfUsers + '',
            },
          },
          {
            userEnteredValue: {
              stringValue: row.details,
            },
          },
        ],
      });
    });
    //
    ReportCustomersComponent._http
      .post(url, JSON.stringify(sheetBody), { headers: headers })
      .toPromise()
      .then((resp: any) => {
        console.log(resp);
        window.open(resp.spreadsheetUrl);
      })
      .catch((error) => {
        console.log(error);
        alert(error.error.error.message);
      });
  }

  get startExporting() {
    return ReportCustomersComponent.exportReport();
  }
}
