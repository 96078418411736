<div>
  <div>
    <a href="javascript:void(0)" (click)="back()">&#60; Back </a>
  </div>
  <div id="title_grp">
    <label id="grp_txt">Group:</label>
    <label id="grp_name">{{ currentGrp.name }}</label>
    <button (click)="AddNewMember()" class="btn_blue_bg float-right m-0">
      Add new member
    </button>
  </div>
  <div id="grp_members">
    <label id="grp_memb">Members:</label>
    <ul>
      <li class="row col-12">
        <span class="col-3 cust_title">Name</span>
        <span class="col-3 cust_title">Email</span>
        <span class="col-3"></span>
      </li>
      <li class="row col-12" *ngFor="let memb of members">
        <span class="col-3">{{ memb.user_name }}</span>
        <span class="col-3">{{ memb.user_email }}</span>
        <span
          (click)="deleteMember(memb.user_group_id, memb.user_name)"
          class="spn_remove fa fa-remove"
        ></span>
      </li>
    </ul>
  </div>
</div>

<!--- NEW MEMBER POPUP --->
<div
  class="modal fade"
  bsModal
  #AddMmbModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: 'static', keyboard: false }"
>
  <div class="modal-dialog">
    <div class="modal-content p-10">
      <div class="modal-header">
        <label class="font-weight-bold text-uppercase"
          >Add new member to '{{ currentGrp.name }}'</label
        >
        <div (click)="AddMmbModal.hide()">
          <span class="fa fa-close sp_popup_close"></span>
        </div>
      </div>
      <div class="modal-body">
        <div class="col-12 row">
          <label for="user-choice">Choose a User:</label>
          <input list="reseller-users" id="user-choice" name="user-choice" class="col-12" [(ngModel)]="userSelected"/>
          <datalist id="reseller-users">
            <ng-container *ngFor="let usr of usersEmail">
              <option value="{{ usr }}"></option>
            </ng-container>
          </datalist>
        </div>
        <div class="row m-t-15 justify-content-center">
          <button class="btn_blue_bg" (click)="saveNewMember()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-background"></div>

