import { SalesComponent } from './sales.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaleDetailsModule } from './sale-details/sale-details.module';

const routes: Routes = [
  { path: '', component: SalesComponent },
  { path: 'sale-details', loadChildren: () => SaleDetailsModule },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SalesRoutingModule { }
