import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsRoutingModule } from './subscriptions-routing.module';
import { SubscriptionsComponent } from './subscriptions.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [SubscriptionsComponent],
  imports: [
    CommonModule,
    FormsModule,ReactiveFormsModule,
    NgbDropdownModule,
    NgbModule,
    ModalModule.forRoot(),
    SubscriptionsRoutingModule
  ]
})
export class SubscriptionsModule { }
