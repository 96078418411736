import { CustomerModel } from './customer.model';
import { BaseModel } from './base.model';
import { StringLiteralLike } from 'typescript';

export class OrderModel extends BaseModel {
  id: string;
  user_rfid: string;
  customer_rfid: string;
  email_to_send_on_execution: string;
  subscription_rfid: string;
  order_action: string;
  created_date: Date;
  trial_expiry_date;
  commitment_expiry_date;
  start_date;
  approved_date1: Date;
  approved_date2: Date;
  gpm_approval_date: Date;
  last_approval_date: Date;
  approval1_email: string;
  approval2_email: string;
  gpm_approval_email: string;
  last_approval_email: string;
  order_status: string;
  po_id: string;
  order_sale_invoice_nbr: string;
  subscription_status_is_updated: boolean = false;
  licences_is_updated: boolean = false;
  plan_is_updated: boolean = false;
  start_paid_service: boolean = false;
  subscription_is_new: boolean = false;
  new_licence_number: number = 0;
  new_plan_rfid: number = 0;
  new_subscription_status: string = null;
  new_sku_id: string = null;
  old_sku_id: string = null;
  old_plan_id: number = 0;
  old_licence_number: number = 0;
  description: string = null;
  new_subscription_values: string = null; // format  = SKU;SEATS;PLAN
  transfer_to_direct: boolean = false;
  update_renewel_settings: boolean = false;
  renewel_type: string = '';
  is_new_customer;
  opportunity_nbr: string = null;
  opportunity_source: string = null;
  opportunity_status: string = null;
  google_rep: string = null;
  selling_price: number;
  cost_price: number;
  with_google_offer;
  google_offer_percentage: number;
  with_customer_offer;
  customer_offer_percentage: number;
  checked: boolean = false;
  is_withdraw: boolean = false;
  billing_method: string;
  rejecter_email: string = '';
  execution_note: string = '';
  invoice_file_rfid: string;
  is_renewal_order: boolean = false;
  currency_rfid: string;
  transfer_relation_id: string;
  orderEmailSubjectAction: string;

  customer_details: CustomerModel;

  constructor() {
    super();
  }
}
