import { CustomerDetailsModule } from './customer-details/customer-details.module';
import { CustomersComponent } from './customers.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsModule } from './subscriptions/subscriptions.module';
import { SubscriptionListModule } from './subscriptions/subscription-list/subscription-list.module';
import { TransferableSubscriptionsModule } from './transferable-subscriptions/transferable-subscriptions.module';

const routes: Routes = [
  {
    path: '', component: CustomersComponent,
    data: { breadcrumb: 'customer' },
    children: [ 
    ]
  },
  { path: 'customer-details', loadChildren: () => CustomerDetailsModule },
  { path: 'customer-subscription-list', loadChildren: () => SubscriptionListModule },
  { path: 'new-subscription', loadChildren: () => SubscriptionsModule },
  { path: 'transferable-subscriptions', loadChildren: () => TransferableSubscriptionsModule }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
