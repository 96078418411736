import { Status } from './../../../../../src/shared/Endpoint';
import { ResultModel } from './../../../../../src/models/result.model';
import { OrderViewModel } from 'src/models/vw_order';
import { OrderModel } from './../../../../../src/models/order.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrencyModel } from 'src/models/currency.model';
import { CustomerModel } from 'src/models/customer.model';
import { SubscriptionModel } from 'src/models/subcription.model';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { UserViewModel } from 'src/models/vw.user';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import {
  Endpoint,
  OrderStatus,
  Permissions,
  PlanValues,
  Unicode,
} from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { RequestResultModel } from 'src/models/request_result.model';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GooglePolicyModel } from 'src/models/google.policy.model';
import { take, tap, filter } from 'rxjs/operators';

@Component({
  selector: 'app-transferable-subscriptions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './transferable-subscriptions.component.html',
  styleUrls: ['./transferable-subscriptions.component.scss'],
})
export class TransferableSubscriptionsComponent implements OnInit {
  currentUser: UserViewModel;
  currentUserRoleData: UserRoleDataModel;
  currentCustomer: CustomerModel;
  transferableSubscriptions: SubscriptionModel[] = [];
  currencies: CurrencyModel[] = [];
  products = [];
  plans = [];
  gpmEmails = [];
  model = {
    transferableSubscriptions: [],
    purchaseOrderId: '',
    invoiceNbr: '',
    customerEmailForSending: '',
    opportunityNbr: '',
    opportunitySource: null,
    opportunityStatus: null,
    googleRep: '',
    description: '',
  };
  opportunitySources = [];
  opportunityStatus = [];
  viewDetails: boolean;
  uploadedFile;
  uploadText: string = 'Upload customer invoice';
  groupPermissions = [];
  order: OrderViewModel = new OrderViewModel();
  orderTransferRelationID: string;
  orderTransferCustomerID: string;
  orderTransferID: number;
  txtSave: string = 'Approve';
  txtDelete: string = 'Delete';
  canDelete: boolean;
  canSave: boolean;
  canWithdraw: boolean;
  canUpdate: boolean = false;
  exectionNote: string = '';
  @ViewChild('executionModal')
  executionModal: ModalDirective;
  @ViewChild('rejectModal')
  rejectModal: ModalDirective;
  rejectReason: string = '';
  statusText: string;
  updateCase: boolean;
  googlePolicies: GooglePolicyModel[] = [];
  displayPolicies: boolean;
  receiveEmailOnExecGrp: string[] = [];
  receiveEmailAfterCreationGrp: string[] = [];
  rejectReasons = [
    { id: 1, label: 'Processed manually' },
    { id: 2, label: 'Others' },
  ];
  rejectReasonSelected;
  subsToBeUpdated = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private httpRequestService: HttpRequestService
  ) {
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.products = JSON.parse(this.localStorage.get(Unicode.GOOGLE_PRODUCTS));
    this.currentCustomer = JSON.parse(
      this.localStorage.get(Unicode.CURRENT_CUSTOMER)
    );
    this.opportunitySources = ['Google source', 'iSoultions source'];
    this.opportunityStatus = ['Submitted', 'Approved'];
  }

  async ngOnInit(): Promise<void> {
    this.gpmEmails = await Utilities.getGPMemails(this.http, this.localStorage);
    this.currencies = await Utilities.getCurrencies(
      this.http,
      this.localStorage
    );
    this.receiveEmailOnExecGrp = Utilities.receiveEmailOnExecGrp(
      this.localStorage
    );
    this.receiveEmailAfterCreationGrp = Utilities.receiveEmailAfterCreationGrp(
      this.localStorage
    );
    var params;
    await this.route.queryParams
      .pipe(take(1))
      .toPromise()
      .then(async (routerParams) => {
        params = routerParams;
        this.viewDetails = !Utilities.isNullOrEmpty(
          params['viewTransferredOrderDetails']
        );
      });
    if (this.viewDetails) {
      // TO VIEW/CONFIRM CREATED TRANSFERRED ORDER
      this.orderTransferRelationID = params['orderTransferRelationID'];
      this.orderTransferCustomerID = params['orderTransferCustomerID'];
      this.orderTransferID = params['orderTransferID'];
      var prms = new HttpParams()
        .set(Unicode.ACTION, Unicode.GET_ALL)
        .set(Unicode.TRANSFER_RELATION_KEY, this.orderTransferRelationID)
        .set(Unicode.CUSTOMER_ID, this.orderTransferCustomerID);
      await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.TRANSFERED_ORDERS_FILE_NAME, {
          params: prms,
        })
        .toPromise()
        .then(async (getResp: any) => {
          var transferableSubscriptions: SubscriptionModel[] = [];
          if (getResp && !getResp.success && getResp.data) {
            this.order = getResp.data.find(
              (x) => x.order_id == this.orderTransferID
            );
            if (this.order.is_withdraw == 0)
              this.statusText = this.order.order_status;
            else this.statusText = OrderStatus.Withdraw;
            this.setLayout();
            for (var i = 0; i < getResp.data.length; i++) {
              var order: OrderViewModel = getResp.data[i];
              var tab = order.new_subscription_values.split(';');
              if (tab.length == 3) {
                if (i == 0) {
                  // FILL THEM ONE TIME(GLOBAL INFO)
                  this.model.invoiceNbr = order.order_sale_invoice_nbr;
                  this.model.customerEmailForSending =
                    order.email_to_send_on_execution;
                  this.model.opportunityNbr = order.opportunity_nbr;
                  this.model.opportunitySource = order.opportunity_source;
                  this.model.opportunityStatus = order.opportunity_status;
                  this.model.googleRep = order.google_rep;
                  this.model.description = order.description;
                }
                var transferableSubscription: SubscriptionModel =
                  new SubscriptionModel();
                transferableSubscription.orderId = order.order_id;
                transferableSubscription.customerId = order.customer_rfid;
                transferableSubscription.subscriptionId =
                  order.subscription_rfid;
                transferableSubscription.invoice_file_rfid =
                  order.invoice_file_rfid;
                transferableSubscription.skuId = order.old_sku_id;
                if (order.new_sku_id && order.old_sku_id != order.new_sku_id)
                  transferableSubscription.newSkuId = order.new_sku_id;
                transferableSubscription.skuName = this.products.find(
                  (x) => x.sku_id == tab[0]
                ).sku_name;
                transferableSubscription.productName =
                  transferableSubscription.skuName;
                transferableSubscription.newPlan = this.plans.find(
                  (x) => x.code.toLowerCase() == tab[2].toLowerCase()
                );
                transferableSubscription.seatsNbr = Number.parseInt(tab[1]);
                transferableSubscription.costPrice = order.cost_price;
                transferableSubscription.sellingPrice = order.selling_price;
                transferableSubscription.invoiceNbr =
                  order.order_sale_invoice_nbr;
                transferableSubscription.withGoogleOffer =
                  Number.parseInt(order.with_google_offer + '') == 1;
                transferableSubscription.currencyRfId = order.currency_rfid;
                transferableSubscription.purchaseOrderId = order.po_id;
                transferableSubscription.is_withdraw = order.is_withdraw;
                transferableSubscriptions.push(transferableSubscription);
              }
            }
          }
          this.model.transferableSubscriptions = transferableSubscriptions;
        })
        .catch((er) => {
          console.log(er);
        });
    } else {
      // TO CREATE TRANFERRED ORDER
      var headers;
      await Utilities.getToken(
        this.http,
        Endpoint.ADMIN_EMAIL,
        'https://www.googleapis.com/auth/apps.order',
        Endpoint.PRIVATE_KEY,
        Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
      ).then((token) => {
        headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
      });
      var url =
        'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?customerAuthToken=' +
        this.currentCustomer.auth_token +
        '&customerId=' +
        this.currentCustomer.id;
      this.currentCustomer.channel_name =
        this.currentCustomer.channel_name.replace('Customers', 'customers');
      this.currentCustomer.channel_name =
        this.currentCustomer?.channel_name?.indexOf('customers/') >= 0
          ? this.currentCustomer.channel_name?.split('customers/')[1] ?? ''
          : '';
      var resp: RequestResultModel = await this.httpRequestService.get(
        url,
        headers
      );
      if (resp.success && resp.data?.subscriptions) {
        this.transferableSubscriptions = resp.data.subscriptions;
        this.transferableSubscriptions.forEach((element) => {
          element.skuId = element.transferInfo?.currentLegacySkuId
            ? element.transferInfo?.currentLegacySkuId
            : element.skuId;
          if (
            element.plan.planName == Unicode.FLEXIBLE_PLAN ||
            element.plan.planName == Unicode.TRIAL_PLAN
          )
            element.seatsNbr = element.seats.maximumNumberOfSeats;
          else element.seatsNbr = element.seats.numberOfSeats ?? 0;
          element.currencyRfId = this.currencies[0]?.id;
          element.newPlan = this.plans.find(
            (x) => x.code.toLowerCase() == element.plan.planName.toLowerCase()
          );
          element.productName = element.skuName;
          if (
            element.skuId
              .toLowerCase()
              .indexOf(Unicode.GOOGLE_DRIVE_SKU_ID.toLowerCase()) >= 0
          ) {
            element.products = this.products.filter(
              (x) => x.sku_id.toLowerCase().indexOf('drive') >= 0
            );
            element.upgrade = true;
            element.productName = 'Google Drive Storage';
          }
        });
        this.model.transferableSubscriptions = this.transferableSubscriptions;
      }
    }

    //POLICIES
    // if (this.currentUser.isGPM || this.currentUserRoleData.toExecute) {//TODO
    if (false) {
      this.displayPolicies = true;
      if (this.order && !Utilities.isNullOrEmpty(this.order.order_id)) {
        var parms = new HttpParams()
          .set(Unicode.ACTION, Unicode.ORDER_POLICIES)
          .set(
            Unicode.ORDER_ID,
            this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
          );
        this.http
          .get(
            Endpoint.GLOBAL_URL + Endpoint.GOOGLE_INCENTIVES_POLICY_FILE_NAME,
            { params: parms }
          )
          .toPromise()
          .then(async (resp: any) => {
            if (resp && !resp.msg) {
              this.googlePolicies = resp?.policies ?? [];
              if (this.googlePolicies.length == 0) {
                this.googlePolicies = await Utilities.getGoogleIncentivesPolicy(
                  this.http,
                  this.localStorage
                );
                this.googlePolicies.forEach((element) => {
                  element.order_rfid = this.order?.order_id;
                  if (!element.GPM_check) element.GPM_check = false;
                  if (!element.finance_check) element.finance_check = false;
                  if (!element.exist) element.exist = false;
                  if (!element.amount) element.amount = 0;
                  if (!element.comments) element.comments = '';
                });
              } else {
                this.googlePolicies.forEach((element) => {
                  element.order_rfid = this.order?.order_id;
                  element.exist = Number.parseInt(element.exist + '') == 1;
                  element.finance_check =
                    Number.parseInt(element.finance_check + '') == 1;
                  element.GPM_check =
                    Number.parseInt(element.GPM_check + '') == 1;
                  if (!element.amount) element.amount = 0;
                  if (!element.comments) element.comments = '';
                });
              }
            }
          });
      } else {
        this.googlePolicies = await Utilities.getGoogleIncentivesPolicy(
          this.http,
          this.localStorage
        );
        this.googlePolicies.forEach((element) => {
          element.order_rfid = this.order?.order_id ?? null;
          if (!element.GPM_check) element.GPM_check = false;
          if (!element.finance_check) element.finance_check = false;
          if (!element.exist) element.exist = false;
          if (!element.amount) element.amount = 0;
          if (!element.comments) element.comments = '';
        });
      }
    }
  }

  setLayout() {
    if (this.currentUser.isGPM) {
      if (
        (this.order.is_withdraw == 0 &&
          this.order.order_status == OrderStatus.Approved2) ||
        (this.order.is_withdraw == 0 &&
          this.order.order_status == OrderStatus.Approved1)
      ) {
        this.canSave = true;
        this.canUpdate = true;
        this.canDelete = true;
        this.canWithdraw = false;
        this.txtSave = 'Approve';
        this.txtDelete = 'Reject';
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canDelete = false;
        this.canWithdraw = false;
      }
    } else if (this.currentUserRoleData.toExecute) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Confirm';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
        this.canWithdraw = false;
      } else if (
        [
          OrderStatus.Pending.toString(),
          OrderStatus.Approved1,
          OrderStatus.Approved2,
          OrderStatus.Approved_by_GPM,
          OrderStatus.CONFIRMED,
        ].indexOf(this.order.order_status.toUpperCase()) >= 0
      ) {
        if (this.order.is_withdraw == 1) {
          this.canWithdraw = false;
          this.canSave = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.txtSave = 'Execute';
          this.canSave = true;
          this.canDelete = true;
          this.canUpdate = true;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canDelete = false;
        this.canWithdraw = false;
        this.canUpdate = false;
      }
    } else if (this.currentUser.approve2) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Approve';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.txtSave = 'Approve';
          this.canSave = true;
          this.canUpdate = true;
          this.canDelete = true;
          this.canWithdraw = false;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Approved1) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.canSave = true;
          this.txtSave = 'Approve';
          this.canWithdraw = false;
          this.canDelete = true;
          this.txtDelete = 'Reject';
          this.canUpdate = true;
          this.canWithdraw = false;
        }
      } else if (this.order.order_status == OrderStatus.Approved2) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = true;
          this.canDelete = false;
        } else {
          this.canSave = false;
          this.canWithdraw = true;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canWithdraw = false;
        this.canDelete = false;
      }
    } else if (this.currentUser.approve1) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Approve';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = false;
          this.canDelete = false;
        } else {
          this.txtSave = 'Approve';
          this.canSave = true;
          this.canUpdate = true;
          this.canDelete = true;
          this.canWithdraw = false;
          this.txtDelete = 'Reject';
        }
      } else if (this.order.order_status == OrderStatus.Approved1) {
        if (this.order.is_withdraw == 1) {
          this.canSave = false;
          this.canWithdraw = false;
          this.canUpdate = true;
          this.canDelete = false;
        } else {
          this.canSave = false;
          this.canWithdraw = true;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Approved2) {
        this.canSave = false;
        this.canWithdraw = false;
        this.canDelete = false;
        this.canUpdate = false;
        this.canWithdraw = false;
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canUpdate = false;
        this.canWithdraw = false;
        this.canDelete = false;
      }
    } else if (this.currentUser.isJustSale) {
      if (this.order.order_status == OrderStatus.Draft) {
        this.txtSave = 'Send for approval';
        this.canSave = true;
        this.canDelete = true;
        this.txtDelete = 'Delete';
      } else if (this.order.order_status == OrderStatus.Pending) {
        if (this.order.is_withdraw == 1) {
          this.canWithdraw = false;
          this.canSave = false;
          this.canUpdate = true;
          this.canDelete = true;
        } else {
          this.canWithdraw = true;
          this.canSave = false;
          this.canUpdate = false;
          this.canDelete = false;
        }
      } else if (this.order.order_status == OrderStatus.Rejected) {
        this.canWithdraw = false;
        this.canSave = false;
        this.canUpdate = true;
        this.canDelete = false;
      } else {
        this.canSave = false;
        this.canDelete = false;
        this.canUpdate = false;
        this.canDelete = false;
      }
    }
  }

  async uploadDocument(fileInput) {
    let fileReader = new FileReader();
    var file = fileInput.target.files[0];
    fileReader.readAsDataURL(file);
    fileReader.onload = async (e) => {
      this.uploadedFile = file;
      this.uploadText = file.name;
    };
  }

  async transferConfirmOrder(isDraft = false) {
    if (isDraft) {
      var resp: any = await this.saveOrdersDB(isDraft);
      if (resp && !resp.msg) {
        alert('Saved');
        this.router.navigate(['customers']);
      } else {
        alert(resp?.msg);
      }
    } else {
      if (!this.IsRequiredFiledsFilled()) {
        alert('Enter required Fields');
        return;
      }
      if (this.currentUserRoleData.toExecute) {
        var result: RequestResultModel =
          await this.transferEntitlementsChannelAPI();
        if (result.success) {
          await this.saveOrdersDB().catch((err) => {
            console.log(err);
          });
          var link_url =
            Endpoint.URL_EMAIL +
            'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
            this.orderTransferRelationID +
            '&orderTransferCustomerID=' +
            this.currentCustomer.id +
            '&orderTransferID=' +
            this.orderTransferID;
          var bodyText =
            '<html><label>Hello,</label><br/><label>' +
            this.currentUser.user_name +
            ' transferred the susbscriptions for domain ' +
            this.currentCustomer.domain +
            '.</label><br/>' +
            '<label>Customer s auth token:' +
            this.currentCustomer.auth_token +
            '.</label><br/>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receiversOnExec = [];
          receiversOnExec.push(...this.receiveEmailOnExecGrp);
          receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receiversOnExec,
            bodyText,
            ''
          );
          //TO CUSTOMER
          if (!Utilities.isNullOrEmpty(this.model.customerEmailForSending)) {
            var customerEmailBody = '';
            var cc = this.currentUser.manager_email;
            if (!Utilities.isNullOrEmpty(this.uploadedFile)) {
              customerEmailBody = Utilities.generateCustomerEmailBody(
                this.currentCustomer.domain,
                this.currentUser.user_email
              );
              var fileName = this.uploadedFile.name ?? '';
              let fileReader = new FileReader();
              fileReader.readAsDataURL(this.uploadedFile);
              fileReader.onload = async (e) => {
                var tragRes: any = e.target.result;
                var fileData = tragRes.split('base64,')[1];
                Utilities.sendMailWithFile(
                  this.http,
                  fileData,
                  this.currentUser.user_email,
                  [this.model.customerEmailForSending],
                  [cc],
                  'Invoice',
                  customerEmailBody,
                  fileName
                );
              };
            } else {
              customerEmailBody =
                Utilities.generateCustomerEmailBodyWithoutFile(
                  this.currentCustomer.domain,
                  this.currentUser.user_email
                );
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                [this.model.customerEmailForSending],
                customerEmailBody,
                '',
                [cc]
              );
            }
          }
          //
          alert('Done');
          if (Number.parseInt(this.currentCustomer.status) == 0)
            this.router.navigate(['/customers']);
          else this.router.navigate(['/orders']);
        } else {
          alert(result.errorMessage);
        }
      } else {
        var resp = await this.saveOrdersDB();
        console.log(resp);
        if (resp && !resp.msg) {
          var link_url =
            Endpoint.URL_EMAIL +
            'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
            this.orderTransferRelationID +
            '&orderTransferCustomerID=' +
            this.currentCustomer.id +
            '&orderTransferID=' +
            this.orderTransferID;
          var bodyText =
            '<html><label>Hello,</label><br/><label>' +
            this.currentUser.user_name +
            ' created an order to transfer the susbscriptions for domain ' +
            this.currentCustomer.domain +
            '.</label><br/>' +
            '<label>Customer s auth token:' +
            this.currentCustomer.auth_token +
            '.</label><br/>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receivers = this.currentUserRoleData.receiverEmail;
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receivers,
            bodyText,
            ''
          );
          alert('Order Send');
          if (Number.parseInt(this.currentCustomer.status) == 0)
            this.router.navigate(['/customers']);
          else this.router.navigate(['/orders']);
        }
      }
    }
  }

  async transferEntitlementsChannelAPI() {
    var tokenAPI;
    await Utilities.getToken(
      this.http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      tokenAPI = token;
    });
    var headers = new HttpHeaders({
      Authorization: `Bearer ${tokenAPI}`,
    });
    headers = headers.set(
      'Content-Type',
      'multipart/mixed; boundary=batch_request'
    );
    var authToken = !Utilities.isNullOrEmpty(this.order?.auth_token)
      ? this.order.auth_token
      : this.currentCustomer?.auth_token;
    var domain = !Utilities.isNullOrEmpty(this.order?.customer_domain)
      ? this.order.customer_domain
      : this.currentCustomer?.domain;
    var customerId = !Utilities.isNullOrEmpty(this.order?.customer_rfid)
      ? this.order.customer_rfid
      : this.currentCustomer?.id;
    var batchBody = [];
    var transfsubs = [];
    for (var i = 0; i < this.model.transferableSubscriptions.length; i++) {
      var current = this.model.transferableSubscriptions[i];
      var seats;
      if (
        current &&
        (current.newPlan.code == Unicode.FLEXIBLE_PLAN ||
          current.newPlan.code == Unicode.TRIAL_PLAN)
      )
        seats = { maximumNumberOfSeats: current.seatsNbr };
      else seats = { numberOfSeats: current.seatsNbr };
      var sub: any = {
        purchaseOrderId: current.purchaseOrderId,
        customerId: customerId,
        skuId: current.newSkuId ?? current.skuId,
        seats: seats,
        plan: {
          planName:
            current.newPlan.code == PlanValues.ANNUAL
              ? PlanValues.ANNUAL_MONTHLY_PAY
              : current.newPlan.code,
        },
        renewalSettings: {
          renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
        },
      };
      var contentId = customerId + i;
      transfsubs.push({ contentId: contentId, sub: sub });
      //
      var sb = JSON.parse(JSON.stringify(sub));
      if (current.newSkuId && current.newSkuId != current.skuId) {
        sb.newSkuId = current.newSkuId;
        this.subsToBeUpdated.push(sb);
      }
      //
      var body = [
        '--batch_request\r\n',
        `Content-ID: ${contentId}\r\n`,
        'Content-Type: application/http\r\n',
        'MIME-Version: 1.0\r\n',
        'Content-Transfer-Encoding: binary\r\n\r\n',

        'POST /apps/reseller/v1/customers/' +
          customerId +
          '/subscriptions?customerAuthToken=' +
          authToken +
          ' HTTP/1.1\r\n',
        'Authorization: Bearer ' + tokenAPI + '\r\n',
        'content-type: application/json; charset=UTF-8\r\n\r\n',

        JSON.stringify(sub) + '\r\n',
      ];
      batchBody.push(...body);
    }
    batchBody.push('--batch_request--');
    var resp: RequestResultModel = await this.httpRequestService.batch(
      'https://reseller.googleapis.com/batch',
      batchBody.join(''),
      headers
    );
    var result = new RequestResultModel();
    result.errorMessage = '';
    if (resp.success) {
      result.success = true;
    } else {
      var err = resp.data ?? '';
      var text = err?.error?.text ?? '';
      if (Utilities.isNullOrEmpty(text)) {
        result.success = false;
        if (err.status == 502)
          result.errorMessage = 'Your transfer could not be completed';
      } else {
        var resps = text.split('Content-Type: application/http');
        for (var i = 1; i < resps.length; i++) {
          var respResult = resps[i];
          var array = respResult.split('\r\n');
          array = array.filter((x: any) => !Utilities.isNullOrEmpty(x));
          var error = array.find(
            (x) =>
              x.toLowerCase().indexOf('error') >= 0 &&
              x.toLowerCase().indexOf('code') >= 0
          );
          if (!error) {
            result.success = true;
          } else {
            result.success = false;
            error = error.replace(/\n/g, '');
            var errorObj = JSON.parse(error);
            var contID =
              array.find((x) => x.toLowerCase().indexOf('content-id') >= 0) ??
              '';
            var contIDArray = contID.split('Content-ID:');
            if (contIDArray.length > 1) contID = contIDArray[1];
            else {
              contIDArray = contID.split('Content-ID');
              if (contIDArray.length > 1) contID = contIDArray[1];
              else contID = '';
            }
            var msg = errorObj.error
              ? errorObj.error.message
              : errorObj.length > 0
              ? errorObj[0].error?.message
              : '';
            result.errorMessage += ' ' + contID + ':' + msg + ' .';
          }
        }
      }
      if (
        result.success == true &&
        this.subsToBeUpdated &&
        this.subsToBeUpdated.length > 0
      ) {
        for (var i = 0; i < this.subsToBeUpdated.length; i++) {
          var sub: any = {
            // FIRST TEST UPGRADE NOT WORKING SO IF THERE IS AN UPGRADE, I WILL EXECUTE/TRANSFER OLD SKU FIRST THEN UPGRADE AFTER TRANSFERRING
            purchaseOrderId: this.subsToBeUpdated[i].purchaseOrderId,
            customerId: this.subsToBeUpdated[i].customerId,
            skuId: this.subsToBeUpdated[i].newSkuId,
            seats: this.subsToBeUpdated[i].seats,
            plan: this.subsToBeUpdated[i].plan,
            renewalSettings: {
              renewalType: Unicode.DEFAULT_RENEWEL_TYPE,
            },
          };
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            this.subsToBeUpdated[i].customerId +
            '/subscriptions';
          var upgradeResult: RequestResultModel =
            await this.httpRequestService.post(url, sub, headers);
          if (upgradeResult.success) {
          }
        }
      }
    }
    return result;
  }

  async saveOrdersDB(isDraft = false) {
    var orders: OrderModel[] = [];
    this.orderTransferRelationID = Utilities.generatePassword(25);
    for (var i = 0; i < this.model.transferableSubscriptions.length; i++) {
      var transfSub = this.model.transferableSubscriptions[i];
      var order = new OrderModel();
      order.customer_rfid = transfSub.customerId;
      order.subscription_rfid = transfSub.subscriptionId ?? null;
      order.transfer_relation_id = this.orderTransferRelationID;
      order.subscription_is_new = true;
      order.old_sku_id = transfSub.skuId;
      if (transfSub.newSkuId) order.new_sku_id = transfSub.newSkuId;
      else order.new_sku_id = transfSub.skuId;
      order.new_subscription_values =
        order.new_sku_id +
        ';' +
        transfSub.seatsNbr +
        ';' +
        transfSub.newPlan.code;
      order.is_new_customer = 3; //TRANSFER
      order.is_renewal_order = false;
      order.email_to_send_on_execution = !Utilities.isNullOrEmpty(
        this.model.customerEmailForSending
      )
        ? this.model.customerEmailForSending
        : '';
      order.order_status = !isDraft
        ? this.currentUserRoleData.orderStatus
        : OrderStatus.Draft;
      order.order_sale_invoice_nbr = this.model.invoiceNbr;
      order.user_rfid = this.currentUser.usr_id + '';
      order.customer_rfid = this.currentCustomer.id;
      order.billing_method = Unicode.ONLINE;
      order.selling_price = transfSub.sellingPrice ?? 0;
      order.cost_price = transfSub.costPrice ?? 0;
      order.currency_rfid = transfSub.currencyRfId ?? null;
      order.commitment_expiry_date = null;
      order.trial_expiry_date = null;
      order.with_google_offer = transfSub.withOffer ? 1 : 0;
      order.po_id = transfSub.purchaseOrderId ?? null;
      order.opportunity_nbr = this.model.opportunityNbr ?? null;
      order.opportunity_status = this.model.opportunityStatus ?? null;
      order.opportunity_source = this.model.opportunitySource ?? null;
      order.google_rep = this.model.googleRep ?? null;
      order.description = this.model.description ?? ' ';
      order.approval1_email = this.currentUserRoleData.approver1Email ?? '';
      order.approval2_email = this.currentUserRoleData.approver2Email ?? '';
      order.gpm_approval_email =
        this.currentUserRoleData.GPMApproverEmail ?? '';
      order.last_approval_email =
        this.currentUserRoleData.lastApproverEmail ?? '';
      order.old_licence_number = order.new_licence_number = transfSub.seatsNbr;
      order.old_plan_id = order.new_plan_rfid = transfSub.newPlan.id;
      order.update_renewel_settings = false;
      order.plan_is_updated = false;
      order.licences_is_updated = false;
      order.subscription_status_is_updated = false;
      orders.push(order);
    }
    if (this.currentCustomer.customerSales ?? [].length == 0)
      this.currentCustomer.customerSales = [{ id: this.currentUser.usr_id }];
    const formData: FormData = new FormData();
    if (
      this.uploadedFile &&
      this.uploadedFile != null &&
      !this.currentUserRoleData.toExecute
    )
      formData.append('file', this.uploadedFile);
    formData.append(
      'model',
      JSON.stringify({ orders: orders, customer: this.currentCustomer })
    );
    var params = new HttpParams();
    if (
      orders.length > 0 &&
      this.googlePolicies &&
      this.googlePolicies.length > 0
    ) {
      params = params
        .set(
          Unicode.ADD,
          this.googlePolicies.find(
            (x) =>
              !Utilities.isNullOrEmpty(x.google_policy_rfid) &&
              !Utilities.isNullOrEmpty(x.order_rfid)
          )
            ? '0'
            : '1'
        )
        .set(Unicode.POLICIES, JSON.stringify(this.googlePolicies));
    }
    var resp: any = await this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.TRANSFERED_ORDERS_FILE_NAME,
        formData,
        { params: params }
      )
      .toPromise();
    if (resp) {
      this.orderTransferID = resp.id ?? null;
      return resp;
    } else {
      return null;
    }
  }

  cancelOrder() {
    this.router.navigate(['/customers']);
  }

  cancel() {
    this.router.navigate(['/orders']);
  }

  async ConfirmOrder() {
    if (!this.IsRequiredFiledsFilled()) {
      alert('Enter required Fields');
      return;
    }
    if (this.currentUserRoleData.toExecute) {
      if (!Utilities.isNullOrEmpty(this.order.gpm_approval_email)) {
        this.updateCase = false;
        this.executionModal.show();
      } else {
        alert('GMP approval is required!!');
      }
    } else {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(
          Unicode.ORDER_ID,
          this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
        )
        .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus);
      var link_url =
        Endpoint.URL_EMAIL +
        'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
        this.orderTransferRelationID +
        '&orderTransferCustomerID=' +
        this.orderTransferCustomerID +
        '&orderTransferID=' +
        this.orderTransferID;
      var bodyText =
        '<html><label>Hello,</label><br/><label>' +
        this.currentUser.user_name +
        ' approved the order to transfer the susbscriptions for domain ' +
        this.order.customer_domain +
        '.</label><br/>' +
        '<label>Customer s auth token:' +
        this.order.auth_token +
        '.</label><br/>' +
        "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
        link_url +
        "'>here</a><label> to view the details</label></div></html>";
      if (this.currentUser.isGPM) {
        params = params.set(
          Unicode.GPM_APPROVAL_EMAIL,
          this.currentUser.user_email
        );
        if (this.googlePolicies && this.googlePolicies.length > 0) {
          params = params
            .set(
              Unicode.ADD,
              this.googlePolicies.find(
                (x) =>
                  !Utilities.isNullOrEmpty(x.google_policy_rfid) &&
                  !Utilities.isNullOrEmpty(x.order_rfid)
              )
                ? '0'
                : '1'
            )
            .set(Unicode.POLICIES, JSON.stringify(this.googlePolicies));
        }
      } else if (this.currentUser.approve2) {
        params = params.set(
          Unicode.APPROVAL2_EMAIL,
          this.currentUser.user_email
        );
      } else if (this.currentUser.approve1) {
        params = params.set(
          Unicode.APPROVAL1_EMAIL,
          this.currentUser.user_email
        );
      }
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var receivers = this.currentUserRoleData.receiverEmail;
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          receivers,
          bodyText,
          ''
        );
        alert('Sent');
        this.router.navigate(['orders']);
      } else {
        alert(resp.msg);
      }
    }
  }

  async updateOrderDB() {
    var orders: OrderModel[] = [];
    for (var i = 0; i < this.model.transferableSubscriptions.length; i++) {
      var transfSub = this.model.transferableSubscriptions[i];
      var order = new OrderModel();
      order.id = transfSub.orderId;
      order.customer_rfid = transfSub.customerId;
      order.execution_note = this.exectionNote ?? '';
      if (transfSub.newSkuId) {
        order.new_sku_id = transfSub.newSkuId;
        order.old_sku_id = transfSub.skuId;
        order.new_subscription_values =
          order.new_sku_id +
          ';' +
          transfSub.seatsNbr +
          ';' +
          transfSub.newPlan.code;
      } else
        order.new_subscription_values =
          transfSub.skuId +
          ';' +
          transfSub.seatsNbr +
          ';' +
          transfSub.newPlan.code;
      order.email_to_send_on_execution = !Utilities.isNullOrEmpty(
        this.model.customerEmailForSending
      )
        ? this.model.customerEmailForSending
        : '';
      order.order_status = this.currentUserRoleData.orderStatus;
      order.order_sale_invoice_nbr = this.model.invoiceNbr;
      order.invoice_file_rfid = transfSub.invoice_file_rfid ?? null;
      order.selling_price = transfSub.sellingPrice ?? 0;
      order.cost_price = transfSub.costPrice ?? 0;
      order.currency_rfid = transfSub.currencyRfId ?? null;
      order.with_google_offer = transfSub.withOffer ? 1 : 0;
      order.po_id = transfSub.purchaseOrderId ?? null;
      order.opportunity_nbr = this.model.opportunityNbr ?? null;
      order.opportunity_status = this.model.opportunityStatus ?? null;
      order.opportunity_source = this.model.opportunitySource ?? null;
      order.google_rep = this.model.googleRep ?? null;
      order.description = this.model.description ?? ' ';
      order.approval1_email = this.currentUserRoleData.approver1Email ?? '';
      order.approval2_email = this.currentUserRoleData.approver2Email ?? '';
      order.gpm_approval_email =
        this.currentUserRoleData.GPMApproverEmail ?? '';
      order.last_approval_email =
        this.currentUserRoleData.lastApproverEmail ?? '';
      orders.push(order);
    }
    var params = new HttpParams();
    if (this.currentUserRoleData.toExecute)
      params = params.set(
        Unicode.iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT,
        '1'
      );
    if (
      this.currentUser.isGPM &&
      this.currentUserRoleData.toExecute &&
      this.googlePolicies &&
      this.googlePolicies.length > 0
    ) {
      params = params
        .set(
          Unicode.ADD,
          this.googlePolicies.find(
            (x) =>
              !Utilities.isNullOrEmpty(x.google_policy_rfid) &&
              !Utilities.isNullOrEmpty(x.order_rfid)
          )
            ? '0'
            : '1'
        )
        .set(Unicode.POLICIES, JSON.stringify(this.googlePolicies));
    }
    var resp: any = await this.http
      .put(
        Endpoint.GLOBAL_URL + Endpoint.TRANSFERED_ORDERS_FILE_NAME,
        JSON.stringify({ orders: orders }),
        {
          params: params,
        }
      )
      .toPromise();
    return resp;
  }

  async updateOrder() {
    if (!this.IsRequiredFiledsFilled()) {
      alert('Enter required Fields');
      return;
    }
    if (this.currentUserRoleData.toExecute) {
      if (!Utilities.isNullOrEmpty(this.order.gpm_approval_email)) {
        this.updateCase = true;
        this.executionModal.show();
      } else {
        alert('GMP approval is required!!');
      }
    } else {
      var link_url =
        Endpoint.URL_EMAIL +
        'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
        this.orderTransferRelationID +
        '&orderTransferCustomerID=' +
        this.orderTransferCustomerID +
        '&orderTransferID=' +
        this.orderTransferID;
      var bodyText =
        '<html><label>Hello,</label><br/><label>' +
        this.currentUser.user_name +
        ' approved the order to transfer the susbscriptions for domain ' +
        this.order.customer_domain +
        '.</label><br/>' +
        '<label>Customer s auth token:' +
        this.order.auth_token +
        '.</label><br/>' +
        "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
        link_url +
        "'>here</a><label> to view the details</label></div></html>";
      await this.updateOrderDB().then(async (resp) => {
        if (resp && !resp.msg) {
          var receivers = this.currentUserRoleData.receiverEmail;
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receivers,
            bodyText,
            ''
          );
          alert('Sent');
          this.router.navigate(['orders']);
        }
      });
    }
  }

  async executePopupClick() {
    var link_url =
      Endpoint.URL_EMAIL +
      'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
      this.orderTransferRelationID +
      '&orderTransferCustomerID=' +
      this.orderTransferCustomerID +
      '&orderTransferID=' +
      this.orderTransferID;
    var bodyText =
      '<html><label>Hello,</label><br/><label>' +
      this.currentUser.user_name +
      ' approved the order to transfer the susbscriptions for domain ' +
      this.order.customer_domain +
      '.</label><br/>' +
      '<label>Customer s auth token:' +
      this.order.auth_token +
      '.</label><br/>' +
      "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
      link_url +
      "'>here</a><label> to view the details</label></div></html>";
    //API EXECUTION
    var result: RequestResultModel =
      await this.transferEntitlementsChannelAPI();
    if (result.success) {
      //DB CHANGES
      var dbResp: any;
      if (!this.updateCase) {
        var params = new HttpParams()
          .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
          .set(
            Unicode.ORDER_ID,
            this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
          )
          .set(Unicode.STATUS_PARAM, this.currentUserRoleData.orderStatus)
          .set(Unicode.LAST_APPROVAL_EMAIL, this.currentUser.user_email)
          .set(Unicode.NOTES, this.exectionNote)
          .set(Unicode.iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT, '1');
        if (this.googlePolicies && this.googlePolicies.length > 0) {
          params = params
            .set(
              Unicode.ADD,
              this.googlePolicies.find(
                (x) =>
                  !Utilities.isNullOrEmpty(x.google_policy_rfid) &&
                  !Utilities.isNullOrEmpty(x.order_rfid)
              )
                ? '0'
                : '1'
            )
            .set(Unicode.POLICIES, JSON.stringify(this.googlePolicies));
        }
        dbResp = await this.http
          .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
            params: params,
          })
          .toPromise()
          .catch((error) => {
            console.log(error);
          });
      } else {
        dbResp = await this.updateOrderDB().catch((error) => {
          console.log(error);
        });
      }
      var receivers = [
        this.order.usr_email,
        this.order.approval1_email,
        this.order.approval2_email,
      ];
      receivers.push(...this.gpmEmails);
      receivers.push(...this.receiveEmailOnExecGrp);
      await Utilities.sendMail(
        this.http,
        this.currentUser.user_email,
        receivers,
        bodyText,
        ''
      );
      //TO CUSTOMER
      if (!Utilities.isNullOrEmpty(this.model.customerEmailForSending)) {
        var cc = this.order.manager_email;
        var customerEmailBody = '';
        if (!Utilities.isNullOrEmpty(dbResp.fileContent)) {
          customerEmailBody = Utilities.generateCustomerEmailBody(
            this.order.customer_domain,
            this.order.usr_email
          );
          var fileData = dbResp.fileContent;
          var fileName = dbResp.fileName ?? '';
          Utilities.sendMailWithFile(
            this.http,
            fileData,
            this.order.usr_email,
            [this.model.customerEmailForSending],
            [cc, this.currentUser.user_email],
            'Invoice',
            customerEmailBody,
            fileName
          );
        } else {
          customerEmailBody = Utilities.generateCustomerEmailBodyWithoutFile(
            this.order.customer_domain,
            this.order.usr_email
          );
          await Utilities.sendMail(
            this.http,
            this.order.usr_email,
            [this.model.customerEmailForSending],
            customerEmailBody,
            '',
            [cc, this.currentUser.user_email]
          );
        }
      }
      //
      this.executionModal.hide();
      alert('Sent');
      this.router.navigate(['orders']);
    } else {
      alert(result.errorMessage);
    }
  }

  async withdrawOrder() {
    var params = new HttpParams()
      .set(Unicode.ACTION, Unicode.WITHDRAW_ORDER_ACTION)
      .set(
        Unicode.ORDER_ID,
        this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
      );
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
        params: params,
      })
      .toPromise();
    if (resp && !resp.msg) {
      alert('Done');
      this.router.navigate(['orders']);
    } else alert(resp.msg);
  }

  async delete() {
    if (this.order.order_status == OrderStatus.Draft) {
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.DELETE_ORDER_ACTION)
        .set(
          Unicode.ORDER_ID,
          this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
        );
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        alert('Deleted');
        this.router.navigate(['orders']);
      } else alert(resp.msg);
    } else {
      this.rejectModal.show();
    }
  }

  async confirmRejection() {
    if (
      this.rejectReasonSelected &&
      (this.rejectReasonSelected == 1 ||
        !Utilities.isNullOrEmpty(this.rejectReason))
    ) {
      if (Utilities.isNullOrEmpty(this.rejectReason))
        this.rejectReason = 'order processed manually';
      var params = new HttpParams()
        .set(Unicode.ACTION, Unicode.CHANGE_STATUS_ACTION)
        .set(
          Unicode.ORDER_ID,
          this.model.transferableSubscriptions.map((x) => x.orderId).join(';')
        )
        .set(Unicode.STATUS_PARAM, OrderStatus.Rejected)
        .set(Unicode.REJECT_REASON, this.rejectReason)
        .set(Unicode.REJECTER_EMAIL, this.currentUser.user_email);
      var resp: any = await this.http
        .get(Endpoint.GLOBAL_URL + Endpoint.UPDATE_ORDER_FILE_NAME, {
          params: params,
        })
        .toPromise();
      if (resp && !resp.msg) {
        var to = [
          this.order.usr_email,
          this.order.approval1_email,
          this.order.approval2_email,
        ];
        if (this.currentUserRoleData.toExecute) {
          to.push(...this.gpmEmails);
          to.push(...this.receiveEmailOnExecGrp);
        }
        var link_url =
          Endpoint.URL_EMAIL +
          'customers/transferable-subscriptions?viewTransferredOrderDetails=true&orderTransferRelationID=' +
          this.orderTransferRelationID +
          '&orderTransferCustomerID=' +
          this.orderTransferCustomerID +
          '&orderTransferID=' +
          this.orderTransferID;
        var bodyText =
          '<html><label>Hello,</label><br/><label>' +
          this.currentUser.user_name +
          ' rejected the order , to transfer the susbscriptions for domain ' +
          this.order.customer_domain +
          '.</label><br/>' +
          '<label>Customer s auth token:' +
          this.order.auth_token +
          '.</label><br/>' +
          "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
          link_url +
          "'>here</a><label> to view the details</label></div></html>";
        await Utilities.sendMail(
          this.http,
          this.currentUser.user_email,
          to,
          bodyText,
          ''
        );

        if (!this.currentUserRoleData.toExecute) {
          alert('Rejected');
          this.router.navigate(['orders']);
        } else {
          Utilities.getCustomerByIdAPI(
            this.http,
            this.httpRequestService,
            this.order.updated_domain
          ).then(async (respAPI) => {
            if (respAPI && respAPI.postalAddress) {
              var cust: any = Utilities.mapFromApiToDbTable(respAPI);
              cust.status = 1;
              cust.is_offline = 0;
              cust.email = !Utilities.isNullOrEmpty(cust.email)
                ? cust.email
                : 'admin@' + cust.domain;
              //UPDATE INTO DB
              var custBody = {
                newcustomerId: cust.id,
                oldcustomerId: this.order.customer_rfid,
                hasInvoiceFile: '',
              };
              await this.http
                .post(
                  Endpoint.GLOBAL_URL + Endpoint.CONFIRM_CUSTOMER_FILE_NAME,
                  JSON.stringify(custBody)
                )
                .toPromise();
            }
            alert('Rejected');
            this.router.navigate(['orders']);
          });
        }
      } else alert(resp.msg);
    } else alert('Please add a reason for rejection');
  }

  selectRejectionReason() {
    if (this.rejectReasonSelected == 1) {
      alert(
        'Please make sure that you have manually proceeded with this order,from the console,before clicking reject button,' +
          'otherwise do that then reject it, to add it to our portal.'
      );
    }
  }

  IsRequiredFiledsFilled() {
    return (
      !Utilities.isNullOrEmpty(this.model.invoiceNbr) &&
      !Utilities.isNullOrEmpty(this.model.opportunityNbr) &&
      !Utilities.isNullOrEmpty(this.model.opportunitySource) &&
      !Utilities.isNullOrEmpty(this.model.opportunityStatus) &&
      !this.model.transferableSubscriptions.find((x) =>
        Utilities.isNullOrEmpty(x.purchaseOrderId)
      )
    );
  }

  upgrade(subId) {
    var sub = this.model.transferableSubscriptions.find(
      (x) => x.subscriptionId == subId
    );
    if (sub) {
      sub.upgrade = true;
    }
    var product = this.products.find((x) => sub.skuId == x.sku_id);
    if (product.sku_id.toLowerCase().indexOf('drive') >= 0) {
      sub.products = this.products.filter(
        (x) => x.sku_id.toLowerCase().indexOf('drive') >= 0
      );
    } else if (!Utilities.isNullOrEmpty(product.upgrade_ids)) {
      sub.products = this.products.filter((x) =>
        product.upgrade_ids.split(';').includes(x.sku_id)
      );
    }
    sub.products.push(product);
  }
}
