import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-reports',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  active = '1';
  tabs = [
    {id:'1', name: 'Orders', link: 'orders-report' },
    {id:'2',  name: 'Customers', link: 'customers-report' },
  ];

  constructor(private router:Router) {}
  ngOnInit(): void {}

  static searchGlobal(x, y, z, t, a, d, b) {}
}
