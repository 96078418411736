import { BaseModel } from './base.model';

export class GroupModel extends BaseModel {
  name: string;
  email: string;
  permissions: any[] = [];
  deletedPermissions: any[] = [];
  initPermissions: any[] = [];

  constructor() {
    super();
  }
}
