import { Plan, SubscriptionModel } from 'src/models/subcription.model';
import { UserViewModel } from './../../../../../src/models/vw.user';
import { CountryModel } from './../../../../../src/models/customer.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Utilities } from './../../../../../src/shared/utilities';
import { Endpoint, Unicode, PlanValues } from 'src/shared/Endpoint';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerModel } from 'src/models/customer.model';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  NgZone,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Encryption } from 'src/servcies/Encryption';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { ModalDirective } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { HttpRequestService } from 'src/servcies/http-request-service';
import { RequestResultModel } from 'src/models/request_result.model';

@Component({
  selector: 'lib-customer-details',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
  model: CustomerModel = new CustomerModel('', '', '', '');
  custForm: FormGroup;
  currentUser: UserViewModel;
  isReadOnly: boolean;
  countries: CountryModel[] = [];
  formTitle: string = '';
  sales = [];
  initCustomerSaleId: string;
  canAssign: boolean = false;
  googleWorkspaceSelected: boolean = true;
  @ViewChild('orderModal')
  orderModal: ModalDirective;
  @ViewChild('transferModal')
  transferModal: ModalDirective;
  saleManagers = [];
  managers = [];
  plans = [];
  currentUserRoleData: UserRoleDataModel;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private router: Router,
    private localStorage: LocalStorageService,
    private zone: NgZone
  ) {
    this.custForm = fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      alternateEmail: ['', [Validators.required, Validators.email]],
      password: [''],
      phone: [''],
      domain: ['', Validators.required],
      organisation: ['', Validators.required],
      locality: ['', Validators.required],
      region: ['', Validators.required],
      address1: ['', Validators.required],
      address2: [''],
      address3: [''],
      postalCode: [''],
      customerSales: [''],
    });
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.canAssign = !this.currentUser.isJustSale;
    this.countries = JSON.parse(this.localStorage.get(Unicode.COUNTRIES));
    this.sales = JSON.parse(this.localStorage.get(Unicode.SALES));
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
  }

  async ngOnInit(): Promise<void> {
    var encryption = new Encryption();
    this.route.queryParams.subscribe(async (params) => {
      var custID = params['customerID'];
      if (!Utilities.isNullOrEmpty(custID)) {
        //   custID = JSON.parse(custID);
        this.model = await Utilities.getCustomerByIdDB(this.http, custID, true);
        if (!Utilities.isNullOrEmpty(this.model.auth_token))
          this.googleWorkspaceSelected = false;
        if (this.googleWorkspaceSelected) {
          if (!Utilities.isNullOrEmpty(this.model.id)) {
            if (!Utilities.isNullOrEmpty(this.model.password))
              this.model.password = encryption.decrypt(
                this.model.password,
                Endpoint.AES_KEY
              );
            else this.model.password = '';
          } else this.model.password = Utilities.generatePassword(8);
        }
        if (!Utilities.isNullOrEmpty(this.model.region_name))
          this.model.region = this.countries.find(
            (x) => x.name == this.model.region_name
          );
        else if (!Utilities.isNullOrEmpty(this.model.country_code))
          this.model.region = this.countries.find(
            (x) => x.iso2.toLowerCase() == this.model.country_code.toLowerCase()
          );
        else this.model.region = null;
        this.model.address_line1 = !Utilities.isNullOrEmpty(
          this.model.address_line1
        )
          ? this.model.address_line1
          : '';
        this.model.address_line2 = !Utilities.isNullOrEmpty(
          this.model.address_line2
        )
          ? this.model.address_line2
          : '';
        this.isReadOnly =
          !Utilities.isNullOrEmpty(this.model.id) && this.model.status == 1;
        this.formTitle = Utilities.isNullOrEmpty(this.model.id)
          ? 'Organisation Information'
          : 'Update Customer';
        if (!Utilities.isNullOrEmpty(this.model.sales)) {
          var tab = this.model.sales.split(','); // AS FAR THERE IS ONLY ONE SALE FOR A CUSTOMER
          tab.forEach((saleId) => {
            if (!Utilities.isNullOrEmpty(saleId)) {
              this.zone.run(() => {
                this.model.customerSaleId = saleId;
                if (saleId == this.currentUser.usr_id + '') return;
              });
            }
          });
        }
        this.initCustomerSaleId = this.model.customerSaleId;
      } else {
        this.model.alternate_email = Endpoint.DEFAULT_ALTERNATE_EMAIL;
      }
    });
  }

  async onSubmit() {
    var add = Utilities.isNullOrEmpty(this.model.id);
    let encryption = new Encryption();
    this.model.domain = this.model.domain.trim();
    if (!Utilities.isNullOrEmpty(this.model.id) && this.model.status == 0) {
      if (
        this.model.email.toLowerCase().split('@')[1].trim() !==
        this.model.domain.toLowerCase().trim()
      ) {
        alert('Invalid Email');
        return;
      }
      // await this.setCustomerSales(add, this.model);
      var encryPassword = encryption.encrypt(
        this.model.password ?? '',
        Endpoint.AES_KEY
      );
      this.model.encryptedPassword = encryPassword;
      var dbSuccess = await this.customerDB(false, false, this.model);
      if (dbSuccess) {
        this.localStorage.save(
          Unicode.CURRENT_CUSTOMER,
          JSON.stringify(this.model)
        );
        this.localStorage.save(
          Unicode.NEW_SUBSC,
          JSON.stringify(new SubscriptionModel())
        );
      }
    } else {
      if (this.googleWorkspaceSelected) {
        if (this.custForm.valid) {
          if (
            this.model.email.toLowerCase().split('@')[1].trim() !==
            this.model.domain.toLowerCase().trim()
          ) {
            alert('Invalid Email');
            return;
          }
          if (
            Utilities.isNullOrEmpty(this.model.id) &&
            (Utilities.isNullOrEmpty(this.model.password) ||
              this.model.password.length < 8)
          ) {
            alert('Invalid Password(A minimum of 8 characters is required');
            return;
          }
          if (
            !Utilities.isNullOrEmpty(this.model.id) &&
            Utilities.isNullOrEmpty(this.model.password)
          )
            this.model.password = ' ';
          this.model.is_offline = this.model.is_offline ?? 0;
          var encryPassword = encryption.encrypt(
            this.model.password,
            Endpoint.AES_KEY
          );
          this.model.encryptedPassword = encryPassword;
          if (this.currentUserRoleData.toExecute) {
            /**API **/
            var apiSuccessId = await Utilities.customerApi(
              this.http,
              this.httpRequestService,
              add,
              this.model
            ); // return id or null
            /** IN DB **/
            if (!Utilities.isNullOrEmpty(apiSuccessId)) {
              this.model.id = apiSuccessId;
              if (add) {
                await this.setCustomerSales(add, this.model);
                this.model.is_new_customer = true;
              } else this.model.customerSales = [];
              this.model.status = 1; // CREATED FROM THE API
              var dbSuccess = await this.customerDB(add, false, this.model);
              if (dbSuccess) {
                if (add) {
                  this.localStorage.save(
                    Unicode.CURRENT_CUSTOMER,
                    JSON.stringify(this.model)
                  );
                  this.localStorage.save(
                    Unicode.NEW_SUBSC,
                    JSON.stringify(new SubscriptionModel())
                  );
                  this.router.navigate(['customers/new-subscription']);
                } else {
                  alert('Successfuly updated');
                  this.router.navigate(['/customers']);
                }
              }
            }
          } else {
            var apiSuccess = null;
            if (add) {
              this.model.status = 0;
              this.model.is_new_customer = true;
              this.model.id = Utilities.randomNumber(1, 10000000) + '';
              apiSuccess = this.model.id;
              await this.setCustomerSales(add, this.model);
            } else {
              apiSuccess = await Utilities.customerApi(
                this.http,
                this.httpRequestService,
                add,
                this.model
              );
              this.model.customerSales = [];
            }
            if (!Utilities.isNullOrEmpty(apiSuccess)) {
              var dbSuccess = await this.customerDB(add, false, this.model);
              if (dbSuccess) {
                if (add) {
                  this.localStorage.save(
                    Unicode.CURRENT_CUSTOMER,
                    JSON.stringify(this.model)
                  );
                  this.localStorage.save(
                    Unicode.NEW_SUBSC,
                    JSON.stringify(new SubscriptionModel())
                  );
                  this.router.navigate(['customers/new-subscription']);
                } else {
                  alert('Successfuly updated');
                  this.router.navigate(['/customers/']);
                }
              }
            }
          }
        } // form valid
      }

      //TRANSFER
      else {
        if (
          !Utilities.isNullOrEmpty(this.model.auth_token) &&
          !Utilities.isNullOrEmpty(this.model.domain)
        ) {
          var add = Utilities.isNullOrEmpty(this.model.id);
          if (add) {
            // import
            var importResult = await Utilities.importCustomerChannelApi(
              this.http,
              this.httpRequestService,
              this.model.domain,
              this.model.auth_token
            );
            if (importResult) {
              this.model.id = importResult.cloudIdentityId;
              this.model.organisation_name = importResult.orgDisplayName;
              this.model.first_name = importResult.primaryContactInfo?.firstName
                ? importResult.primaryContactInfo.firstName ?? ''
                : '';
              this.model.last_name =
                importResult.primaryContactInfo?.lastName ?? '';
              if (
                this.model.first_name == '' &&
                !Utilities.isNullOrEmpty(
                  importResult.primaryContactInfo.displayName
                )
              ) {
                this.model.first_name =
                  importResult.primaryContactInfo.displayName.split(' ')[0];
                this.model.last_name =
                  importResult.primaryContactInfo.displayName.split(' ')[1];
              }
              var regionName =
                this.countries.find(
                  (x) =>
                    x.iso2.toUpperCase() ==
                      importResult.orgPostalAddress?.regionCode ?? ''
                ).name ?? '';
              this.model.region = new CountryModel(
                regionName,
                importResult.orgPostalAddress?.regionCode ?? ''
              );
              this.model.country_code =
                importResult.orgPostalAddress?.regionCode ?? '';
              this.model.locality =
                importResult.orgPostalAddress?.locality ?? '';
              this.model.postal_code =
                importResult.orgPostalAddress?.postalCode ?? '';
              this.model.encryptedPassword = '';
              this.model.address_line1 =
                importResult.orgPostalAddress?.addressLines &&
                importResult.orgPostalAddress?.addressLines.length > 0
                  ? importResult.orgPostalAddress.addressLines[0]
                  : '';
              this.model.address_line2 =
                importResult.orgPostalAddress?.addressLines &&
                importResult.orgPostalAddress?.addressLines.length > 1
                  ? importResult.orgPostalAddress.addressLines[1]
                  : '';
              this.model.address_line3 =
                importResult.orgPostalAddress?.addressLines &&
                importResult.orgPostalAddress?.addressLines.length > 2
                  ? importResult.orgPostalAddress.addressLines[2]
                  : '';
              this.model.email = importResult.primaryContactInfo?.email ?? '';
              this.model.alternate_email =
                importResult.cloudIdentityInfo?.alternateEmail ?? '';
              this.model.phone =
                importResult.cloudIdentityInfo?.phoneNumber ?? '';
              this.model.channel_name = importResult.name;
              this.model.status = this.currentUserRoleData.toExecute ? 1 : 0;
              this.model.is_offline = 0;
              this.setCustomerSales(true, this.model);
              this.localStorage.save(
                Unicode.CURRENT_CUSTOMER,
                JSON.stringify(this.model)
              );
              this.localStorage.save(Unicode.IS_TRANSFER_ACTION, '1');
              this.localStorage.save(
                Unicode.NEW_SUBSC,
                JSON.stringify(new SubscriptionModel())
              );
              this.router.navigate(['customers/transferable-subscriptions']);
            }
          }
          //TRANSFER / UPDATE
          else {
          }
        } else {
          alert('Enter required fields');
        }
      }
    }
  }

  async transferSubscription(subscriptions, headers) {
    for (var i = 0; i < subscriptions.length; i++) {
      var sub = subscriptions[i];
      var url = '';
      var subBody = {};
      var result: RequestResultModel = await this.httpRequestService.post(
        url,
        subBody,
        headers
      );
      if (result.success) {
        console.log(result.data);
      } else {
        console.log(result.errorMessage);
      }
    }
  }

  async assignSales() {
    var add = Utilities.isNullOrEmpty(this.model.id);
    if (!add) {
      await this.setCustomerSales(add, this.model);
      var params = new HttpParams();
      params = params.set(Unicode.IS_ASSIGN_OPERATION, '1');
      params.set(Unicode.IS_ADD_OPERATION, undefined);
      var resp: any = await this.http
        .post(
          Endpoint.GLOBAL_URL + Endpoint.UPDATE_CUSTOMER_FILE_NAME,
          JSON.stringify(this.model),
          { params: params }
        )
        .toPromise();
      console.log(resp);
      if (resp && !resp.msg) {
        alert('Assigned');
      } else {
        alert(resp.msg);
      }
    }
  }

  transferPopupClick() {
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.model)
    );
    this.localStorage.save(Unicode.IS_TRANSFER_ACTION, '1');
    // this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(new SubscriptionModel()));
    this.router.navigate(['customers/new-subscription']);
  }

  async customerDB(
    add: boolean,
    updateSales: boolean,
    customer: CustomerModel,
    isTranfer: boolean = false
  ): Promise<boolean> {
    var params = new HttpParams();
    params = params.set(Unicode.IS_ADD_OPERATION, add ? '1' : '0');
    if (isTranfer)
      params = params.set(
        Unicode.CUSTOMER_TYPE,
        Unicode.TRANSFER_CUSTOMER_TYPE
      );
    var resp: any = await this.http
      .post(
        Endpoint.GLOBAL_URL + Endpoint.UPDATE_CUSTOMER_FILE_NAME,
        JSON.stringify(customer),
        { params: params }
      )
      .toPromise();
    console.log(resp);
    if (resp && !resp.msg) {
      return true;
    } else {
      alert(resp.msg);
      return false;
    }
  }

  cancel() {
    this.router.navigate(['/customers/']);
  }

  purchaseNewService() {
    //POPUP
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.model)
    );
    this.localStorage.save(
      Unicode.NEW_SUBSC,
      JSON.stringify(new SubscriptionModel())
    );
    this.router.navigate(['customers/new-subscription']);
  }

  saveAsTrial() {
    this.model.is_new_trial = true;
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER,
      JSON.stringify(this.model)
    );
    var sub = new SubscriptionModel();
    sub.plan = new Plan();
    sub.plan.planName = PlanValues.TRIAL;
    this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(sub));
    this.router.navigate(['customers/new-subscription']);
  }

  async setCustomerSales(add: boolean, customer: CustomerModel) {
    customer.customerSales = [];
    if (add) {
      if (!Utilities.isNullOrEmpty(customer.customerSaleId + '')) {
        customer.customerSales.push({ id: customer.customerSaleId });
      } else {
        // if (!this.localStorage.get(Unicode.MANAGERS)) {
        //   var resp: any = await this.http.get(Endpoint.GLOBAL_URL + Endpoint.MANAGERS_FILE_NAME).toPromise();
        //   if (resp && !resp.msg) {
        //     this.managers = resp.managers;
        //     this.localStorage.save(Unicode.MANAGERS, JSON.stringify(this.managers));
        //   }
        // }
        // if (this.currentUser.isJustSale)
        customer.customerSales.push({ id: this.currentUser.usr_id });
        // else {
        //   this.managers.forEach(mang => {
        //     customer.customerSales.push({ id: mang.id });
        //   });
        // }
      }
    } else {
      if (this.initCustomerSaleId != customer.customerSaleId) {
        if (!Utilities.isNullOrEmpty(customer.customerSaleId + '')) {
          customer.customerSales.push({
            id: customer.customerSaleId,
            action: 'A',
          });
        } else if (!Utilities.isNullOrEmpty(this.initCustomerSaleId + '')) {
          customer.customerSales.push({
            id: this.initCustomerSaleId,
            action: 'D',
          });
        }
      }
    }
  }

  eltClick(section) {
    this.googleWorkspaceSelected = section == '1';
  }

  changeDomain(event) {
    var value = event.target.value;
    this.model.email = 'admin@' + value;
  }

  get firstName() {
    return this.custForm.get('firstName');
  }
  get lastName() {
    return this.custForm.get('lastName');
  }
  get email() {
    return this.custForm.get('email');
  }
  get alternateEmail() {
    return this.custForm.get('alternateEmail');
  }
  get password() {
    return this.custForm.get('password');
  }
  get phone() {
    return this.custForm.get('phone');
  }
  get domain() {
    return this.custForm.get('domain');
  }
  get organisation() {
    return this.custForm.get('organisation');
  }
  get region() {
    return this.custForm.get('region');
  }
  get locality() {
    return this.custForm.get('locality');
  }
  get address1() {
    return this.custForm.get('address1');
  }
  get address2() {
    return this.custForm.get('address2');
  }
  get address3() {
    return this.custForm.get('address3');
  }
  get postalCode() {
    return this.custForm.get('postalCode');
  }
  get customerSales() {
    return this.custForm.get('customerSales');
  }
}
