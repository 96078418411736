import { BaseModel } from './base.model';

export class VwExchangeRate extends BaseModel{
   
    currency_from_rfid:number;
    currency_to_rfid:number;
    rate:number;
    inverse_rate:number;
    currency_from_code:string;
    currency_to_code:string;
    action:string;

    constructor(){
        super();
    }
}