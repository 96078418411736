<div id="cust_content">
    <div class="row justify-content-between customer-header p-0" style="margin-bottom: 0px !important;">
            <div class="p-0 m-t-5">
                <label class="page_title">{{pageTitle}}</label>
            </div>
            <div class="m-t-5 p-0 m-0" *ngIf="canCreateCustomer">
                <button (click)="AddNewCustomer()" class="btn_blue_bg float-right m-0" data-toggle="tooltip"
                    title="create new customer">
                    <!-- <span class="fa fa-plus btn_plus_span"></span> -->
                    <!-- <img src="../../assets/+.png"/> -->
                    <img class='ic_plus_white' src="../../assets/+.png" />
                    Create Customer
                </button>
            </div>
        </div>
        <!-- <div style="margin-bottom: 20px;">
            <input type="text" placeholder="Search By Name/Domain" class="col-lg-2 col-md-3 col-6 col-sm-6 input_search"
                (keyup.enter)="searchCustomer($event)" id="cust_search"/>
        </div> -->
    
        <div class="div_table m-0" style="overflow-x: scroll;margin-top: 20px !important;">
            <ngx-datatable id="cust-table" class="bootstrap row col-12" [rows]="GetData" [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="50" rowHeight="auto" [externalPaging]="true"
                [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size" (page)="pageChanged($event)">
    
                <ngx-datatable-column prop="first_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                    class="col_remove" name="Name" headerClass="col-lg-3 col-md-3 col_remove"
                    cellClass="col-lg-3 col-md-3 col_remove">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a href="javascript:void(0)" (click)='viewSubscByCustomerId(row.id)'>{{row.first_name}}
                            {{row.last_name}} </a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="customer_created_date" [sortable]="true" [draggable]="true"
                    [canAutoResize]="true" name="Created Date"
                    headerClass="tbl_cust_org_col col-lg-2 col-md-3 col-3 col-sm-3"
                    cellClass="tbl_cust_org_col col-lg-2 col-md-3 col-3 col-sm-3">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.customer_created_date |date:'yyyy-MM-dd'}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="domain" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                    name="Domain" headerClass="tbl_cust_domain_col col-lg-3 col-md-5 col-sm-9 col-9"
                    cellClass="tbl_cust_domain_col col-lg-3 col-md-5 col-sm-9 col-9">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <a href="javascript:void(0)" (click)='viewSubscByCustomerId(row.id)'>{{row.domain}} </a>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="phone" [sortable]="true" [draggable]="true" [canAutoResize]="true" name="Phone"
                    headerClass="tbl_cust_phone_col col-lg-2 col-md-2 col_remove"
                    cellClass="tbl_cust_phone_col col-lg-2 col-md-2 col_remove">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.phone}}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column prop="region_name" [sortable]="true" [draggable]="true" [canAutoResize]="true"
                    name="Region" headerClass="tbl_cust_phone_col col-lg-1 col-md-1"
                    cellClass="tbl_cust_phone_col col-lg-1 col-md-1">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        {{row.region_name ? row.region_name : row.country_code }}
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column headerClass="col-lg-1 col-md-2 tbl_cust_edit_col" cellClass="col-lg-1 col-md-2" *ngIf="!currentUser.isSuspenser">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div class="" id="cust_drop_mnu">
                            <!-- *ngIf="row.status == '1' && !isAccountant" for id:cust_drop_mnu -->
                            <!-- <button class="btn_cust_ord" (click)="viewSubscByCustomerId(row.id)">Orders</button> -->
                            <img class="ic_cust_edit" src="../../assets/edit-icon-normal.png"
                                onmouseover="this.src='../../assets/edit-icon-hover.png'"
                                onmouseout="this.src='../../assets/edit-icon-normal.png'" (click)="editCustomer(row.id)" />
                        </div>
                    </ng-template>
                </ngx-datatable-column>
    
            </ngx-datatable>
        </div>
    </div>