import { BaseModel } from './base.model';

export class CurrencyModel extends BaseModel{
   
    name:string;
    code:string;

    constructor(){
        super();
    }
}