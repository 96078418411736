import { map } from 'rxjs/operators';
import { Utilities } from './../../../../../src/shared/utilities';
import { CurrencyModel } from './../../../../../src/models/currency.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Endpoint, Unicode } from 'src/shared/Endpoint';
import { VwExchangeRate } from 'src/models/vw_exchange_rate';

@Component({
  selector: 'settings-exchange-rate',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './exchange-rate.component.html',
  styleUrls: ['./exchange-rate.component.scss']
})
export class ExchangeRateComponent implements OnInit {

  exchForm: FormGroup;
  currencies: CurrencyModel[] = [];
  initialRates: VwExchangeRate[] = [];
  rates: VwExchangeRate[] = [];

  constructor(private http: HttpClient, private fb: FormBuilder, private localStorage: LocalStorageService) {
    this.exchForm = fb.group({
      currencyFromRfid: ['', Validators.required],
      currencyToRfid: ['', Validators.required],
      rate: ['', Validators.required],
      inverseRate: [''],
    });

  }

  async ngOnInit(): Promise<void> {
    //EXCHANGE RATES
    var params = new HttpParams().set(Unicode.ACTION, Unicode.GET_ALL);
    await this.http.get(Endpoint.GLOBAL_URL + "" + Endpoint.EXCHANGE_RATE_FILE_NAME, { params: params }).toPromise()
      .then((resp: any) => {
        if (resp && resp.rates) {
          this.rates = resp.rates;
          this.initialRates = JSON.parse(JSON.stringify(this.rates))
        }
      });
    //CURRENCIES
    this.currencies = await Utilities.getCurrencies(this.http, this.localStorage);
  }


  addNew() {
    if (this.exchForm.valid) {
      var model = new VwExchangeRate();
      model.currency_from_rfid = this.currencyFromRfid.value.id;
      model.currency_to_rfid = this.currencyToRfid.value.id;
      model.currency_from_code = this.currencyFromRfid.value.code;
      model.currency_to_code = this.currencyToRfid.value.code;
      model.rate = this.rate.value;
      model.inverse_rate = this.inverseRate.value;
      model.action = "A";
      this.rates.push(model);
     // this.exchForm.reset();
     this.rate.setValue(0);
     this.inverseRate.setValue(0);
     this.currencyFromRfid.setValue(this.currencies[0]);
     this.currencyToRfid.setValue(this.currencies[0]);
    }
  }

  changeRate(event) {
    var value = event.target.value;
    this.inverseRate.setValue(value);
  }

  async save() {
    var list: VwExchangeRate[] = [];
    this.rates.forEach(rate => {
      if (rate.action !== 'A') {
        var initialRate = this.initialRates.find(x => x.id == rate.id);
        if (initialRate.rate !== rate.rate || initialRate.inverse_rate !== rate.inverse_rate) {
          rate.action = 'U';
          list.push(rate);
        }
      }
      else {
        list.push(rate);
      }
    });
    if (list.length > 0) {
      var params = new HttpParams().set(Unicode.ACTION, Unicode.ADD);
      await this.http.post(Endpoint.GLOBAL_URL + "" + Endpoint.EXCHANGE_RATE_FILE_NAME, JSON.stringify({list:list}), { params: params }).toPromise()
        .then((resp: any) => {
          if (resp && !resp.msg) {

          }
          else {
            console.log(resp.msg)
          }
        });
    }
  }

  get currencyFromRfid() { return this.exchForm.get('currencyFromRfid'); }
  get currencyToRfid() { return this.exchForm.get('currencyToRfid'); }
  get rate() { return this.exchForm.get('rate'); }
  get inverseRate() { return this.exchForm.get('inverseRate'); }
}
