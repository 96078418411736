import { CustomerModel } from './../../../../../../src/models/customer.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { SubscriptionModel } from './../../../../../../src/models/subcription.model';
import { Router } from '@angular/router';
import { Endpoint, Unicode } from './../../../../../../src/shared/Endpoint';
import { StorageService } from './../../../../../../src/servcies/storage-service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { cpuUsage } from 'process';
import { Utilities } from 'src/shared/utilities';

@Component({
  selector: 'lib-products',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  googleProducts = [];
  currentNewSubsc = new SubscriptionModel();
  currentCustomer: CustomerModel;
  transferableSubscriptions: SubscriptionModel[] = [];

  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {}

  async ngOnInit(): Promise<void> {
    this.currentNewSubsc = JSON.parse(this.localStorage.get(Unicode.NEW_SUBSC));
    this.currentCustomer = JSON.parse(
      this.localStorage.get(Unicode.CURRENT_CUSTOMER)
    );
    this.currentNewSubsc.oldSkuId = this.currentNewSubsc.skuId ?? null;
    this.googleProducts = JSON.parse(
      this.localStorage.get(Unicode.GOOGLE_PRODUCTS)
    );
    var fromEditSusbscriptionPage = this.localStorage.get(
      Unicode.FROM_SUBS_PAGE
    );
      if (fromEditSusbscriptionPage && fromEditSusbscriptionPage == '1') {
        var isDowngrade = this.localStorage.get(Unicode.IS_DOWNGRADE);
        var product = this.googleProducts.find(
          (x) =>
            this.currentNewSubsc.skuId && x.sku_id == this.currentNewSubsc.skuId
        );
        if (product) {
          if (isDowngrade == '1') {
            if (product.downgrade_ids) {
              this.googleProducts = this.googleProducts.filter((x) =>
                product.downgrade_ids.split(';').includes(x.sku_id)
              );
            } else this.googleProducts = [];
          } else {
            if (product.upgrade_ids) {
              this.googleProducts = this.googleProducts.filter((x) =>
                product.upgrade_ids.split(';').includes(x.sku_id)
              );
            } else this.googleProducts = [];
          }
        }
      }
  }

  selectProduct(productId: string, productName: string) {
    var currentSubs = [];
    var subs = this.localStorage.get(Unicode.CURRENT_CUSTOMER_SUBSCS);
    if (!Utilities.isNullOrEmpty(subs)) currentSubs = JSON.parse(subs);
    if (
      Utilities.isNullOrEmpty(this.currentCustomer.id) ||
      this.currentCustomer.status == 0 ||
      this.currentCustomer.status == '0'
    )
      currentSubs = [];
    if (currentSubs.indexOf(productId.toLowerCase().trim()) >= 0) {
      alert('this SKU already exists for ' + this.currentCustomer.domain);
    } else {
      if (
        this.currentNewSubsc.subscriptionId &&
        this.currentNewSubsc.skuId &&
        this.currentNewSubsc.skuId !== productId
      ) {
        this.currentNewSubsc.isProductChanged = true;
        this.currentNewSubsc.newSkuId =
          productId + ';' + this.localStorage.get(Unicode.IS_DOWNGRADE);
      }
      this.currentNewSubsc.skuId = productId;
      this.currentNewSubsc.productName = productName;
      this.localStorage.save(
        Unicode.NEW_SUBSC,
        JSON.stringify(this.currentNewSubsc)
      );
      this.router.navigate(['customers/new-subscription/plans']);
    }
  }
}
