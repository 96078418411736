<div class="main-content" id="sub-main">
    <ul class="nav nav-tabs steps" role="tablist">
        <li class="nav-item li_steps_1 col-4" role="presentation" *ngIf='!changePlan' [ngClass]="{'hidden':changePlan}">
            <!-- <a style="color: black;display: flex;" class="nav-link justify-content-center active" routerLink="products"
                role="tab" data-toggle="tab" [routerLinkActive]="['active-tab']" 
                [routerLinkActiveOptions]="{exact:true}">
                1-Select eligible SKU</a> -->
            <a style="color: black;display: flex;" class="nav-link justify-content-center" routerLink="products"
                [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:true}">1-Select eligible SKU</a>
        </li>
        <li class="nav-item li_steps_2" role="presentation" [ngClass]="{'col-4':!changePlan,'col-6':changePlan}" (click)='test()'>
            <a style="color: black;display: flex;" class="nav-link justify-content-center" routerLink="plans"
                [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:true}">2-Select purchase options</a>
        </li>
        <li class="nav-item li_steps_3" id="last" role="presentation" [ngClass]="{'col-4':!changePlan,'col-6':changePlan}">
                <a style="color: black;display: flex;" class="nav-link justify-content-center" routerLink="review-order"
                [routerLinkActive]="['active-link']" [routerLinkActiveOptions]="{exact:true}">3-Review order</a>
        </li>
    </ul>
    <div class="steps_router m-0">
        <router-outlet></router-outlet>
    </div>
</div>