import { ReviewOrderModule } from './review-order/review-order.module';
import { PlansModule } from './plans/plans.module';
import { ProductsModule } from './products/products.module';
import { PlansComponent } from './plans/plans.component';
import { ProductsComponent } from './products/products.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionsComponent } from './subscriptions.component';

const routes: Routes = [
  {
    path: '', component: SubscriptionsComponent,
    data: { breadcrumb: 'subs' },
    children: [
      { path: '', loadChildren: () => ProductsModule, redirectTo: 'products', pathMatch: 'full' },
      { path: 'products', loadChildren: () => ProductsModule },
      { path: 'plans', loadChildren: () => PlansModule },
      { path: 'review-order', loadChildren: () => ReviewOrderModule },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SubscriptionsRoutingModule { }
