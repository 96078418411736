import { ReportCustomersComponent } from './../layouts/reports/customers/customers.component';
import { ReportOrdersComponent } from './../layouts/reports/orders/orders.component';
import { ReportsComponent } from './../layouts/reports/reports.component';
import { OrdersComponent } from './../../../projects/orders/src/lib/orders.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomersComponent } from './../../../projects/customers/src/lib/customers.component';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuModel } from './../../models/menu.model';
import {
  Component,
  OnInit,
  ViewEncapsulation,
  AfterViewInit,
  NgZone,
  EventEmitter,
  Output,
} from '@angular/core';
import * as $ from 'jquery';
import { UserModel } from 'src/models/user.model';
import { StorageService } from 'src/servcies/storage-service';
import { Endpoint, Group, Permissions, Unicode } from 'src/shared/Endpoint';
import { throwMatDialogContentAlreadyAttachedError } from '@angular/material/dialog';
import { UserViewModel } from 'src/models/vw.user';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { SalesComponent } from 'projects/sales/src/public-api';
import { DatePipe } from '@angular/common';
import { CustomerModel } from 'src/models/customer.model';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { Utilities } from 'src/shared/utilities';
import { profile } from 'console';

@Component({
  selector: 'app-navigation',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  currentUser: UserViewModel;
  isLoggedIn: boolean = false;
  isAdmin: boolean = false;
  pendingOrderCount: number = 1;
  menus: MenuModel[] = [];
  isMenuOpened: boolean;
  currentUserRoleData: UserRoleDataModel;

  constructor(
    private storage: StorageService,
    private router: Router,
    private route: ActivatedRoute,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
    var that = this;
    this.pendingOrderCount = 1;
    // $("#menu-toggle").click(function (e) {
    //   e.preventDefault();
    //   // $("#wrapper").toggleClass("toggled");
    //   that.showNav = !that.showNav;
    // });
    var stg_usr = this.localStorage.getLoggedinUser();
    if (stg_usr) this.currentUser = JSON.parse(stg_usr);
    var stg_mnus = this.localStorage.get(Unicode.MENUS);
    if (stg_mnus) this.menus = JSON.parse(stg_mnus);
    this.isLoggedIn = this.currentUser != null;
    this.menus.forEach((menu) => {
      if (location.pathname.indexOf(menu.link) >= 0)
        menu.img = '../../assets/' + menu.img_hover;
      else menu.img = '../../assets/' + menu.img_normal;
    });
    if (this.currentUser)
      this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
        this.localStorage,
        this.currentUser
      );
  }

  ngOnInit(): void {}
  openNav() {
    this.isMenuOpened = !this.isMenuOpened;
  }

  ngAfterViewInit(): void {}

  logout() {
    this.localStorage.logout();
    this.router.navigate(['login']);
  }

  menuClick(menuId) {
    this.menus.forEach((menu) => {
      if (menu.id == menuId) menu.img = '../../assets/' + menu.img_hover;
      else menu.img = '../../assets/' + menu.img_normal;
    });
    this.isMenuOpened = false;
  }

  search(event) {
    const val = event.target.value.toLowerCase().trim();
    if (document.getElementById(Unicode.CUSTOMER_PAGE_ID))
      CustomersComponent.searchGlobal(val, this.currentUser, this.http);
    else if (document.getElementById(Unicode.USER_PAGE_ID))
      SalesComponent.searchGlobal(val, this.currentUser, this.http);
    else if (document.getElementById(Unicode.ORDER_PAGE_ID)) {
      this.route.queryParams.subscribe((params) => {
        OrdersComponent.searchGlobal(
          this.datePipe,
          this.localStorage,
          params['status'],
          val,
          this.currentUser,
          this.currentUserRoleData,
          this.http
        );
      });
    } else if (document.getElementById(Unicode.ORSERS_REPORT_PAGE_ID)) {
      this.route.queryParams.subscribe((params) => {
        ReportOrdersComponent.searchGlobal(
          this.datePipe,
          this.localStorage,
          params['status'],
          val,
          this.currentUser,
          this.currentUserRoleData,
          this.http
        );
      });
    } else if (document.getElementById(Unicode.CUSTOMERS_REPORT_PAGE_ID)) {
      this.route.queryParams.subscribe((params) => {
        ReportCustomersComponent.searchGlobal();
      });
    }
  }
  addUser() {
    this.router.navigate(['/sales/sale-details'], {
      queryParams: { model: JSON.stringify(new CustomerModel()) },
      skipLocationChange: true,
    });
  }
  createCustomer() {
    this.router.navigate(['/customers/customer-details'], {
      queryParams: { model: JSON.stringify(new CustomerModel()) },
    });
  }
  createOrder() {
    this.router.navigate(['/customers'], {
      queryParams: { model: JSON.stringify(new CustomerModel()) },
    });
  }

  async refreshLocalData() {
    //USER
    var prs = new HttpParams().set(Unicode.EMAIL, this.currentUser.user_email);
    var resp = await this.http
      .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.LOGIN_FILE_NAME, {
        params: prs,
      })
      .toPromise();
    if (resp && !resp.success && resp.msg) {
    } else {
      var user: UserViewModel = resp[0];
      var groups = [];
      var permissions = [];
      resp.forEach((row: UserViewModel) => {
        if (!Utilities.isNullOrEmpty(row.group_rfid)) {
          if (!groups.includes((x) => x.id == row.group_rfid))
            groups.push({ id: row.group_rfid, name: row.group_name });
        }
        if (!Utilities.isNullOrEmpty(row.permission_rfid)) {
          if (!permissions.includes((x) => x.id == row.permission_rfid))
            permissions.push({
              id: row.permission_rfid,
              name: row.permission_name,
              code: row.permission_code,
            });
        }
      });
      user.groups = groups;
      user.permissions = permissions;
      //perm
      if (
        user.groups.length == 1 &&
        user.groups.find((x) => x.name == Group.GPM)
      ) {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = false;
        user.isGPM = true;
      } else if (
        user.permissions.find(
          (x) =>
            x.code && x.code.indexOf(Permissions.sales_manager_approve2) >= 0
        )
      ) {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = true;
      } else if (
        user.permissions.find(
          (x) =>
            x.code && x.code.indexOf(Permissions.sales_manager_approve1) >= 0
        )) {
        user.isJustSale = false;
        user.approve1 = true;
        user.approve2 = false;
      } else if (
        user.groups.length == 1 &&
        user.groups.find((x) => x.name == Group.Sale)
      ) {
        user.isJustSale = true;
        user.approve1 = false;
        user.approve2 = false;
      } else {
        user.isJustSale = false;
        user.approve1 = false;
        user.approve2 = false;
      }
      //
      user.img = this.currentUser.img;
      //MENUS
      var menus = [];
      var prs = new HttpParams().set(
        Unicode.GROUP_ID,
        this.currentUser.groups.map((x) => x.id).join(';')
      );
      var resp_menu = await this.http
        .get<any>(Endpoint.GLOBAL_URL + '' + Endpoint.MENU_FILE_NAME, {
          params: prs,
        })
        .toPromise();
      if (resp_menu && !resp_menu.success && !resp_menu.msg) {
        menus = resp_menu;
        menus.forEach((menu) => {
          menu.img = '../../assets/' + menu.img_normal;
        });
        this.localStorage.save(Unicode.MENUS, JSON.stringify(menus));
      }
    }
    //GLOBAL DATA
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.GLOBAL_DATA_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(Unicode.COUNTRIES, JSON.stringify(resp.countries));
      this.localStorage.save(
        Unicode.GOOGLE_PRODUCTS,
        JSON.stringify(resp.products)
      );
      this.localStorage.save(Unicode.PLANS, JSON.stringify(resp.plans));
      this.localStorage.save(
        Unicode.GROUP_PERMISSIONS,
        JSON.stringify(resp.groups_permissions)
      );
      this.localStorage.save(
        Unicode.DEFAULT_CURRENCY_ID,
        resp.defaultCurrencyId ?? '1'
      );
      if (Utilities.isNullOrEmpty(resp.gpm_emails))
        this.localStorage.save(Unicode.GPM_EMAILS, JSON.stringify([]));
      else
        this.localStorage.save(
          Unicode.GPM_EMAILS,
          JSON.stringify(resp.gpm_emails.map((x) => x.email))
        );
      if (resp.groups_permissions) {
        var userGrpPermi = resp.groups_permissions.filter((x) =>
          user.groups.find((y) => y.id == x.group_id)
        );
        userGrpPermi.forEach((element) => {
          user.permissions.push({
            id: element.permission_id,
            name: element.permission_name,
            code: element.permission_code,
          });
        });
      }
      this.localStorage.registerUser(JSON.stringify(user));
      this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
      this.localStorage.save(Unicode.USERS, JSON.stringify(resp.users));
    }
    //SALES
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.SALES_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(Unicode.SALES, JSON.stringify(resp.sales));
    }
    //GROUPS
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.GROUPS_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(Unicode.GROUPS, JSON.stringify(resp.groups));
    }
    //SALES MANAGER
    var resp: any = await this.http
      .get(Endpoint.GLOBAL_URL + Endpoint.SALE_MANAGERS_FILE_NAME)
      .toPromise();
    if (resp && !resp.msg) {
      this.localStorage.save(
        Unicode.SALE_MANAGERS,
        JSON.stringify(resp.saleManagers)
      );
    }
    //GOOGLE INCENTIVES POLICY
    localStorage.removeItem(Unicode.GOOGLE_INCENTIVES_POLICY);
    await Utilities.getGoogleIncentivesPolicy(this.http, this.localStorage);

    this.router.navigate([menus[0].link]);
  }
}
