<div class="container-fluid h-100">
    <div class="cust_head" (click)="cancel()">
        <!-- <img src="../../assets/list-next-arrow-normal.png" class="ic_back_arrow">
        <label class="txt_back_head">Back</label> -->
        <a href="javascript:void(0)" (click)="cancel()">&#60; Back </a>
    </div>
    <div class="new_cust_txt">
        Choose whether to create a new customer or transfer an already exisying one
    </div>

    <div class="row h-100">
        <div id="div_new"
            [ngClass]="{'col-new-cust':model.id == null || model.id == '' ,'col-12':model.id !== null && model.id !== ''}">
            <div id="div_cust_form" (click)="eltClick(1)">
                <label class="form_title">Create a New Customer</label>
                <form (ngSubmit)="onSubmit()" id="customer-form" [formGroup]="custForm" role="form" #formDir="ngForm"
                    novalidate class="ng-untouched ng-pristine ng-invalid">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" formControlName="firstName" class="form-control"
                                    [(ngModel)]="model.first_name" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && firstName.invalid }" required>
                                <label for="label-name">First Name<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && firstName.invalid">
                                <p *ngIf="firstName.errors.required">First Name is required</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" formControlName="lastName" class="form-control"
                                    [(ngModel)]="model.last_name" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && lastName.invalid }" required>
                                <label for="label-name">Last Name<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && lastName.invalid">
                                <p *ngIf="lastName.errors.required">Last Name is required</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="last_name" class="form-control" formControlName="organisation"
                                    [(ngModel)]="model.organisation_name" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && organisation.invalid }" required>
                                <label for="label-name">Organisation Name<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && organisation.invalid">
                                <p *ngIf="organisation.errors.required">Organisation Name is required</p>
                            </div>
                        </div>
                        <!-- </div> -->

                        <!-- <div class="row m-t-15"> -->
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="email" class="form-control" formControlName="email"
                                    [(ngModel)]="model.email" placeholder=" " [readonly]="isReadOnly"
                                    [ngClass]="{ 'is-invalid': formDir.submitted && email.invalid }" required>
                                <label for="label-name">Email<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && email.invalid">
                                <p *ngIf="email.errors.required">Email is required</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="email" class="form-control" formControlName="alternateEmail"
                                    [(ngModel)]="model.alternate_email" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && alternateEmail.invalid }" required>
                                <label for="label-name">Alternate Email<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && alternateEmail.invalid">
                                <p *ngIf="alternateEmail.errors.required">Alternate Email is required</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type=" text" name="name" class="form-control" formControlName="password"
                                    placeholder="  " [(ngModel)]="model.password" [readonly]="isReadOnly">
                                <label for="label-name">Password</label>
                            </span>
                            <!-- <div class="invalid-feedback" *ngIf="formDir.submitted && password.invalid">
                            <p *ngIf="password.errors.required">Password is required</p>
                        </div> -->
                        </div>

                        <!-- </div> -->

                        <!-- <div class="row m-t-15"> -->
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" class="form-control" formControlName="domain"
                                    [(ngModel)]="model.domain" [readonly]="isReadOnly" placeholder=" "
                                    (change)="changeDomain($event);"
                                    [ngClass]="{ 'is-invalid': formDir.submitted && domain.invalid }" required>
                                <label for="label-name">Domain<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && domain.invalid">
                                <p *ngIf="domain.errors.required">Domain is required</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 inline-block"> -->
                            <span class="border-lable-flt col-12 p-0">
                                <ng-select bindLabel="reg.name" appendTo="body" [searchable]="true" [clearable]="true"
                                    name="region" class="form-control" formControlName="region" placeholder=" "
                                    style="padding: 0 !important;border:none !important;" [(ngModel)]="model.region"
                                    [ngClass]="{ 'is-invalid': formDir.submitted && region.invalid }" required>
                                    <ng-option [value]="reg" *ngFor="let reg of countries">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div title="{{item.name}}">{{item.name}}</div>
                                        </ng-template>
                                        {{reg.name}}
                                    </ng-option>
                                </ng-select>
                                <label for="label-name">Region<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && region.invalid">
                                <p *ngIf="region.errors.required">Region is required</p>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="last_name" class="form-control" formControlName="locality"
                                    [(ngModel)]="model.locality" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && locality.invalid }" required>
                                <label for="label-name">City<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && locality.invalid">
                                <p *ngIf="locality.errors.required">City is required</p>
                            </div>
                        </div>
                        <!-- </div> -->

                        <!-- <div class="row m-t-15"> -->
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" class="form-control" formControlName="address1"
                                    [(ngModel)]="model.address_line1" placeholder=" "
                                    [ngClass]="{ 'is-invalid': formDir.submitted && address1.invalid }" required>
                                <label for="label-name">Address1<span class="spn_required"> *</span></label>
                            </span>
                            <div class="invalid-feedback" *ngIf="formDir.submitted && address1.invalid">
                                <p *ngIf="address1.errors.required">Street Address is required</p>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" class="form-control" formControlName="address2"
                                    [(ngModel)]="model.address_line2" placeholder=" ">
                                <label for="label-name">Address2</label>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" class="form-control" formControlName="address3"
                                    [(ngModel)]="model.address_line3" placeholder=" ">
                                <label for="label-name">Address3</label>
                            </span>
                        </div>
                        <!-- </div>

                    <div class="row m-t-15"> -->
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="last_name" class="form-control" formControlName="phone"
                                    [(ngModel)]="model.phone" placeholder=" ">
                                <label for="label-name">Phone</label>
                            </span>
                        </div> 
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group">
                            <span class="border-lable-flt col-12 p-0">
                                <input type="text" name="name" class="form-control" formControlName="postalCode"
                                    [(ngModel)]="model.postal_code" placeholder=" ">
                                <label for="label-name">Postal Code</label>
                            </span>
                        </div>
                        <div class="col-lg-4 col-md-6 col-6 col-sm-12 m-t-15 input-group" *ngIf="canAssign">
                            <span class="border-lable-flt col-12 p-0">
                                <ng-select [multiple]="false" bindLabel="sale.name" appendTo="body" [searchable]="true"
                                    [clearable]="true" name="customerSales" class="form-control" placeholder=" "
                                    formControlName="customerSales" (change)="assignSales()"
                                    style="padding: 0 !important;border:none !important;"
                                    [(ngModel)]="model.customerSaleId">
                                    <ng-option [value]="sale.id" *ngFor="let sale of sales">
                                        {{sale.name}}
                                    </ng-option>
                                </ng-select>
                                <label for="label-name">Assigned Sale</label>
                            </span>
                        </div>
                        <!-- </div> -->
                        <div class="col-12 row col-12 div_btns justify-content-end m-0"
                            style="margin-left: -15px !important;">
                            <button class="m-0"
                                [ngClass]="{'btn_blue_bg':googleWorkspaceSelected,'btn_disabled':!googleWorkspaceSelected}">{{model.id
                                ?'Update' : 'Create'}}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div id="div_transf" (click)="eltClick(2)"
        *ngIf="model.id == null || model.id == ''" class="col-trans">
        <div class="tranf_form">
                <label class="form_title">Transfer Customer</label>
                <div class="col-12 input-group p-0 m-t-15">
                    <span class="border-lable-flt col-12 p-0">
                        <input type="text" class="col-12 form-control" [(ngModel)]="model.domain" placeholder=" " autofocus>
                        <label for="label-name">Domain<span class="spn_required"> *</span></label>
                    </span>
                </div>
                <div class="col-12 input-group p-0 m-t-15">
                    <span class="border-lable-flt col-12 p-0">
                        <input type="text" class="col-12 form-control" [(ngModel)]="model.auth_token" placeholder=" " autofocus>
                        <label for="label-name">Transfer token<span class="spn_required"> *</span></label>
                    </span>
                </div>
                <div class="col-lg-12 m-t-15 input-group p-0" *ngIf="canAssign">
                    <span class="border-lable-flt col-12 p-0">
                        <ng-select [multiple]="false" bindLabel="sale.name" appendTo="body" [searchable]="true"
                            [clearable]="true" name="customerSales" class="form-control" placeholder=" "
                            formControlName="customerSales"
                            style="padding: 0 !important;border:none !important;"
                            [(ngModel)]="model.customerSaleId">
                            <ng-option [value]="sale.id" *ngFor="let sale of sales">
                                {{sale.name}}
                            </ng-option>
                        </ng-select>
                        <label for="label-name">Assigned Sale</label>
                    </span>
                </div>
                <div class="col-12 row m-t-20 col-12 div_btns justify-content-end"
                    style="margin-right: -28px !important;">
                    <button class="m-0" (click)="onSubmit()"
                        [ngClass]="{'btn_blue_bg':!googleWorkspaceSelected,'btn_disabled':googleWorkspaceSelected}">Transfer</button>
                </div>
            </div>
        </div>

    </div>


</div>