export class Endpoint {
  /*** CREDENTIALS ***/
  // FOR THE RESELLER API
  public static SERVICE_ACCOUNT_CLIENT_EMAIL =
    'reseller-internal@poetic-freedom-315311.iam.gserviceaccount.com';
  public static PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDUJL85kDMCh7BR\nokyQOvvdwSL0k2l72lc5vxroJ53Xlo6ndUjGun/yr9/VtrYIn3/IMBS22tnwhT1V\n0WnyzJpYZgh8ATQNo1h+v2R1nQvaphThZ2Puz2K7irPIKBCJyF00Y4SSLaahLSJ/\ncHp2sU5bMTXrDAOnna9rom8iQKuvaFCnHuunE+1dSOps4zWWubfGrki82zNfjCO+\nbmFpN0jYlLXoYnkxJ4WySXE8qEI5ltE7gj6fbWyH2mpniquiKl7+x+uGpbOJJlMC\nSlCzotqPYY2axec1LL2TyrBweOrkvS5udimM5DuC6AhqhqO/AZv7MucTRqBc/kim\nsTEyMYZFAgMBAAECggEACfOeA32ajFArMEPUeEucDGoGpsHSMpzVIgCvVXFOUT0J\n0hyJXnEvQVuIvNtxAfSMJ6rsaIDZtRO7Ay9AsMLHrBqVOU6hJNFN0cb5V8nB3ZmU\nOdgCHcNywT4fcuXrk1sSgZD4OMHqzX1y3yw6l8jM1oWiHuBAZ6ODsCE9j2DIl6t6\nLEddaw6s2nOWtTQ9opzDM2RbHg3mrYp8410UJ+P9PHycUkzi7HrhdCA+ygMveutH\nwR3m0S/GE7t6jTPrtFj1foYxLmsvXZGcJWgsjp2KvfaVpuPkpTSnH0WD7DBFaIgM\nu1DoJZFZitRiHoapBfhmJJbOk+I78s5YMbiUtFbJoQKBgQD0SSUpHgPuMr9HJ0CX\nI46dkT/hJ576L7mOD7xnjnCzOzjxEH8FktcKTdcHvAe1CXHeX0zNa8b/ptjX0QQA\nrV4xpunaEQc1+H415I4CmoN2Ci+rJiSuZ8chkbhRiMSQgxgHgTv4XXTnOzu+Mz++\n+dDazqHuL4VDK4oaRj/mEAi6TQKBgQDeUQYjCbwYf1ph8zO+jIE/OSjqOjEWZgud\n4gxAxodtaT6AZxiA93UZPoA22k+nvhWSJorFkyOyc1lCl4qd8ZsWtYRT261/QD9i\nOpqHNhCKcwrkBqPeo+5sMIn1SatkuS3qRoz6xTljh/3QybY8SkbBl2fvFu00sVwr\n7b4r/0GH2QKBgQCYqFkjn9E++tGT6pCvopXOG+X6k2MC3+4qO+U2JcqCBwFnjDKE\nziW6F7jH6ZY7aSKSn6WEPdUHEEI0ZDcHIVBa4P8Eul1yOc7NaP3h3KNIkccGQnjr\n0Iidjs3LjhegY3wRvI44gFa85cRI8QfmFYGSK00AZwv1VPEhyELIR77chQKBgEBu\nlyq8fYlUD6vnwo1tR2A9fsAz0Vo+hQtjIk37u/BX2kd4mb92+LcySBKdvwxMqQ/M\nn9eXR3dGuw3zwbb4+Qvh29I6DtScmEIt3e9mGfEmWnev/Ppf5I/ymWk/yq7MhKQr\nglyDoJhaJ5PFgk2C8XLWIMdIi8MDzETnCIDT3/gxAoGActCFCDriLBXw58Ykl97H\nXpiSVpRQjF+ay/MR9yTRHI/O+S8fGJ3KvJtiEOkUhgg3qUrjdxRoFoepyYHpU5ql\nFS/2tY9gChLTbaOMato4IDyk0kvF80nisnPx7++JD4ddrYZiCabP/0giSLfk8qLo\nLill5dJ/TRE6O4Vuzc/8NrY=\n-----END PRIVATE KEY-----\n';
  public static ADMIN_EMAIL = 'admin@premier.isolutions-sa.com';
  public static RSELLER_ACCOUNTS = 'accounts/C043009as';
  public static DEFAULT_ALTERNATE_EMAIL = 'ahmedyeid@gmail.com';
  public static PROJECT_ID = 'poetic-freedom-315311';
  public static LOG_NAME = 'reseller-app-errors';

  // FOR OUR REQUEST/EMAIL
  public static SCOPES = ['https://mail.google.com/'].join(' ');
  public static DOMAIN = 'ismena.com';
  public static CLIENT_ID =
    '404204591697-r7v5qd7c23vtmhn7k1bb9e5sis7de50u.apps.googleusercontent.com';
  public static OUR_SERVICE_ACCOUNT_CLIENT_EMAIL =
    'reseller-test-sa@reseller-app-authentication.iam.gserviceaccount.com';
  public static OUR_PRIVATE_KEY =
    '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDA9dPn6I3Za+ke\nlXXWICyC4dPyITEAgoPpn9Jb6xQoi+/LO591VCi9voqeOeKBawdTyv99AHVquilH\n29jsTQEPXFYcg9/7cd75sr8UcCqUWAJJBlMSNwBse7nuJBsUECSPJWxJ9HU3hSbl\noZnrhavBQa9a2dfiI2lhKxbIZSEMXzJxmiGoaLvkGA4h2RtQRSwqIyNdbCM2NG2C\nhV/3HGJGF6JtqFnFkwK5H0u/pckXEJbU2PMEAhoRS3D+oBc0+4QeqNov4juoEegV\nHRCnCqylT8VFnd/6AXt3sAh10XK1JGg+22YIUoE9DTcG2ZkSH/jy2WlLc6x3+Aup\nsYrFX66RAgMBAAECggEAPwnwtUJl2SKmcb3tVcuEDyw85E0Qp7R76P60KCBoIQj6\niaBMa3XXq4mM2r1ZcONo7OiUSB+2vGveIaJCv4w2rPCDF2TD728Xo+bQAxrT5kvM\nT1QYHorGgip3p0O3bvoJH5TfyFdrVfyTpRCC6w1r+8J8SceCflgITNzZYBWd0t7T\nkgFznJz6doDMbMmNKdB1bSzkJf7s3+/F323N51b5M6mROxtnUjqmuiCQfEGpHnN7\nQxfev6+mhn316BUDnjQwnwfe9dP6x4dIT0rrGY1vHLknBzUncnBBHnIkqmr0EIQc\nu5yTnWHKh0yyMK1Tw49sXmfpZ1+IsTYY4plNoLSTuwKBgQDnfkc47gdyJhcJwBjr\nZvkA6c/PIoY6ItAo7eEsquS5kUxxtU0cW3vgsw1hoOExAboFkbFYFLDqrWp3YAgI\nf+xwp2HhzwOOWKyChSQUB6MxGCfk0eFtloHpDd2FHDmXCrk5hByJKhrmOadDoQDB\n495jBgwt//JIEC/D9QtHZmZkVwKBgQDVY0NAFVJuTmDsTk8wwwjTXhgRR5PMjHCm\nwU3BTONydnpXDSh132Lr2a7bhGSBUP064sge2NVrE5hqsCEmsiTMLVlGr88bpyVb\nuiEe9PLJ64b4xhePt6vjkUIui8Y1aE7FM9b+qIbL0ExjKkKnC03rfVNXPxVHSzPG\nrxryRjPzVwKBgELGEhVB07QBeLO/TXDZiwnQZ7j/5Gfwksq7kzAnO0Krvrkt6sg7\nO0hdXYbDuTFBpj7k/5rAnSeUThmRAcAx5im4nmtcI1J0EY60iOueKFyI8zj1sWjC\n+UYYUcWLlrESzsrgaLrIAmpWRwEi9+z1pbg6jljWczSGNsD3dgFqcC6RAoGAP4IQ\ndNUjuGKpZAsWdIvHZ6j8fsAP0/QEXwzvCFXK57ktRhVLcnFuroUWDo1DoF0rUMhI\nCS49rmWhmjcbVrn0S7Q/etM+T5uaecdWrjD2xXPMsuO6BXMDvHHyAYpmDmUh9H0u\nQpKDs8slwOnF9pNduHWPRUsoGRTfrwVUfsC4G3cCgYEApziP9V0DAImnFTOWDwfN\nlHwKHiXtLFJo3GUjVSV08vYGoa/sQ0IXu093uCm5Vjo20SJE8yy/2EfZXwCWvMJZ\nr9vRjDTH7a77msGrkNVVd7em9bFaCyh8PlhfA5X4LUO9v4C7gNGkkCnorU7pj7DL\nL9X0RhuT/k7YUTCCgtyv0dE=\n-----END PRIVATE KEY-----\n';

  /** BACKEND/PHP **/
  public static GLOBAL_URL =
    'https://reseller-portal-be.ismena.com/reseller_app_services/';
  public static URL_EMAIL = 'https://reseller-portal.ismena.com/';

  public static TEST = 'test.php';
  public static LOGIN_FILE_NAME = 'login.php';
  public static MENU_FILE_NAME = 'menu/MenuController.php';
  public static READ_CUSTOMER_FILE_NAME = 'customer/CustomerReadController.php';
  public static GET_CUSTOMER_BY_ID_FILE_NAME =
    'customer/GetCustomerByIdController.php';
  public static UPDATE_CUSTOMER_FILE_NAME =
    'customer/CustomerUpdateController.php';
  public static DELETE_CUSTOMER_FILE_NAME =
    'customer/CustomerDeleteController.php';
  public static FLEX_CUSTOMER_FILE_NAME = 'customer/FlexCustomerController.php';
  public static IMPORT_CUSTOMERS_FILE_NAME =
    'customer/CustomersImportController.php';
  public static READ_SALE_FILE_NAME = 'sales/SalesReadController.php';
  public static UPDATE_SALE_FILE_NAME = 'sales/SalesUpdateController.php';
  public static DELETE_SALE_FILE_NAME = 'sales/SalesDeleteController.php';
  public static SALES_CUSTOMERS_FILE_NAME =
    'sales/SalesCustomersController.php';
  public static GLOBAL_DATA_FILE_NAME = 'core/GetGlobalData.php';
  public static SKUS_PRICES_FILE_NAME = 'core/PriceList.php';
  public static NEW_ORDER_FILE_NAME = 'order/NewOrderController.php';
  public static READ_ORDERS_FILE_NAME = 'order/OrderReadController.php';
  public static DELETE_ORDER_FILE_NAME = 'order/OrderDeleteController.php';
  public static UPDATE_ORDER_FILE_NAME = 'order/UpdateOrderController.php';
  public static ORDER_CUSTOMER_FILE_NAME = 'order/OrderCustomerController.php';
  public static CONFIRM_CUSTOMER_FILE_NAME =
    'order/ConfirmCustomerController.php';
  public static UPDATE_EXPORTED_ORDERS_FILE_NAME =
    'order/UpdateExportedOrderController.php';
  public static UPDATE_CHECKED_ORDERS_FILE_NAME =
    'order/UpdateCheckedOrderController.php';
  public static EXECUTE_ORDERS_FILE_NAME = 'order/ExecuteOrderController.php';
  public static TRANSFERED_ORDERS_FILE_NAME =
    'order/OrderTransferController.php';
  public static GROUPS_FILE_NAME = 'core/Groups.php';
  public static GROUPS_PERMISSIONS_FILE_NAME = 'core/GroupPermissions.php';
  public static SALE_MANAGERS_FILE_NAME = 'core/SaleManagers.php';
  public static SALES_FILE_NAME = 'core/Sales.php';
  public static SETTINGS_HELP_PAGE_FILE_NAME = 'core/HelpPageContent.php';
  public static GOOGLE_INCENTIVES_POLICY_FILE_NAME =
    'core/GooglePoliciesController.php';
  public static DASHBOARD_FILE_NAME = 'dashboard/ReadController.php';
  public static CUSTOMER_DASHBOARD_FILE_NAME =
    'dashboard/CustomerController.php';
  public static REPORTS_FILE_NAME = 'dashboard/ReportController.php';
  public static SKU_SUMMARY_FILE_NAME = 'dashboard/SkuSummaryController.php';
  public static CURRENCY_FILE_NAME = 'settings/CurrencyController.php';
  public static EXCHANGE_RATE_FILE_NAME = 'settings/ExchangeRateController.php';
  public static GRPS_FILE_NAME = 'settings/GroupController.php';
  public static USR_GRP_FILE_NAME = 'settings/UserGroupController.php';
  public static PERMS_FILE_NAME = 'settings/PermissionController.php';

  public static KJUR_P_HEDAER = { alg: 'RS256', typ: 'JWT' };

  public static AES_KEY = 'S123Rz343I';
}
export class Unicode {
  public static ITEMS_PER_PAGE = 20;
  public static COST_MARGIN = 0.8;
  public static CURRENT_USR = 'Logged in user';
  public static EMAIL = 'email';
  public static ROLE_ID = 'roleId';
  public static GROUP_ID = 'groupId';
  public static CUSTOMER_ID = 'customerId';
  public static OLD_CUSTOMER_ID = 'oldcustomerId';
  public static NEW_CUSTOMER_ID = 'newcustomerId';
  public static USER_ID = 'userId';
  public static ORDER_ID = 'orderId';
  public static ORDER_IDS = 'orderIds';
  public static USR_GRP_ID = 'userGroupId';
  public static PERMISSION_ID = 'permissionId';
  public static ORDER_STATUS = 'orderStatus';
  public static PRICES = 'prices';
  public static WITH_SALES = 'withSales';
  public static MENUS = 'menus';
  public static PAGE = 'page';
  public static LIMIT = 'limit';
  public static TO_DATE = 'to';
  public static FROM_DATE = 'from';
  public static ONLINE = 'ONLINE';
  public static ONLY_NOT_CHECKED_ORDERS = 'Not_Checked';
  public static CUSTOMER_FILTER = 'customerFilter';
  public static SALES_FILTER = 'salesFilter';
  public static IS_ADD_OPERATION = 'isAdd';
  public static IS_ASSIGN_OPERATION = 'assign';
  public static SALE_ROLE_CODE = 'SALE';
  public static CUSTOMER_TYPE = 'type';
  public static OFFLINE_BILLING_METHOD = 'offline';
  public static TRANSFER_CUSTOMER_TYPE = 'transfer';
  public static ACTION_TYPE = 'actionType';
  public static COUNTRIES = 'countries';
  public static CURRENCIES = 'currencies';
  public static GOOGLE_PRODUCTS = 'google products';
  public static PLANS = 'plans';
  public static GROUP_PERMISSIONS = 'groups permissions';
  public static PERMISSIONS = 'permissions';
  public static GPM_EMAILS = 'gpm emails';
  public static NEW_SUBSC = 'new subscription object'; //NEW OR CURRENT/EDIT
  public static CURRENT_CUSTOMER = 'current customer';
  public static CURRENT_GRP = 'current group';
  public static FLEXIBLE_PLAN = 'FLEXIBLE';
  public static TRIAL_PLAN = 'TRIAL';
  public static PENDING_TOS_ACCEPTANCE = 'PENDING_TOS_ACCEPTANCE';
  public static RENEWAL_WITH_TYPE_CANCEL = 'RENEWAL_WITH_TYPE_CANCEL';
  public static RESELLER_INITIATED = 'RESELLER_INITIATED'; //A manual suspension invoked by a Reseller.
  public static TRIAL_ENDED = 'TRIAL_ENDED';
  public static OTHER = 'OTHER';
  public static DOMAINS = 'domains';
  public static FROM_SUBS_PAGE = 'from edit subscriptin page';
  public static IS_DOWNGRADE = 'is downgrade action';
  public static ORDER = 'order object';
  public static ACTION = 'action';
  public static GET_ALL = 'getAll';
  public static ADD = 'add';
  public static EDIT = 'edit';
  public static GET = 'get';
  public static POLICIES = 'policies';
  public static ADD_GRP = 'addGrp';
  public static ADD_USR_GRP = 'addUsrGrp';
  public static GOOGLE_INCENTIVES_POLICY = 'google incentives policy';
  public static ORDER_POLICIES = 'order_policies';
  public static CHANGE_STATUS_ACTION = 'changeStatus';
  public static DELETE_ORDER_ACTION = 'delete';
  public static WITHDRAW_ORDER_ACTION = 'withdraw';
  public static UPDATE_ORDER_ACTION = 'update';
  public static STATUS_PARAM = 'status';
  public static TRIAL_EXPIRY_DATE = 'trialExpDate';
  public static COMMITMENT_EXPIRY_DATE = 'commitmentExpDate';
  public static APPROVAL1_NAME = 'approval1Name';
  public static APPROVAL2_NAME = 'approval2Name';
  public static APPROVAL1_EMAIL = 'approval1Email';
  public static APPROVAL2_EMAIL = 'approval2Email';
  public static GPM_APPROVAL_EMAIL = 'gpmEmail';
  public static LAST_APPROVAL_EMAIL = 'lastEmail';
  public static STARTDATE = 'startDate';
  public static ENDDATE = 'endDate';
  public static WITH_GOOGLE_OFFER = 'withGoogleOffer';
  public static GOOGLE_OFFER_PERCENTAFE = 'googleOfferPercentage';
  public static WITH_CUSTOMER_OFFER = 'withCustomerOffer';
  public static CUSTOMER_OFFER_PERCENTAFE = 'customerOfferPercentage';
  public static NOTES = 'notes';
  public static DESCRIPTION = 'description';
  public static iS_NORMAL_EXECUTION_TO_RETURN_FILE_CONTENT =
    'isNormalExecution';
  public static HAS_INVOICE_FILE = 'hasInvoiceFile';
  public static iS_RENEWAL_EXECUTION = 'isRenewalExecution';
  public static REJECT_REASON = 'rejectReason';
  public static REJECTER_EMAIL = 'rejecterEmail';
  public static DELETE_FILE = 'deleteFile';
  public static FILE_ID = 'fileID';
  public static GROUPS = 'groups';
  public static GROUP_MEMBERS = 'groupMembers';
  public static SALE_MANAGERS = 'sale manageres';
  public static MANAGERS = 'managers';
  public static SALES = 'sales';
  public static ORDER_CHANGE_PLAN = 'change plan';
  public static GOOGLE_DRIVE_SKU_ID = 'Google-Drive';
  public static Google_Vault_SKU_ID = 'Google-Vault';
  public static CLOUD_IDENTITY_SKU_ID = 'Cloud Identity';
  public static DEFAULT_CURRENCY_ID = 'default currency ID';
  public static DEFAULT_SKU_ID = '1010020027'; //Google Workspace Business Starter
  public static DEFAULT_LICNECES = 20;
  public static IS_TRANSFER_ACTION = 'is transfer action';
  public static MUST_TRANSFER_ALL = 'must tranfser all sub/batch';
  public static TRANSFERABLE_SUBSCRIPTIONS = 'transferable subcs';
  public static DEFAULT_RENEWEL_TYPE = 'SWITCH_TO_PAY_AS_YOU_GO';
  public static CUSTOMER_PAGE_ID = 'cust_content';
  public static USER_PAGE_ID = 'usr_content';
  public static ORDER_PAGE_ID = 'order_content';
  public static ORSERS_REPORT_PAGE_ID = 'orders_report_content';
  public static CUSTOMERS_REPORT_PAGE_ID = 'customers_report_content';
  public static USERS = 'all users';
  public static CURRENT_CUSTOMER_SUBSCS = 'current customer subscriptions';
  public static ONLY_REWEWAL = 'renewal';
  public static WITH_SALES_CUSTOMERS = 'withSales';
  public static SPECIFIC_DAY = 'day';
  public static ORDERS = 'orders';
  public static WITHOUT_LOADER = 'withoutLoader';
  public static TRANSFER_RELATION_KEY = 'transferRelationKey';
  public static WITH_SKU_SUMMARY = 'withSkuSummary';
  public static WITH_CUSTOMER_SUMMARY = 'withCustSummary';
}
export enum Group {
  Admin = 'ADM',
  Sale = 'sales',
  Manager = 'manager',
  Accounting = 'accounting',
  GPM = 'gpm', //GOOGLE PRODUCT MANAGER
  SUSPENSION = 'suspension',
}
export enum Permissions {
  sales_manager_approve1 = 'APP1',
  sales_manager_approve2 = 'APP2',
  FINAL_EXECUTION = 'EXC',
  receive_email_on_execution = 'EMAIL_ON_EXC',
  receive_email_after_creation = 'EMAIL_AFTER_CREATION',
  can_view_all = 'VIEW_ALL',
}

export enum Status {
  Active = 'ACTIVE',
  Suspended = 'SUSPENDED',
  Canceled = 'CANCELED',
}
export enum OrderStatus {
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Deleted = 'DELETED',
  Approved1 = 'APPROVED1', // LEVEL1
  Approved2 = 'APPROVED2', // LEVEL2
  Approved_by_GPM = 'GPM APPROVED', // LEVEL2
  Executed = 'EXECUTED', // DAWAM/FINANCE MANG CONFIRM IT
  NOT_Executed = 'NOT EXECUTED', // EXUTED WITH ORDER (RENEWAL AUTOMATICALY EXECUTION CASE)
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Withdraw = 'WITHDRAW',
  CONFIRMED = 'CONFIRMED', //RENEWAL ORDER CONFIRMED BY EXECUTER
}
export enum DeletionType {
  DELETION_TYPE_UNDEFINED = 'DELETION_TYPE_UNDEFINED',
  CANCEL = 'CANCEL',
  TRANSFER_TO_DIRECT = 'TRANSFER_TO_DIRECT',
}
export enum PlanValues {
  ANNUAL = 'ANNUAL',
  ANNUAL_MONTHLY_PAY = 'ANNUAL_MONTHLY_PAY',
  ANNUAL_YEARLY_PAY = 'ANNUAL_YEARLY_PAY',
  FLEXIBLE = 'FLEXIBLE',
  TRIAL = 'TRIAL',
  FREE = 'FREE',
}
