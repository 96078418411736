import { RequestResultModel } from './../../../../../../src/models/request_result.model';
import { HttpRequestService } from './../../../../../../src/servcies/http-request-service';
import { ConfirmDialogService } from 'src/servcies/confirm-dialog.service';
import { LocalStorageService } from 'src/servcies/localstorage-service';
import { Group, Unicode } from './../../../../../../src/shared/Endpoint';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SubscriptionModel,
  Seats,
  RenewalSettings,
} from 'src/models/subcription.model';
import { Endpoint, PlanValues, Status } from 'src/shared/Endpoint';
import { Utilities } from 'src/shared/utilities';
import { CustomerModel } from 'src/models/customer.model';
import { ModalDirective } from 'ngx-bootstrap/modal/ngx-bootstrap-modal';
import { DatePipe } from '@angular/common';
import { UserViewModel } from 'src/models/vw.user';
import { OrderModel } from 'src/models/order.model';
import { UserRoleDataModel } from 'src/models/user.role.data';
import { CurrencyModel } from 'src/models/currency.model';

@Component({
  selector: 'lib-subscription-list',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
})
export class SubscriptionListComponent implements OnInit {
  cusomerId: string;
  token;
  allSubcs: SubscriptionModel[] = [];
  subdata: SubscriptionModel[] = [];
  currentCustomer: CustomerModel;
  globalSubsInfo = [];
  @ViewChild('licences1Modal')
  licences1Modal: ModalDirective;
  @ViewChild('licences2Modal')
  licences2Modal: ModalDirective;
  @ViewChild('flexibleLicencesModal')
  flexibleLicencesModal: ModalDirective;
  @ViewChild('renewalModal')
  renewalModal: ModalDirective;
  changeLicencesSubId: string;
  changeLicencesProductName: string;
  changeLicencesOldLic: number;
  changeLicencesAdditionalLic: number = null;
  startDate: string;
  endDate: string;
  licensesSuffixText: string;
  updatedSubsc = new SubscriptionModel();
  currentUser: UserViewModel;
  plans: any[];
  totalPrice: string = '50000';
  pageTitle: string = '';
  currentUserRoleData: UserRoleDataModel;
  receiveEmailOnExecGrp: string[] = [];
  receiveEmailAfterCreationGrp: string[] = [];
  gpmEmails = [];
  executorEmails = [];
  currencies: CurrencyModel[] = [];
  currencyRfId: string;
  uploadedFile;
  uploadText: string = 'Upload customer invoice';
  defaultCurrencyID: number;
  prices = [];
  updateLicenceSubModel = {
    changeLicencesPO: '',
    orderSaleInvoiceNbr: '',
    customerEmail: '',
    googleOfferPercentage: null,
    withGoogleOffer: false,
    customerOfferPercentage: null,
    withCustomerOffer: false,
    sellingPrice: undefined,
    costPrice: undefined,
    startDate: undefined,
    endDate: undefined,
    margin: undefined,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private httpRequestService: HttpRequestService,
    private localStorage: LocalStorageService,
    private datePipe: DatePipe,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.localStorage.remove(Unicode.NEW_SUBSC);
    this.localStorage.remove(Unicode.ORDER_CHANGE_PLAN);
    // this.localStorage.remove(Unicode.IS_DOWNGRADE);
    this.currentUser = JSON.parse(this.localStorage.getLoggedinUser());
    this.currentUserRoleData = Utilities.setOrderStatusEmailReceived(
      this.localStorage,
      this.currentUser
    );
    this.plans = JSON.parse(this.localStorage.get(Unicode.PLANS));
    this.defaultCurrencyID = Number.parseInt(
      localStorage.get(Unicode.DEFAULT_CURRENCY_ID) ?? '1'
    );
    this.currencyRfId = this.defaultCurrencyID + '';
  }

  async ngOnInit(): Promise<void> {
    this.gpmEmails = await Utilities.getGPMemails(this.http, this.localStorage);
    var gpPerms: any = this.localStorage.get(Unicode.GROUP_PERMISSIONS);
    gpPerms = gpPerms ? JSON.parse(gpPerms) : [];
    this.currencies = await Utilities.getCurrencies(
      this.http,
      this.localStorage
    );
    this.receiveEmailOnExecGrp = Utilities.receiveEmailOnExecGrp(
      this.localStorage
    );
    this.receiveEmailAfterCreationGrp = Utilities.receiveEmailAfterCreationGrp(
      this.localStorage
    );
    this.localStorage.save(Unicode.PRICES, undefined); //TO BE REMOVED
    this.prices = await Utilities.getPrices(this.http, this.localStorage);
    this.executorEmails = Utilities.executorsGrp(this.localStorage);
    var menus = JSON.parse(this.localStorage.get(Unicode.MENUS));
    var mnu = menus.find(
      (x) =>
        x.link.toLowerCase().trim() ==
        document.location.pathname.toLowerCase().trim()
    );
    if (mnu) this.pageTitle = mnu.name;
    else this.pageTitle = 'Subscriptions';
    this.route.queryParams.subscribe((params) => {
      var custId = params['customerId'];
      this.cusomerId = JSON.parse(custId);
    });
    this.currentCustomer = JSON.parse(
      this.localStorage.get(Unicode.CURRENT_CUSTOMER)
    );
    this.updateLicenceSubModel.customerEmail = this.currentCustomer.email;

    /** GET SUBSC. BY CUSTOMER ID **/
    await Utilities.getToken(
      this.http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      this.token = token;
    });
    var headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });
    if (
      !Utilities.isNullOrEmpty(this.currentCustomer.auth_token) &&
      this.currentCustomer.status == 1
    ) {
      var url =
        'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?customerAuthToken=' +
        this.currentCustomer.auth_token +
        '&customerId=' +
        this.cusomerId;
      var result: RequestResultModel = await this.httpRequestService.get(
        url,
        headers
      );
      if (result.success) this.allSubcs.push(...result.data.subscriptions);
      else console.log(result.errorMessage);
    } else if (this.currentCustomer.status == 1) {
      var url =
        'https://reseller.googleapis.com/apps/reseller/v1/subscriptions?customerId=' +
        this.cusomerId;
      var result: RequestResultModel = await this.httpRequestService.get(
        url,
        headers
      );
      if (result.success) this.allSubcs.push(...result.data.subscriptions);
      else console.log(result.errorMessage);
    }
    this.allSubcs.forEach((subs) => {
      if (!subs.seats) {
        subs.seats = new Seats();
        subs.seats.numberOfSeats = 0;
      }
      if (
        subs.billingMethod
          .toLowerCase()
          .includes(Unicode.OFFLINE_BILLING_METHOD)
      ) {
        if (
          subs.plan.planName
            .toLowerCase()
            .includes(PlanValues.ANNUAL.toLowerCase())
        )
          subs.displayedPlanName = 'Commitment Offline Plan';
        else subs.displayedPlanName = subs.plan.planName + '/Offline';
      } else {
        if (subs.plan.planName.toUpperCase() == PlanValues.ANNUAL)
          subs.displayedPlanName = PlanValues.ANNUAL;
        else subs.displayedPlanName = subs.plan.planName;
      }
      //
      var licences = 0;
      if (
        subs.plan.planName == PlanValues.FLEXIBLE ||
        subs.plan.planName == PlanValues.TRIAL
      ) {
        licences = subs.seats.maximumNumberOfSeats;
        if (subs.plan.planName == PlanValues.FLEXIBLE) subs.isFlexible = true;
      } else {
        licences = subs.seats ? subs.seats.numberOfSeats : 0;
      }
      this.globalSubsInfo.push({
        sku: subs.skuName,
        licences: licences,
        status: subs.status,
      });
      //fileds
      //if (subs.suspensionReasons && subs.suspensionReasons.find(x => x == Unicode.RESELLER_INITIATED))
      if (
        subs.plan.planName == Unicode.FLEXIBLE_PLAN ||
        subs.plan.planName == Unicode.TRIAL_PLAN
      ) {
        subs.seatsNbr = subs.seats.maximumNumberOfSeats;
        if (subs.plan.planName == Unicode.FLEXIBLE_PLAN) {
          subs.licensesSuffixText = ' /month';
        } else subs.licensesSuffixText = '';
      } else {
        subs.seatsNbr = subs.seats ? subs.seats.numberOfSeats : 0;
        if (subs.plan.planName.toUpperCase() == PlanValues.ANNUAL_YEARLY_PAY)
          subs.licensesSuffixText = ' /year';
        else subs.licensesSuffixText = ' /month';
      }
      if (subs.status == Status.Active) {
        subs.txtStatusEvent = 'Suspend';
        subs.canActivate = true;
      } else if (subs.status == Status.Suspended) {
        subs.txtStatusEvent = 'Activate';
        if (
          subs.suspensionReasons &&
          subs.suspensionReasons.find((x) => x == Unicode.RESELLER_INITIATED)
        )
          subs.canActivate = true;
        else subs.canActivate = false;
      }
    });
    this.localStorage.save(
      Unicode.CURRENT_CUSTOMER_SUBSCS,
      JSON.stringify(this.allSubcs.map((x) => x.skuId.toLowerCase().trim()))
    );
  }

  async uploadDocument(fileInput) {
    let fileReader = new FileReader();
    var file = fileInput.target.files[0];
    fileReader.readAsDataURL(file);
    fileReader.onload = async (e) => {
      this.uploadedFile = file;
      this.uploadText = file.name;
    };
  }

  createNewOrder() {
    var currentSubc = new SubscriptionModel();
    this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(currentSubc));
    this.router.navigate(['customers/new-subscription']);
  }

  // editSubsc(id) {
  //   var subsc = this.subdata.find((x) => x.subscriptionId == id);
  //   this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(subsc));
  //   this.router.navigate([
  //     '/customers/customer-subscription-list/subscription-details',
  //   ]);
  // }

  changePlan(subsId: string) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    this.updatedSubsc.oldPlanId = this.plans.find(
      (x) => x.code == this.updatedSubsc.plan.planName
    ).id;
    this.localStorage.save(
      Unicode.NEW_SUBSC,
      JSON.stringify(this.updatedSubsc)
    );
    this.localStorage.save(Unicode.ORDER_CHANGE_PLAN, '1');
    this.router.navigate(['customers/new-subscription/plans']);
  }

  changeLicences(subsId: string) {
    this.resetPopups();
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    if (
      subs.plan.commitmentInterval &&
      subs.plan.commitmentInterval.startTime
    ) {
      this.startDate = this.datePipe.transform(
        subs.plan.commitmentInterval.startTime,
        'yyyy-MM-dd hh:mm a zzzz'
      );
      this.endDate = this.datePipe.transform(
        subs.plan.commitmentInterval.endTime,
        'yyyy-MM-dd hh:mm a zzzz'
      );
    }
    this.changeLicencesSubId = subsId;
    this.changeLicencesProductName = subs.skuName;
    this.changeLicencesOldLic = subs.seatsNbr;
    this.updateLicenceSubModel.changeLicencesPO = '';
    this.licensesSuffixText = subs.licensesSuffixText ?? '';
    if (this.updatedSubsc.plan.planName.toUpperCase() == PlanValues.FLEXIBLE) {
      this.updateLicenceSubModel = {
        changeLicencesPO: '',
        orderSaleInvoiceNbr: '',
        customerEmail: '',
        googleOfferPercentage: null,
        withGoogleOffer: false,
        customerOfferPercentage: null,
        withCustomerOffer: false,
        sellingPrice: undefined,
        costPrice: undefined,
        startDate: Utilities.setDatePickerDate(this.datePipe, new Date()),
        endDate: undefined,
        margin: undefined,
      };
      this.calculatePrices(false);
      this.flexibleLicencesModal.show();
    } else this.licences1Modal.show();
  }

  continueLicence1() {
    this.updateLicenceSubModel = {
      changeLicencesPO: '',
      orderSaleInvoiceNbr: '',
      customerEmail: '',
      googleOfferPercentage: null,
      withGoogleOffer: false,
      customerOfferPercentage: null,
      withCustomerOffer: false,
      sellingPrice: undefined,
      costPrice: undefined,
      startDate: Utilities.setDatePickerDate(this.datePipe, new Date()),
      endDate: undefined,
      margin: undefined,
    };
    this.updateLicenceSubModel.endDate =
      this.updatedSubsc.plan.commitmentInterval &&
      this.updatedSubsc.plan.commitmentInterval.endTime
        ? Utilities.setDatePickerDate(
            this.datePipe,
            this.updatedSubsc.plan.commitmentInterval.endTime
          )
        : null;
    this.calculatePrices(false);
    if (
      !Utilities.isNullOrEmpty(this.changeLicencesAdditionalLic) &&
      this.changeLicencesAdditionalLic !== 0
    ) {
      this.licences1Modal.hide();
      this.licences2Modal.show();
    }
  }

  BackLic2() {
    this.licences2Modal.hide();
    this.licences1Modal.show();
  }

  async confirmLic2() {
    var validated = this.checkPriceFileds();
    if (validated) {
      var newLic = this.changeLicencesOldLic + this.changeLicencesAdditionalLic;
      if (this.currentUserRoleData.toExecute) {
        this.licences2Modal.hide();
        var headers;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        var body = {};
        var plan_name = this.updatedSubsc.plan.planName;
        if (
          plan_name == Unicode.FLEXIBLE_PLAN ||
          plan_name == Unicode.TRIAL_PLAN
        ) {
          body = { maximumNumberOfSeats: newLic };
        } else {
          body = { numberOfSeats: newLic };
        }
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          this.currentCustomer.id +
          '/subscriptions/' +
          this.changeLicencesSubId +
          '/changeSeats';
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          body,
          headers
        );
        if (result.success) {
          console.log(result.data);
          if (
            plan_name == Unicode.FLEXIBLE_PLAN ||
            plan_name == Unicode.TRIAL_PLAN
          )
            this.updatedSubsc.seats.maximumNumberOfSeats = newLic;
          else this.updatedSubsc.seats.numberOfSeats = newLic;
          if (result.data && result.data.trialSettings && result.data.plan) {
            this.updatedSubsc.trialSettings = result.data.trialSettings;
            this.updatedSubsc.plan = result.data.plan;
          }
          this.updatedSubsc.seatsNbr = newLic;
          this.licences2Modal.hide();

          var order = await this.saveOrder(true, false, false, false, false);
          order.orderEmailSubjectAction = 'New Order- Update licenses';
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order?.id;
          var htmlBody =
            '<html><div>' +
            this.currentUser.user_name +
            ' created and order : updated licenses for ' +
            this.currentCustomer.domain +
            '</div>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receiversOnExec = [];
          receiversOnExec.push(...this.receiveEmailOnExecGrp);
          receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receiversOnExec,
            htmlBody,
            order.orderEmailSubjectAction
          );

          if (
            !Utilities.isNullOrEmpty(this.updateLicenceSubModel.customerEmail)
          ) {
            var customerEmailBody = '';
            var cc = this.currentUser.manager_email;
            if (!Utilities.isNullOrEmpty(this.uploadedFile)) {
              customerEmailBody = Utilities.generateCustomerEmailBody(
                this.currentCustomer.domain,
                this.currentUser.user_email
              );
              var fileName = this.uploadedFile.name ?? '';
              let fileReader = new FileReader();
              fileReader.readAsDataURL(this.uploadedFile);
              fileReader.onload = async (e) => {
                var tragRes: any = e.target.result;
                var fileData = tragRes.split('base64,')[1];
                Utilities.sendMailWithFile(
                  this.http,
                  fileData,
                  this.currentUser.user_email,
                  [this.updateLicenceSubModel.customerEmail],
                  [cc],
                  'Invoice',
                  customerEmailBody,
                  fileName
                );
              };
            } else {
              customerEmailBody =
                Utilities.generateCustomerEmailBodyWithoutFile(
                  this.currentCustomer.domain,
                  this.currentUser.user_email
                );
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                [this.updateLicenceSubModel.customerEmail],
                customerEmailBody,
                'Invoice',
                [cc]
              );
            }
          }
        } else {
          console.log(result.errorMessage);
          alert(result.errorMessage);
        }
      } else {
        var order = await this.saveOrder(true, false, false, false, false);
        order.orderEmailSubjectAction = 'New Order- Update licenses';
        if (order) {
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order.id;
          var bodyText =
            "<html><h4 style='text-transform: capitalize;'>" +
            this.currentUser.user_name +
            ' updated the licences' +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Old Licences</th><th style='border: 1px solid;border-collapse: collapse;'>New Licences</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.changeLicencesOldLic +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            newLic +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentCustomer.domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receivers = this.currentUserRoleData.receiverEmail;
          if (
            this.gpmEmails.filter((o1) => receivers.some((o2) => o1 === o2))
              .length > 0
          )
            receivers.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receivers,
            bodyText,
            order.orderEmailSubjectAction
          );
          this.licences2Modal.hide();
          alert(
            'Order sent, The licences will be updated after the order is confirmed'
          );
        }
      }
    }
  }

  async confirmFlexibleLicenses() {
    var validated = this.checkPriceFileds();
    if (validated) {
      if (this.currentUserRoleData.toExecute) {
        var headers;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        var newLicences = this.changeLicencesAdditionalLic;
        var body = { maximumNumberOfSeats: newLicences };
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          this.currentCustomer.id +
          '/subscriptions/' +
          this.changeLicencesSubId +
          '/changeSeats';
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          body,
          headers
        );
        if (result.success) {
          console.log(result.data);
          this.updatedSubsc.seats.maximumNumberOfSeats = newLicences;
          this.updatedSubsc.seatsNbr = newLicences;
          this.flexibleLicencesModal.hide();
          if (result.data && result.data.trialSettings && result.data.plan) {
            this.updatedSubsc.trialSettings = result.data.trialSettings;
            this.updatedSubsc.plan = result.data.plan;
          }
          var order = await this.saveOrder(true, false, false, false, false);
          order.orderEmailSubjectAction = 'New Order- Update licenses';
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order?.id;
          var htmlBody =
            '<html><div>' +
            this.currentUser.user_name +
            ' created and order : updated licenses for ' +
            this.currentCustomer.domain +
            '</div>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receiversOnExec = [];
          receiversOnExec.push(...this.receiveEmailOnExecGrp);
          receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receiversOnExec,
            htmlBody,
            order.orderEmailSubjectAction
          );

          if (
            !Utilities.isNullOrEmpty(this.updateLicenceSubModel.customerEmail)
          ) {
            var customerEmailBody = '';
            var cc = this.currentUser.manager_email;
            if (!Utilities.isNullOrEmpty(this.uploadedFile)) {
              customerEmailBody = Utilities.generateCustomerEmailBody(
                this.currentCustomer.domain,
                this.currentUser.user_email
              );
              var fileName = this.uploadedFile.name ?? '';
              let fileReader = new FileReader();
              fileReader.readAsDataURL(this.uploadedFile);
              fileReader.onload = async (e) => {
                var tragRes: any = e.target.result;
                var fileData = tragRes.split('base64,')[1];
                Utilities.sendMailWithFile(
                  this.http,
                  fileData,
                  this.currentUser.user_email,
                  [this.updateLicenceSubModel.customerEmail],
                  [cc],
                  'Invoice',
                  customerEmailBody,
                  fileName
                );
              };
            } else {
              customerEmailBody =
                Utilities.generateCustomerEmailBodyWithoutFile(
                  this.currentCustomer.domain,
                  this.currentUser.user_email
                );
              await Utilities.sendMail(
                this.http,
                this.currentUser.user_email,
                [this.updateLicenceSubModel.customerEmail],
                customerEmailBody,
                'Invoice',
                [cc]
              );
            }
          }
        } else {
          console.log(result.errorMessage);
          alert(result.errorMessage);
        }
      } else {
        var order = await this.saveOrder(true, false, false, false, false);
        order.orderEmailSubjectAction = 'New Order- Update licenses';
        if (order) {
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order.id;
          var bodyText =
            "<html><h4 style='text-transform: capitalize;'>" +
            this.currentUser.user_name +
            ' updated the licences' +
            "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Old Licences</th><th style='border: 1px solid;border-collapse: collapse;'>New Licences</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
            "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.changeLicencesOldLic +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            newLicences +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.po_id +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            order.order_sale_invoice_nbr +
            "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
            this.currentCustomer.domain +
            '</td></tr></table>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receivers = this.currentUserRoleData.receiverEmail;
          if (
            this.gpmEmails.filter((o1) =>
              this.currentUserRoleData.receiverEmail.some((o2) => o1 === o2)
            ).length > 0
          )
            receivers.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receivers,
            bodyText,
            order.orderEmailSubjectAction
          );
          this.flexibleLicencesModal.hide();
          alert(
            'Order sent, The licences will be updated after the order is confirmed'
          );
        }
      }
    }
  }

  async saveOrder(
    isLicences: boolean,
    paidService: boolean,
    activate: boolean,
    suspend: boolean,
    transfer: boolean,
    updateRenewel: boolean = false
  ) {
    var order = new OrderModel();
    order.is_renewal_order = false;
    order.email_to_send_on_execution = !Utilities.isNullOrEmpty(
      this.updateLicenceSubModel.customerEmail
    )
      ? this.updateLicenceSubModel.customerEmail
      : '';
    order.invoice_file_rfid = null;
    order.gpm_approval_email = '';
    order.last_approval_email = this.currentUserRoleData.toExecute
      ? this.currentUser.user_email
      : '';
    order.approval2_email = this.currentUser.approve2
      ? this.currentUser.user_email
      : '';
    order.approval1_email =
      this.currentUser.approve1 ||
      (!this.currentUser.isJustSale &&
        this.currentUser.groups.find((x) => x.name == Group.Accounting))
        ? this.currentUser.user_email
        : '';
    order.rejecter_email = '';
    order.subscription_is_new = false;
    order.subscription_is_new = false;
    order.is_new_customer = 0;
    order.with_google_offer =
      this.updateLicenceSubModel.withGoogleOffer ?? false;
    order.google_offer_percentage =
      this.updateLicenceSubModel?.googleOfferPercentage ?? 0;
    order.with_customer_offer =
      this.updateLicenceSubModel.withCustomerOffer ?? false;
    order.customer_offer_percentage =
      this.updateLicenceSubModel?.customerOfferPercentage ?? 0;
    order.cost_price = this.updateLicenceSubModel.costPrice ?? 0;
    order.selling_price = this.updateLicenceSubModel.sellingPrice ?? 0;
    order.user_rfid = this.currentUser.usr_id + '';
    order.customer_rfid = this.currentCustomer.id;
    order.billing_method = this.updatedSubsc.billingMethod;
    order.subscription_rfid = this.updatedSubsc.subscriptionId;
    order.order_status = this.currentUserRoleData.orderStatus;
    order.order_action = '';
    if (
      this.updateLicenceSubModel &&
      this.updateLicenceSubModel.endDate &&
      this.updateLicenceSubModel.startDate
    ) {
      //TODO : SHOULD RESET IT FOR OTHER SERVICES
      order.commitment_expiry_date = Utilities.setDBDate(
        this.datePipe,
        this.updateLicenceSubModel.endDate
      );
      order.start_date = Utilities.setDBDate(
        this.datePipe,
        this.updateLicenceSubModel.startDate
      );
    } else {
      if (
        this.updatedSubsc.plan.isCommitmentPlan &&
        this.updatedSubsc.plan.commitmentInterval &&
        this.updatedSubsc.plan.commitmentInterval.endTime
      )
        order.commitment_expiry_date = this.datePipe.transform(
          new Date(Number(this.updatedSubsc.plan.commitmentInterval.endTime)),
          'yyyy-MM-dd hh:mm'
        );
      else order.commitment_expiry_date = null;
    }
    if (
      this.updatedSubsc.trialSettings.isInTrial &&
      this.updatedSubsc.trialSettings &&
      this.updatedSubsc.trialSettings.trialEndTime
    )
      order.trial_expiry_date = this.datePipe.transform(
        new Date(Number(this.updatedSubsc.trialSettings.trialEndTime)),
        'yyyy-MM-dd hh:mm'
      );
    else order.trial_expiry_date = null;
    order.po_id = !Utilities.isNullOrEmpty(
      this.updateLicenceSubModel.changeLicencesPO
    )
      ? this.updateLicenceSubModel.changeLicencesPO
      : !Utilities.isNullOrEmpty(this.updatedSubsc.purchaseOrderId)
      ? this.updatedSubsc.purchaseOrderId
      : null;
    order.order_sale_invoice_nbr = this.updateLicenceSubModel
      .orderSaleInvoiceNbr
      ? this.updateLicenceSubModel.orderSaleInvoiceNbr
      : null;
    order.old_sku_id = this.updatedSubsc.skuId;
    order.new_sku_id = this.updatedSubsc.skuId;
    order.old_plan_id = this.plans.find(
      (x) => x.code == this.updatedSubsc.plan.planName
    ).id;
    order.new_plan_rfid = order.old_plan_id;
    order.currency_rfid = this.currencyRfId ?? this.defaultCurrencyID + '';
    if (isLicences) {
      order.licences_is_updated = true;
      order.old_licence_number = this.changeLicencesOldLic;
      if (this.updatedSubsc.plan.planName.toUpperCase() == PlanValues.FLEXIBLE)
        order.new_licence_number = this.changeLicencesAdditionalLic;
      else
        order.new_licence_number =
          this.changeLicencesAdditionalLic + this.changeLicencesOldLic;
    } else {
      order.old_licence_number = this.updatedSubsc.seatsNbr;
      order.new_licence_number = this.updatedSubsc.seatsNbr;
    }
    order.update_renewel_settings = false;
    order.plan_is_updated = false;
    if (activate || suspend) {
      order.email_to_send_on_execution = '';
      order.subscription_status_is_updated = true;
      if (activate) order.new_subscription_status = Status.Active;
      else order.new_subscription_status = Status.Suspended;
    } else order.subscription_status_is_updated = false;
    if (paidService) {
      order.start_paid_service = true;
      order.email_to_send_on_execution = '';
    } else order.start_paid_service = false;
    if (transfer) {
      order.transfer_to_direct = true;
      order.email_to_send_on_execution = '';
    } else order.transfer_to_direct = false;
    if (updateRenewel) {
      order.update_renewel_settings = true;
      order.renewel_type = this.updatedSubsc.newRenewelType;
    }
    const formData: FormData = new FormData();
    if (
      this.uploadedFile &&
      this.uploadedFile != null &&
      !this.currentUserRoleData.toExecute
    )
      formData.append('file', this.uploadedFile);
    formData.append('model', JSON.stringify(order));
    var resp: any = await this.http
      .post(Endpoint.GLOBAL_URL + Endpoint.NEW_ORDER_FILE_NAME, formData)
      .toPromise();
    console.log(resp);
    if (resp && !resp.msg) {
      order.id = resp['id'];
      return order;
    } else {
      return null;
    }
  }

  resetPopups() {
    this.changeLicencesSubId = '';
    this.changeLicencesProductName = '';
    this.changeLicencesOldLic = 0;
    this.updateLicenceSubModel = {
      changeLicencesPO: '',
      orderSaleInvoiceNbr: '',
      customerEmail: '',
      googleOfferPercentage: null,
      withGoogleOffer: false,
      customerOfferPercentage: null,
      withCustomerOffer: false,
      sellingPrice: undefined,
      costPrice: undefined,
      startDate: undefined,
      endDate: undefined,
      margin: undefined,
    };
    this.changeLicencesAdditionalLic = null;
    this.licensesSuffixText = '';
    this.startDate = '';
    this.endDate = '';
    this.totalPrice = '0';
  }

  startPaidService(subsId: string) {
    var that = this;
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    if (this.updatedSubsc.plan.planName.toUpperCase() !== PlanValues.TRIAL) {
      alert('Start paid service only from a free trial subscription');
      return;
    }
    that.confirmDialogService.confirmThis(
      'Are you sure you want to start a paid service ?',
      async function () {
        if (that.currentUserRoleData.toExecute) {
          var headers;
          await Utilities.getToken(
            that.http,
            Endpoint.ADMIN_EMAIL,
            'https://www.googleapis.com/auth/apps.order',
            Endpoint.PRIVATE_KEY,
            Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
          ).then((token) => {
            headers = new HttpHeaders({
              Authorization: `Bearer ${token}`,
            });
          });
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            that.currentCustomer.id +
            '/subscriptions/' +
            that.updatedSubsc.subscriptionId +
            '/startPaidService';
          var result: RequestResultModel = await that.httpRequestService.post(
            url,
            null,
            headers
          );
          if (result.success) {
            console.log(result.data);
            if (result.data && result.data.trialSettings && result.data.plan) {
              that.updatedSubsc.trialSettings = result.data.trialSettings;
              that.updatedSubsc.plan = result.data.plan;
            }
            var order = await that.saveOrder(false, true, false, false, false);
            order.orderEmailSubjectAction = 'New Order- Start paid service';
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order?.id;
            var body =
              '<html><div>' +
              that.currentUser.user_name +
              ' created and order : start paid service for ' +
              that.currentCustomer.domain +
              '</div>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receiversOnExec = [];
            receiversOnExec.push(...that.receiveEmailOnExecGrp);
            receiversOnExec.push(...that.receiveEmailAfterCreationGrp);
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receiversOnExec,
              body,
              order.orderEmailSubjectAction
            );
          } else {
            console.log(result.errorMessage);
            alert(result.errorMessage);
          }
        } else {
          var order = await that.saveOrder(false, true, false, false, false);
          order.orderEmailSubjectAction = 'New Order- Start paid service';
          if (order) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order.id;
            var bodyText =
              '<html><h4>' +
              that.currentUser.user_name +
              ' start a paid service' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.subscriptionId +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.skuName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.plan.planName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.currentCustomer.domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receivers = that.currentUserRoleData.receiverEmail;
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receivers,
              bodyText,
              order.orderEmailSubjectAction
            );
            alert('Order sent');
          }
        }
      },
      function () {}
    );
  }

  statusEvent(subsId: string) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    var activate = true;
    if (this.updatedSubsc.status == Status.Active) activate = false;
    var that = this;
    that.confirmDialogService.confirmThis(
      'Are you sure you want to ' +
        (activate ? 'Activate' : 'Suspend') +
        ' this subscription?',
      async function () {
        if (that.currentUserRoleData.toExecute) {
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            that.currentCustomer.id +
            '/subscriptions/' +
            subsId +
            '/' +
            (activate ? 'activate' : 'suspend');
          var headers;
          await Utilities.getToken(
            that.http,
            Endpoint.ADMIN_EMAIL,
            'https://www.googleapis.com/auth/apps.order',
            Endpoint.PRIVATE_KEY,
            Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
          ).then((token) => {
            headers = new HttpHeaders({
              Authorization: `Bearer ${token}`,
            });
          });
          var result: RequestResultModel = await that.httpRequestService.post(
            url,
            null,
            headers
          );
          if (result.success) {
            console.log(result.data);
            if (result.data && result.data.trialSettings && result.data.plan) {
              that.updatedSubsc.trialSettings = result.data.trialSettings;
              that.updatedSubsc.plan = result.data.plan;
            }
            that.updatedSubsc.txtStatusEvent = activate
              ? 'Suspend'
              : 'Activate';
            var order = await that.saveOrder(
              false,
              false,
              activate,
              !activate,
              false
            );
            order.orderEmailSubjectAction =
              'New Order- ' +
              that.updatedSubsc.txtStatusEvent +
              ' subscription';
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order?.id;
            var body =
              '<html><div>' +
              that.currentUser.user_name +
              ' created and order : ' +
              that.updatedSubsc.txtStatusEvent +
              ' a service for ' +
              that.currentCustomer.domain +
              '</div>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receiversOnExec = [];
            receiversOnExec.push(...that.receiveEmailOnExecGrp);
            receiversOnExec.push(...that.receiveEmailAfterCreationGrp);
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receiversOnExec,
              body,
              order.orderEmailSubjectAction
            );
            window.location.reload();
          } else {
            console.log(result.errorMessage);
            alert(result.errorMessage);
          }
        } else {
          var order = await that.saveOrder(
            false,
            false,
            activate,
            !activate,
            false
          );
          order.orderEmailSubjectAction =
            'New Order- ' + that.updatedSubsc.txtStatusEvent + ' subscription';
          if (order) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order.id;
            var bodyText =
              '<html><h4>' +
              that.currentUser.user_name +
              ' ' +
              that.updatedSubsc.txtStatusEvent +
              ' a subscription ' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.subscriptionId +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.skuName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.plan.planName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.currentCustomer.domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receivers = that.currentUserRoleData.receiverEmail;
            if (
              that.gpmEmails.filter((o1) => receivers.some((o2) => o1 === o2))
                .length > 0
            )
              receivers.push(...that.receiveEmailAfterCreationGrp);
            if (receivers.length == 0)
              receivers.push(...that.receiveEmailAfterCreationGrp);
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receivers,
              bodyText,
              order.orderEmailSubjectAction
            );
            alert(
              'Order sent, status will be updated after the order is confirmed'
            );
          }
        }
      },
      function () {}
    );
  }

  transferToDirect(subsId: string) {
    var that = this;
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    that.confirmDialogService.confirmThis(
      'Are you sure you want to transfer it directly to google ?',
      async function () {
        if (that.currentUserRoleData.toExecute) {
          var headers;
          await Utilities.getToken(
            that.http,
            Endpoint.ADMIN_EMAIL,
            'https://www.googleapis.com/auth/apps.order',
            Endpoint.PRIVATE_KEY,
            Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
          ).then((token) => {
            headers = new HttpHeaders({
              Authorization: `Bearer ${token}`,
            });
          });
          var params = new HttpParams().set(
            'deletionType',
            'TRANSFER_TO_DIRECT'
          );
          var url =
            'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
            that.currentCustomer.id +
            '/subscriptions/' +
            subsId;
          var result = await that.httpRequestService.delete(
            url,
            headers,
            params
          );
          if (result.success) {
            console.log(result.data);
            var order = await that.saveOrder(false, false, false, false, true);
            order.orderEmailSubjectAction = 'New Order- Transfer to direct';
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order?.id;
            var body =
              '<html><div>' +
              that.currentUser.user_name +
              ' created and order : transfer ' +
              that.currentCustomer.domain +
              ' to direct ' +
              '</div>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receiversOnExec = [];
            receiversOnExec.push(...that.receiveEmailOnExecGrp);
            receiversOnExec.push(...that.receiveEmailAfterCreationGrp);
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receiversOnExec,
              body,
              order.orderEmailSubjectAction
            );
            that.router.navigate(['/customers']);
          } else {
            console.log(result.errorMessage);
            alert(result.errorMessage);
          }
        } else {
          var order = await that.saveOrder(false, false, false, false, true);
          order.orderEmailSubjectAction = 'New Order- Transfer to direct';
          if (order) {
            var link_url =
              Endpoint.URL_EMAIL +
              'orders/order-details?fromEmail=1&orderId=' +
              order.id;
            var bodyText =
              '<html><h4>' +
              that.currentUser.user_name +
              ' transfer a subscription directly to Google ' +
              "</h4><table style='width:100%;margin-top:15px;text-align:center;border: 1px solid;border-collapse: collapse;'><tr><th style='border: 1px solid;border-collapse: collapse;'>Subscription ID</th><th style='border: 1px solid;border-collapse: collapse;'>SKU</th><th style='border: 1px solid;border-collapse: collapse;'>Plan</th><th style='border: 1px solid;border-collapse: collapse;'>P.O#</th><th style='border: 1px solid;border-collapse: collapse;'>S.I Nbr</th><th style='border: 1px solid;border-collapse: collapse;'>Customer Domain</th>" +
              "<tr><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.subscriptionId +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.skuName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.updatedSubsc.plan.planName +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.po_id +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              order.order_sale_invoice_nbr +
              "</td><td style='border: 1px solid;border-collapse: collapse;'>" +
              that.currentCustomer.domain +
              '</td></tr></table>' +
              "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
              link_url +
              "'>here</a><label> to view the details</label></div></html>";
            var receivers = that.currentUserRoleData.receiverEmail;
            await Utilities.sendMail(
              that.http,
              that.currentUser.user_email,
              receivers,
              bodyText,
              order.orderEmailSubjectAction
            );
            alert('Order sent');
            that.router.navigate(['/customers']);
          }
        }
      },
      function () {}
    );
  }

  downUpGrade(subsId: string, isDowngrade: boolean) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.localStorage.save(Unicode.NEW_SUBSC, JSON.stringify(subs));
    this.localStorage.save(Unicode.FROM_SUBS_PAGE, '1');
    this.localStorage.save(Unicode.IS_DOWNGRADE, isDowngrade ? '1' : '0');
    this.router.navigate(['customers/new-subscription'], {
      queryParams: { isdowngrade: isDowngrade },
    });
  }

  updateRenewalType(subsId: string) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    if (!this.updatedSubsc.renewalSettings)
      this.updatedSubsc.renewalSettings = new RenewalSettings();
    if (
      this.updatedSubsc.renewalSettings &&
      this.updatedSubsc.renewalSettings.renewalType == 'AUTO_RENEW'
    )
      this.updatedSubsc.renewalSettings.renewalType = 'AUTO_RENEW_MONTHLY_PAY';
    if (
      this.updatedSubsc.renewalSettings &&
      this.updatedSubsc.renewalSettings.renewalType == 'RENEW_CURRENT_USERS'
    )
      this.updatedSubsc.renewalSettings.renewalType =
        'RENEW_CURRENT_USERS_MONTHLY_PAY';
    this.updatedSubsc.newRenewelType =
      this.updatedSubsc.renewalSettings.renewalType;
    this.renewalModal.show();
  }

  hideRenewalModal() {
    this.updatedSubsc.newRenewelType = null;
    this.renewalModal.hide();
  }

  async saveRenwal() {
    if (
      !Utilities.isNullOrEmpty(this.updatedSubsc.newRenewelType) &&
      this.updatedSubsc.newRenewelType.toLowerCase().trim() !=
        this.updatedSubsc.renewalSettings.renewalType.toLowerCase().trim()
    ) {
      if (this.currentUserRoleData.toExecute) {
        var headers;
        await Utilities.getToken(
          this.http,
          Endpoint.ADMIN_EMAIL,
          'https://www.googleapis.com/auth/apps.order',
          Endpoint.PRIVATE_KEY,
          Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
        ).then((token) => {
          headers = new HttpHeaders({
            Authorization: `Bearer ${token}`,
          });
        });
        var body = {
          renewalType: this.updatedSubsc.newRenewelType,
        };
        var url =
          'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
          this.updatedSubsc.customerId +
          '/subscriptions/' +
          this.updatedSubsc.subscriptionId +
          '/changeRenewalSettings';
        var result: RequestResultModel = await this.httpRequestService.post(
          url,
          body,
          headers
        );
        if (result.success) {
          console.log(result.data);
          if (result.data && result.data.trialSettings && result.data.plan) {
            this.updatedSubsc.trialSettings = result.data.trialSettings;
            this.updatedSubsc.plan = result.data.plan;
          }
          var order = await this.saveOrder(
            false,
            false,
            false,
            false,
            false,
            true
          );
          order.orderEmailSubjectAction = 'New Order- Update renewal settings';
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order?.id;
          var htmlBody =
            '<html><div>' +
            this.currentUser.user_name +
            ' created and order : changed renewal settings for ' +
            this.currentCustomer.domain +
            '</div>' +
            "<div style='margin-top:15px;'><label style=''>click </label><a href='" +
            link_url +
            "'>here</a><label> to view the details</label></div></html>";
          var receiversOnExec = [];
          receiversOnExec.push(...this.receiveEmailOnExecGrp);
          receiversOnExec.push(...this.receiveEmailAfterCreationGrp);
          await Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receiversOnExec,
            htmlBody,
            order.orderEmailSubjectAction
          );
          alert('Saved');
          this.renewalModal.hide();
          this.router.navigate(['/customers/customer-subscription-list'], {
            queryParams: {
              customerId: JSON.stringify(this.updatedSubsc.customerId),
            },
          });
        } else {
          console.log(result.errorMessage);
          alert(result.errorMessage);
        }
      } else {
        var order = await this.saveOrder(
          false,
          false,
          false,
          false,
          false,
          true
        );
        order.orderEmailSubjectAction = 'New Order- Update renewal settings';
        var link_url =
          Endpoint.URL_EMAIL +
          'orders/order-details?fromEmail=1&orderId=' +
          order.id;
        if (order) {
          var bodyText =
            'Hello, ' +
            this.currentUser.user_name +
            ' want to change the renwel settings: ' +
            this.updatedSubsc.renewalSettings.renewalType +
            ' to ' +
            this.updatedSubsc.newRenewelType +
            ' . Click the link to view the details. ' +
            link_url;
          var link_url =
            Endpoint.URL_EMAIL +
            'orders/order-details?fromEmail=1&orderId=' +
            order.id;
          var receivers = this.currentUserRoleData.receiverEmail;
          Utilities.sendMail(
            this.http,
            this.currentUser.user_email,
            receivers,
            bodyText,
            order.orderEmailSubjectAction
          );
          alert('Saved');
          this.renewalModal.hide();
          this.router.navigate(['/customers/customer-subscription-list'], {
            queryParams: {
              customerId: JSON.stringify(this.updatedSubsc.customerId),
            },
          });
        }
      }
    }
  }

  changeSellingPrie(event) {
    var value = event.target.value;
    this.totalPrice = value * this.changeLicencesAdditionalLic + '';
  }

  newRenewalOrder(subsId: string) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    this.updatedSubsc.oldPlanId = this.plans.find(
      (x) => x.code == this.updatedSubsc.plan.planName
    ).id;
    this.updatedSubsc.newPlanId = this.plans.find(
      (x) => x.code == PlanValues.ANNUAL
    ).id;
    this.updatedSubsc.plan.planName = PlanValues.ANNUAL;
    this.updatedSubsc.isRenewalOrder = true;
    this.localStorage.save(
      Unicode.NEW_SUBSC,
      JSON.stringify(this.updatedSubsc)
    );
    this.router.navigate(['customers/new-subscription/review-order']);
  }

  async transferSubsc(subsId: string) {
    var subs = this.allSubcs.find((x) => x.subscriptionId == subsId);
    this.updatedSubsc = subs;
    var headers;
    await Utilities.getToken(
      this.http,
      Endpoint.ADMIN_EMAIL,
      'https://www.googleapis.com/auth/apps.order',
      Endpoint.PRIVATE_KEY,
      Endpoint.SERVICE_ACCOUNT_CLIENT_EMAIL
    ).then((token) => {
      headers = new HttpHeaders({
        Authorization: `Bearer ${token}`,
      });
    });
    var subsc = {
      customerId: this.updatedSubsc.customerId,
      //  "subscriptionId": this.updatedSubsc.subscriptionId,
      skuId: this.updatedSubsc.skuId,
      seats: {
        maximumNumberOfSeats: this.updatedSubsc.seats?.maximumNumberOfSeats,
      }, //this.updatedSubsc.seats?.maximumNumberOfSeats // numberOfSeats
      // "customerDomain": this.updatedSubsc.customerDomain,
      plan: { planName: this.updatedSubsc.plan.planName },
    };
    var url =
      'https://reseller.googleapis.com/apps/reseller/v1/customers/' +
      this.currentCustomer.id +
      '/subscriptions?customerAuthToken=' +
      this.currentCustomer.auth_token;
    var result: RequestResultModel = await this.httpRequestService.post(
      url,
      subsc,
      headers
    );
    if (result.success) {
      console.log(result.data);
    } else {
      console.log(result.errorMessage);
    }
  }
  calculatePrices(checkValidation: boolean = true) {
    if (
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.startDate) ||
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.endDate)
    ) {
      if (checkValidation) alert('Choose From/To Date');
      return;
    }
    var from_ = new Date(
      this.updateLicenceSubModel.startDate.year,
      this.updateLicenceSubModel.startDate.month - 1,
      this.updateLicenceSubModel.startDate.day
    );
    var to_ = new Date(
      this.updateLicenceSubModel.endDate.year,
      this.updateLicenceSubModel.endDate.month - 1,
      this.updateLicenceSubModel.endDate.day
    );
    var days = Utilities.calculateDiffDays(from_, to_);
    var planID = this.plans.find(
      (x) => x.code == this.updatedSubsc.plan.planName
    )?.id;
    var priceObj = this.prices.find(
      (x) => x.sku_rfid == this.updatedSubsc.skuId && x.plan_rfid == planID
    );
    if (priceObj) {
      var price = priceObj.price / 30;
      var costPrice: number = price * Unicode.COST_MARGIN * days;
      var sellingPrice: number = price * days;

      //OFFERS
      if (
        this.updateLicenceSubModel.withGoogleOffer &&
        this.updateLicenceSubModel.googleOfferPercentage &&
        this.updateLicenceSubModel.googleOfferPercentage != 0
      ) {
        var offer =
          (100 - this.updateLicenceSubModel.googleOfferPercentage) / 100;
        costPrice *= offer;
      }
      if (
        this.updateLicenceSubModel.withCustomerOffer &&
        this.updateLicenceSubModel.customerOfferPercentage &&
        this.updateLicenceSubModel.customerOfferPercentage != 0
      ) {
        var offer =
          (100 - this.updateLicenceSubModel.customerOfferPercentage) / 100;
        sellingPrice *= offer;
      }
      //LICENSES
      var licencesNbr = 0;
      if (
        this.updatedSubsc.plan?.planName?.toUpperCase() ==
          PlanValues.FLEXIBLE &&
        this.changeLicencesAdditionalLic > this.changeLicencesOldLic
      ) {
        licencesNbr =
          this.changeLicencesAdditionalLic - this.changeLicencesOldLic;
      } else if (
        this.updatedSubsc.plan?.planName?.toUpperCase() !== PlanValues.FLEXIBLE
      ) {
        licencesNbr = this.changeLicencesAdditionalLic;
      }

      if (licencesNbr > 0) {
        sellingPrice = sellingPrice * licencesNbr;
        costPrice = costPrice * licencesNbr;

        this.updateLicenceSubModel.sellingPrice = Number.parseFloat(
          sellingPrice.toFixed(2)
        );
        this.updateLicenceSubModel.costPrice = Number.parseFloat(
          costPrice.toFixed(2)
        );
      }
    }
  }

  checkPriceFileds(): boolean {
    if (
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.startDate) ||
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.endDate)
    ) {
      alert('Choose From/To Date');
      return false;
    }
    if (
      this.updateLicenceSubModel.withGoogleOffer == true &&
      (!this.updateLicenceSubModel.googleOfferPercentage ||
        this.updateLicenceSubModel.googleOfferPercentage == 0)
    ) {
      alert('Add Google Offer Percentage');
      return false;
    }
    if (
      this.updateLicenceSubModel.withCustomerOffer == true &&
      (!this.updateLicenceSubModel.customerOfferPercentage ||
        this.updateLicenceSubModel.customerOfferPercentage == 0)
    ) {
      alert('Add Customer Offer Percentage');
      return false;
    }
    if (
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.changeLicencesPO) ||
      Utilities.isNullOrEmpty(this.updateLicenceSubModel.orderSaleInvoiceNbr)
    ) {
      alert('P.O.# and SI Nbr. are required');
      return false;
    }
    return true;
  }
}
