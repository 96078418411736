<div class="container-fluid">
  <div class="dash_header row">
    <div class="" style="padding: 0 7px 0 0">
      <div class="dash_card">
        <img
          src="../../../assets/orders-big-icon(dashboard-page).png"
          width="23"
          id="ordr_total"
        />
        <label class="dash_header_title"
          >&nbsp;&nbsp;{{ totalOrders }} Orders</label
        >
      </div>
    </div>
    <div class="" style="padding: 0 7px 0 0">
      <div class="dash_card">
        <img
          src="../../../assets/customers-big-icon(dashboard-page).png"
          width="23"
        />
        <label class="dash_header_title"
          >&nbsp;&nbsp;{{ totalCustomers }} Customers</label
        >
      </div>
    </div>
    <div class="p-0">
      <div class="dash_card">
        <img
          src="../../../assets/users-big-icon(dashboard-page).png"
          width="23"
        />
        <label class="dash_header_title"
          >&nbsp;&nbsp;{{ totalUsers }} Users</label
        >
      </div>
    </div>
  </div>

  <div class="row m-t-15">
    <div class="col-lg-6 col-md-12 col-12 p-0">
      <div class="div_chart h-100">
        <label class="cust_title f-productSans">Orders Status</label>
        <div class="row col-12 m-t-40 justify-content-center">
          <div class="chart-wrapper col-12 row" id="status_pie">
            <canvas
              baseChart
              [data]="statuschartData"
              [labels]="statuschartLabels"
              id="pieChartOrders"
              [chartType]="statusLineChartType"
              width="400"
              height="400"
              [options]="statuschartOptions"
              [colors]="pieChartColors"
              (chartClick)="chartClicked($event)"
              [legend]="lineChartLegend"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-12 device_chart">
      <div class="div_chart h-100">
        <label class="cust_title f-productSans">Customers</label>
        <div class="row col-12 m-t-40 justify-content-center">
          <div class="chart-wrapper col-12 row" id="status_pie">
            <canvas
              baseChart
              [data]="customerschartData"
              [labels]="customerschartLabels"
              id="pieChart2Custs"
              [chartType]="statusLineChartType"
              [options]="customersChartOptions"
              [colors]="customerchartColors"
              [legend]="lineChartLegend"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row m-t-15">
    <div class="col-12 device_lines">
      <div class="div_chart">
        <label class="cust_title f-productSans">Created Orders</label
        ><span
          *ngIf="totalOrdersChart2 && totalOrdersChart2 != ''"
          class="sp_total"
          >{{ totalOrdersChart2 }}</span
        >
        <div class="row col-12 div_dates">
          <div class="dash_date_p">
            <span (click)="fdate.toggle()" class="dash_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              [(ngModel)]="fromSelectedDate"
              ngbDatepicker
              placeholder="Date(from)"
              #fdate="ngbDatepicker"
              (ngModelChange)="selectDate()"
              class="my-dp"
            />
          </div>
          <div class="dash_date_p">
            <span (click)="tdate.toggle()" class="dash_date_p_span">
              <img src="../../../assets/calendar icon.png" width="20" />
            </span>
            <input
              name="dp"
              placeholder="To"
              [(ngModel)]="toSelectedDate"
              ngbDatepicker
              placeholder="Date(to)"
              #tdate="ngbDatepicker"
              (ngModelChange)="selectDate()"
              class="my-dp"
            />
          </div>

          <div
            class="col-lg-3 col-md-4 col-sm-6 col-12 m-t-15 p-0"
            style="display: flex; margin: 22px 10px 20px 0 !important"
          >
            <input
              style="width: 20px; margin: 0 10px 0 10px; height: 30px"
              type="checkbox"
              [(ngModel)]="onlyExecutedOrders"
              (change)="getOrders()"
            />
            <label class="m-t-5">Only executed Orders</label>
          </div>
        </div>

        <div class="charts row m-t-20">
          <div class="chart-wrapper col-12" id="email">
            <canvas
              baseChart
              id="lineChart"
              width="400"
              height="400"
              [datasets]="chartData"
              [labels]="chartLabels"
              [colors]="chartColors"
              [options]="chartOptions"
              [legend]="lineChartLegend"
              [chartType]="orderLineChartType"
            >
            </canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-t-15 div_chart">
    <label class="page_title">Summary per SKU</label>
    <div class="row col-12 div_dates">
      <div class="dash_date_p">
        <span (click)="ssfdate.toggle()" class="dash_date_p_span">
          <img src="../../../assets/calendar icon.png" width="20" />
        </span>
        <input
          name="dp"
          [(ngModel)]="summaryFromSelectedDate"
          ngbDatepicker
          placeholder="from"
          #ssfdate="ngbDatepicker"
          (ngModelChange)="summarySelectDate()"
          class="my-dp"
        />
      </div>
      <div class="dash_date_p">
        <span (click)="sstdate.toggle()" class="dash_date_p_span">
          <img src="../../../assets/calendar icon.png" width="20" />
        </span>
        <input
          name="dp"
          placeholder="To"
          [(ngModel)]="summaryToSelectedDate"
          ngbDatepicker
          #sstdate="ngbDatepicker"
          (ngModelChange)="summarySelectDate()"
          class="my-dp"
        />
      </div>
    </div>

    <table class="w-100" id="sku_sm_table">
      <tr>
        <th>S</th>
        <th colspan="4">SKU</th>
        <th>License #</th>
        <th>Deleted during period</th>
        <th>Adding during period</th>
        <th>Total as of today</th>
      </tr>
      <ng-container *ngFor="let ssku of summarySkuData; let index = index">
        <tr>
          <td>{{ index + 1 }}</td>
          <td colspan="4">{{ ssku.sku_name }}</td>
          <td>{{ ssku.old }}</td>
          <td>{{ ssku.deletion_total }}</td>
          <td>{{ ssku.addition_total }}</td>
          <td>{{ ssku.new }}</td>
        </tr>
      </ng-container>
      <tr
        *ngIf="summarySkuData.length == 0"
        style="
          font-weight: bold;
          border: 1px solid #e6eef2;
          font-size: 16px;
          text-align: center;
        "
      >
        No Data
      </tr>
    </table>

    <label class="page_title m-t-30">Summary per Customer</label>
    <div
      class="div_table m-0"
      style="overflow-x: scroll; margin-top: 5px !important"
    >
      <div>
          <input
            type="text"
            class="form-control"
            placeholder="Search By Customer"
            [(ngModel)]="summaryCustomerSearch"
            aria-label="Username"
            aria-describedby="basic-addon1"
            id="nav-search-input"
            (keyup.enter)="search($event)"
          />
        </div>
      <ngx-datatable
        #table
        id="sum-cust-table"
        class="bootstrap row col-12 w-100"
        [rows]="GetData"
        [columnMode]="'force'"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [scrollbarH]="true"
        [scrollbarV]="false"
        [externalPaging]="true"
        [count]="GetPage.totalElements"
        [offset]="GetPage.pageNumber"
        [limit]="GetPage.size"
        (page)="pageChanged($event)"
      >
        <!-- ngx-resize-watcher -->
        <!-- <ngx-datatable
          #table
          id="sum-cust-table"
          class="bootstrap"
          [columnMode]="'force'"
          [rows]="GetData"
          [rowHeight]="40"
          [headerHeight]="38"
          [footerHeight]="0"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [externalPaging]="true"
          [count]="GetPage.totalElements"
          [offset]="GetPage.pageNumber"
          [limit]="GetPage.size"
          (page)="pageChanged($event)"
        > -->
        <ngx-datatable-column
          prop="execution_date"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="true"
          class=""
          name="Execution Date"
          headerClass="col-2"
          cellClass="col-2"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.execution_date | date }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="domain"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="Domain"
          headerClass="col-lg-3 col-md-3"
          cellClass="col-lg-3 col-md-3"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.domain }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="sku_id"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="SKU"
          headerClass="col-lg-3 col-md-3"
          cellClass="col-lg-3 col-md-3"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.product_name }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="licenses"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="License#"
          headerClass="col-2"
          cellClass="col-2"
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.licenses }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="cost_price"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="Cost"
          headerClass="col-1 "
          cellClass="col-1 "
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.cost_price }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="total"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="Total"
          headerClass="col-1 "
          cellClass="col-1 "
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.total }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="execution_date"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="Start Date"
          headerClass="col-2 "
          cellClass="col-2 "
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.execution_date | date }}
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column
          prop="end_date"
          [sortable]="true"
          [draggable]="true"
          [canAutoResize]="false"
          class=""
          name="End Date"
          headerClass="col-2 "
          cellClass="col-2 "
        >
          <ng-template let-row="row" ngx-datatable-cell-template>
            {{ row.end_date | date }}
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</div>
